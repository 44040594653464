import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import api from 'services/api'

import SimulationIcon from 'assets/images/visit_yellow.svg'
import ButtonGroup from 'components/ButtonGroup'
import {FullScreenLoader} from 'components/fullScreenLoader'
import PageHeader from 'components/pageHeader'
import {getBillRequest} from 'store/modules/bill/action'
import {requestSchedule} from 'store/modules/schedule/action'

import ScheduleCalendar from './partials/calendar'
import ListScheduly from './partials/listScheduly'
import {StyledEconomySimulationContent} from './styles'
import {toast} from 'react-toastify'

function Technicalvisit() {
  const {formatMessage} = useIntl()
  const _pageTitle_ = formatMessage({id: 'technicalvisit.header.title'})
  const _pageDescription_ = formatMessage({id: 'technicalvisit.header.description'})

  const dispatch = useDispatch()
  const scheduling = useSelector(state => state.schedule)

  const [isScheduling, setIsScheduling] = useState(true)

  const [isSubscription, setIsSubscription] = useState(true)

  useEffect(() => {
    api
      .get('/portal/customers')
      .then(({data}) => {
        const {subscription_lending} = data

        setIsSubscription(subscription_lending === 'SUBSCRIPTION')
      })
      .catch(err => console.error('error getting user data: ', err))
  }, [])

  useEffect(() => {
    dispatch(requestSchedule())
    dispatch(getBillRequest())
  }, [dispatch])

  useEffect(() => {
    if (!scheduling.pending) setIsScheduling(false)
  }, [isScheduling, scheduling])

  const handleSubmit = form => {
    form.phone = form.phone.replace(/[^\d]/g, '')
    form.cep = form.cep.replace(/[^\d]/g, '')

    api
      .post('/portal/customers/scheduling', form)
      .then(() => toast.success('Visita técnica marcada com sucesso!'))
      .catch(err => console.error('Error scheduling technical visit: ', err))
      .finally(() => {
        dispatch(requestSchedule())
        dispatch(getBillRequest())
      })
  }

  const selectComponent = () => {
    if (scheduling.schedule.id) return <ListScheduly scheduling={scheduling.schedule} />

    return <ScheduleCalendar onSubmit={handleSubmit} />
  }

  return (
    <>
      {scheduling.pending && <FullScreenLoader />}
      <StyledEconomySimulationContent>
        <PageHeader title={_pageTitle_} description={_pageDescription_} icon={SimulationIcon} />

        {selectComponent()}

        {isSubscription ? (
          <ButtonGroup
            status={scheduling.schedule ? scheduling.schedule.status !== 'ACCOMPLISHED_AND_APPROVED' : true}
            back="bill"
            next="plan-signature"
            nextTitle="Escolha seu plano"
          />
        ) : (
          ''
        )}
      </StyledEconomySimulationContent>
    </>
  )
}

export default Technicalvisit

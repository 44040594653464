import {theme} from 'assets/styles/theme'
import {Field, Form} from 'formik'
import styled from 'styled-components'

export const FormWrapper = styled(Form)`
  width: ${theme.width.full};
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const HeaderText = styled.h4`
  font-family: Roboto;
  font-weight: ${theme.fontWeight.normal};
  font-size: ${theme.fontSize.xl};
  line-height: ${theme.lineHeight.tight};
`

export const RadiosWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 100px;
  gap: ${theme.spacing[5]};
`

export const LabelWrapper = styled.div`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  width: ${theme.width.full};
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: ${theme.spacing[2]};
  border: 1px solid ${theme.colors.solar_21.gray_light};
  background-color: ${theme.colors.white};

  &:hover {
    & > label > input {
      border: 1px solid ${theme.colors.solar_21.regular};
    }
  }
`

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const StyledSpan = styled.span`
  margin-left: ${theme.spacing[2]};
  font-family: Roboto;
  color: ${theme.colors.solar_21.gray_dark};
  font-weight: ${theme.fontWeight.normal};
  font-size: ${theme.fontSize.lg};
  line-height: ${theme.lineHeight.tight};
`

export const StyledInput = styled(Field)`
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: ${theme.colors.solar_21.gray_dark};
  width: ${theme.spacing[6]};
  height: ${theme.spacing[6]};
  border: 1px solid ${theme.colors.solar_21.gray_light};
  border-radius: ${theme.width['1/2']};
  cursor: pointer;

  &:checked {
    border: ${theme.spacing[2]} solid ${theme.colors.solar_21.regular} !important;
  }

  &:checked:disabled {
    border: ${theme.spacing[2]} solid ${theme.colors.solar_21.gray_light} !important;
    cursor: default;
  }

  &:not(:checked):disabled {
    border: 1px solid ${theme.colors.solar_21.gray_light} !important;
    cursor: default;
  }
`

import React from 'react'

import ItauIcon from 'assets/images/sales/itau-bank.svg'
import SantanderIcon from 'assets/images/sales/santander-bank.svg'
import BVIcon from 'assets/images/sales/bv-bank.svg'

import BankInfoCard from '../BankInfoCard/BankInfoCard'
import * as S from '../styles'

const BanksOptions = () => {
  return (
    <S.BanksWrapper>
      <BankInfoCard hidden icon={ItauIcon} iconText="ícone Itaú" tax={0} parcelNumber={72} parcel={0} />
      <BankInfoCard
        hidden
        icon={SantanderIcon}
        iconText="ícone Santander"
        tax={0}
        parcelNumber={60}
        parcel={0}
      />
      <BankInfoCard
        hidden
        icon={BVIcon}
        iconText="ícone BV Financeira"
        tax={0}
        parcelNumber={84}
        parcel={0}
      />
    </S.BanksWrapper>
  )
}

export default BanksOptions

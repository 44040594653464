import React from 'react'
import {useIntl} from 'react-intl'

import CheckImg from 'assets/images/check-mark.svg'
import PropTypes from 'prop-types'

import {
  ButtonSignature,
  Container,
  DivButton,
  DivImage,
  DivTitle,
  Image,
  Title,
  SignedContainer,
} from './styles'

export default function SignatureCard({title, image, onClick, disabled, isSigned}) {
  const {formatMessage} = useIntl()

  const _buttonTitle_ = formatMessage({id: 'signature.button.visualization'})
  const isDisabled = isSigned || disabled
  return (
    <Container>
      <DivTitle>
        <Title>{title}</Title>
      </DivTitle>
      <DivImage>
        {isSigned && <SignedContainer src={CheckImg} alt="Contract ok" />}
        <Image src={image} />
      </DivImage>
      <DivButton>
        <ButtonSignature disabled={isDisabled} onClick={onClick}>
          {isSigned ? 'Assinado' : _buttonTitle_}
        </ButtonSignature>
      </DivButton>
    </Container>
  )
}

SignatureCard.defaultProps = {
  title: 'No Title',
  image: null,
  onClick: () => {},
  disabled: 'true',
  isSigned: false,
}

SignatureCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isSigned: PropTypes.bool,
}

import React, {FC, MouseEventHandler, ReactNode} from 'react'
import * as S from './styles'

type ButtonProps = {
  text: ReactNode
  disabled?: boolean
  color?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  type?: "button" | "reset" | "submit"
}

const Button: FC<ButtonProps> = ({text, disabled = false, color = undefined, onClick = undefined, type = 'button'}) => {
  return (
    <S.Button type={type} color={color} disabled={disabled} onClick={onClick}>
      <S.Text>{text}</S.Text>
    </S.Button>
  )
}

export default Button

import styled from 'styled-components'

export const Container = styled.div`
  background-color: #ffd500;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Title = styled.span`
  font-size: 25px;
  letter-spacing: 14.4px;
  display: block;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    font-size: 18px;
    letter-spacing: 8px;
  }
`

export const SubTitle = styled.span`
  font-size: 19px;
  // letter-spacing: 3px;
  display: block;
  margin-bottom: 10px;
  letter-spacing: 4.75px;
`

export const SubTitle2 = styled.span`
  font-size: 19px;
  // letter-spacing: 3px;
  display: block;
  margin-bottom: 38px;
  letter-spacing: 4.75px;
`

export const Seconds = styled.span`
  font-size: 60px;
  display: block;
  font-weight: bold;
`

export const Logo = styled.img`
  margin-bottom: 64px;
  @media (max-width: 767px) {
    width: 80%;
  }
`

import React, {useState, useEffect} from 'react'
import {BsArrowLeft, BsArrowRight} from 'react-icons/bs'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'

import PropTypes from 'prop-types'

import history from 'services/history'
import lang from 'services/language'
import {setIdPlanSelectedRequest} from 'store/modules/booking-payment/action'
import {setClientPlanId} from 'store/modules/plan/action'

import ImageModal from '../../../../assets/images/select_plan.svg'
import Button from '../../../../components/ui/button'
import ModalContent from '../../../../components/ui/modal'
import {
  StyledButtonGroup,
  StyledflexGroup,
  ItemFlex,
  StyledMinTitle,
  StyledTextPrice,
  StyledDivider,
  StyledImage,
} from './styles'

const ConfirmModal = ({open, onClose, planValue, planSave, planTitle, planId}) => {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()
  const _back_ = formatMessage({id: 'common.button.back'})
  // const _confirm_ = formatMessage({id: 'common.button.confirm'})
  const _titleModal_ = formatMessage({id: 'plans.content.modal.title'})
  const _planValue_ = formatMessage({id: 'plans.content.modal.plan.value'})
  const _planSave_ = formatMessage({id: 'plans.content.modal.plan.save'})
  const [selectedPlanId, setSelectedPlanId] = useState()

  const handleNextPage = e => {
    const dataPlan = e.target.value
    dispatch(setIdPlanSelectedRequest(dataPlan))
    dispatch(setClientPlanId(dataPlan))
    history.push(`/${lang}/booking-payment`)
  }

  useEffect(() => {
    setSelectedPlanId(planId)
  }, [planId])

  return (
    <>
      {open ? (
        <ModalContent onClose={onClose} width="535px">
          <StyledImage src={ImageModal} alt="" />
          <h1>
            {_titleModal_} {planTitle}
          </h1>
          <StyledflexGroup>
            <ItemFlex>
              <StyledMinTitle>{_planValue_}</StyledMinTitle>
              <StyledTextPrice>
                <small>R$</small> {planValue} <small>/mês</small>
              </StyledTextPrice>
            </ItemFlex>
            <StyledDivider />
            <ItemFlex>
              <StyledMinTitle>{_planSave_}</StyledMinTitle>
              <StyledTextPrice>
                <small>R$</small> {planSave} <small>/mês</small>
              </StyledTextPrice>
            </ItemFlex>
          </StyledflexGroup>
          <StyledButtonGroup>
            <Button startIcon={<BsArrowLeft size={26} />} onClick={onClose} color="gray" variant="outlined">
              {_back_}
            </Button>
            <Button endIcon={<BsArrowRight size={26} />} onClick={handleNextPage} value={selectedPlanId}>
              Siga para assinatura
            </Button>
          </StyledButtonGroup>
        </ModalContent>
      ) : null}
    </>
  )
}

ConfirmModal.defaultProps = {
  open: null,
  onClose: () => {},
  planValue: '',
  planSave: '',
  planTitle: '',
  planId: '',
}

ConfirmModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  planValue: PropTypes.number,
  planSave: PropTypes.number,
  planTitle: PropTypes.string,
  planId: PropTypes.string,
}

export default ConfirmModal

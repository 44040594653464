import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import PropTypes from 'prop-types'

import RobotIcon from '../../assets/images/aurora/happy/happy-01.svg'
import NotificatitonIcon from '../../assets/images/notification.svg'
import {notificationRequest, notificationDealsRequest} from '../../store/modules/common/action'
import Notification from '../ui/Notification'
import {
  Container,
  TitleGroup,
  TitleGroupText,
  Title,
  Subtitle,
  Notifications,
  NotificationIcon,
  Badge,
  NotificatitonList,
} from './styles'

export default function PageHeader({title, description, icon}) {
  const dispatch = useDispatch()
  const [openNotification, setOpenNotification] = useState(false)
  const valid = useRef(true)

  useEffect(() => {
    if (valid.current) {
      dispatch(notificationRequest())
      valid.current = false
    }
  })

  const notifications = useSelector(state => state.common.notifications)

  const handleOnClose = notificationId => {
    dispatch(notificationDealsRequest(notificationId))
    setTimeout(() => {
      dispatch(notificationRequest())
    }, 700)
  }

  const existNotification = !!(notifications && notifications.items && notifications.items.length > 0)

  const listNotification = existNotification
    ? notifications.items.map(notification => (
        <Notification textRequest={notification.message} image={RobotIcon} notificationId={notification.id} />
      ))
    : null

  const handleChange = e => {
    if (!openNotification) {
      setOpenNotification(true)
    } else {
      setOpenNotification(false)
      if (e.target.id) handleOnClose(e.target.id)
    }
  }

  return (
    <Container>
      <TitleGroup>
        {icon && <img src={icon} alt={title} />}
        <TitleGroupText>
          <Title>{title}</Title>
          <Subtitle>{description}</Subtitle>
        </TitleGroupText>
      </TitleGroup>

      <Notifications onClick={handleChange}>
        {existNotification && <Badge>{notifications.items.length}</Badge>}
        <NotificationIcon src={NotificatitonIcon} />
        {openNotification && <NotificatitonList onClick={handleChange}>{listNotification}</NotificatitonList>}
      </Notifications>
    </Container>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

import styled from 'styled-components'

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  margin-top: 40px;

  & button {
    padding: 10px 20px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    justify-content: center;
    font-size: 0.7em;

    & button {
      margin: 0 auto;
    }
  }
`

export const ResponsiveTable = styled.div`
  display: block;
  overflow-x: auto;
  width: 100%;
`
export const Economy = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 0.95em;
  font-weight: 300;
  text-align: center;
  display: ${props => (props.selected ? 'block' : 'none')};
  transition: all 0.8s;
  margin-bottom: 5px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    left: 70px;
    margin-left: 0px;
    font-size: 0.9em;
  }
`

export const TrHead = styled.tr`
  .price {
    font-size: 1.6em;
    font-weight: 300;
    margin: 30px 0 -20px 30px;

    & small {
      font-weight: 300;
      font-size: 0.5em;
    }

    @media only screen and (max-width: 768px) {
      font-size: 1em;
      margin: 20px 0 -10px 0px;
    }
  }
`

export const Th = styled.th`
  text-align: center;
  font-size: 1.15em;
  background-color: ${props => (props.selected ? '#ffd500' : 'white')};
  color: #010202;
  font-weight: 700;

  .column-selected {
    background: ${props => (props.selected ? '#f2f4f7' : 'white')};
  }
`

export const SmartSave = styled.small`
  font-weight: 300;
  text-transform: uppercase;
  font-size: 0.6em;
  letter-spacing: 2.37px;
  margin-left: 20px;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    letter-spacing: 1px;
  }
`
export const Price = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  margin: 30px 0 -20px 10px;

  & small {
    top: -4px;
    position: relative;
    font-weight: 300;
    font-size: 0.5em;
  }

  @media only screen and (max-width: 768px) {
    font-size: 1em;
    margin: 20px 0 -10px 0px;
  }
`

export const Tbody = styled.tbody`
  tr {
    height: 60px;
  }
`

export const Tr = styled.tr`
  box-shadow: 0 1px 0px 0px #f2f4f7;
  &:last-child:not(.head) {
    box-shadow: none;
  }
`
export const TdTitle = styled.td`
  font-family: 'Roboto', sans-serif;
  max-width: 240px;
  width: 240px;
  text-align: center;
  font-weight: ${props => (props.selected ? 700 : 500)};
  color: ${props => (props.selected ? '#000000' : '#8e8e8e')};
`

export const Td = styled.td`
  font-family: 'Roboto', sans-serif;
  max-width: 240px;
  width: 240px;
  text-align: center;
  font-size: 1.15em;
  font-weight: 200;
  color: ${props => (props.selected ? '#000000' : '#8e8e8e')};
  background: ${props => (props.selected ? '#f2f4f7' : '#ffffff')};
`

export const TdEconomy = styled.td`
  font-family: 'Roboto' sans-serif;
  font-family: inherit;
  text-align: center;
  font-size: 1.15em;
  font-weight: 800;
  color: #000000;
  background: ${props => (props.selected ? '#ffd500' : '#ffffff')};
`

export const ImageLogo = styled.img`
  width: 20px;
  margin-bottom: -3px;
`

export const Thead = styled.thead`
  tr {
    height: 155px;
    color: #010202;
    box-shadow: 0 1px 0px 0px #f2f4f7;
    &:first-child {
      height: 15px;
      box-shadow: none;
    }
  }
`

export const SectionTable = styled.table`
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  color: #8e8e8e;
`

/* eslint-disable react/jsx-no-bind */
import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import Solar_21_black from 'assets/images/logo.svg'
import Button from 'components/ui/button'
import history from 'services/history'
import lang from 'services/language'
import {signUpRequest, signUpRequestPj} from 'store/modules/auth/action'

import CreateAccountFormPj from '../forms/b2b/createAccountForm'
import CreateAccountForm from '../forms/b2c/createAccountForm'
import * as S from './styles'
import LogoText from '../logo-text'

type TemporaryState = {
  auth: {
    phone: string
    pending: boolean
  }
  initial: {
    isBusiness: boolean
  }
}

type ValuesType = {
  bill: any
  cnpj?: string
  email: string
  razaoSocial?: string
  state: string
  unit_type: string
  city: string
  name: string
  coupon?: string
  terms: boolean
  isPartnership?: string
}

function CreateAccount() {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()

  const fullNumber = useSelector((state: TemporaryState) => state.auth.phone)
  const submitting = useSelector((state: TemporaryState) => state.auth.pending)
  const isBusiness = useSelector((state: TemporaryState) => state.initial.isBusiness)

  const _title_ = formatMessage({id: 'signup.access.title'})
  const _subtitle_ = formatMessage({id: 'signup.access.subtitle'})
  const _description_ = formatMessage({id: 'signup.access.description'})
  const buttonText = formatMessage({id: 'common.button.access'})
  const _leftTitle_ = formatMessage({id: 'signup.right.title'})
  const _leftSubtitle_ = formatMessage({id: 'signup.right.subtitle'})

  const handleSubmit = useCallback(
    (values: ValuesType) => {
      if (isBusiness) {
        const document_type = 'CNPJ'
        const {bill, cnpj, email, razaoSocial, state, unit_type} = values
        // eslint-disable-next-line radix
        const city = parseInt(values.city)

        dispatch(
          signUpRequestPj(bill, city, cnpj, email, razaoSocial, state, unit_type, document_type, fullNumber)
        )
        return
      }

      const {name, email, state, unit_type, bill, coupon, terms, isPartnership} = values
      // eslint-disable-next-line radix
      const city = parseInt(values.city)
      const hasPartnership = isPartnership === 'true'

      if (!terms) return
      dispatch(signUpRequest(name, email, state, city, unit_type, bill, fullNumber, coupon, hasPartnership))
    },
    [isBusiness, dispatch, fullNumber]
  )

  return (
    <S.Wrapper>
      <S.ImageBackground>
        <S.StickyAccess>
          <LogoText logo={Solar_21_black} alt="Logo Solar21 branco" color="white" />
          <S.Title color="white">{_leftTitle_}</S.Title>
          <S.Subtitle color="white">{_leftSubtitle_}</S.Subtitle>
          <Button
            width="250px"
            onClick={() => history.push(`/${lang}/login`)}
            startIcon={undefined}
            endIcon={undefined}
            radius={undefined}
            selected={undefined}
          >
            {buttonText}
          </Button>
        </S.StickyAccess>
      </S.ImageBackground>
      <S.FormSide>
        <S.FormWrapper>
          <LogoText logo={Solar_21_black} alt="Logo Solar21 preto" />
          <S.Title>{_title_}</S.Title>
          <S.Subtitle>{_subtitle_}</S.Subtitle>
          <S.TextContent>{_description_}</S.TextContent>
          {isBusiness ? (
            <CreateAccountFormPj onSubmit={handleSubmit} submitting={submitting} />
          ) : (
            <CreateAccountForm onSubmit={handleSubmit} submitting={submitting} />
          )}
        </S.FormWrapper>
      </S.FormSide>
    </S.Wrapper>
  )
}

export default CreateAccount

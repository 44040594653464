import {useFormikContext} from 'formik'
import React, {FC, useCallback, useEffect, useRef} from 'react'
import * as S from './styles'

type DoubleRadioInputProps = {
  header: string
  onChange: Function
  trueLabel?: string
  trueValue?: string
  falseLabel?: string
  falseValue?: string
  disabled?: boolean
}

const DoubleRadioInput: FC<DoubleRadioInputProps> = ({
  header,
  onChange,
  trueLabel = 'Sim',
  trueValue = 'true',
  falseLabel = 'Não',
  falseValue = 'false',
  disabled = false,
}) => {
  const {values} = useFormikContext()

  const trueInputRef = useRef<HTMLLabelElement>(null)
  const falseInputRef = useRef<HTMLLabelElement>(null)

  const setInputClick = useCallback(
    (state: string) => {
      if (state === trueValue) trueInputRef.current?.click()
      else falseInputRef.current?.click()
    },
    [trueValue]
  )

  useEffect(() => {
    onChange(values)
  }, [values, onChange])

  return (
    <S.FormWrapper>
      <S.HeaderText>{header}</S.HeaderText>
      <S.RadiosWrapper role="group" aria-labelledby="owner-radio-group">
        <S.LabelWrapper onClick={() => setInputClick(trueValue)}>
          <S.StyledLabel ref={trueInputRef}>
            <S.StyledInput type="radio" name="owner" value={trueValue} disabled={disabled} />
            <S.StyledSpan>{trueLabel}</S.StyledSpan>
          </S.StyledLabel>
        </S.LabelWrapper>
        <S.LabelWrapper onClick={() => setInputClick(falseValue)}>
          <S.StyledLabel ref={falseInputRef}>
            <S.StyledInput type="radio" name="owner" value={falseValue} disabled={disabled} />
            <S.StyledSpan>{falseLabel}</S.StyledSpan>
          </S.StyledLabel>
        </S.LabelWrapper>
      </S.RadiosWrapper>
    </S.FormWrapper>
  )
}

export default DoubleRadioInput

import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'
import reactotronSaga from 'reactotron-redux-saga'

const enabledReactron = process.env.REACT_APP_ENABLE_REACTRON || 'true'

if (process.env.NODE_ENV === 'development' && enabledReactron.toLocaleLowerCase() !== 'false') {
  const tron = Reactotron.configure().use(reactotronRedux()).use(reactotronSaga()).connect()

  tron.clear()

  console.tron = tron
}

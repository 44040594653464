import PageHeader from 'components/pageHeader'
import React, {useCallback, useEffect, useState} from 'react'
import {find, isEmpty, last} from 'lodash'

import PlanIcon from 'assets/images/plan_signature_yellow.svg'
import {profileRequest} from 'store/modules/profile/action'

import * as S from './styles'

import {Step, Stepper, StepLabel, StepIconProps} from '@material-ui/core'
import IconStepActive from './partials/iconStepActive.svg'
import HelpModal from './partials/help-modal/index'
import DropZone from 'pages/booking-payment/partials/upload'
import Button from 'components/ui/button'
import {useDispatch, useSelector} from 'react-redux'
import {steps, StepsType} from './partials/data/steps'
import {customerSuccessMessageRequest, customerSuccessUploadRequest} from 'store/modules/customer/action'
import {faqRequest} from 'store/modules/faq/action'

const ProjectStatusPage = () => {
  const dispatch = useDispatch()
  //@ts-ignore
  const profile = useSelector(state => state.profile?.profileValues)
  //@ts-ignore
  const messages = useSelector(state => state.customer?.messages)
  //@ts-ignore
  const faqs = useSelector(state => state.faq?.faqs)

  const [open, setOpen] = useState<boolean>(false)
  const [stepper, setStepper] = useState<'horizontal' | 'vertical'>('horizontal')
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [isUploadAvailable, setIsUploadAvailable] = useState<boolean>(false)

  useEffect(() => {
    const {customer_success: cs} = profile

    if (cs?.status)
      setCurrentStep(() => {
        const filteredStep = find(steps, step => step.status === cs.status)
        return filteredStep?.id ?? 0
      })
    if (cs?.id) dispatch(customerSuccessMessageRequest(cs?.id))
    setIsUploadAvailable(cs?.upload_available ?? false)

    return () => {
      setCurrentStep(0)
      setIsUploadAvailable(false)
    }
  }, [dispatch, profile])

  useEffect(() => {
    const handleResize = () => setStepper(window.innerWidth <= 800 ? 'vertical' : 'horizontal')

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    dispatch(profileRequest())
    dispatch(faqRequest())
  }, [dispatch])

  const CustomStepIcon = ({active, completed}: StepIconProps) => {
    const inactive = active ? <img src={IconStepActive} alt="Etapa atual" /> : <S.FutureStep />

    return completed ? <S.StepChecked /> : inactive
  }

  const renderMessages = useCallback(() => {
    if (isEmpty(messages)) return <S.Message color="bold">Nenhuma mensagem no momento</S.Message>

    const renderedMessages = messages.map((msg: {id: string; message: string}) => {
      const bold = msg === last(messages) ? undefined : 'bold'
      return (
        <S.Message key={msg.id} color={bold}>
          {msg.message}
        </S.Message>
      )
    })

    return renderedMessages.reverse()
  }, [messages])

  const handleUpload = useCallback(
    (files: any) => {
      if (files.file.length > 0 && profile.id) {
        const formData = new FormData()
        formData.append('file', files.file[0])

        dispatch(customerSuccessUploadRequest(profile.id, formData))
      }
    },
    [dispatch, profile]
  )

  return (
    <S.MainProjectStatus>
      <PageHeader
        title={'Status do Projeto'}
        description={'Acompanhe seu processo de instalação da Solar21 na sua casa'}
        icon={PlanIcon}
      />
      <Stepper alternativeLabel={stepper === 'horizontal'} activeStep={currentStep} orientation={stepper}>
        {steps.map((st: StepsType) => (
          <Step key={st.id}>
            <StepLabel StepIconComponent={CustomStepIcon}>{st.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <S.Subtitle>Mural de Status</S.Subtitle>
      <S.MessageContainer>{renderMessages()}</S.MessageContainer>
      {isUploadAvailable && (
        <S.DropzoneContainer>
          <DropZone insideText="Arraste e solte seu(s) arquivo(s) aqui" type="card" onChange={handleUpload} />
        </S.DropzoneContainer>
      )}
      <S.ContainerBtnNeedContact>
        <Button endIcon="" startIcon="" radius="" width="" selected={false} onClick={() => setOpen(true)}>
          Preciso de atendimento
        </Button>
      </S.ContainerBtnNeedContact>

      <HelpModal open={open} onClose={() => setOpen(false)} questions={faqs} />
    </S.MainProjectStatus>
  )
}

export default ProjectStatusPage

import React from 'react'

import {LinearProgress, makeStyles} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#8f8e73',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#fdf500',
    },
  },
}))

const Solar21LinearProgress = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  )
}

export default Solar21LinearProgress

export const abbreviateValue = (number, fixedPlaces = 2) => {
  let decPlaces = 0
  /* eslint-disable */
  if (number < 1000) return parseFloat(number).toFixed(fixedPlaces)
  decPlaces = Math.pow(10, decPlaces)

  const abbrev = [' mil', ' milhões', ' bilhões', ' trilhões', ' quatrilhões']

  for (let i = abbrev.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3)

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces

      if (number == 1000 && i < abbrev.length - 1) {
        number = 1
        i++
      }

      if (i === 1) {
        number += number == 1 ? ' milhão' : abbrev[i]
      } else if (i === 2) {
        number += number == 1 ? ' bilhão' : abbrev[i]
      } else if (i === 3) {
        number += number == 1 ? ' trilhão' : abbrev[i]
      } else if (i === 4) {
        number += number == 1 ? ' quatrilhão' : abbrev[i]
      } else {
        number += abbrev[i]
        break
      }
    }
  }

  return number
  /* eslint-enable */
}

export const trimFilename = (name, chars, subst) =>
  name.replace(new RegExp('(^[^\\.]{' + chars + '})[^\\.]+'), '$1' + subst)

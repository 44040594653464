import React from 'react'
import {useIntl} from 'react-intl'

import ReCAPTCHA from "react-google-recaptcha";

import {useFormik, FormikProvider} from 'formik'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import theme from '../../../../assets/styles/theme'
import Button from '../../../../components/ui/button'
import {Input} from '../../../../components/ui/Fields'
import history from '../../../../services/history'
import lang from '../../../../services/language'

const Form = styled.form`
  width: 100%;
  @media only screen and (max-width: 520px) {
    margin-bottom: 1.2rem;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 25% 1fr;
  column-gap: 0.8rem;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`
const StyledSpan = styled.span`
  color: ${theme('colors.solar_21.gray')};
  font-size: 18px;
  font-weight: 300;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
`

export const MessageError = styled.div`
  position: relative;
  top: -15px;
  margin-bottom: 15px;
  text-align: right;

  > small {
    font-size: 1rem;
    color: red;
  }
`

export default function LoginForm({onSubmit, submitting, failure}) {
  const {formatMessage} = useIntl()

  const _countryCode_ = formatMessage({id: 'common.form.countryCode'})

  const _cellphone_ = formatMessage({id: 'common.form.cellphone'})

  const _verificationMessage_ = formatMessage({id: 'common.form.verificationMessage'})
  // const _send_ = formatMessage({id: 'common.button.send'})
  const _submitting_ = formatMessage({id: 'common.button.submitting'})
  const _back_ = formatMessage({id: 'common.button.signup'})

  const formikbag = useFormik({
    initialValues: {
      country_code: '55',
      phone: '',
    },
    onSubmit,
  })

  return (
    <FormikProvider value={formikbag}>
      <Form>
        <StyledGrid>
          <Input
            type="tel"
            label={_countryCode_}
            name="country_code"
            width="120px"
            icon="brazil"
            maskNumber={[/[1-9]/, /\d/]}
            placeholder="+55"
          />
          <Input
            type="tel"
            label={_cellphone_}
            name="phone"
            placeholder="(XX) 90000-0000"
            maskNumber={[
              '(',
              /[1-9]/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
          />
        </StyledGrid>

        {failure && (
          <MessageError>
            <small>Você ainda não está cadastrado. Cadastre-se já!</small>
          </MessageError>
        )}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={(value) => formikbag.setFieldValue("recaptcha", value)}
          name="recaptcha"
        />
        <StyledSpan>{_verificationMessage_}</StyledSpan>
        <Button width="100%" type="submit" onClick={formikbag.handleSubmit} style={{marginTop: '1.2rem'}}>
          {submitting ? _submitting_ : 'Receber código por SMS'}
        </Button>
        <Button
          width="100%"
          variant="link"
          style={{marginTop: '1.2rem'}}
          radius="25px"
          disabled={!!submitting}
          onClick={() => {
            history.push(`/${lang}/sign-up`)
          }}
        >
          {_back_}
        </Button>
      </Form>
    </FormikProvider>
  )
}

LoginForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
  failure: false,
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  failure: PropTypes.bool,
}

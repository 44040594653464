import styled from 'styled-components'

import Button from 'components/ui/button'

export const DivTitle = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
export const Title = styled.span`
  font-size: 25px;
  opacity: 1;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`

export const DivButton = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 30px;
    justify-content: center;
  }
`
export const DivText = styled.div`
  overflow-y: scroll;
  width: 100%;
  background: #f2f4f7 0% 0% no-repeat padding-box;
  border-left: 2px solid #cfcfcf;
  border-right: 2px solid #cfcfcf;
  border-top: 2px solid #cfcfcf;
  opacity: 1;
  padding: inherit;

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`
export const ButtonModal = styled(Button)`
  @media only screen and (max-width: 768px) {
    font-size: 13px;
    height: 56px;
  }
`

export const DivTextBottom = styled.div`
  width: 100%;
  background: #f2f4f7 0% 0% no-repeat;
  height: 96px;
  border-left: 2px solid #cfcfcf;
  border-right: 2px solid #cfcfcf;
  border-bottom: 2px solid #cfcfcf;
`

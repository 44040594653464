import React from 'react'

import {Form} from '@unform/web'
import Input from 'components/Form/Input'
import Select from 'components/Form/Select'
import PropTypes from 'prop-types'

import Button from '../../../../components/ui/button'
import ModalContent from '../../../../components/ui/modal'
import {StyledButtonGroup, StyledTitle, StyledInputs} from './styles'
import {
  CheckboxContainer,
  CheckboxGrid,
  TitleCheckBox,
  UseTerm,
} from 'pages/signup/partials/forms/b2b/createAccountForm'
import Checkbox from 'components/ui/Checkbox'

const ConsumerModal = ({open, onClose, onSubmit, isHolder, isLoading}) => {
  const [termsAccepted, setTermsAccepted] = React.useState(false)

  const marital_status = [
    {label: 'Solteiro', value: 'solteiro'},
    {label: 'Casado', value: 'casado'},
    {label: 'Viúvo', value: 'viuvo'},
    {label: 'Divorciado', value: 'divorciado'},
    {label: 'União estável', value: 'uniao estavel'},
  ]

  return (
    <>
      {open && (
        <ModalContent onClose={onClose} width="535px" loading={isLoading}>
          <StyledTitle>
            Dados do titular da Unidade Consumidora <br />
            <small>Para continuar preencha os dados do titular da Unidade Consumidora</small>
          </StyledTitle>

          <StyledInputs>
            <Form onSubmit={consumer => onSubmit(consumer, isHolder)}>
              {isHolder ? (
                <>
                  <Input name="occupation" label="Profissão" placeholder="Profissão" />
                  <Select
                    name="marital_status"
                    placeholder="Selecione"
                    label="Estado Civil"
                    options={marital_status}
                  />
                  <Input
                    name="date_of_birth"
                    type="tel"
                    label="Data de nascimento"
                    placeholder="dd/mm/yyyy"
                    mask="99/99/9999"
                  />
                </>
              ) : (
                <>
                  <Input
                    name="name"
                    type="text"
                    label="Nome Completo"
                    placeholder="Digite seu nome completo"
                    mask={false}
                  />
                  <Input
                    name="document_value"
                    type="tel"
                    label="CPF"
                    placeholder="Digite seu CPF"
                    mask="999.999.999-99"
                  />
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Digite seu email"
                    mask={false}
                  />
                  <Input
                    name="phone"
                    type="tel"
                    label="Telefone"
                    placeholder="(99) 90000-0000"
                    mask="(99) 99999-9999"
                  />
                  <Input
                    name="date_of_birth"
                    type="tel"
                    label="Data de nascimento"
                    placeholder="dd/mm/yyyy"
                    mask="99/99/9999"
                  />
                  <Input name="occupation" label="Profissão" placeholder="Profissão" />
                  <Select
                    name="marital_status"
                    placeholder="Selecione"
                    label="Estado Civil"
                    options={marital_status}
                  />
                </>
              )}
              {/* Botão para aceitar os termos */}
              <CheckboxGrid>
                <CheckboxContainer>
                  <Checkbox
                    name="terms"
                    checked={termsAccepted}
                    onClick={() => setTermsAccepted(!termsAccepted)}
                  />
                  <TitleCheckBox>
                    Declaro que li e estou de acordo com os{' '}
                    <UseTerm href={process.env.REACT_APP_PRIVACY_POLICY} target="blank">
                      Termos e Condições Gerais
                    </UseTerm>
                  </TitleCheckBox>
                </CheckboxContainer>
              </CheckboxGrid>

              <StyledButtonGroup>
                <Button type="button" onClick={onClose} color="gray" variant="outlined" selected={false}>
                  Cancelar
                </Button>
                <Button disabled={!termsAccepted} loading={isLoading} type="submit" height="25px">
                  Continuar
                </Button>
              </StyledButtonGroup>
            </Form>
          </StyledInputs>
        </ModalContent>
      )}
    </>
  )
}

ConsumerModal.defaultProps = {
  open: null,
  onClose: () => {},
  onSubmit: () => {},
  isHolder: false,
  isLoading: false,
}

ConsumerModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isHolder: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default ConsumerModal

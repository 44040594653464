export default {
  COMMON_STATES_REQUEST: 'COMMON_STATES_REQUEST',
  COMMON_STATES_SUCCESS: 'COMMON_STATES_SUCCESS',
  COMMON_STATES_FAILURE: 'COMMON_STATES_FAILURE',
  COMMON_CITIES_REQUEST: 'COMMON_CITIES_REQUEST',
  COMMON_CITIES_SUCCESS: 'COMMON_CITIES_SUCCESS',
  COMMON_CITIES_FAILURE: 'COMMON_CITIES_FAILURE',
  COMMON_NOTIFICATION_REQUEST: 'COMMON_NOTIFICATION_REQUEST',
  COMMON_NOTIFICATION_SUCCESS: 'COMMON_NOTIFICATION_SUCCESS',
  COMMON_NOTIFICATION_FAILURE: 'COMMON_NOTIFICATION_FAILURE',
  COMMON_NOTIFICATIONDEALS_REQUEST: 'COMMON_NOTIFICATIONDEALS_REQUEST',
  COMMON_NOTIFICATIONDEALS_SUCCESS: 'COMMON_NOTIFICATIONDEALS_SUCCESS',
  COMMON_NOTIFICATIONDEALS_FAILURE: 'COMMON_NOTIFICATIONDEALS_FAILURE',

  AUTH_SIGN_IN_REQUEST: 'AUTH_SIGN_IN_REQUEST',
  AUTH_SIGN_IN_SUCCESS: 'AUTH_SIGN_IN_SUCCESS',
  AUTH_SIGN_IN_FAILURE: 'AUTH_SIGN_IN_FAILURE',
  AUTH_ACCESS_CODE_REQUEST: 'AUTH_ACCESS_CODE_REQUEST',
  AUTH_ACCESS_CODE_SUCCESS: 'AUTH_ACCESS_CODE_SUCCESS',
  AUTH_ACCESS_CODE_FAILURE: 'AUTH_ACCESS_CODE_FAILURE',
  AUTH_SIGN_UP_REQUEST: 'AUTH_SIGN_UP_REQUEST',
  AUTH_SIGN_UP_SUCCESS: 'AUTH_SIGN_UP_SUCCESS',
  AUTH_SIGN_UP_FAILURE: 'AUTH_SIGN_UP_FAILURE',
  AUTH_SIGN_UP_PHONE_REQUEST: 'AUTH_SIGN_UP_PHONE_REQUEST',
  AUTH_SIGN_UP_PHONE_SUCCESS: 'AUTH_SIGN_UP_PHONE_SUCCESS',
  AUTH_SIGN_UP_PHONE_FAILURE: 'AUTH_SIGN_UP_PHONE_FAILURE',
  AUTH_SIGN_UP_ACCESS_CODE_REQUEST: 'AUTH_SIGN_UP_ACCESS_CODE_REQUEST',
  AUTH_SIGN_UP_ACCESS_CODE_SUCCESS: 'AUTH_SIGN_UP_ACCESS_CODE_SUCCESS',
  AUTH_SIGN_UP_ACCESS_CODE_FAILURE: 'AUTH_SIGN_UP_ACCESS_CODE_FAILURE',
  AUTH_RESET_VARIABLES: 'AUTH_RESET_VARIABLES',
  AUTH_LOGOUT_CLIENT: 'AUTH_LOGOUT_CLIENT',
  AUTH_LOGOUT_CLIENT_SUCCESS: 'AUTH_LOGOUT_CLIENT_SUCCESS',
  AUTH_LOGOUT_CLIENT_FAILURE: 'AUTH_LOGOUT_CLIENT_FAILURE',
  AUTH_SIGN_UP_REQUEST_PJ: 'AUTH_SIGN_UP_REQUEST_PJ',
  AUTH_SIGN_UP_REQUEST_PJ_SUCCESS: 'AUTH_SIGN_UP_REQUEST_PJ_SUCCESS',
  AUTH_SIGN_UP_REQUEST_PJ_FAILURE: 'AUTH_SIGN_UP_REQUEST_PJ_FAILURE',
  SET_AUTH_SIGN_IN_FAILURE: 'SET_AUTH_SIGN_IN_FAILURE',

  B2C_PLAN_SIMULATION_REQUEST: 'B2C_PLAN_SIMULATION_REQUEST',
  B2C_PLAN_SIMULATION_SUCCESS: 'B2C_PLAN_SIMULATION_SUCCESS',
  B2C_PLAN_SIMULATION_FAILURE: 'B2C_PLAN_SIMULATION_FAILURE',

  B2C_PLAN_SIGNATURE_REQUEST: 'B2C_PLAN_SIGNATURE_REQUEST',
  B2C_PLAN_SIGNATURE_SUCCESS: 'B2C_PLAN_SIGNATURE_SUCCESS',
  B2C_PLAN_SIGNATURE_FAILURE: 'B2C_PLAN_SIGNATURE_FAILURE',

  B2C_ALL_PLAN_REQUEST: 'B2C_ALL_PLAN_REQUEST',
  B2C_ALL_PLAN_SUCCESS: 'B2C_ALL_PLAN_SUCCESS',
  B2C_ALL_PLAN_FAILURE: 'B2C_ALL_PLAN_FAILURE',
  B2C_PLAN_REQUEST: 'B2C_PLAN_REQUEST',
  B2C_PLAN_SUCCESS: 'B2C_PLAN_SUCCESS',
  B2C_PLAN_FAILURE: 'B2C_PLAN_FAILURE',

  B2C_PLAN_CONFIRMATION_REQUEST: 'B2C_PLAN_CONFIRMATION_REQUEST',
  B2C_PLAN_CONFIRMATION_SUCCESS: 'B2C_PLAN_CONFIRMATION_SUCCESS',
  B2C_PLAN_CONFIRMATION_FAILURE: 'B2C_PLAN_CONFIRMATION_FAILURE',

  B2C_PLAN_CHECKOUT_REQUEST: 'B2C_PLAN_CHECKOUT_REQUEST',
  B2C_PLAN_CHECKOUT_SUCCESS: 'B2C_PLAN_CHECKOUT_SUCCESS',
  B2C_PLAN_CHECKOUT_FAILURE: 'B2C_PLAN_CHECKOUT_FAILURE',

  B2C_VERIFY_CHECKOUT_REQUEST: 'B2C_VERIFY_CHECKOUT_REQUEST',
  B2C_VERIFY_CHECKOUT_SUCCESS: 'B2C_VERIFY_CHECKOUT_SUCCESS',
  B2C_VERIFY_CHECKOUT_FAILURE: 'B2C_VERIFY_CHECKOUT_FAILURE',

  B2C_VERIFY_ARCHIVE_REQUEST: 'B2C_VERIFY_ARCHIVE_REQUEST',
  B2C_VERIFY_ARCHIVE_SUCCESS: 'B2C_VERIFY_ARCHIVE_SUCCESS',
  B2C_VERIFY_ARCHIVE_FAILURE: 'B2C_VERIFY_ARCHIVE_FAILURE',

  SET_PLAN_SELECTED_PLAN: 'SET_PLAN_SELECTED_PLAN',
  SET_SELECTED_PLAN_MODAL_REQUEST: 'SET_SELECTED_PLAN_MODAL_REQUEST',

  PLAN_DOC_SEND_REQUEST: 'PLAN_DOC_SEND_REQUEST',
  PLAN_DOC_SEND_SUCCESS: 'PLAN_DOC_SEND_SUCCESS',
  PLAN_DOC_SEND_FAILURE: 'PLAN_DOC_SEND_FAILURE',
  PLAN_DOC_RECEIVE_REQUEST: 'PLAN_DOC_RECEIVE_REQUEST',
  PLAN_DOC_RECEIVE_SUCCESS: 'PLAN_DOC_RECEIVE_SUCCESS',
  PLAN_DOC_RECEIVE_FAILURE: 'PLAN_DOC_RECEIVE_FAILURE',

  PLAN_HOLDER_REQUEST: 'PLAN_HOLDER_REQUEST',
  PLAN_HOLDER_SUCCESS: 'PLAN_HOLDER_SUCCESS',
  PLAN_HOLDER_FAILURE: 'PLAN_HOLDER_FAILURE',

  GET_USER_PLAN: 'GET_USER_PLAN',
  GET_USER_PLAN_SUCCESS: 'GET_USER_PLAN_SUCCESS',
  GET_USER_PLAN_FAILURE: 'GET_USER_PLAN_FAILURE',

  BILL_ACCOUNT_SEND_REQUEST: 'BILL_ACCOUNT_SEND_REQUEST',
  BILL_ACCOUNT_SEND_SUCCESS: 'BILL_ACCOUNT_SEND_SUCCESS',
  BILL_ACCOUNT_SEND_FAILURE: 'BILL_ACCOUNT_SEND_FAILURE',

  UPDATE_TECHNICAL_VISIT_SCHEDULE_FORM_DATA: 'UPDATE_TECHNICAL_VISIT_SCHEDULE_FORM_DATA',
  TECHNICAL_VISIT_SCHEDULE_REQUEST: 'TECHNICAL_VISIT_SCHEDULE_REQUEST',
  TECHNICAL_VISIT_SCHEDULE_REQUEST_SUCCESS: 'TECHNICAL_VISIT_SCHEDULE_REQUEST_SUCCESS',
  TECHNICAL_VISIT_SCHEDULE_REQUEST_FAILURE: 'TECHNICAL_VISIT_SCHEDULE_REQUEST_FAILURE',
  TECHNICAL_VISIT_SCHEDULE_FORM_POST: 'TECHNICAL_VISIT_SCHEDULE_FORM_POST',
  TECHNICAL_VISIT_SCHEDULE_FORM_POST_SUCCESS: 'TECHNICAL_VISIT_SCHEDULE_FORM_POST_SUCCESS',
  TECHNICAL_VISIT_SCHEDULE_FORM_POST_FAILURE: 'TECHNICAL_VISIT_SCHEDULE_FORM_POST_FAILURE',

  MENU_NOTIFICATIONCHECK_REQUEST: 'MENU_NOTIFICATIONCHECK_REQUEST',
  MENU_NOTIFICATIONCHECK_SUCCESS: 'MENU_NOTIFICATIONCHECK_SUCCESS',
  MENU_NOTIFICATIONCHECK_FAILURE: 'MENU_NOTIFICATIONCHECK_FAILURE',

  BILL_ACCOUNT_RECEIVE_REQUEST: 'BILL_ACCOUNT_RECEIVE_REQUEST',
  BILL_ACCOUNT_RECEIVE_SUCCESS: 'BILL_ACCOUNT_RECEIVE_SUCCESS',
  BILL_ACCOUNT_RECEIVE_FAILURE: 'BILL_ACCOUNT_RECEIVE_FAILURE',
  BILL_OWNER_REQUEST: 'BILL_OWNER_REQUEST',
  BILL_OWNER_SUCCESS: 'BILL_OWNER_SUCCESS',
  BILL_OWNER_FAILURE: 'BILL_OWNER_FAILURE',
  BILL_INFO_REQUEST: 'BILL_INFO_REQUEST',
  BILL_INFO_SUCCESS: 'BILL_INFO_SUCCESS',
  BILL_INFO_FAILURE: 'BILL_INFO_FAILURE',

  SIGNATURE_ACCEPT_REQUEST: 'SIGNATURE_ACCEPT_REQUEST',
  SIGNATURE_SIGNED_ADHESION_REQUEST: 'SIGNATURE_SIGNED_ADHESION_REQUEST',
  SIGNATURE_SIGNED_PROCURATION_REQUEST: 'SIGNATURE_SIGNED_PROCURATION_REQUEST',
  SIGNATURE_READ_REQUEST: 'SIGNATURE_READ_REQUEST',
  SIGNATURE_CONTRACT_REQUEST: 'SIGNATURE_CONTRACT_REQUEST',
  SIGNATURE_CONTRACT_SUCCESS: 'SIGNATURE_CONTRACT_SUCCESS',
  SIGNATURE_CONTRACT_FAILURE: 'SIGNATURE_CONTRACT_FAILURE',
  SIGNATURE_TERMS_REQUEST: 'SIGNATURE_TERMS_REQUEST',
  SIGNATURE_TERMS_SUCCESS: 'SIGNATURE_TERMS_SUCCESS',
  SIGNATURE_TERMS_FAILURE: 'SIGNATURE_TERMS_FAILURE',
  SIGNATURE_SIGNED_REQUEST: 'SIGNATURE_SIGNED_REQUEST',
  SIGNATURE_SIGNED_SUCCESS: 'SIGNATURE_SIGNED_SUCCESS',
  SIGNATURE_SIGNED_FAILURE: 'SIGNATURE_SIGNED_FAILURE',
  CONSUMER_INFORMATIONS_MODAL_REQUEST: 'CONSUMER_INFORMATIONS_MODAL_REQUEST',
  CONSUMER_INFORMATIONS_MODAL_SUCCESS: 'CONSUMER_INFORMATIONS_MODAL_SUCCESS',
  CONSUMER_INFORMATIONS_MODAL_FAILURE: 'CONSUMER_INFORMATIONS_MODAL_FAILURE',
  CONSUMER_SET_SHOW_CONSUMER_MODAL: 'CONSUMER_SET_SHOW_CONSUMER_MODAL',
  INITIAL_BUSINESS_REQUEST: 'INITIAL_BUSINESS_REQUEST',

  PROFILE_REQUEST: 'PROFILE_REQUEST',
  PROFILE_SUCCESS: 'PROFILE_SUCCESS',
  PROFILE_FAILURE: 'PROFILE_FAILURE',
  PROFILE_REQUEST_UPDATE: 'PROFILE_REQUEST_UPDATE',
  PROFILE_REQUEST_UPDATE_SUCCESS: 'PROFILE_REQUEST_UPDATE_SUCCESS',
  PROFILE_REQUEST_UPDATE_FAILURE: 'PROFILE_REQUEST_UPDATE_FAILURE',

  USER_REQUEST: 'USER_REQUEST',
  USER_REQUEST_SUCCESS: 'USER_REQUEST_SUCCESS',
  USER_REQUEST_FAILURE: 'USER_REQUEST_FAILURE',

  GET_PLAN_SIMULATED_REQUEST: 'GET_PLAN_SIMULATED_REQUEST',
  GET_PLAN_SIMULATED_SUCCESS: 'GET_PLAN_SIMULATED_SUCCESS',
  GET_PLAN_SIMULATED_FAILURE: 'GET_PLAN_SIMULATED_FAILURE',

  CREATE_PLAN_SIMULATED_REQUEST: 'CREATE_PLAN_SIMULATED_REQUEST',
  CREATE_PLAN_SIMULATED_SUCCESS: 'CREATE_PLAN_SIMULATED_SUCCESS',
  CREATE_PLAN_SIMULATED_FAILURE: 'CREATE_PLAN_SIMULATED_FAILURE',

  SET_IS_BOOKING_PAYMENT: 'SET_IS_BOOKING_PAYMENT',

  SET_CLIENT_PLAN_ID: 'SET_CLIENT_PLAN_ID',

  CUSTOMER_SUCCESS_MESSAGE_REQUEST: 'CUSTOMER_SUCCESS_MESSAGE_REQUEST',
  CUSTOMER_SUCCESS_MESSAGE_SUCCESS: 'CUSTOMER_SUCCESS_MESSAGE_SUCCESS',
  CUSTOMER_SUCCESS_MESSAGE_FAILURE: 'CUSTOMER_SUCCESS_MESSAGE_FAILURE',

  CUSTOMER_SUCCESS_UPLOAD_REQUEST: 'CUSTOMER_SUCCESS_UPLOAD_REQUEST',
  CUSTOMER_SUCCESS_UPLOAD_SUCCESS: 'CUSTOMER_SUCCESS_UPLOAD_SUCCESS',
  CUSTOMER_SUCCESS_UPLOAD_FAILURE: 'CUSTOMER_SUCCESS_UPLOAD_FAILURE',

  CUSTOMER_SUCCESS_TABLE_REQUEST: 'CUSTOMER_SUCCESS_TABLE_REQUEST',
  CUSTOMER_SUCCESS_TABLE_SUCCESS: 'CUSTOMER_SUCCESS_TABLE_SUCCESS',
  CUSTOMER_SUCCESS_TABLE_FAILURE: 'CUSTOMER_SUCCESS_TABLE_FAILURE',

  PARTNERSHIP_REQUEST: 'PARTNERSHIP_REQUEST',
  PARTNERSHIP_SUCCESS: 'PARTNERSHIP_SUCCESS',
  PARTNERSHIP_FAILURE: 'PARTNERSHIP_FAILURE',

  EMPLOYEE_CARD_SEND_REQUEST: 'EMPLOYEE_CARD_SEND_REQUEST',
  EMPLOYEE_CARD_SEND_SUCCESS: 'EMPLOYEE_CARD_SEND_SUCCESS',
  EMPLOYEE_CARD_SEND_FAILURE: 'EMPLOYEE_CARD_SEND_FAILURE',

  FAQ_REQUEST: 'FAQ_REQUEST',
  FAQ_SUCCESS: 'FAQ_SUCCESS',
  FAQ_FAILURE: 'FAQ_FAILURE',

  GET_FREE_MONTH_REQUEST: 'GET_FREE_MONTH_REQUEST',
  GET_FREE_MONTH_SUCCESS: 'GET_FREE_MONTH_SUCCESS',
  GET_FREE_MONTH_FAILURE: 'GET_FREE_MONTH_FAILURE',
}

import React from 'react'

import ImageModal from 'assets/images/archive_send.svg'
import Button from 'components/ui/button'
import ModalContent from 'components/ui/modal'
import PropTypes from 'prop-types'

import {StyledButtonGroup, StyledImage, StyledTitle} from './styles'

const SuccessUploadModal = ({open, onClose, Title, subTitle, onConfirm}) => {
  return (
    <>
      {open ? (
        <ModalContent onClose={onClose} width="535px">
          <StyledImage src={ImageModal} alt="" />
          <StyledTitle>
            {Title} <br />
            <small>{subTitle}</small>
          </StyledTitle>
          <StyledButtonGroup>
            <Button onClick={onConfirm}>Assine seu contrato</Button>
          </StyledButtonGroup>
        </ModalContent>
      ) : null}
    </>
  )
}

SuccessUploadModal.defaultProps = {
  open: null,
  onClose: () => {},
  onConfirm: () => {},
  Title: '',
  subTitle: '',
}

SuccessUploadModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  Title: PropTypes.string,
  subTitle: PropTypes.string,
}

export default SuccessUploadModal

import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { readContract } from 'store/modules/signature/action'

import { Paragraph } from './style'

const GeneralConditions = () => {
  const divRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const observable = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        const payload = {
          read: true,
        }
        dispatch(readContract(payload))
      }
    })

    observable.observe(divRef.current)
  }, [divRef, dispatch])

  return (
    <>
      <Paragraph>
        <b>Sumário</b>
      </Paragraph>
      <Paragraph>
        <b>1. DO OBJETO</b>
      </Paragraph>
      <Paragraph>
        <b>2. DA ECONOMIA</b>
      </Paragraph>
      <Paragraph>
        <b>3. DO PRAZO DE LOCAÇÃO</b>
      </Paragraph>
      <Paragraph>
        <b>4. DO COMPROMISSO DE PERMANÊNCIA MÍNIMA</b>
      </Paragraph>
      <Paragraph>
        <b>5. DO VALOR DA LOCAÇÃO</b>
      </Paragraph>
      <Paragraph>
        <b>6. DAS OBRIGAÇÕES DA SOLAR21</b>
      </Paragraph>
      <Paragraph>
        <b>7. DAS OBRIGAÇÕES DO CLIENTE</b>
      </Paragraph>
      <Paragraph>
        <b>8. DAS PENALIDADES</b>
      </Paragraph>
      <Paragraph>
        <b>9. DA RESCISÃO</b>
      </Paragraph>
      <Paragraph>
        <b>10. DA ALTERAÇÃO CONTRATUAL</b>
      </Paragraph>
      <Paragraph>
        <b>11. DAS DISPOSIÇÕES GERAIS</b>
      </Paragraph>
      <Paragraph>
        <b>SFCR:</b> Sistema Fotovoltaico Conectado à Rede de propriedade da SOLAR21, o qual contempla placas
        solares, inversores e todos os materiais e equipamentos necessários à produção de energia limpa e
        renovável no endereço do CLIENTE.
      </Paragraph>
      <Paragraph>
        <b>Termo de Adesão: </b>Instrumento por meio do qual o CLIENTE contrata os serviços de locação do SFCR
        e que define (i) o Plano de Assinatura Solar e (ii) as condições específicas de cada negociação,
        identificando as Partes Contratantes, o período da locação, bem como os preços e demais condições
        envolvidas. São partes integrantes do TERMO DE ADESÃO: (a) Termos e Condições Gerais do Plano de
        Assinatura Solar; e (b) a conta de energia fornecida pelo CLIENTE.
      </Paragraph>
      <Paragraph>
        <b>Plano de Assinatura Solar: </b>Plano da SOLAR21 escolhido pelo CLIENTE, que possui prazo de
        locação, período de permanência mínima, estimativa de economia ANUAL na conta do CLIENTE e condições
        diferenciadas que constarão no TERMO DE ADESÃO.
      </Paragraph>
      <Paragraph>
        <b>Termos e Condições Gerais do Plano de Assinatura Solar (“Condições Gerais”): </b>São os
        dispositivos deste instrumento que regem as condições comuns a todos os Planos de Assinatura Solar.
      </Paragraph>
      <Paragraph>
        <b>Termo de Instalação: </b>Documento a ser assinado pelo CLIENTE, onde constará todos os equipamentos
        e os materiais instalados pela SOLAR21 e de sua propriedade para a produção de energia limpa e
        renovável no endereço do CLIENTE, o qual será utilizado como base para conferição na desmobilização do
        SFCR ao final da contratação. Neste documento também constará as informações técnicas do sistema e as
        orientações de BOM USO.
      </Paragraph>
      <Paragraph>
        <b>Locatário (“Cliente”): </b>Pessoa física ou jurídica identificada na proposta de assinatura, ordem
        de serviço de instalação, TERMO DE ADESÃO e/ou no banco de dados da SOLAR21, que realizou a
        contratação dos serviços de Locação de Sistema Fotovoltaico Conectado à Rede de propriedade da
        SOLAR21.
      </Paragraph>
      <Paragraph>
        <b>Permanência Mínima: </b>Período estabelecido pela SOLAR21 para o CLIENTE permanecer vinculado ao
        Plano de Assinatura Solar em contrapartida às promoções do plano escolhido. É uma condição do
        benefício e não se confunde com o prazo de vigência contratual.
      </Paragraph>
      <Paragraph>
        <b>Período de Locação do SFCR: </b>Prazo estabelecido no TERMO DE ADESÃO, conforme o Plano de
        Assinatura Solar. Referido prazo começa a contar da data de Conexão do SFCR ou do Início da Produção
        de energia.
      </Paragraph>
      <Paragraph>
        <b>Economia na Conta de Energia: </b>Estimativa ANUAL da SOLAR21 de redução nos custos com energia do
        CLIENTE anteriores à contratação.
      </Paragraph>
      <Paragraph>
        <b>Data de ativação do SFCR</b>
      </Paragraph>
      <Paragraph>
        Data em que a SOLAR21 finaliza a instalação do sistema e realiza com sucesso a Startup (ligação dos
        equipamentos) e comissionamento (testes nos equipamentos). Data que constará no TERMO DE INSTALAÇÃO.
      </Paragraph>
      <Paragraph>
        <b>Data de Conexão do SFCR ou Início da Produção de energia </b>Data em que a concessionária autoriza
        oficialmente a adesão ao Sistema de Compensação de energia conforme Resolução Normativa da Aneel nº
        482, de 17 de abril de 2012, alterada pela Resolução Normativa da Aneel nº 687, de 24 de novembro de
        2015. Essa data constará oficialmente no site da ANEEL.
      </Paragraph>
      <Paragraph>
        <b>1. DO OBJETO</b>
      </Paragraph>
      <Paragraph>
        <b>1.1 </b>O presente termo tem a finalidade de regular as relações entre a SOLAR21 TECNOLOGIA E
        ENERGIA SOLAR LTDA, inscrita no CNPJ sob o nº 27.150.825/0001-49 e no CREA/DF sob o nº 12972/RF, com
        sede na SBS Quadra 2 Bloco E Número 12 Sobreloja, Sala 206, Parte 9, Asa Sul, Brasília-DF, CEP
        70.070-120, e filial inscrita no CNPJ sob o nº 27.150.825/0002-20, com sede na Rua Conceição, no 233,
        Sala 916, Centro, Campinas-SP, CEP 13010-050, doravante denominada SOLAR21; e o CLIENTE do Sistema
        Fotovoltaico Conectado à Rede (SFCR), doravante simplesmente CLIENTE.
      </Paragraph>
      <Paragraph>
        <b>
          1.2 O O CLIENTE declara ter ciência de que o SFCR é de propriedade da SOLAR21 e concorda em
          resguardar e fazer valer todos os direitos de propriedade da SOLAR21 e a devolução da posse após o
          encerramento da relação contratual, inclusive impedindo sua penhora, sequestro, arresto,
          arrecadação, entre outros, por terceiros.
        </b>
      </Paragraph>
      <Paragraph>
        <b>1.3 </b>O CLIENTE fica ciente e concorda que o objetivo principal da locação do SFCR é a produção
        de energia limpa e renovável, a sustentabilidade ambiental e o proveito econômico decorrente da
        produção de energia elétrica pelo SFCR.
      </Paragraph>
      <Paragraph>
        <b>1.4 </b> Os planos de assinatura solar contemplam a manutenção, monitoramento e instalação básica
        do SFCR, a qual engloba os seguintes serviços, materiais e equipamentos:
      </Paragraph>
      <Paragraph>
        <b>I.</b>elaboração de projeto elétrico em conformidade com as normas vigentes;
      </Paragraph>
      <Paragraph>
        <b>II. </b> instalação do SFCR, de acordo com o projeto aprovado pela Concessionária de Energia Local;
      </Paragraph>
      <Paragraph>
        <b>III. </b> acompanhamento junto à equipe da Concessionária quando houver a integração do SFCR à rede
        de distribuição;
      </Paragraph>
      <Paragraph>
        <b>IV. </b> acesso ao sistema de monitoramento on-line, por intermédio do aplicativo mobile,
        permitindo ao CLIENTE verificar a produção de energia solar, performance do SFCR e economias
        auferidas; e
      </Paragraph>
      <Paragraph>
        <b>V. </b>disponibilização dos seguintes materiais e equipamentos, de acordo com o TERMO DE
        INSTALAÇÃO:
      </Paragraph>
      <Paragraph>
        <b>a: </b>módulos fotovoltaicos mono ou poli cristalinos;
      </Paragraph>
      <Paragraph>
        <b>b: </b>inversores grid-tie (on-grid);
      </Paragraph>
      <Paragraph>
        <b>c: </b>sistema de proteção de correntes contínua e alternada;
      </Paragraph>
      <Paragraph>
        <b>d: </b>sistema de aterramento, cabeamento e conectores; e
      </Paragraph>
      <Paragraph>
        <b>e: </b>estrutura completa de fixação dos módulos.
      </Paragraph>
      <Paragraph>
        <b>1.5 </b>Caso seja necessária a utilização de materiais ou serviços excedentes à instalação básica,
        haverá a cobrança dos respectivos valores pela SOLAR21 ou a rescisão da contratação, sem ônus para as
        PARTES.
      </Paragraph>
      <Paragraph>
        <b>2. DA ECONOMIA</b>
      </Paragraph>
      <Paragraph>
        <b>2.1. </b>A estimativa de economia percentual ANUAL do CLIENTE, estabelecida no TERMO DE ADESÃO e/ou
        Plano de Assinatura Solar, considera o consumo anual de energia junto à CONCESSIONÁRIA LOCAL
        apresentado no processo de assinatura da contratação, o qual será utilizado para dimensionamento do
        SFCR.
      </Paragraph>
      <Paragraph>
        <b>2.2. </b>O cálculo de economia ANUAL estimada leva em consideração a produção de energia anual do
        SFCR, o preço da tarifa da CONCESSIONÁRIA LOCAL e o valor de locação anual do SFCR, conforme a
        seguinte fórmula: EAE = (PSA x TC) - LA ONDE:
      </Paragraph>
      <Paragraph>
        EAE = Economia Anual Estimada. PSA = Produção de Energia Solar Anual do SFCR. TC = Preço da Tarifa da
        Concessionária Local. LA = Valor das 12 (doze) mensalidades da locação do sistema.
      </Paragraph>
      <Paragraph>
        <b>2.3 </b> Fica ciente o CLIENTE que a economia ANUAL percentual se trata de estimativa, podendo
        variar conforme estabelecido no TERMO DE ADESÃO, haja vista os diversos fatores que influenciam na
        produção de energia pelo SFCR,entre eles, as condições meteorológicas e eventuais interrupções do
        sistema.
      </Paragraph>
      <Paragraph>
        <b>2.4. </b>A partir da data de Conexão do SFCR ou do Início da Produção de energia, a SOLAR21
        disponibilizará acesso ao aplicativo mobile que permitirá o monitoramento da produção de energia do
        sistema.
      </Paragraph>
      <Paragraph>
        <b>2.5 </b>Atendendo aos requisitos citados nestas Condições Gerais e TERMO DE ADESÃO, caso não seja
        obtida a economia estimada ANUAL dentro da faixa de variação percentual contida no TERMO DE ADESÃO, a
        SOLAR21 concederá ao CLIENTE descontos nas faturas dos 12 (doze) meses subsequentes do valor
        correspondente apurado, para que seja alcançada a diferença entre a economia citada e a efetivamente
        obtida.
      </Paragraph>
      <Paragraph>
        <b>2.6. </b>Caso a economia ANUAL seja superior ao estimado, inclusive superior à faixa de variação
        percentual contida no TERMO DE ADESÃO, não haverá qualquer repasse de valores adicionais à SOLAR21.
      </Paragraph>
      <Paragraph>
        <b>2.7. </b>Fica ciente o CLIENTE de que não se aplicará o item 2.5, no caso em que houver a
        diminuição do consumo ANUAL de energia, o qual será aferido por meio da comparação entre a CONTA DE
        ENERGIA anexa ao TERMO DE ADESÃO e o consumido em 12 (doze) meses da janela de apuração.
      </Paragraph>
      <Paragraph>
        <b>3. DO PRAZO DE LOCAÇÃO</b>
      </Paragraph>
      <Paragraph>
        <b>3.1. </b>Dar-se-á início ao prazo de locação na data em que iniciar a PRODUÇÃO DE ENERGIA, quando
        também se iniciará as obrigações financeiras dispostas no TERMO DE ADESÃO.
      </Paragraph>
      <Paragraph>
        <b>3.2. </b>O prazo de locação escolhido pelo CLIENTE é aquele disposto no Plano de Assinatura Solar,
        que se encontra registrado no TERMO DE ADESÃO.
      </Paragraph>
      <Paragraph>
        <b>3.3. </b>A SOLAR21 se reserva o direito de promover a análise cadastral do CLIENTE e, em
        determinadas situações, necessitará de tempo hábil para aprovação de crédito.
      </Paragraph>
      <Paragraph>
        <b>3.4. </b>A SOLAR21 poderá, a seu critério, dispensar a análise cadastral mediante apresentação de
        cartão de crédito válido em nome do CLIENTE, com disponibilidade de limite mínimo definido no TERMO DE
        ADESÃO.
      </Paragraph>
      <Paragraph>
        <b>
          3.5. O prazo para ativação do SFCR será estabelecido no TERMO DE ADESÃO, estando ciente o CLIENTE,
          que a viabilidade da contratação carece de avaliação e aprovação de Fundo de Investimento ou
          Financeira ou ainda aprovação de oferta pública para captação de recursos via plataforma eletrônica
          de investimento coletivo, podendo a contratação ser rescindida por quaisquer das PARTES, sem ônus,
          caso o prazo estabelecido no TERMO DE ADESÃO para ativação do SFCR não seja cumprido pela SOLAR21.
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          3.6. A manifestação de interesse em rescindir, nos termos do item 3.5, deverá ser exercitada no
          prazo de 30 (trinta) dias contados do término do prazo estabelecido no TERMO DE ADESÃO para ativação
          do SFCR, presumindo-se, após esse prazo, a concordância na prorrogação automática do prazo de
          ativação por mais 90 (noventa) dias.
        </b>
      </Paragraph>
      <Paragraph>
        <b>3.7. </b>Não ocorrendo a manifestação do CLIENTE até o término da vigência estabelecido no TERMO DE
        ADESÃO, a contratação será considerada renovada automaticamente por igual e sucessivos período.
      </Paragraph>
      <Paragraph>
        <b>3.8. </b>Caso as PARTES, por qualquer motivo, decidirem pela não prorrogação da contratação, a
        SOLAR21 reaverá os equipamentos descritos no TERMO DE INSTALAÇÃO, no prazo máximo de 30 (trinta) dias
        após o prazo de locação.
      </Paragraph>
      <Paragraph>
        <b>
          3.9. Para o cumprimento do prazo de retirada dos equipamentos e materiais pela SOLAR21, o CLIENTE
          deverá viabilizar acesso à SOLAR21 ao local onde se encontra instalado o SFCR, sob pena de multa
          diária de R$ 50,00 (cinquenta reais), sem prejuízo de eventuais perdas e danos, sendo exigida nesta
          hipótese uma notificação prévia ao CLIENTE, com a concessão de prazo de 10 (dez) dias, para
          viabilizar o referido acesso ao SFCR.
        </b>
      </Paragraph>
      <Paragraph>
        <b>4. DO COMPROMISSO DE PERMANÊNCIA MÍNIMA</b>
      </Paragraph>
      <Paragraph>
        <b>
          4.1. O CLIENTE declara ter ciência de que em função dos benefícios descritos no TERMO DE ADESÃO e/ou
          Plano de Assinatura Solar contratado, a SOLAR21 poderá estipular prazo de PERMANÊNCIA MÍNIMA,
          devendo o CLIENTE continuar vinculado ao plano durante o período estipulado, contados do início de
          produção de energia.{ ' ' }
        </b>
      </Paragraph>
      <Paragraph>
        <b>4.2. </b>A PERMANÊNCIA MÍNIMA se justifica na necessidade de assegurar à SOLAR21 um período para
        recuperar o investimento realizado com a concessão de mensalidades inferiores, bônus, concessão de
        instalação dos equipamentos e outras promoções.
      </Paragraph>
      <Paragraph>
        <b>4.3. </b>A PERMANÊNCIA MÍNIMA é uma condição do benefício concedido, não se confundindo com o prazo
        de locação, e que se o cancelamento ocorrer antes de completado o prazo de PERMANÊNCIA MÍNIMA, o
        CLIENTE perderá o direito ao benefício promocional especificado no TERMO DE ADESÃO e/ou Plano de
        Assinatura Solar contratado, e o valor do benefício será cobrado pela SOLAR21, juntamente com
        quaisquer outros valores eventualmente devidos no momento do cancelamento.
      </Paragraph>
      <Paragraph>
        <b>4.4. </b>A formalização do aceite às condições de PERMANÊNCIA MÍNIMA ocorrerá mediante a adesão
        pelo CLIENTE de qualquer plano de assinatura com opção de PERMANÊNCIA MÍNIMA, cujas condições da
        oferta contratada e do prazo de PERMANÊNCIA MÍNIMA serão especificadas no TERMO DE ADESÃO.
      </Paragraph>
      <Paragraph>
        <b>5. DO VALOR DA LOCAÇÃO</b>
      </Paragraph>
      <Paragraph>
        <b>5.1. </b>O valor mensal a ser pago pelo CLIENTE à SOLAR21 será aquele definido para o Plano de
        Assinatura Solar aceito pelo CLIENTE, devidamente registrado no TERMO DE ADESÃO, assegurados os
        reajustes previstos neste instrumento.
      </Paragraph>
      <Paragraph>
        <b>
          5.2. O CLIENTE declara ter ciência de que o valor mensal a ser pago à SOLAR21 não engloba eventuais
          valores cobrados por parte da Concessionária Local pelo fornecimento de energia adicional ao
          produzido pelo SFCR.
        </b>
      </Paragraph>
      <Paragraph>
        <b>5.3. </b>Para efeito de caracterização de faturamento, fica ajustado que a primeira mensalidade da
        locação será lançada 30 (trinta) dias após o início da produção de energia e as demais mensalidades
        serão devidas em igual dia ao primeiro dos meses subsequentes, de acordo com a data de vencimento do
        cartão de crédito do CLIENTE, no caso de pessoa física, ou por meio de boleto bancário, no caso de
        pessoa jurídica.
      </Paragraph>
      <Paragraph>
        <b>
          5.4. O valor mensal estipulado para a locação será reajustado anualmente pela variação positiva do
          Reajuste Tarifário Anual (RTA) ou Reajuste Tarifário Extraordinário (RTE) da CONCESSIONÁRIA LOCAL,
          podendo ser cumulativo, quando houver.
        </b>
      </Paragraph>
      <Paragraph>
        <b>5.5. </b>O valor da locação não sofrerá reajuste referente às bandeiras tarifárias.
      </Paragraph>
      <Paragraph>
        <b>
          5.6. Em caso de interrupção na produção de energia ocasionada pela paralização do sistema,
          decorrente de manutenção ou defeito nos equipamentos, o CLIENTE declara ter ciência de que o valor
          do aluguel será cobrado integralmente no respectivo mês e ao final do período de 12 (doze) meses,
          caso não seja obtida a economia estimada ANUAL dentro da faixa de variação percentual contida no
          TERMO DE ADESÃO, aplicar-se-á o estabelecido no item 2.5.
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          5.7. O CLIENTE declara ter ciência de que na falta de fornecimento de energia pela CONCESSIONÁRIA
          LOCAL, fator preponderante para funcionamento do SFCR, ou em interrupção ocasionada pelo CLIENTE,
          não será aplicado o item
        </b>
      </Paragraph>
      <Paragraph>
        <b>6. DAS OBRIGAÇÕES DA SOLAR21</b>
      </Paragraph>
      <Paragraph>
        <b>6.1 </b>A SOLAR21 oferece plena garantia do perfeito funcionamento do SFCR, quando da respectiva
        instalação, obedecidas as especificações técnicas do projeto, podendo o SFCR conter componentes
        revisados, dentro dos mais rigorosos padrões técnicos e de controle de qualidade, observando, ainda,
        os seguintes parágrafos:
      </Paragraph>
      <Paragraph>
        <b>6.2 São responsabilidades da SOLAR21:</b>
      </Paragraph>
      <Paragraph>
        <b>I. </b>a obtenção da autorização junto à Concessionária Local para instalação do SFCR e início da
        produção de energia e demais tratativas, não sendo sua atribuição responder sobre o prazo de análise e
        trâmites burocráticos da Concessionária, inclusive, quanto ao prazo para autorização oficial de adesão
        ao Sistema de Compensação de Energia;
      </Paragraph>
      <Paragraph>
        <b>II. </b>a instalação elétrica do SFCR, limitando-se ao ponto de conexão do SFCR à rede elétrica
        existente do CLIENTE;
      </Paragraph>
      <Paragraph>
        <b>III. </b>a execução dos serviços em conformidade com as especificações técnicas, respeitando as
        Normas da ABNT, da ANEEL, da Concessionária Local, ambientais, trabalhistas, de segurança e medicina
        do trabalho, especialmente no que prescreve as NR’s nº 10 - Segurança em Instalações e Serviços em
        Eletricidade e nº 35 – Trabalho em Altura;
      </Paragraph>
      <Paragraph>
        <b>6.4. </b>Caso o CLIENTE opte por não realizar as adequações solicitadas pela SOLAR21, a contratação
        poderá ser rescindida sem ônus às PARTES.
      </Paragraph>
      <Paragraph>
        <b>6.5. </b>A SOLAR21 oferecerá o serviço de Operação Assistida, monitorando remotamente a planta
        solar 24h/d, 7d/s, sendo que, em caso de qualquer anormalidade, no prazo máximo de 72 (setenta e duas)
        horas, a equipe técnica da SOLAR21 realizará o atendimento da ocorrência.
      </Paragraph>
      <Paragraph>
        <b>7. DAS OBRIGAÇÕES DO CLIENTE</b>
      </Paragraph>
      <Paragraph>
        <b>
          7.1 O CLIENTE terá o direito de plena utilização do SFCR locado, a partir da data de Conexão do SFCR
          ou do Início da Produção de energia, obrigando-se a:
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          I. outorgar procuração específica para que a SOLAR21 possa representa-lo junto à CONCESSIONÁRIA
          LOCAL referente à conexão de microgeração distribuída;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          II. responder por quaisquer condições na sua propriedade que afete a instalação do sistema, nos
          limites das suas responsabilidades, excluindo ainda a ocorrência de caso fortuito e de força
          maior;a;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          III. acompanhar a SOLAR21 quando da elaboração do inventário dos materiais e equipamentos que
          compõem o SFCR e ao final corroborar com o TERMO DE INSTALAÇÃO;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          IV. disponibilizar sinal de wifi próximo do inversor e com qualidade compatível para monitoramento
          do SFCR;
        </b>
      </Paragraph>
      <Paragraph>
        <b>V. manter os equipamentos nos locais exatos de instalação;</b>
      </Paragraph>
      <Paragraph>
        <b>
          VI. comunicar imediatamente à SOLAR21 qualquer mudança na propriedade que influencia direta ou
          indiretamente no SFCR, para que não haja interferência na produtividade do sistema ou comprometa o
          seu bom funcionamento, sob pena de arcar com eventuais prejuízos;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          VII. arcar com as despesas decorrentes de mudança de local, inclusive, mas não exclusivamente,
          desmobilização, transporte, montagem, realocação dos equipamentos no novo local e novas instalações
          ao SFCR, desde que motivadas por conveniência e iniciativa própria do CLIENTE e que não sejam
          resultantes de orientação da SOLAR21;
        </b>
      </Paragraph>
      <Paragraph>
        <b>VIII. não introduzir modificações de qualquer natureza no SFCR;</b>
      </Paragraph>
      <Paragraph>
        <b>
          IX. defender e fazer valer todos os direitos de propriedade e de posse da SOLAR21 sobre todos os
          equipamentos do SFCR, inclusive impedindo sua penhora, sequestro, arresto, arrecadação, entre
          outros, por terceiros, notificando-os sobre os direitos de propriedade e de posse da SOLAR21 sobre
          os equipamentos;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          X. comunicar imediatamente à SOLAR21 qualquer intervenção ou violação por terceiros de qualquer de
          seus direitos em relação ao SFCR;
        </b>
      </Paragraph>
      <Paragraph>
        <b>XI. zelar pela devida conservação do bem;</b>
      </Paragraph>
      <Paragraph>
        <b>XII. não executar qualquer alteração no telhado que possa sobrecarregar a estrutura;</b>
      </Paragraph>
      <Paragraph>
        <b>XIII. não pisar, apoiar ou situar objetos sobre os módulos;</b>
      </Paragraph>
      <Paragraph>
        <b>
          XIV. não atuar na estrutura do telhado ou executar reformas e pinturas próximas aos equipamentos sem
          acompanhamento e comunicação à SOLAR21;
        </b>
      </Paragraph>
      <Paragraph>
        <b>XV. não utilizar produtos químicos ou de limpeza pesada próximo aos equipamentos;</b>
      </Paragraph>
      <Paragraph>
        <b>
          XVI. comunicar a SOLAR21 a existência de bichos e/ou animais alojados nos equipamentos, bem como
          ninhos, colmeias entre outros;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          XVII. manter árvores, galhos e arbustos podados para que o SFCR tenha incidência solar com o
          projeto;
        </b>
      </Paragraph>
      <Paragraph>
        <b>XVIII. não modificar sua propriedade de modo que produza sombreamento sobre o SFCR;</b>
      </Paragraph>
      <Paragraph>
        <b>XIX. manter o painel solar desobstruído para o seu funcionamento adequado; </b>
      </Paragraph>
      <Paragraph>
        <b>
          XX. comunicar imediatamente à SOLAR21 situações de interrupção na produção de energia ocasionada
          pela paralização do sistema ou quando constatado defeito visível nos equipamentos;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          XXI. manter-se no Plano de Assinatura Solar contratado durante o período de permanência mínima,
          quando houver, e do prazo de locação, sob pena de multa por rescisão antecipada e reembolso dos
          benefícios concedidos a título de PERMANÊNCIA MÍNIMA;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          XXII. permitir o acesso de pessoal autorizado da SOLAR21 para realização da manutenção, inspeção,
          reparos e, ainda, para o seu desligamento ou remoção, mediante a devida identificação destes junto
          ao CLIENTE, não se permitindo o ingresso deles em área diversa daquela destinada aos bens
          disponibilizados para uso, salvo se decorrente de autorização prévia;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          XXIII. impedir que terceiros não autorizados ou não credenciados pela SOLAR21 interfiram no
          funcionamento do SFCR instalados;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          XXIV. responsabilizar‐se por qualquer dano, prejuízo ou inutilização dos equipamentos instalados, se
          comprovado a não realização de alguma das obrigações constantes nestas CONDIÇÕES GERAIS, ressalvadas
          as hipóteses de casos fortuitos ou de força maior, bem como em lei;
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          XXV. pagar as contraprestações à SOLAR21, inclusive em caso de interrupção contratual decorrente de
          sua responsabilidade; e
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          XXVI. devolver todos os equipamentos instalados à SOLAR21 no estado em que se encontram, findo o
          prazo de locação.
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          7.2. O CLIENTE fica ciente, que nos casos de culpa ou dolo, responderá pelos danos causados ao SFCR,
          excluídos aqueles decorrentes de caso fortuito ou força maior, sendo responsável pelos custos de
          substituição dos componentes.
        </b>
      </Paragraph>
      <Paragraph>
        <b>8. DAS PENALIDADES</b>
      </Paragraph>
      <Paragraph>
        <b>8.1. </b>No caso de atraso no pagamento das parcelas mensais, o CLIENTE ficará automaticamente
        constituído em mora, incorrendo no pagamento de multa de 2% (dois por cento) sobre o valor do débito,
        mais os juros de 1% (um por cento) ao mês, sendo o valor do débito atualizado com base na variação do
        IGP-M, ou outro referencial que por força de lei venha a substituí-lo, proporcional ao número de dias
        do atraso.
      </Paragraph>
      <Paragraph>
        <b>8.2. </b>Em caso de inadimplemento superior a 60 (sessenta) dias, contados do dia imediatamente
        seguinte ao do vencimento da mensalidade, além de incorrer nas penalidades previstas no item acima, a
        SOLAR21 poderá promover o protesto e a inclusão do nome do CLIENTE em qualquer cadastro de
        inadimplentes, sendo exigida nesta hipótese uma notificação prévia ao CLIENTE, com a concessão de
        prazo de 10 (dez) dias, para viabilizar a regularização do pagamento do valor devido ou a apresentação
        das justificativas correlatas no mesmo prazo.
      </Paragraph>
      <Paragraph>
        <b>
          8.3. Caso o atraso seja superior a 90 (noventa) dias, a contratação poderá ser rescindida de pleno
          direito pela SOLAR21, ficando sujeito o CLIENTE, nesta hipótese, além do estipulado acima, a multa
          rescisória equivalente a 03 (três) mensalidades reajustadas acrescidas da multa prevista no TERMO DE
          ADESÃO pelo descumprimento da PERMANÊNCIA MÍNIMA, caso houver, ocasião que a SOLAR21 reaverá o SFCR
          instalado nos moldes e condições previstas neste instrumento.
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          8.4. Na ocorrência da rescisão, o CLIENTE deverá viabilizar o acesso necessário, à equipe da
          SOLAR21, para retirada completa do SFCR, sob pena de multa diária de R$ 50,00 (cinquenta reais), sem
          prejuízo de eventuais perdas e danos.
        </b>
      </Paragraph>
      <Paragraph>
        <b>8.5.</b>O descumprimento de qualquer dos itens destas CONDIÇÕES GERAIS pelas PARTES poderá ensejar
        multa equivalente ao valor da mensalidade atual, quando não houver previsão de penalidade específica,
        sem prejuízo do dever de ressarcir eventuais prejuízos sofridos pela PARTE contrária.
      </Paragraph>
      <Paragraph>
        <b>8.6. </b>Em caso de cobrança extrajudicial ou judicial, realizada por intermédio do jurídico da
        SOLAR21, será acrescido 20% (vinte por cento) do valor do débito a título de honorários advocatícios.
      </Paragraph>
      <Paragraph>
        <b>9. DA RESCISÃO</b>
      </Paragraph>
      <Paragraph>
        <b>9.1. </b>Além das rescisões previstas neste instrumento, a contratação poderá ser rescindida por:
      </Paragraph>
      <Paragraph>
        <b>I. </b>Solicitação do CLIENTE, apresentada a qualquer tempo, ficando ressalvado à SOLAR21 o direito
        de cobrar, do CLIENTE, a multa rescisória equivalente a 03 (três) mensalidades atuais acrescidas da
        multa prevista no TERMO DE ADESÃO pelo descumprimento da PERMANÊNCIA MÍNIMA ou taxa de desinstalação,
        caso houver;
      </Paragraph>
      <Paragraph>
        <b>II. </b>Não cumprimento, pelo CLIENTE, dos termos e condições previstos neste instrumento;
      </Paragraph>
      <Paragraph>
        <b>III. </b>de forma imotivada pela SOLAR21, a qualquer momento, acarretando a multa rescisória
        equivalente a 03 (três) mensalidades atuais, não cumulada com nenhuma outra espécie de multa por
        rescisão contratual, ocasião em que a SOLAR21 reaverá o SFCR instalado nos moldes e condições
        previstas nestas Condições Gerais;
      </Paragraph>
      <Paragraph>
        <b>IV. </b>Morte, interdição, falência ou insolvência do CLIENTE.
      </Paragraph>
      <Paragraph>
        <b>
          9.2. A multa rescisória estabelecida no inciso I do item 9.1, será devida a partir da data de aceite
          do TERMO DE ADESÃO.
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          9.3. A multa rescisória estabelecida no inciso I do item 9.1, será devida ainda que o imóvel em que
          se encontra instalado o SFCR seja objeto de locação com terceiros, não cabendo qualquer desoneração
          da multa sob o argumento de rescisão ou não renovação do contrato de locação do imóvel; bem como, a
          negativa de autorização pelo proprietário do imóvel quanto à instalação do SFCR.
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          9.4. O prazo para a retirada dos equipamentos que compõe o SFCR, começa a contar da data de
          recebimento pela SOLAR21 da comunicação de cancelamento.
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          9.5. A rescisão da contratação, por qualquer das hipóteses acima referidas, obriga ao CLIENTE o
          pagamento proporcional ao número de dias da locação.
        </b>
      </Paragraph>
      <Paragraph>
        <b>9.6 </b>Em caso de rescisão contratual e negativa de acesso para retirada dos bens, após do decurso
        do prazo de 10 (dez) dias da notificação prévia ao CLIENTE, para viabilizar o referido acesso ao SFCR
        e/ou para eventual manifestação no mesmo prazo, será cabível a Ação de Reintegração de Posse, cumulada
        com as perdas e danos, ocasião que será acrescido ao débito os honorários advocatícios fixados em 20%
        (vinte por cento) sobre o valor da causa, sem prejuízo de comunicação à autoridade policial acerca da
        ocorrência do crime de apropriação indébita (art. 168 Código Penal).
      </Paragraph>
      <Paragraph>
        <b>10. DA ALTERAÇÃO CONTRATUAL</b>
      </Paragraph>
      <Paragraph>
        <b>10.1.</b> Em caso de aumento no consumo de energia e desde que solicitado pelo CLIENTE, a SOLAR21
        terá o prazo de 30 (trinta) dias para verificar a possibilidade de adequação do SFCR e informar
        eventuais valores a serem acrescidos.
      </Paragraph>
      <Paragraph>
        <b>10.2.</b> Caso o CLIENTE tenha interesse em realizar upgrade no SFCR (aumento de produtividade de
        energia gerada), a contratação poderá ser alterada por meio de assinatura de novo TERMO DE ADESÃO,
        após avaliação da SOLAR21.
      </Paragraph>
      <Paragraph>
        <b>11. DAS DISPOSIÇÕES GERAIS</b>
      </Paragraph>
      <Paragraph>
        <b>11.1. </b>Estas CONDIÇÕES GERAIS são disponibilizadas eletronicamente, enviadas por e-mail a todos
        os CLIENTES em conjunto com o TERMO DE ADESÃO. O CLIENTE obriga-se a ter plena ciência dos termos e
        condições aqui dispostos, divulgados pela SOLAR21 e mantidos disponíveis para consulta em seu site
        www.solar21.com.br ou por outros meios.
      </Paragraph>
      <Paragraph>
        <b>11.2. </b>A concordância pelo CLIENTE dos termos e condições do presente instrumento e do TERMO DE
        ADESÃO poderá ocorrer por meio de, pelo menos, uma das seguintes formas: (i) aceitação da proposta de
        assinatura do plano solar; (ii) aceitação pelo sistema eletrônico de televendas ou SAC; (iii)
        assinatura do TERMO DE INSTALAÇÃO; (iv) aceitação eletrônica via site www.solar21.com.br ou
        aplicativo; (v) confirmação por qualquer meio eletrônico disponibilizado; ou (vi) pagamento de
        mensalidades relativas à locação.
      </Paragraph>
      <Paragraph>
        <b>11.3. </b>A SOLAR21 compromete-se a divulgar no site www.solar21.com.br e/ou em outros meios de
        comunicação as novas versões do presente instrumento, ficando facultado ao CLIENTE o direito de
        formalizar sua oposição, de forma fundamentada, em até 30 (trinta) dias contados da divulgação. Após
        esse prazo, passam a vigorar as novas CONDIÇÕES GERAIS.
      </Paragraph>
      <Paragraph>
        <b>11.4. </b>A eventual anulação de um dos itens destas CONDIÇÕES GERAIS ou do TERMO DE ADESÃO não
        invalidará as demais regras estabelecidas.
      </Paragraph>
      <Paragraph>
        <b>11.5. </b>O CLIENTE manifesta sua ciência e concordância de que a SOLAR21, no processo de adesão e
        no decorrer da vigência da contratação, efetuará coleta e armazenamento de seus dados e informações
        pessoais, bem como de registros de suas ações, necessários para a comprovação de validade desta
        contratação.
      </Paragraph>
      <Paragraph>
        <b>
          11.6. O CLIENTE tem ciência de que, caso seja constatado o uso de informação incorreta, incompleta
          ou falsa no cadastramento, em especial em relação aos dados pessoais (nome, CPF etc..) e dados
          financeiros (cartão de créditos etc...), a SOLAR21 poderá rescindir a contratação, independentemente
          de notificação, sem prejuízo da adoção das medidas cíveis e criminais cabíveis.
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          11.7. A SOLAR21 poderá ceder ou transferir a terceiros, total ou parcialmente, os créditos
          decorrentes da contratação realizada.
        </b>
      </Paragraph>
      <Paragraph>
        <b>
          11.8. O CLIENTE autoriza a SOLAR21 a compartilhar o TERMO DE ADESÃO com empresas, organizações,
          instituições financeiras ou pessoas físicas de fora da SOLAR21, caso seja necessário para atender a
          motivos jurídicos e ou obter investimentos financeiros de bancos, fundos ou plataforma eletrônica de
          investimentos, desde que seja perpetuado os melhores esforços para garantir que os destinatários
          mantenham os dados pessoais em segurança, tomem todas as medidas razoáveis para protegê-los contra
          uso indevido e os usem apenas conforme as leis e regulamentos aplicáveis à proteção de dados.{ ' ' }
        </b>
      </Paragraph>
      <Paragraph>
        <b>11.9. </b>Para efeitos legais, o presente instrumento entra em vigor na data de registro no
        Cartório de Registro de Títulos e Documentos competente.
      </Paragraph>

      <div ref={ divRef }>
        <Paragraph>
          <b>11.10 </b>O foro eleito para dirimir qualquer dúvida ou desavença advinda da contratação é o da
          comarca do domicílio do CLIENTE.
        </Paragraph>
      </div>
    </>
  )
}

export default GeneralConditions

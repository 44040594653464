import types from 'store/types'

export function customerSuccessMessageRequest(id) {
  return {
    type: types.CUSTOMER_SUCCESS_MESSAGE_REQUEST,
    payload: {id},
  }
}

export function customerSuccessMessageSuccess(messages) {
  return {
    type: types.CUSTOMER_SUCCESS_MESSAGE_SUCCESS,
    payload: {messages},
  }
}

export function customerSuccessMessageFailure() {
  return {
    type: types.CUSTOMER_SUCCESS_MESSAGE_FAILURE,
  }
}

export function customerSuccessUploadRequest(id, file) {
  return {
    type: types.CUSTOMER_SUCCESS_UPLOAD_REQUEST,
    payload: {id, file},
  }
}

export function customerSuccessUploadSuccess() {
  return {
    type: types.CUSTOMER_SUCCESS_UPLOAD_SUCCESS,
  }
}

export function customerSuccessUploadFailure() {
  return {
    type: types.CUSTOMER_SUCCESS_UPLOAD_FAILURE,
  }
}

export function customerSuccessTableRequest(id) {
  return {
    type: types.CUSTOMER_SUCCESS_TABLE_REQUEST,
    payload: {id},
  }
}

export function customerSuccessTableSuccess() {
  return {
    type: types.CUSTOMER_SUCCESS_TABLE_SUCCESS,
  }
}

export function customerSuccessTableFailure() {
  return {
    type: types.CUSTOMER_SUCCESS_TABLE_FAILURE,
  }
}

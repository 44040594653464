import React, {FC, useMemo} from 'react'

import * as S from '../styles'

type BankInfoCardProps = {
  icon: string
  iconText: string
  tax: number
  parcelNumber: number
  parcel: number
  hidden?: boolean
}

const BankInfoCard: FC<BankInfoCardProps> = ({icon, iconText, tax, parcelNumber, parcel, hidden = false}) => {
  const taxValue = useMemo(
    () => tax.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
    [tax]
  )

  const parcelValue = useMemo(
    () => parcel.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
    [parcel]
  )

  return hidden ? null : (
    <S.Sheet>
      <S.SingleBank>
        <S.IconRight src={icon} alt={iconText} />
        <S.DescriptionText>Taxas a partir de {taxValue}%</S.DescriptionText>
        <S.DescriptionText>
          {parcelNumber}x de R$ {parcelValue}
        </S.DescriptionText>
      </S.SingleBank>
    </S.Sheet>
  )
}

export default BankInfoCard

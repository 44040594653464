import React, {useCallback, useEffect, useState} from 'react'
import * as S from './styles'
import PageHeader from 'components/pageHeader'
import CostInfos from './CostInfos/CostInfos'
import Barcode from './Barcode'
import Button from 'components/button'
import Financiamento from './FinanciamentoV2'
import {useDispatch, useSelector} from 'react-redux'
import {isEmpty, last} from 'lodash'
import api from 'services/api'
import ModalConsumer from './ModalConsumer'
import ModalIframe from './ModalIframe'
import {default as ModalIframe2} from './ModalIframe'
import {requestContract} from 'store/modules/signature/action'
import ProposalModal from 'pages/proposal-step/ProposalModal/ProposalModal'
import GeneralDataCards from './GeneralDataCards'
import DetailsCard from 'pages/proposal-step/DetailsCard'
import history from 'services/history'
import FeaturesCard from './FeaturesCard/FeaturesCard'
import {AxiosRequestConfig} from 'axios'
import FileInput from 'components/fileInput'
import {toast} from 'react-toastify'

export enum LendingStatusEnum {
  NOT_REQUESTED = 'NOT_REQUESTED',
  IN_EVALUATION = 'IN_EVALUATION',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export type ProposalType = {
  id: string
  created_at: string
  updated_at: string
  power_of_the_project: number
  client_id: string
  distributor_kit_cost: number
  sale_value: number
  franchisee_commission: number
  final_value: number
  final_installed_wp: number
  value_kit_for_customer: number
  nf_value: number
  tax_solar21: number
  contribution_margin: number
  total_amount_receivable_franchisee: number
  inverter_quantity: number
  inverter_power: number
  inverter_brand: string
  inverter_warranty: string
  module_quantity: number
  module_brand: string
  module_power: number
  annual_production: number
  annual_savings: number
  payback: number
  module_warranty: number
  structure_type: string
  structure_warranty: string
  pdf_link: string
}

const PaymentStep = () => {
  const dispatch = useDispatch()

  const profile = useSelector((stt: any) => stt.profile.profileValues)
  const contracts = useSelector((stt: any) => stt.signature?.url)

  const [openProposal, setOpenProposal] = useState<boolean>(false)

  const [description, setDescription] = useState<string>('')
  const [paymentOption, setPaymentOption] = useState<string>('CASH_PURCHASE')
  const [status, setStatus] = useState<LendingStatusEnum>(LendingStatusEnum.NOT_REQUESTED)
  const [proposal, setProposal] = useState<ProposalType | undefined>()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openSignature, setOpenSignature] = useState<boolean>(false)
  const [openAttorney, setOpenAttorney] = useState<boolean>(false)
  const [signedContract, setSignedContract] = useState<boolean>(false)
  const [signedContractAttorney, setSignedContractAttorney] = useState<boolean>(false)

  const [purchaseContract, setpurchaseContract] = useState({url: '', key: ''})
  const [attorneyContract, setAttorneyContract] = useState({url: '', key: ''})

  const [currentStep, setCurrentStep] = useState<string>('CHECKOUT')

  const [pageTitle, setPageTitle] = useState<string>('Pagamento')

  const [hasDocument, setHasDocument] = useState<boolean>(true)
  const [hasProofOfAddress, setHasProofOfAddress] = useState<boolean>(true)
  const [hasProofOfIncome, setHasProofOfIncome] = useState<boolean>(true)

  const [invoicePaid, setInvoicePaid] = useState<boolean>(false)
  const [clientLending, setClientLending] = useState<string>('NOT_REQUESTED')

  const handleCloseProposal = () => setOpenProposal(false)

  useEffect(() => {
    if (isEmpty(profile)) return

    const step = profile.steps.find((step: any) => step.status === 'PENDING')
    setCurrentStep(step.type)

    if (isEmpty(step)) return
    if (step.type === 'SUPPLY') {
      setPageTitle('Tudo pronto!')
      setDescription('Parabéns por adquirir seu sistema solar!')
    }
  }, [profile])

  useEffect(() => {
    api
      .get('/portal/customers/documents')
      .then(({data}) => {
        data.forEach((element: any) => {
          if (element.type === 'PROOF_OF_ADDRESS') setHasProofOfAddress(true)
          if (element.type === 'PROOF_OF_INCOME') setHasProofOfIncome(true)
          if (element.type === 'DOCUMENT') setHasDocument(true)
        })
      })
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    api
      .get('/portal/customer/invoice_status')
      .then(({data}) => {
        if (data?.data?.data?.invoice_status === 'paid') setInvoicePaid(true)
      })
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    api
      .get('/portal/client_lending')
      .then(({data}) => setClientLending(data.status))
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    const sendToSupply = () =>
      api
        .patch('/portal/customers/step_supply')
        .catch(err => console.error('Error sending client to supply step: ', err))
        .finally(() => window.location.reload())

    if (isEmpty(contracts)) return

    const contract = contracts.find((contract: any) => contract.name === 'Contrato de compra')
    if (!contract.is_signed) return

    const paymentType = localStorage.getItem('paymentType')
    if (paymentType === 'CASH_PURCHASE' && hasDocument && invoicePaid) sendToSupply()

    if (
      paymentType === 'FINANCING' &&
      hasDocument &&
      hasProofOfAddress &&
      hasProofOfIncome &&
      clientLending === 'APPROVED'
    )
      sendToSupply()
  }, [profile, contracts, hasDocument, hasProofOfAddress, hasProofOfIncome, invoicePaid, clientLending])

  useEffect(() => {
    api
      .get('/portal/client_lending')
      .then(({data}) => setStatus(data.status))
      .catch(err => console.error('Error getting client lending status: ', err))
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkContract = () => {
    if (isEmpty(contracts)) return
    const contract = contracts.find((contract: any) => contract.name === 'Contrato de compra')

    api.get(`portal/check_signers/${contract.key}`).then(({data}) => {
      if (data.is_sign) setSignedContract(true)
    })
    setpurchaseContract(contract)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkAttorney = () => {
    if (isEmpty(contracts)) return
    const contract = contracts.find((contract: any) => contract.name === 'Procuração')

    api.get(`portal/check_signers/${contract.key}`).then(({data}) => {
      if (data.is_sign) setSignedContractAttorney(true)
    })
    setAttorneyContract(contract)
  }

  useEffect(() => {
    checkAttorney()
    checkContract()
  }, [checkAttorney, checkContract])

  useEffect(() => {
    dispatch(requestContract())
  }, [dispatch])

  useEffect(() => {
    const paymentType = localStorage.getItem('paymentType')

    setPaymentOption(paymentType ?? 'CASH_PURCHASE')
  }, [])

  useEffect(() => {
    if (isEmpty(profile) || !profile.id) return

    if (isEmpty(proposal))
      api
        .get(`portal/customers/${profile.id}/proposal`)
        .then(({data}) => setProposal(last(data)))
        .catch(err => console.error('proposal retrieving did not work: ', err))
  }, [profile, proposal])

  const getProjectPower = useCallback(() => {
    if (!proposal?.power_of_the_project) return 0

    return proposal.power_of_the_project.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }, [proposal])

  const getPowerCost = useCallback(() => {
    if (!proposal) return 0

    const {power_of_the_project, final_value} = proposal
    if (!power_of_the_project || !final_value) return 0

    const pricePerPower = final_value / (power_of_the_project * 1000)

    return pricePerPower.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }, [proposal])

  const getAnnualProduction = useCallback(() => {
    if (!proposal?.annual_production) return 0

    const {annual_production} = proposal
    const yearProduction = annual_production > 0 ? annual_production : annual_production * -1

    return yearProduction.toFixed(1).toString().replace('.', ',')
  }, [proposal])

  const getAnnualSavings = useCallback(() => {
    if (!proposal?.annual_savings) return 0

    const {annual_savings} = proposal
    const yearSavings = annual_savings > 0 ? annual_savings : annual_savings * -1

    return (yearSavings / 1000).toFixed(1).toString().replace('.', ',') + 'mil'
  }, [proposal])

  const getPayback = useCallback(() => {
    if (!proposal?.payback) return 0
    return proposal.payback + ' meses'
  }, [proposal])

  const [fileToUpload, setFileToUpload] = useState<File | null>(null)
  const [isFileSent, setIsFileSent] = useState<boolean>(false)
  const getFileName = useCallback(() => {
    if (fileToUpload) return fileToUpload.name
    return ''
  }, [fileToUpload])

  const getDocuments = useCallback(() => {
    api
      .get('/portal/customers/documents')
      .then(({data}) => {
        data.forEach((element: any) => {
          if (element.type === 'DOCUMENT') setIsFileSent(true)
        })
      })
      .catch(err => console.error(err))
  }, [])

  const handleUpload = useCallback(async () => {
    if (isFileSent) {
      toast.error('Arquivo já encaminhado!')
      return
    }
    if (fileToUpload === null) {
      toast.error('Insira o documento necessário!')
      return
    }

    const formData = new FormData()
    formData.append('document', fileToUpload)

    const headerParams = {
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Content-Type': 'multipart/form-data',
    }

    await api
      .post('/portal/customers/documents/proposal', formData, headerParams as AxiosRequestConfig)
      .then(() => toast.success('Documentos enviados com sucesso!'))
      .catch(err => console.error('Error upaloadin document', err))
      .finally(() => getDocuments())
  }, [fileToUpload, getDocuments, isFileSent])

  return (
    <S.Container>
      {(!signedContract || !signedContractAttorney || !invoicePaid) && (
        <>
          <PageHeader title={pageTitle} description={description} icon={''} />
          <GeneralDataCards
            main={[
              getProjectPower(),
              getPowerCost(),
              getAnnualProduction(),
              getAnnualSavings(),
              getPayback(),
            ]}
          />
          {currentStep === 'SUPPLY' && (
            <S.Subcontainer>
              <DetailsCard simulationValues={proposal} isInitial={false} setOpenProposal={setOpenProposal} />
            </S.Subcontainer>
          )}
          <CostInfos totalCost={proposal?.final_value || 0} />
          <S.SubcontainerGap>
            {paymentOption === 'CASH_PURCHASE' && currentStep !== 'SUPPLY' && (
              <Barcode
                handleOpen={setOpenModal}
                handleSign={setOpenSignature}
                handleAttorney={() => setOpenAttorney(true)}
                signedContract={signedContract}
                signedContractAttorney={signedContractAttorney}
              />
            )}
            {paymentOption === 'FINANCING' && currentStep !== 'SUPPLY' && (
              <Financiamento
                state={status}
                handleOpen={setOpenModal}
                handleSign={setOpenSignature}
                handleAttorney={() => setOpenAttorney(true)}
                signedContract={signedContract}
                signedContractAttorney={signedContractAttorney}
              />
            )}
          </S.SubcontainerGap>
          {currentStep === 'SUPPLY' && (
            <S.Sheet>
              <div style={{width: '100%', textAlign: 'center', fontSize: '1.5rem', fontWeight: 500}}>
                Agora é só esperar a entrega e instalação de seu kit!
              </div>
            </S.Sheet>
          )}
          <S.ButtonWrapper>
            {!signedContract && (
              <Button
                disabled={signedContract}
                text={'Voltar'}
                onClick={() => {
                  localStorage.removeItem('paymentType')
                  history.push(`proposal-final`)
                }}
              />
            )}
          </S.ButtonWrapper>
          <ModalConsumer
            open={openModal}
            setOpenSignature={() => setOpenSignature(true)}
            onClose={() => setOpenModal(false)}
          />
          <ProposalModal open={openProposal} handleClose={handleCloseProposal} proposal={proposal as any} />
          {openSignature && !isEmpty(purchaseContract) && (
            <ModalIframe
              onClose={() => {
                checkContract()
                setOpenSignature(false)
              }}
              open={openSignature}
              url={purchaseContract?.url as any}
              contractId={purchaseContract?.key as any}
              contractType={'T'}
              contractAdhesion={false}
              contractProcuration={false}
            />
          )}
          {openAttorney && !isEmpty(attorneyContract) && (
            <ModalIframe2
              onClose={() => {
                checkAttorney()
                setOpenAttorney(false)
              }}
              open={openAttorney}
              url={attorneyContract?.url as any}
              contractId={attorneyContract?.key as any}
              contractType={'T'}
              contractAdhesion={false}
              contractProcuration={false}
            />
          )}
        </>
      )}
      {signedContract && signedContractAttorney && invoicePaid && (
        <>
          <PageHeader title={'Pagamento confirmado, mas...'} description={''} icon={''} />
          <S.MainBox>
            <S.Box>
              <FeaturesCard header="Documentos para homologação">
                <S.ExplainingText>
                  Envie um documento pessoal com foto (CNH, ou RG) para que seja possível homologar seu
                  projeto nas concessionárias
                </S.ExplainingText>
                <S.FileWrapper>
                  <FileInput
                    header={''}
                    text="Arraste ou clique aqui para fazer upload"
                    mimes={['PDF', 'JPG', 'PNG']}
                    onChange={setFileToUpload}
                    hasFile={!!fileToUpload}
                    isFileSent={isFileSent}
                    fileName={getFileName()}
                  />
                </S.FileWrapper>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                  <S.Button onClick={() => handleUpload()}>
                    <S.ButtonText>Submeter documentos</S.ButtonText>
                  </S.Button>
                </div>
              </FeaturesCard>
            </S.Box>
          </S.MainBox>
        </>
      )}
    </S.Container>
  )
}

export default PaymentStep

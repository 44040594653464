import React from 'react'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import ArrowDownImage from '../../assets/images/down-arrow.svg'
import theme from '../../assets/styles/theme'

const sizes = {
  larger: css`
    font-size: 0.95em;
    padding: 19px;
  `,
  large: css`
    font-size: 1em;
    padding: 16px;
  `,
  medium: css`
    font-size: 0.9em;
    padding: 14px;
  `,
  small: css`
    font-size: 0.8em;
    padding: 10px;
  `,
}

const StyledSelect = styled.select`
  font-size: 1em;
  box-sizing: border-box;
  background: ${theme('colors.white')};
  :focus {
    outline: none !important;
  }
  width: ${props => (props.fullwidth ? '100%' : 'auto')};
  appearance:none;
  background: url(${ArrowDownImage}) no-repeat ${theme('colors.white')};
  background-position: right 12px center;
  ${props => sizes[props.size]}
  border: 1px solid ${theme('colors.solar_21.gray_light')};
  :disabled {
    color: ${theme('colors.gray.700')};
    background-color: ${theme('colors.blue.100')};
    cursor: not-allowed;
  }
  padding-right: 40px;
  ${props =>
    props.hasError &&
    css`
      border: 1px solid ${theme('colors.red.500')};
    `}
`

// ===================================================

const Select = ({ ...rest }) => {
  return <StyledSelect {...rest} />
}

Select.defaultProps = {
  size: 'medium',
  fullwidth: true,
  hasError: null,
}

Select.propTypes = {
  size: PropTypes.oneOf(['medium', 'small', 'large', 'larger']),
  fullwidth: PropTypes.bool,
  hasError: PropTypes.bool,
}

export default Select

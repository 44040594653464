import React from 'react'
import MaskedInput from 'react-text-mask'

import styled, { css } from 'styled-components'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import theme from '../../assets/styles/theme'
import Box from './Box'
import Label from './Label'

const StyledWrapper = styled.div`
  display: flex;
  position: relative;
`

const StyledWrapperInput = styled.div`
  position: relative;
  width: 100%;
`

const borders = {
  before: css`
    border-radius: 6px 0 0 6px;
  `,
  after: css`
    border-radius: 0 6px 6px 0;
  `,
  both: css`
    border-radius: 0px;
  `,
}

const sizes = {
  large: css`
    padding: 16px;
  `,
  medium: css`
    padding: 13px;
  `,
  small: css`
    ${theme('fontSize.sm')};
    padding: 10px;
  `,
}

const variants = {
  filled: css`
    background: ${theme('colors.white')};
    border: 1px solid ${theme('colors.solar_21.gray_light')};
    :focus {
      box-shadow: 0 0 0 1px ${theme('colors.solar_21.gray_light')};
    }
  `,

  outlined: css`
    color: ${theme('colors.blue.900')};
    background: ${theme('colors.white')};
    border: 1px solid ${theme('colors.gray.400')};
    ${props =>
      props.dark &&
      css`
        background: ${theme('colors.blue.100')};
      `}
    :focus {
      border: 1px solid ${theme('colors.blue.600')};
      box-shadow: 0 0 0 1px ${theme('colors.blue.600')};
    }
  `,
}

const StyledInput = styled.input`
  text-transform: ${props => props.textTransform};
  font-size: ${theme('fontSize.base')};
  box-sizing: border-box;
  color: ${theme('colors.gray.700')};
  ::placeholder {
    color: ${theme('colors.solar_21.gray')};
  }
  :disabled {
    color: ${theme('colors.gray.700')};
    background-color: ${theme('colors.blue.100')};
    cursor: not-allowed;
  }
  :focus {
    outline: none !important;
  }
  width: ${props => (props.width ? props.width : 'auto')};
  text-align: ${props => (props.align ? props.align : null)};
  ${props => sizes[props.size]}
  ${props => variants[props.variant]}
  ${props =>
    props.hasIcon &&
    css`
      padding-left: ${props.size === 'large' ? '52px' : '36px'};
    `}
  ${props =>
    props.hasError &&
    css`
      border: 1px solid ${theme('colors.red.500')};
    `}
  ${props => borders[props.auxiliar]}

  ::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
`

const billMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 10,
  allowNegative: false,
  allowLeadingZeroes: false,
}

// eslint-disable-next-line react/prop-types
const CurrencyInput = ({ placeholder, label, name, hasError, errorMessage, ...rest }) => {
  const currencyMask = createNumberMask(billMaskOptions)

  return (
    <Box mb={3}>
      <StyledWrapper>
        <StyledWrapperInput>
          <Box mb="6px" fontWeight={null} color={null}>
            <Label>{label}</Label>
          </Box>
          <MaskedInput
            mask={currencyMask}
            id="input-currency"
            name={name}
            width="100%"
            placeholder={placeholder}
            onChange={rest.onChange}
            render={(ref, props) => (
              <StyledInput
                hasError={hasError}
                size="medium"
                variant="filled"
                ref={ref}
                {...props}
                name={name}
              />
            )}
          />
        </StyledWrapperInput>
      </StyledWrapper>
      {hasError && errorMessage && (
        <Box mt={2} color={theme('colors.red.600')} fontSize="0.857em">
          {errorMessage}
        </Box>
      )}
    </Box>
  )
}

export default CurrencyInput

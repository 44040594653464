import React from 'react'
import * as S from './styles'

type LogoTextProps = {
  text?: string
  logo: any
  alt: string
  color?: string
}

export default function LogoText({text, logo, alt, color = 'black'}: LogoTextProps) {
  return (
    <S.Wrapper>
      <S.Logo src={logo} alt={alt} />
      {text && <S.Text color={color}>{text}</S.Text>}
    </S.Wrapper>
  )
}

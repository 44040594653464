export const LANGUAGES = {
  pt: {
    code: 'pt',
  },
  default: (() => {
    // let language = localStorage.getItem('@language')
    let language = 'pt'
    if (!language) {
      language = window.navigator.language.split('-')[0]
      localStorage.setItem('@language', language)
    }

    return language
  })(),
}

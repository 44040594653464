import React from 'react'
import {render} from 'react-dom'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import IuguSdkScriptProvider from 'contexts/IuguContext'

import App from './App'

Bugsnag.start({
  apiKey: 'e88784ff137e38393713732e983eba56',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
})

const rootElement = document.querySelector('#root')

render(
  <IuguSdkScriptProvider>
    <App />
  </IuguSdkScriptProvider>,
  rootElement
)

import theme from 'assets/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 2px solid ${theme('colors.solar_21.gray_light')};
`

export const TitleGroup = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 3.5rem;
    height: 3.5rem;

    @media screen and (max-width: 1024px) {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
`

export const TitleGroupText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;

  @media only screen and (max-width: 768px) {
    margin-left: 10px;
  }
`
export const Title = styled.span`
  font-size: 40px;
  color: ${theme('colors.solar_21.gray_dark')};
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    font-size: 26px;
    width: 170px;
  }

  &:after {
    content: '';
    margin-left: 15px;
    border-right: 7px solid ${theme('colors.solar_21.regular')};

    @media only screen and (max-width: 768px) {
      border-right: 4px solid ${theme('colors.solar_21.regular')};
    }
  }
`

export const Subtitle = styled.span`
  font-size: 22px;
  font-weight: 200;

  @media only screen and (max-width: 1024px) {
    width: 195px;
    font-size: 14px;
  }
`

export const Notifications = styled.div`
  width: 45px;
  height: 45px;
  padding: 10px;
  border-radius: 50%;
  background: ${theme('colors.solar_21.regular')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  & > span {
    position: absolute;
    padding: 1px 5px;
    border-radius: 100%;
    background-color: red;
    color: white;
    font-size: 13px;
    margin-bottom: 35px;
    margin-left: 35px;
    display: block;
  }
`

export const NotificationIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`

export const Badge = styled.span``

export const NotificatitonList = styled.div`
  background-color: transparent;
  position: absolute;
  padding: 3px 61px;
  margin: 0px 0px 0px -375px;
  width: 420px;
  height: 600px;
  top: 125px;
  z-index: 1030;

  @media only screen and (max-width: 768px) {
    padding: 0px 0px;
    margin: 96px 0px 0px -213px;
    height: 500px;
    top: 0px;
  }

  @media only screen and (max-width: 520px) {
    position: absolute;
    margin: 0;
    margin-top: 100px;
    right: -70px;
  }
`

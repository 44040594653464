export const getCurrentStep = steps => {
  const currentStep = steps.find(step => step.status === 'PENDING')
  let currentType = currentStep?.type
  const hasCurrentStepInstalation = steps?.some(
    step => step.type === 'INSTALATION' && step.status === 'APPROVED'
  )
  const hasCurrentStepProjectStatus = steps?.some(step => step.type === 'PROJECT_STATUS')
  if (hasCurrentStepInstalation && !hasCurrentStepProjectStatus) currentType = 'INSTALATION'
  if (hasCurrentStepProjectStatus) currentType = 'PROJECT_STATUS'
  return currentType
}

/**
 * Returns route to push user based in client step
 * @param {string} step step type, enum
 * @returns {string} route to push user
 */
export const getRouteByStep = (step, isLending = false) => {
  const checkoutRoute = isLending ? '/payment' : '/booking-payment'
  const contractRoute = isLending ? '/payment' : '/customers-signature'

  const options = {
    // best performant than a switch case
    CONTRACT: contractRoute,
    CHECKOUT: checkoutRoute,
    SUPPLY: checkoutRoute,
    BILL: '/bill',
    INSTALATION: '/customers-signature',
    SIGNATURE: '/plan-signature',
    SIMULATION: '/bill',
    PROPOSAL_INITIAL: '/proposal-initial',
    TECHNICAL_VISIT: '/technical-visit',
    PROPOSAL_FINAL: '/proposal-final',
    PROJECT_STATUS: '/project-status',
  }

  return options[step] || '/simulation'
}

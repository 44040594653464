import { takeLatest, all, call, put } from 'redux-saga/effects'

import api from 'services/api'

import type from '../../types'
import { menuSuccess, menuFailure } from './action'

export function* requestMenu () {
  try {
    const response = yield call(api.get, '/portal/customers/steps')
    const data = response.data

    yield put(menuSuccess({ data }))
  } catch (error) {
    yield put(menuFailure())
  }
}

export default all([takeLatest(type.MENU_NOTIFICATIONCHECK_REQUEST, requestMenu)])

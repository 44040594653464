import {toast} from 'react-toastify'

import {takeLatest, all, put, call} from 'redux-saga/effects'

import api from '../../../services/api'
import type from '../../types'
import {
  profileSuccess,
  profileFailure,
  updateProfileRequestSuccess,
  updateProfileRequestFailure,
} from './action'

export function* getProfile() {
  try {
    const response = yield call(api.get, '/portal/customers')
    const data = response.data
    yield put(profileSuccess(data))
  } catch (error) {
    yield put(profileFailure(error))
  }
}

export function* updateProfile({payload}) {
  const {data} = payload
  try {
    yield call(api.patch, '/portal/customers', data)
    yield put(updateProfileRequestSuccess())
    toast.success('Perfil atualizado com sucesso')
  } catch (error) {
    yield put(updateProfileRequestFailure())
    toast.error('Houve um problema ao atualizar o perfil')
  }
}

export default all([
  takeLatest(type.PROFILE_REQUEST, getProfile),
  takeLatest(type.PROFILE_REQUEST_UPDATE, updateProfile),
])

import styled from 'styled-components'

import Button from 'components/ui/button'

export const DivIframe = styled.div`
  width: 100%;
  height: 100%;
`
export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`
export const DivButton = styled.div`
  width: 100%;
  margin-top: 10px;
`

export const ButtonIframe = styled(Button)`
  float: right;
`

import produce from 'immer'

import type from '../../types'

const INITIAL_STATE = {
  pending: false,
  isBusiness: false,
}

export default function initial(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.INITIAL_BUSINESS_REQUEST: {
        draft.pending = false
        draft.isBusiness = action.payload.isBusiness
        break
      }

      default:
    }
  })
}

import React, {useRef, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
// import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import ClipperIcon from 'assets/images/clipper.svg'

// import { sendBillRequest } from '../../../store/modules/bill/action'
import {StyleComp, FileContainerText, StyledButtonUpload} from './styles'

const DropZone = ({onChange, type, insideText, selectBtnText = 'Selecionar documento'}) => {
  const {formatMessage} = useIntl()

  const _fileNotPermitted_ = formatMessage({id: 'bill.file.not.permitted'})
  // const _fileSending_ = formatMessage({ id: 'bill.file.sending' })
  // const _sendArchive_ = formatMessage({ id: 'bill.file.button.send' })

  // const dispatch = useDispatch()

  const fileInputRef = useRef()
  const progressRef = useRef()
  const uploadRef = useRef()
  const uploadModalRef = useRef()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [validFiles, setValidFiles] = useState([])
  const [unsupportedFiles, setUnsupportedFiles] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const filteredArr = selectedFiles.reduce((acc, current) => {
      const items = acc.find(item => item.name === current.name)
      if (!items) {
        return acc.concat([current])
      }
      return acc
    }, [])
    setValidFiles([...filteredArr])
  }, [selectedFiles])

  const validateFile = file => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
    if (validTypes.indexOf(file.type) === -1) {
      return false
    }

    return true
  }

  const handleFiles = files => {
    if (validateFile(files[0])) {
      setSelectedFiles(prevArray => [...prevArray, files[0]])
    } else {
      files[0].invalid = true
      setSelectedFiles(prevArray => [...prevArray, files[0]])
      setErrorMessage(_fileNotPermitted_)
      setUnsupportedFiles(prevArray => [...prevArray, files[0]])
    }
  }

  const preventDefault = e => {
    e.preventDefault()
  }

  const dragOver = e => {
    preventDefault(e)
  }

  const dragEnter = e => {
    preventDefault(e)
  }

  const dragLeave = e => {
    preventDefault(e)
  }

  const fileDrop = e => {
    preventDefault(e)
    const files = e.dataTransfer.files
    if (files.length) {
      handleFiles(files)
    }
  }

  const filesSelected = () => {
    setSelectedFiles([])
    setValidFiles([])
    setUnsupportedFiles([])
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files)
      onChange({type, file: fileInputRef.current.files})
    }
  }

  const fileInputClicked = () => {
    fileInputRef.current.click()
  }

  const closeUploadModal = () => {
    uploadModalRef.current.style.display = 'none'
  }

  return (
    <>
      <StyleComp>
        <div className="container">
          <div
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            onClick={fileInputClicked}
            role="presentation"
          >
            <div className="drop-message">
              <img src={ClipperIcon} alt="clipper" style={{marginRight: '0.5rem', marginBottom: '-0.4rem'}} />
              <FileContainerText>
                {validFiles.length === 0 ? insideText : validFiles.map(data => data.name)}
                {validFiles.map(
                  data => data.invalid && <span className="file-error-message">({errorMessage})</span>
                )}
              </FileContainerText>
              <StyledButtonUpload>{selectBtnText || 'Selecionar documento'}</StyledButtonUpload>
            </div>
            <input ref={fileInputRef} className="file-input" type="file" onChange={filesSelected} />
          </div>
        </div>
      </StyleComp>

      {unsupportedFiles.length ? <p>{_fileNotPermitted_}</p> : ''}

      <div className="upload-modal" ref={uploadModalRef}>
        <div className="overlay" />
        <div className="close" onClick={() => closeUploadModal()} role="presentation" />
        <div className="progress-container">
          <span ref={uploadRef} />
          <div className="progress">
            <div className="progress-bar" ref={progressRef} />
          </div>
        </div>
      </div>
    </>
  )
}
DropZone.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  insideText: PropTypes.string.isRequired,
}

export default DropZone

import React from 'react'
import ReactLoading from 'react-loading'

import PropTypes from 'prop-types'

import theme from '../../assets/styles/theme'
import { Container } from './styles'

function Loading({ width, height, color, type, positionWidth, positionHeight, margin }) {
  return (
    <Container width={positionWidth} height={positionHeight} margin={margin}>
      <ReactLoading type={type} width={width} height={height} color={color} />
    </Container>
  )
}

Loading.defaultProps = {
  width: '50px',
  height: '50px',
  color: theme('colors.solar_21.regular'),
  type: 'spin',
  positionWidth: '100%',
  positionHeight: '100%',
  margin: '0',
}

Loading.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  positionWidth: PropTypes.string,
  positionHeight: PropTypes.string,
  margin: PropTypes.string,
}

export default Loading

import React from 'react'

import PropTypes from 'prop-types'

function LayoutAuth({children}) {
  return <>{children}</>
}

LayoutAuth.propTypes = {
  children: PropTypes.element.isRequired,
}

export default LayoutAuth

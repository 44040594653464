import styled from 'styled-components'

export const SmallDivContent = styled.div`
  width: 100%;
  min-height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`

export const SmallIconRight = styled.img`
  width: 32px;
  height: 32px;
  align-self: center;
  justify-self: center;
  margin-right: 5px;
  margin-bottom: 15px;
`

export const SmallDescriptioContent = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  column-gap: 0.5rem;
`

export const SmallDescriptionText = styled.span`
  align-self: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  max-width: 230px;

  color: #020203;

  @media only screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 1060px) {
    font-size: 1.5rem;
  }
`

export const CenterDiv = styled.div`
  display: grid;
  place-items: center;
`

export const MonthsValue = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 43px;
`

export const MonthsDescription = styled.span`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 16px;
  text-align: center;

  @media only screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 1060px) {
    font-size: 1.5rem;
  }
`

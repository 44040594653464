import React from 'react'

import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {layout} from 'styled-system'

import theme from '../../assets/styles/theme'

const variants = {
  square: css`
    width: 24px;
    height: 24px;
    background: ${props => (props.checked ? theme('colors.solar_21.regular') : theme('colors.white'))};
    border: 2px solid
      ${props => (props.checked ? theme('colors.solar_21.regular') : theme('colors.solar_21.gray_light'))};
    border-radius: 3px;
  `,
  circle: css`
    width: 24px;
    height: 24px;
    background: ${theme('colors.white')};
    border: 2px solid ${theme('colors.solar_21.regular')};
    border-radius: 12px;
    cursor: pointer;
  `,
  medium: css`
    width: 20px;
    height: 20px;
    background: ${theme('colors.white')};
    border-radius: 3px;
  `,
}

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 6px ${theme('colors.blue.200')};
  }
  ${layout}
  ${props => variants[props.variant]}
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

const IconsVariants = {
  circle: <circle cx="12" cy="12" r="8" fill={theme('colors.blue.500')} />,
  square: <polyline points="20 6 9 17 4 12" />,
  medium: <polyline points="20 6 9 17 4 12" stroke="black" />,
}

const Checkbox = ({className, checked, onClick, variant, ...props}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} onClick={onClick} variant={variant}>
      <Icon viewBox="0 0 24 24">{IconsVariants[variant]}</Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

Checkbox.defaultProps = {
  className: '',
  checked: false,
  variant: 'square',
  onClick: () => {},
}

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['square', 'circle', 'medium']),
}

export default Checkbox

import styled, { css } from 'styled-components'
import { layout, space } from 'styled-system'

import theme from '../../../assets/styles/theme'

const sizes = {
  medium: css`
    display: flex;
    align-items: center;
    font-size: 1.2em;
    border-radius: 10px;
    padding: 11px 35px;
  `,
  small: css`
    display: flex;
    align-items: center;
    font-size: 1em;
    border-radius: 8px;
    padding: 6px 10px;
  `,
}

const colors = {
  default: {
    filled_color: theme('colors.black'),
    filled_background: theme('colors.solar_21.regular'),
    filled_hover: theme('colors.solar_21.hover'),
    outlined_color: theme('colors.green.900'),
    outlined_background: theme('colors.white'),
    outlined_border: theme('colors.green.600'),
    outlined_hover: theme('colors.green.700'),
  },
  primary: {
    filled_color: theme('colors.white'),
    filled_background: theme('colors.blue.500'),
    filled_hover: theme('colors.blue.600'),
    outlined_color: theme('colors.blue.500'),
    outlined_background: theme('colors.white'),
    outlined_border: theme('colors.blue.500'),
    outlined_hover: theme('colors.blue.600'),
  },
  secondary: {
    filled_color: theme('colors.white'),
    filled_background: theme('colors.red.500'),
    filled_hover: theme('colors.red.600'),
    outlined_color: theme('colors.red.900'),
    outlined_background: theme('colors.white'),
    outlined_border: theme('colors.red.600'),
    outlined_hover: theme('colors.red.700'),
  },
  gray: {
    filled_color: theme('colors.gray.600'),
    filled_background: theme('colors.gray.300'),
    filled_hover: theme('colors.gray.400'),
    outlined_color: theme('colors.solar_21.gray_light'),
    outlined_background: theme('colors.white'),
    outlined_border: theme('colors.solar_21.gray_light'),
    outlined_hover: theme('colors.gray.100'),
    outlined_hover_color: theme('colors.black'),
    outlined_hover_background: theme('colors.solar_21.regular'),
  },
}

const getColor = property => props => colors[props.color][property]

const variants = {
  filled: css`
    color: ${getColor('filled_color')};
    background-color: ${getColor('filled_background')};
    border: 2px solid ${getColor('filled_background')};
    transition: background-color 0.1s ease-in-out;
    &:hover {
      background-color: ${theme('colors.white')};
      border: 2px solid ${getColor('filled_background')};
    }
  `,

  outlined: css`
    color: ${getColor('outlined_color')};
    background-color: ${getColor('outlined_background')};
    border: 2px solid ${getColor('outlined_border')};
    &:hover {
      color: ${getColor('outlined_hover_color') || getColor('outlined_background')};
      background-color: ${getColor('outlined_hover_background') || getColor('outlined_hover')};
      border: 2px solid ${getColor('outlined_hover_background')};
    }
  `,

  link: css`
    font-size: 0.9em;
    color: ${getColor('outlined_color')};
    background-color: transparent;
    border: none;
    padding: 0;
    font-weight: normal;
    &:hover {
      text-decoration: underline;
    }
  `,

  border: css`
    font-size: 0.9em;
    color: white;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 40px;
    padding: 10px 35px;
    font-weight: normal;
    transition: 0.2s all;
    :hover {
      border: 2px solid ${theme('colors.teal.200')};
      color: ${theme('colors.teal.200')};
    }
  `,
}

export const StyledButton = styled.div`
  display: flex;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform:  ${props => (props.uppercase ? 'uppercase' : 'none')};
  cursor: pointer;
  width: ${props => (props.width ? props.width : 'auto')};
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0.3rem;
  :focus {
    outline: none !important;
  }

  ${props => sizes[props.size]}
  ${props => variants[props.variant]}
  ${props =>
    props.fontColor &&
    css`
      color: ${props.fontColor};
    `}
  ${props =>
    props.borderColor &&
    css`
      border-color: ${props.borderColor};
    `}
  ${props =>
    props.hoverBgColor &&
    css`
      &:hover {
        background-color: ${props.hoverBgColor};
      }
    `}

  ${props =>
    props.selected
      ? css`
          color: ${getColor('outlined_hover_color') || getColor('outlined_background')};
          background-color: ${getColor('outlined_hover_background') || getColor('outlined_hover')};
          border: 2px solid ${getColor('outlined_hover_background')};
        `
      : ''}

  ${layout}
  ${space}
  :disabled,
  [disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

import {all, takeLatest, put, call} from 'redux-saga/effects'
import {toast} from 'react-toastify'

import types from 'store/types'

import api from 'services/api'
import {
  customerSuccessMessageSuccess,
  customerSuccessMessageFailure,
  customerSuccessUploadSuccess,
  customerSuccessUploadFailure,
  customerSuccessTableFailure,
  customerSuccessTableSuccess,
} from './action'

import {profileRequest} from '../profile/action'

export function* getCustomerSuccessMessages({payload}) {
  const {id} = payload
  try {
    const {data} = yield call(api.get, `/portal/customers_success/${id}/get_messages`)

    yield put(customerSuccessMessageSuccess(data))
  } catch (err) {
    yield put(customerSuccessMessageFailure())
  }
}

export function* sendCustomerSuccessUpload({payload}) {
  const {id, file} = payload
  try {
    yield call(api.post, `portal/customers_success/${id}/upload`, file)

    yield put(customerSuccessUploadSuccess())
    toast.success('Arquivo enviado com sucesso!')
  } catch (err) {
    yield put(customerSuccessUploadFailure())
    toast.error('Não foi possível enviar o arquivo')
  }
}

export function* sendCreatCustomerSuccessTable({payload}) {
  const {id} = payload
  try {
    yield call(api.post, 'portal/customers_success/create', {client_id: id})

    yield put(profileRequest())
    yield put(customerSuccessTableSuccess())
  } catch (err) {
    yield put(customerSuccessTableFailure())
    toast.error('Houve erro na verificação de acompanhamento do processo')
  }
}

export default all([
  takeLatest(types.CUSTOMER_SUCCESS_MESSAGE_REQUEST, getCustomerSuccessMessages),
  takeLatest(types.CUSTOMER_SUCCESS_UPLOAD_REQUEST, sendCustomerSuccessUpload),
  takeLatest(types.CUSTOMER_SUCCESS_TABLE_REQUEST, sendCreatCustomerSuccessTable),
])

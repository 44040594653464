export const setCookie = (name: string, value: string, days: number, domain?: string): void => {
  let expires = ``
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  let cookie = `${name}=${value || ``}${expires}; path=/`
  if (domain) cookie += `; domain=${domain}`
  // eslint-disable-next-line
  console.log('cookie', cookie)
  document.cookie = cookie
}

export const getCookie = (name: string): any => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(`;`)
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ` `) c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const eraseCookie = (name: string): void => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

import React from 'react'
import styled from 'styled-components'
import {trimFilename} from 'utils'
import DocumentBackIcon from './images/DocumentBackIcon'
import DocumentFrontIcon from './images/DocumentFrontIcon'

const Container = styled.div`
  padding: 20px;
  z-index: 300;
  margin-top: 30px;
  cursor: pointer;
  width: 100%;
  height: 253px;
  border: 1px dashed #a3aab6;
  border-radius: 8px;
  text-align: center;
  &:hover {
    border: 1px dashed #000;
  }
`

const Title = styled.h2`
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 20px;
`

const Subtitle = styled.span`
  display: block;
  text-align: center;
  font-size: 12px;
`

const Action = styled(Subtitle)`
  font-size: 14px;
  margin-top: 10px;
  span {
    border-bottom: 4px solid #ffd500;
    padding-bottom: 8px;
    font-weight: bold;
  }
`

const InputFile = styled.input`
  display: none;
`

interface Props {
  title: string
  isFront?: boolean
  onSelectDocument: (file: File) => void
}

const CardUpload: React.FC<Props> = ({title, isFront = false, onSelectDocument}) => {
  const fileInputRef = React.createRef<HTMLInputElement>()

  const [selectedFile, setSelectedFile] = React.useState<File>()

  const handleFiles = (file: File) => setSelectedFile(file)

  React.useEffect(() => {
    if (selectedFile) onSelectDocument(selectedFile)
    // eslint-disable-next-line
  }, [selectedFile])

  const filesSelected = () => {
    if (fileInputRef?.current?.files?.length) {
      handleFiles(fileInputRef.current.files[0])
    }
  }

  const fileInputClicked = () => fileInputRef?.current?.click()

  const RenderIcon = () => {
    const style = {width: 200, height: 114}
    return isFront ? <DocumentFrontIcon style={style} /> : <DocumentBackIcon style={style} />
  }

  const preventD = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault()

  const fileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    preventD(event)
    const {files} = event.dataTransfer as DataTransfer
    if (files.length) handleFiles(files[0])
  }

  return (
    <div
      role="presentation"
      onDragOver={preventD}
      onDragEnter={preventD}
      onDragLeave={preventD}
      onDrop={fileDrop}
    >
      <InputFile ref={fileInputRef} onChange={filesSelected} type="file" />

      <Container onClick={fileInputClicked}>
        <Title>{title}</Title>

        <RenderIcon />
        <Subtitle>
          {selectedFile
            ? `Arquivo ${trimFilename(selectedFile.name, 8, '[...]')} selecionado`
            : 'Somente imagens coloridas'}
        </Subtitle>
        <Action>
          <span>Clique aqui para</span>
          {selectedFile ? ' alterar o arquivo selecionado' : ' selecionar o arquivo'}
        </Action>
      </Container>
    </div>
  )
}

export default CardUpload

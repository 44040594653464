import React, { Component } from 'react'
import { IntlProvider } from 'react-intl'

import PropTypes from 'prop-types'

import lang from '../services/language'
import { LANGUAGES } from './languages'
import translations from './translations'

export default class IntlProviderConfigured extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      locale: null,
    }
  }

  componentDidMount() {
    const currentLocale = lang
    const language = LANGUAGES[currentLocale]
    if (!language) {
      window.location.href = `/${LANGUAGES.default}`
      return
    }

    this.setState({ locale: language.code, loading: false })
  }

  render() {
    const { locale, loading } = this.state
    const { children } = this.props

    if (loading) return <div>...</div>

    return (
      <IntlProvider locale={locale} messages={translations[locale]}>
        {children}
      </IntlProvider>
    )
  }
}

IntlProviderConfigured.propTypes = {
  children: PropTypes.element.isRequired,
}

import axios from 'axios'

import {config} from 'config'
import {store} from 'store'
import {logoutClientSuccess} from 'store/modules/auth/action'

import translations from '../i18n/translations'
import lang from './language'

const api = axios.create({
  baseURL: config.api_url,
  withCredentials: true,
})

const getErrorInfo = error => {
  const hasResponse = !!error.response
  const hasData = hasResponse ? !!error.response.data : false
  const info = {status: null, statusText: null, message: ''}
  let messageId = null

  if (hasResponse) {
    info.status = error.response.status
    info.statusText = error.response.statusText
  } else {
    info.message = error.toJSON().message
  }

  if (hasData) {
    info.statusCode = error.response.data.statusCode
    info.code = error.response.data.code
    info.message = error.response.data.message
  }

  if (info.code) {
    messageId = `code.${info.message}` // `code.${info.code}`
  }

  if (info.status === 404) {
    messageId = `code.API_NOT_FOUND`
  }

  if (info.status === 401) {
    messageId = `code.UNAUTHORIZED`
    store.dispatch(logoutClientSuccess())
  }

  if (messageId && translations.hasOwnProperty(lang)) {
    info.originalMessage = info.message
    if (translations[lang].hasOwnProperty(messageId)) {
      info.message = translations[lang][messageId]
    }
    if (translations[lang].hasOwnProperty(`code.${info.code}`)) {
      info.message = translations[lang][`code.${info.code}`]
    }
  }

  return {
    hasResponse,
    hasData,
    ...info,
  }
}

api.interceptors.request.use(async config => {
  // --- Language

  config.headers['Accept-Language'] = lang

  // --- Authorization

  const auth = JSON.parse(localStorage.getItem('persist:solar_21'))

  if (auth && auth.token) {
    const token = JSON.parse(auth.token)
    config.headers.Authorization = `Bearer ${token.token}`
  }

  return config
})

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const info = getErrorInfo(error)

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('\n --------- ERROR ---------')
      // eslint-disable-next-line no-console
      console.table({...info, requestBody: error.config.data})
    }
    throw error
  }
)

export default api

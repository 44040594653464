import React from 'react'
import styled from 'styled-components'

const TooltipContent = styled.div``

const TooltipBox = styled.div`
  position: absolute;
  z-index: 20;
  top: calc(100% + 10px);
  left: 30px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 150px;
  padding: 5px 5px;
  border-radius: 4px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s, padding 0.5s ease-in-out;
  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 40px;
    top: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`
const TooltipCard = styled.div`
  position: relative;
  & ${TooltipContent}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8);
    }
  }
`

interface ITooltip {
  children: React.ReactNode
  tooltipContent: React.ReactNode
  enabled?: boolean
}

const Tooltip: React.FC<ITooltip> = ({children, tooltipContent, enabled = true}) => {
  return (
    <>
      <TooltipCard>
        <TooltipContent>{children}</TooltipContent>
        {enabled && <TooltipBox>{tooltipContent}</TooltipBox>}
      </TooltipCard>
    </>
  )
}

export default Tooltip

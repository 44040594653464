/* eslint-disable */
import React, {createContext, useContext, useState, useEffect} from 'react'

export const IuguContext = createContext()

export const useIuguContext = () => useContext(IuguContext)

const IuguSdkScriptProvider = ({children}) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [Iugu, setIugu] = useState(null)

  const scriptAlreadyExists = () => {
    if (document.querySelector('script#iugu-js') !== null) {
      setHasLoaded(true)
      return true
    }
    return false
  }

  const appendSdkScript = () => {
    const script = document.createElement('script')
    script.id = 'iugu-js'
    script.src = 'https://js.iugu.com/v2'
    script.onload = () => {
      setHasLoaded(true)
      setIugu(window.Iugu)
    }
    document.body.append(script)
  }

  useEffect(() => {
    if (!scriptAlreadyExists()) appendSdkScript()
  }, [])

  useEffect(() => {
    if (!hasLoaded || !Iugu) return

    Iugu.setTestMode(false)
    Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)
    setIsReady(true)
  }, [hasLoaded, Iugu])

  return <IuguContext.Provider value={{isReady, hasLoaded, Iugu}}>{children}</IuguContext.Provider>
}

export default IuguSdkScriptProvider

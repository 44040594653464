import styled from 'styled-components'

import AccessSignupCodeBackground from 'assets/images/access-signup-background.jpeg'
import theme from 'assets/styles/theme'

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`

export const PainelRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  flex-basis: 90%;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
    overflow: hidden;
  }
`

export const PainelLeft = styled.div`
  flex-basis: 50%;
  background-color: ${theme('colors.black')};
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url(${AccessSignupCodeBackground});
  background-repeat: no-repeat;
  background-position: 42% 0;
  background-size: cover;
`

export const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  @media only screen and (max-width: 520px) {
    margin-top: 1.2rem;
  }
`

export const StyledImg = styled.img`
  margin-right: 0.8rem;
  width: 10rem;
  @media only screen and (max-width: 320px) {
    width: 5rem;
  }
`

export const StyledWrapper = styled.div`
  margin: 0 auto;
  display: block;

  @media only screen and (max-width: 1024px) {
    width: 80%;
  }

  @media only screen and (max-width: 520px) {
    width: 80%;
  }
`

export const StyledTextBlack = styled.span`
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 8px;
  color: ${theme('colors.black')};
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 520px) {
    font-size: 16px;
  }
`

export const StyledTitleBlack = styled.span`
  font-weight: 300;
  font-size: 32px;
  color: ${theme('colors.black')};
  @media only screen and (max-width: 1024px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 520px) {
    font-size: 20px;
  }
`

export const StyledSubtitleBlack = styled.span`
  font-weight: bold;
  font-size: 50px;
  color: ${theme('colors.black')};
  @media only screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 520px) {
    font-size: 26px;
  }
`

export const StyledContent = styled.span`
  font-weight: 300;
  font-size: 18px;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
`

export const StyledText = styled.span`
  font-weight: 300;
  font-size: 18px;
`

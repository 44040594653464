import styled from 'styled-components'

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1800px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 1350px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 1260px) {
    grid-template-columns: 1fr;
  }
`

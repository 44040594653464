import React from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'

import Solar_21_black from 'assets/images/logo.svg'
import {accessRequest} from 'store/modules/auth/action'
import * as Yup from 'yup'

import AccessAccountForm from '../forms/accessAccountForm'
import {
  Wrapper,
  PainelLeft,
  PainelRight,
  Background,
  StyledLogo,
  StyledImg,
  StyledWrapper,
  StyledTitleBlack,
  StyledSubtitleBlack,
  StyledText,
} from './styles'
import {formatPhone} from 'utils/phone'

function Login() {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()

  const submitting = useSelector(state => state.auth.pending)
  const failure = useSelector(state => state.auth.failureAccessCode)
  const fullNumber = useSelector(state => state.auth.phone)

  const _accessCode_ = formatMessage({id: 'common.access.code'})

  const _leftTitle_ = formatMessage({id: 'login.left.title'})
  const _leftSubtitle_ = formatMessage({id: 'login.left.subtitle'})
  const _requiredCode_ = formatMessage({id: 'common.access.form.requiredCode'})
  const _maxCode_ = formatMessage({id: 'common.access.form.maxCode'})

  async function handleSubmit(phone, verificationCode) {
    try {
      await Yup.string()
        .min(6, _maxCode_)
        .required(_requiredCode_)
        .validate(verificationCode, {abortEarly: true})

      dispatch(accessRequest(phone, verificationCode))
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.error(err.errors[0])
      }
    }
  }

  return (
    <Wrapper>
      <PainelLeft>
        <StyledWrapper>
          <StyledLogo>
            <StyledImg src={Solar_21_black} />
          </StyledLogo>
          <div style={{marginBottom: '1.2rem', display: 'flex', flexDirection: 'column'}}>
            <StyledTitleBlack>{_leftTitle_}</StyledTitleBlack>
            <StyledSubtitleBlack>{_leftSubtitle_}</StyledSubtitleBlack>
          </div>
          <div style={{marginBottom: '1rem'}}>
            <StyledText>
              {_accessCode_} <span style={{fontWeight: 'bold'}}>{formatPhone(fullNumber)}</span>
            </StyledText>
          </div>
          <AccessAccountForm
            onSubmit={(event, code) => {
              event.preventDefault()
              handleSubmit(fullNumber, code)
            }}
            submitting={submitting}
            fullNumber={fullNumber}
            failure={failure}
          />
        </StyledWrapper>
      </PainelLeft>
      <PainelRight>
        <Background />
      </PainelRight>
    </Wrapper>
  )
}

export default Login

import React, {useState, useEffect, useMemo} from 'react'
import {cloneDeep} from 'lodash'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import api from 'services/api'

import checkIcon from '../../assets/images/icon_check.svg'
import warningIcon from '../../assets/images/icon_warning.svg'
import loadingIcon from '../../assets/images/loading_check.svg'
import logoutIcon from '../../assets/images/logout.svg'
import profileIcon from '../../assets/images/profile.svg'
import FAQIcon from '../../assets/images/faq-button.svg'
import {logoutClientService} from '../../store/modules/auth/action'
import {menuRequest} from '../../store/modules/menu/action'
import {profileRequest} from '../../store/modules/profile/action'
import Nav from '../nav'
import Text from '../ui/Text'
import {
  Sidebar,
  SidebarNav,
  SidebarNavItem,
  SidebarNavLink,
  SidebarLogoContainer,
  SidebarLogo,
  SidebarNavIcon,
  StyledIcon,
  IconsCheck,
  SidebarNavLinkFAQ,
  StyledIconFAQ,
} from './styles'

export default function Navbar() {
  const dispatch = useDispatch()
  const {formatMessage, locale} = useIntl()

  const logout = formatMessage({id: 'navbar.link.logout'})
  const myProfile = formatMessage({id: 'navbar.link.myProfile'})

  const history = useHistory()
  const location = useLocation()

  const clientSteps = useSelector(state => state.menu.menu)
  const profile = useSelector(state => state.profile.profileValues)

  const [showNavbarContent, setShowNavbarContent] = useState(false)
  const [loadSubscriptionLending, setLoadSubscriptionLending] = useState(false)
  const [selected, setSelect] = useState('')
  const [subscriptionLending, setSubscriptionLending] = useState('SUBSCRIPTION')
  const [items, setItems] = useState({
    bill: {
      label: formatMessage({id: 'navbar.link.electricityBill'}),
      to: '/bill',
      icon: 'bill',
      status: <IconsCheck src={loadingIcon} />,
      enable: false,
      style: 'not-allowed',
    },
    proposalInitial: {
      label: formatMessage({id: 'navbar.link.proposalInitial'}),
      to: '/proposal-initial',
      icon: 'bill',
      status: <IconsCheck src={loadingIcon} />,
      enable: false,
      style: 'not-allowed',
    },
    visit: {
      label: formatMessage({id: 'navbar.link.technicalVisit'}),
      to: '/technical-visit',
      icon: 'visit',
      status: <IconsCheck src={loadingIcon} />,
      enable: false,
      style: 'not-allowed',
    },
    plan: {
      label: formatMessage({id: 'navbar.link.planSubscription'}),
      to: '/plan-signature',
      icon: 'plan',
      status: <IconsCheck src={loadingIcon} />,
      enable: false,
      style: 'not-allowed',
    },
    projectStatus: {
      label: formatMessage({id: 'navbar.link.projectStatus'}),
      to: '/project-status',
      icon: 'projectStatus',
      status: <IconsCheck src={loadingIcon} />,
      enable: false,
      style: 'not-allowed',
    },
    payment: {
      label: formatMessage({id: 'navbar.link.payment'}),
      to: '/payment',
      icon: 'projectStatus',
      status: <IconsCheck src={loadingIcon} />,
      enable: false,
      style: 'not-allowed',
    },
  })

  useEffect(() => {
    api
      .get('/portal/customers')
      .then(({data}) => {
        const {subscription_lending} = data

        setSubscriptionLending(subscription_lending)
        setLoadSubscriptionLending(true)
        localStorage.setItem('register-type', subscription_lending)
      })
      .catch(err => console.error('error getting user data: ', err))
  }, [])

  useEffect(() => {
    const billStatus = clientSteps.find(clientStep => clientStep.type === 'BILL')?.status
    if (!billStatus) return

    const register = localStorage.getItem('register-type')
    const isLending = billStatus !== 'PENDING' || register === 'LENDING'
    const isSubscription = billStatus !== 'PENDING' && register === 'SUBSCRIPTION'

    setTimeout(() => {
      setShowNavbarContent(isLending || isSubscription)
    }, 1000)
  }, [clientSteps, setShowNavbarContent])

  const handleChange = (item, _key) => {
    const goto = `/${locale}${item.to}`
    history.push(goto)
  }

  const pathname = useMemo(() => {
    return `/${location.pathname.split('/').slice(2, 3).join('')}`
  }, [location])

  const handleLogout = () => {
    dispatch(logoutClientService())
  }

  const handleFAQ = () => {
    window.location.href = `https://www.solar21.com.br/faq`
  }

  useEffect(() => {
    const itemKey = Object.keys(items).find(key => items[key].to === pathname)

    if (itemKey) {
      setSelect(itemKey)
    } else if (
      (pathname === '/plan-signature' ||
        pathname === '/customers-signature' ||
        pathname === '/booking-payment') &&
      subscriptionLending !== 'LENDING'
    ) {
      setSelect('plan')
    } else if (pathname === '/payment') {
      setSelect('payment')
    } else {
      setSelect('')
    }
  }, [items, pathname, subscriptionLending])

  useEffect(() => {
    dispatch(menuRequest())
    dispatch(profileRequest())
  }, [dispatch, history.location])

  /*useEffect(() => {
    if (!loadSubscriptionLending) return
    if (clientSteps && clientSteps.length) {
      const isLanding = subscriptionLending === 'LENDING'
      const projectStatus = profile?.customer_success_id ? 'APPROVED' : 'PENDING'
      let newStepsArray = clientSteps.concat([
        {client_id: profile.id, type: 'PROJECT_STATUS', status: projectStatus},
      ])

      const status = {
        APPROVED: <IconsCheck src={checkIcon} />,
        NOT_DONE: <IconsCheck src={loadingIcon} />,
        PENDING: <IconsCheck src={loadingIcon} />,
        DENIED: <IconsCheck src={warningIcon} />,
      }

      const clientStepsHandled = {
        bill: newStepsArray.find(clientStep => clientStep.type === 'BILL')?.status,
        visit: newStepsArray.find(clientStep => clientStep.type === 'TECHNICAL_VISIT')?.status,
        plan: newStepsArray.find(clientStep => clientStep.type === 'SIGNATURE')?.status,
        projectStatus: newStepsArray.find(clientStep => clientStep.type === 'PROJECT_STATUS')?.status,
        proposalInitial: newStepsArray.find(clientStep => clientStep.type === 'PROPOSAL_INITIAL')?.status,
        payment: newStepsArray.find(clientStep => clientStep.type === 'CHECKOUT')?.status,
      }

      setItems(prevState => {
        const bodyReturn = {
          ...prevState,
          bill: {...prevState.bill, status: status[clientStepsHandled.bill]},
          visit: {...prevState.visit, status: status[clientStepsHandled.visit]},
          plan: {...prevState.plan, status: status[clientStepsHandled.plan]},
          projectStatus: {...prevState.projectStatus, status: status[clientStepsHandled.projectStatus]},
          proposalInitial: {...prevState.proposalInitial, status: status[clientStepsHandled.proposalInitial]},
          payment: {...prevState.payment, status: status[clientStepsHandled.payment]},
        }
        return bodyReturn
      })

      setItems(prevState => {
        return {
          ...prevState,
          bill: {...prevState.bill, enable: true, style: 'pointer'},
        }
      })

      if (clientStepsHandled.bill === 'APPROVED')
        setItems(prevState => {
          return {
            ...prevState,
            visit: {...prevState.visit, enable: !isLanding, style: isLanding ? 'not-allowed' : 'pointer'},
            proposalInitial: {
              ...prevState.proposalInitial,
              enable: isLanding,
              style: isLanding ? 'pointer' : 'not-allowed',
            },
          }
        })

      if (clientStepsHandled.projectStatus === 'APPROVED')
        setItems(prevState => {
          return {
            ...prevState,
            projectStatus: {...prevState.projectStatus, enable: true, style: 'pointer'},
          }
        })

      if (clientStepsHandled.proposalInitial === 'APPROVED')
        setItems(prevState => {
          return {
            ...prevState,
            proposalInitial: {...prevState.proposalInitial, enable: true, style: 'pointer'},
            visit: {...prevState.visit, enable: true, style: 'pointer'},
          }
        })

      if (clientStepsHandled.visit === 'APPROVED'){
        setItems(prevState => {
          return {
            ...prevState,
            payment: {...prevState.payment, enable: true, style: 'pointer'},
          }
        })

        setItems(prevState => {
          return {
            ...prevState,
            proposalInitial: {...prevState.proposalInitial, enable: false, style: 'not-allowed'},
          }
        })
      }

      if (!isLanding)
        setItems(prevState => {
          const newState = cloneDeep(prevState)
          delete newState.proposalInitial
          delete newState.finalProposal
          delete newState.payment

          return newState
        })
      if (isLanding)
        setItems(prevState => {
          const newState = cloneDeep(prevState)
          delete newState.plan
          delete newState.projectStatus

          return newState
        })
    }
  }, [clientSteps, profile, subscriptionLending, loadSubscriptionLending])*/

  /**
   * TODO: remove this use effect and the comments above
   * so client can naigate over all steps
   */
  useEffect(() => {
    const billState = clientSteps.find(clientStep => clientStep.type === 'BILL')?.status

    const status = {
      APPROVED: <IconsCheck src={checkIcon} />,
      NOT_DONE: <IconsCheck src={loadingIcon} />,
      PENDING: <IconsCheck src={loadingIcon} />,
      DENIED: <IconsCheck src={warningIcon} />,
    }

    setItems({
      bill: {
        label: formatMessage({id: 'navbar.link.electricityBill'}),
        to: '/bill',
        icon: 'bill',
        status: status[billState],
        enable: true,
        style: 'pointer',
      },
    })
  }, [clientSteps])

  return (
    <Sidebar>
      <SidebarNav>
        <SidebarLogoContainer>
          <SidebarLogo>
            <SidebarNavIcon alt="Solar 21" />
          </SidebarLogo>
        </SidebarLogoContainer>
        {showNavbarContent && <Nav onChange={handleChange} selected={selected} items={items} />}
        <SidebarNavItem>
          <SidebarNavLinkFAQ onClick={handleFAQ}>
            <StyledIconFAQ src={FAQIcon} className="ic_nav" />
          </SidebarNavLinkFAQ>
          <SidebarNavLink onClick={() => history.push(`/${locale}/profile`)}>
            <StyledIcon src={profileIcon} className="ic_nav" />
            <Text size="18px">{myProfile}</Text>
          </SidebarNavLink>
          <SidebarNavLink onClick={handleLogout}>
            <StyledIcon src={logoutIcon} className="ic_nav" />
            <Text size="18px">{logout}</Text>
          </SidebarNavLink>
        </SidebarNavItem>
      </SidebarNav>
    </Sidebar>
  )
}

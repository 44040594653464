import React, {useCallback, useEffect, useState} from 'react'
import * as S from '../styles'
import ItauIcon from 'assets/images/banks/itau.svg'
import SantanderIcon from 'assets/images/banks/santander.svg'
import BVIcon from 'assets/images/banks/bv.svg'
import FeaturesCard from '../FeaturesCard/FeaturesCard'
import Hourglass from 'assets/images/hourglass.svg'
import Check from 'assets/images/Check.svg'
import Thumbsup from 'assets/images/thumbsup.svg'
import Exclamation from 'assets/images/exclamation.svg'
import FilesUploads from '../FilesUploads/FilesUploads'
import {LendingStatusEnum} from '..'
import {useSelector} from 'react-redux'
import * as BS from '../Barcode/styles'
import api from 'services/api'

const BANKS = [
  {
    name: 'Itaú',
    icon: ItauIcon,
  },
  {
    name: 'Santander',
    icon: SantanderIcon,
  },
  {
    name: 'BV',
    icon: BVIcon,
  },
]

interface FinanciamentoProps {
  state: LendingStatusEnum
  handler?: number
  handleOpen?: any
  handleSign?: any
  signedContract?: any
  signedContractAttorney?: any
  handleAttorney?: any
}

const Financiamento = ({
  handleOpen,
  handleSign,
  signedContractAttorney,
  handleAttorney,
  signedContract,
}: FinanciamentoProps) => {
  const [state, setState] = useState(LendingStatusEnum.NOT_REQUESTED)

  const loadLendingStatus = async () => {
    const res = await api.get(`/portal/client_lending`)
    setState(res.data.status)
  }

  useEffect(() => {
    loadLendingStatus()
  }, [])

  return (
    <>
      <S.Subcontainer>
        <FinanciamentoRight
          state={state}
          handleOpen={handleOpen}
          handleSign={handleSign}
          signedContractAttorney={signedContractAttorney}
          handleAttorney={handleAttorney}
          signedContract={signedContract}
        />
        <FinanciamentoLeft
          state={state}
          signedContractAttorney={signedContractAttorney}
          signedContract={signedContract}
        />
      </S.Subcontainer>
    </>
  )
}

const FinanciamentoRight = ({
  state,
  handleOpen,
  handleSign,
  handleAttorney,
  signedContract,
  signedContractAttorney,
}: FinanciamentoProps) => {
  const profile = useSelector((stt: any) => stt.profile.profileValues)

  const checkContract = useCallback(() => {
    const currentStep = profile.steps.find((step: any) => step.status === 'PENDING')
    currentStep.type === 'CONTRACT' ? handleSign(true) : handleOpen(true)
  }, [profile, handleOpen, handleSign])

  let iconSrc = Hourglass

  switch (state) {
    case LendingStatusEnum.NOT_REQUESTED:
    case LendingStatusEnum.IN_EVALUATION:
      iconSrc = Hourglass
      break
    case LendingStatusEnum.APPROVED:
      if (signedContract === true && signedContractAttorney === true) {
        iconSrc = Check
      } else {
        iconSrc = Thumbsup
      }
      break
    case LendingStatusEnum.DENIED:
      iconSrc = Exclamation
      break
    default:
      break
  }

  return (
    <FeaturesCard header="Financiamento">
      <S.WaitingPayment>
        <S.WaitingIconWrapper>
          <S.WaitingIcon src={iconSrc} />
        </S.WaitingIconWrapper>
        <S.WaitingTitle style={{paddingBottom: 15}}>
          {state === LendingStatusEnum.NOT_REQUESTED && 'Solicitando financiamento'}
          {state === LendingStatusEnum.IN_EVALUATION && 'Aguarde'}
          {state === LendingStatusEnum.APPROVED &&
            (signedContract === false || signedContractAttorney === false) &&
            'Financiamento aprovado e contrato gerado'}
          {state === LendingStatusEnum.DENIED && 'Financiamento reprovado'}
        </S.WaitingTitle>
        {state === LendingStatusEnum.APPROVED &&
          (signedContract === false || signedContractAttorney === false) && (
            <S.WaitingTitle>Assine digitalmente clicando no botão abaixo.</S.WaitingTitle>
          )}
        {signedContract === true && signedContractAttorney === true && (
          <S.WaitingTitle>Contrato assinado. Siga para as próximas etapas.</S.WaitingTitle>
        )}
        <S.WaitingDescription>
          {state === LendingStatusEnum.NOT_REQUESTED &&
            'Estamos trabalhando para te oferecer a melhor opção de financiamento, em breve um especialista entrará em contato'}
          {state === LendingStatusEnum.IN_EVALUATION &&
            'Estamos simulando as condições de seu financiamento em nossos parceiros, em breve um especialista entrará em contato'}
          {state === LendingStatusEnum.DENIED && 'Seu financiamento não foi aprovado no momento!'}
        </S.WaitingDescription>
        {state === LendingStatusEnum.APPROVED && (
          <>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
              <BS.Button
                disabled={signedContract === true}
                disabled_color={signedContract === true}
                onClick={() => checkContract()}
              >
                <S.ButtonText>Assinar Contrato De Compra</S.ButtonText>
              </BS.Button>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
              <BS.Button
                disabled={signedContract === false || signedContractAttorney === true}
                disabled_color={signedContract === false || signedContractAttorney === true}
                onClick={() => handleAttorney()}
              >
                <S.ButtonText>Assinar Procuração</S.ButtonText>
              </BS.Button>
            </div>
          </>
        )}
      </S.WaitingPayment>
    </FeaturesCard>
  )
}

const FinanciamentoLeft = ({state, signedContractAttorney, signedContract}: FinanciamentoProps) => {
  return (
    <FeaturesCard header="">
      <S.Description>
        O financiamento é realizado por bancos parceiros, que utilizam critérios próprios para liberação ou
        reprovação do financiamento.
      </S.Description>
      <S.BanksContainer>
        {BANKS.map((bank, index) => (
          <S.BankIcon key={index + 'bankIcon'} src={bank.icon} alt={bank.name} />
        ))}
      </S.BanksContainer>
      {state === LendingStatusEnum.APPROVED && (
        <div style={{paddingTop: 50}}>
          <FilesUploads
            status={state}
            signedContractAttorney={signedContractAttorney}
            signedContract={signedContract}
          />
        </div>
      )}
    </FeaturesCard>
  )
}

export default Financiamento

import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import theme from 'assets/styles/theme'
import Button from 'components/ui/button'
import Checkbox from 'components/ui/Checkbox'
import CurrencyInput from 'components/ui/CurrencyInput'
import {Input, Select} from 'components/ui/Fields'
import {useFormik, FormikProvider} from 'formik'
import PropTypes from 'prop-types'
import api from 'services/api'
import history from 'services/history'
import lang from 'services/language'
import {statesRequest} from 'store/modules/common/action'
import styled from 'styled-components'
import * as Yup from 'yup'

const Form = styled.form`
  width: 100%;
  @media only screen and (max-width: 520px) {
    margin-bottom: 1.2rem;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.8rem;
`

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  @media screen and (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`
export const CheckboxGrid = styled.div`
  display: grid;
  @media screen and (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`
export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const TitleCheckBox = styled.p`
  margin-left: 5px;
  text-align: left;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  letter-spacing: 0px;
  color: #a4a2a2;

  @media only screen and (max-width: 768px) {
    font-size: 17px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 14px;
  }
`

export const UseTerm = styled.a`
  text-decoration: underline;
  color: ${theme('colors.solar_21.regular')};
`
const UseTermError = styled.p`
  color: ${theme('colors.red.600')};
  font-size: 0.857em;
`
export default function CreateAccountForm({onSubmit, submitting}) {
  const {formatMessage} = useIntl()
  const dispatch = useDispatch()

  const states = useSelector(state => state.common.states)

  const _email_ = formatMessage({id: 'common.form.email'})
  const _requiredEmail_ = formatMessage({id: 'common.form.email.required'})
  const _validEmail_ = formatMessage({id: 'common.form.email.valid'})
  const _razaoSocial_ = formatMessage({id: 'common.form.razaoSocial'})
  const _requiredRazaoSocial_ = formatMessage({id: 'common.form.razaoSocial.required'})
  const _minRazaoSocial_ = formatMessage({id: 'common.form.razaoSocial.min'})
  const _uf_ = formatMessage({id: 'common.form.uf'})
  const _ufFirstOption_ = formatMessage({id: 'common.form.uf.firstOption'})
  const _requiredUf_ = formatMessage({id: 'common.form.uf.required'})
  const _city_ = formatMessage({id: 'common.form.city'})
  const _cityFirstOption_ = formatMessage({id: 'common.form.city.firstOption'})
  const _requiredCity_ = formatMessage({id: 'common.form.city.required'})
  const _unit_ = formatMessage({id: 'common.form.unit'})
  const _unitFirstOption_ = formatMessage({id: 'common.form.unit.firstOption'})
  const _requiredUnit_ = formatMessage({id: 'common.form.unit.required'})
  const _bill_ = formatMessage({id: 'common.form.bill'})
  const _requiredBillValue_ = formatMessage({id: 'common.form.bill.required'})

  const _cnpj_ = formatMessage({id: 'common.form.cnpj'})
  const _requiredCnpj_ = formatMessage({id: 'common.form.cnpj.required'})
  const _maxCnpj_ = formatMessage({id: 'common.form.cnpj.max'})

  const _send_ = formatMessage({id: 'common.button.send'})
  const _submitting_ = formatMessage({id: 'common.button.submitting'})
  const _back_ = formatMessage({id: 'common.button.back'})

  const _namePlaceholder_ = formatMessage({id: 'common.placeholder.name'})
  const _cnpjPlaceholder_ = formatMessage({id: 'common.placeholder.cnpj'})
  const _emailPlaceholder_ = formatMessage({id: 'common.placeholder.email'})
  const _billPlaceholder_ = formatMessage({id: 'common.placeholder.bill'})

  const [hasUseTermError, setHasUseTermError] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [hasErrorBill, setHasErrorBill] = useState(false)
  const [cities, setCities] = useState([])

  const handleValidateForm = async values => {
    const schema = Yup.object().shape({
      cnpj: Yup.string().required(_requiredCnpj_).max(14, _maxCnpj_),
      razaoSocial: Yup.string().required(_requiredRazaoSocial_).min(3, _minRazaoSocial_),
      email: Yup.string().required(_requiredEmail_).email(_validEmail_),
      state: Yup.string().required(_requiredUf_),
      city: Yup.string().required(_requiredCity_),
      unit_type: Yup.string().required(_requiredUnit_),
      bill: Yup.number().min(1, _requiredBillValue_).required(_requiredBillValue_),
    })

    if (!isChecked) setHasUseTermError(true)

    if (!values?.bill) setHasErrorBill(true)

    const errors = {}

    await schema.validate(values, {abortEarly: false}).catch(error =>
      error.inner.forEach(item => {
        errors[item.path] = item.message
      })
    )

    return errors
  }

  const formikbag = useFormik({
    initialValues: {
      cnpj: '',
      razaoSocial: '',
      email: '',
      state: '',
      city: '',
      unit_type: '',
    },
    onSubmit,
    validate: handleValidateForm,
    validateOnBlur: false,
    validateOnChange: false,
  })

  useEffect(() => {
    dispatch(statesRequest())
  }, [dispatch])

  useEffect(() => {
    if (formikbag.values.state) {
      api.get(`/portal/cities/${formikbag.values.state}`).then(({data}) => {
        if (data.success) setCities(data.data)
      })
    }
  }, [formikbag.values.state])

  const handleClickCheckbox = () => {
    setIsChecked(!isChecked)
    setHasUseTermError(false)
  }

  return (
    <FormikProvider value={formikbag}>
      <Form>
        <StyledGrid>
          <Input label={_cnpj_} name="cnpj" placeholder={_cnpjPlaceholder_} />
          <Input label={_razaoSocial_} name="razaoSocial" placeholder={_namePlaceholder_} />
          <Input label={_email_} name="email" placeholder={_emailPlaceholder_} />
          <GridGroup>
            <Select
              label={_uf_}
              name="state"
              onChange={formikbag.handleChange}
              value={formikbag.values.state}
            >
              <option value="">{_ufFirstOption_}</option>
              {states.map(uf => (
                <option value={uf} key={uf}>
                  {uf}
                </option>
              ))}
            </Select>
            <Select label={_city_} name="city" disabled={!formikbag.values.state}>
              <option value="">{_cityFirstOption_}</option>
              {cities.map(city => (
                <option value={city.city_id} key={city.city_id}>
                  {city.name}
                </option>
              ))}
            </Select>
          </GridGroup>
          <GridGroup>
            <Select label={_unit_} name="unit_type">
              <option value="">{_unitFirstOption_}</option>
              <option value="CONDOMINIO">Condomínio</option>
              <option value="EMPRESA">Empresa</option>
            </Select>
            <CurrencyInput
              onChange={e => {
                const {value} = e.target
                const withoutPrefix = parseFloat(value.replace('R$ ', '').replace(',', '.'))
                setHasErrorBill(false)
                formikbag.setFieldValue('bill', withoutPrefix)
              }}
              label={_bill_}
              name="bill"
              placeholder={_billPlaceholder_}
              hasError={hasErrorBill}
              errorMessage="O valor da conta é obrigatório"
            />
          </GridGroup>
          <CheckboxGrid>
            <CheckboxContainer>
              <Checkbox name="terms" checked={isChecked} onClick={handleClickCheckbox} />
              <TitleCheckBox>
                Declaro que estou ciente dos{' '}
                <UseTerm href={process.env.REACT_APP_PRIVACY_POLICY} target="blank">
                  termos de uso
                </UseTerm>
              </TitleCheckBox>
            </CheckboxContainer>
            {hasUseTermError && <UseTermError>Você deve concordar com os Termos!</UseTermError>}
          </CheckboxGrid>
        </StyledGrid>
        <Button
          width="100%"
          type="submit"
          disabled={!!submitting}
          onClick={formikbag.handleSubmit}
          style={{marginTop: '1.2rem'}}
        >
          {submitting ? _submitting_ : _send_}
        </Button>
        <Button
          width="100%"
          variant="link"
          style={{marginTop: '1.2rem'}}
          radius="25px"
          onClick={() => {
            history.push(`/${lang}/sign-up`)
          }}
        >
          {_back_}
        </Button>
      </Form>
    </FormikProvider>
  )
}

CreateAccountForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
}

CreateAccountForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
}

import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  width: 90%;
  @media (max-width: 767px) {
    width: 100%;
  }
  margin: 40px auto 0;
  word-break: break-all;
`

export const BlocTabs = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
`

export const TabTitle = styled.span`
  word-break: break-word;
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1em, 1em + 3vw, 1em);
  display: flex;
  align-items: center;
`

export const Tabs = styled.div<{isActive?: boolean}>`
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  // width: 140px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  text-align: center;
  box-sizing: content-box;
  outline: none;
  width: 100%;
  margin: 0 auto;
  img {
    width: 24px;
    margin-right: 10px;
    @media (max-width: 767px) {
      width: 20px;
      margin-right: 5px;
    }
  }
  display: flex;
  align-items: center;
  ${({isActive}) => `
    cursor: ${isActive ? `default` : `pointer`};
    border: 1px solid ${isActive ? `none` : `#D8D7D4`};
    background-color: ${isActive ? `#ffd500` : `#f3f4f8`};
    box-shadow: ${isActive ? `#D8D7D4 0px 7px 19px 0px` : `0`};
  `}
`

export const ContentTabs = styled.div`
  font-family: 'Roboto', sans-serif;
  flex-grow: 1;
  transition: all 0.3s;
  background-color: #f2f4f7;
  padding: 0px;
`

export const Content = styled.div<{active?: boolean}>`
  font-family: 'Roboto', sans-serif;
  background-color: #f3f4f8;
  /* padding: 20px; */
  width: 100%;
  /* height: 100%; */
  display: none;
  transition: all 0.3s;
  display: ${props => (props.active ? 'block' : 'none')};
`

import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'

import closePlan from 'assets/images/close-plan.svg'
import NotHavPlan from 'assets/images/close.svg'
import HavPlan from 'assets/images/have_in_plan.svg'
import iconSolar from 'assets/images/solar_21_logo_black.svg'
import Button from 'components/ui/button'
import PropTypes from 'prop-types'
import {setSelectedPlan} from 'store/modules/plan/action'
import priceFormatter from 'utils/priceFormatter'

import * as S from './styles'

function TablePlans({content, onSelect}) {
  const {formatMessage} = useIntl()
  const dispatch = useDispatch()
  const _planLow_ = formatMessage({id: 'plans.content.plan.low'})
  const _planMed_ = formatMessage({id: 'plans.content.plan.med'})
  const _planMax_ = formatMessage({id: 'plans.content.plan.max'})
  const _deadline_ = formatMessage({id: 'plans.table.content.deadline'})
  const _instalation_ = formatMessage({id: 'plans.table.content.installation.membership'})
  const _monitoring_ = formatMessage({id: 'plans.table.content.monitoring'})
  const _maintenance_ = formatMessage({id: 'plans.table.content.maintenance.and.service'})
  const _freeMonth_ = formatMessage({id: 'plans.table.content.free.month.fee'})
  const _freeMonthpartnership_ = formatMessage({id: 'plans.table.content.free.month.fee.partnership'})
  const _free_ = formatMessage({id: 'plans.table.content.free'})
  const _twenty_ = formatMessage({id: 'plans.table.content.twenty'})
  const _monthSaving_ = formatMessage({id: 'plans.table.content.month.savings'})
  const _powerandsizeSystem_ = formatMessage({id: 'plans.table.content.powerandsize'})
  const _TitleSistemaSolar_ = formatMessage({id: 'plans.table.content.SystemSolar'})
  const [selected, setSelected] = useState({
    first: false,
    second: false,
    third: true,
  })

  const handleSelected = e => {
    const isSelected = e.target.value
    const low = isSelected === 'low'
    const med = isSelected === 'med'
    const max = isSelected === 'max'
    setSelected({
      first: low,
      second: med,
      third: max,
    })
    dispatch(setSelectedPlan(isSelected))
    if (onSelect) onSelect()
  }

  const table = (
    <S.Tbody>
      <S.Tr>
        <S.TdTitle>{_deadline_}</S.TdTitle>
        <S.Td selected={selected.first}>{content.planLow.deadline} Anos</S.Td>
        <S.Td selected={selected.second}>{content.planMed.deadline} Anos</S.Td>
        <S.Td selected={selected.third}>{content.planMax.deadline} Anos</S.Td>
      </S.Tr>
      <S.Tr>
        <S.TdTitle selected>{_monthSaving_}</S.TdTitle>
        <S.TdEconomy selected={selected.first}>
          <ul>
            <li style={{fontFamily: 'Roboto'}}>
              {priceFormatter(content.planLow.reduction_percent, true)} %
            </li>
            <li style={{fontFamily: 'Roboto'}}>R$ {priceFormatter(content.planLow.annual_savings / 12)}</li>
          </ul>
        </S.TdEconomy>
        <S.TdEconomy selected={selected.second}>
          <ul>
            <li style={{fontFamily: 'Roboto'}}>
              {priceFormatter(content.planMed.reduction_percent, true)} %
            </li>
            <li style={{fontFamily: 'Roboto'}}> R$ {priceFormatter(content.planMed.annual_savings / 12)}</li>
          </ul>
        </S.TdEconomy>
        <S.TdEconomy selected={selected.third}>
          <ul>
            <li style={{fontFamily: 'Roboto'}}>
              {priceFormatter(content.planMax.reduction_percent, true)} %
            </li>
            <li style={{fontFamily: 'Roboto'}}>R$ {priceFormatter(content.planMax.annual_savings / 12)}</li>
          </ul>
        </S.TdEconomy>
      </S.Tr>
      {content.planMax.partnership_validation ? (
        <S.Tr>
          <S.TdTitle>{_freeMonthpartnership_}</S.TdTitle>
          <S.Td selected={selected.first}>
            <img src={NotHavPlan} alt="Não" />
          </S.Td>
          <S.Td selected={selected.second}>
            <img src={NotHavPlan} alt="Não" />
          </S.Td>
          <S.Td selected={selected.third}>
            <img src={HavPlan} alt="Sim" />
          </S.Td>
        </S.Tr>
      ) : null}
      <S.Tr>
        <S.TdTitle>{_instalation_}</S.TdTitle>
        <S.Td selected={selected.first}>{_free_}</S.Td>
        <S.Td selected={selected.second}>{_free_}</S.Td>
        <S.Td selected={selected.third}>{_free_}</S.Td>
      </S.Tr>
      <S.Tr>
        <S.TdTitle>{_powerandsizeSystem_}</S.TdTitle>
        <S.Td selected={selected.first}>
          <ul>
            <li style={{fontFamily: 'Roboto'}}>{content.planLow.total_potential} kWp</li>
            <li style={{fontFamily: 'Roboto'}}>
              {parseFloat(content.planLow.annual_production).toFixed()} kWh/ano
            </li>
          </ul>
        </S.Td>
        <S.Td selected={selected.second}>
          <ul>
            <li style={{fontFamily: 'Roboto'}}>{content.planMed.total_potential} kWp</li>
            <li style={{fontFamily: 'Roboto'}}>
              {parseFloat(content.planMed.annual_production).toFixed()} kWh/ano
            </li>
          </ul>
        </S.Td>
        <S.Td selected={selected.third}>
          <ul>
            <li style={{fontFamily: 'Roboto'}}>{content.planMax.total_potential} kWp</li>
            <li style={{fontFamily: 'Roboto'}}>
              {parseFloat(content.planMax.annual_production).toFixed()} kWh/ano
            </li>
          </ul>
        </S.Td>
      </S.Tr>
      <S.Tr>
        <S.TdTitle>{_TitleSistemaSolar_}</S.TdTitle>
        <S.Td selected={selected.first}>
          <img style={{maxHeight: '20px'}} src={closePlan} alt="" />
        </S.Td>
        <S.Td selected={selected.second}>
          <img style={{maxHeight: '20px'}} src={closePlan} alt="" />
        </S.Td>
        <S.Td selected={selected.third}>{_twenty_}</S.Td>
      </S.Tr>
      <S.Tr>
        <S.TdTitle>{_monitoring_}</S.TdTitle>
        <S.Td selected={selected.first}>
          <img src={content.planLow.has_monitoring_by_app ? HavPlan : NotHavPlan} alt="" />
        </S.Td>
        <S.Td selected={selected.second}>
          <img src={content.planMed.has_monitoring_by_app ? HavPlan : NotHavPlan} alt="" />
        </S.Td>
        <S.Td selected={selected.third}>
          <img src={content.planMax.has_monitoring_by_app ? HavPlan : NotHavPlan} alt="" />
        </S.Td>
      </S.Tr>
      <S.Tr>
        <S.TdTitle>{_maintenance_}</S.TdTitle>
        <S.Td selected={selected.first}>
          <img src={content.planLow.has_assistance ? HavPlan : NotHavPlan} alt="" />
        </S.Td>
        <S.Td selected={selected.second}>
          <img src={content.planMed.has_assistance ? HavPlan : NotHavPlan} alt="" />
        </S.Td>
        <S.Td selected={selected.third}>
          <img src={content.planMax.has_assistance ? HavPlan : NotHavPlan} alt="" />
        </S.Td>
      </S.Tr>
      {content.planMax.freeMonth ? (
        <S.Tr>
          <S.TdTitle>{_freeMonth_}</S.TdTitle>
          <S.Td selected={selected.first}>
            <img src={NotHavPlan} alt="Não" />
          </S.Td>
          <S.Td selected={selected.second}>
            <img src={NotHavPlan} alt="Não" />
          </S.Td>
          <S.Td selected={selected.third}>
            <img src={HavPlan} alt="Sim" />
          </S.Td>
        </S.Tr>
      ) : null}
    </S.Tbody>
  )

  const renderPlanPrice = (hasFit, price) => {
    if (!hasFit) return <span>Não apto</span>

    return (
      <>
        <small>R$</small>
        {priceFormatter(price)}
        <small>/mês</small>
      </>
    )
  }

  const tableHead = (
    <S.Thead>
      <S.Tr>
        <td />
        <td></td>
        <td></td>
        <td></td>
      </S.Tr>
      <S.TrHead>
        <td />
        <S.Th selected={selected.first}>
          <p>
            <S.ImageLogo src={iconSolar} alt="" /> {_planLow_}
          </p>
          <S.Price>{renderPlanPrice(content.planLow.is_fit, content.planLow.price)}</S.Price>
        </S.Th>

        <S.Th selected={selected.second}>
          <p>
            <S.ImageLogo src={iconSolar} alt="" /> {_planMed_}
          </p>
          <S.Price>{renderPlanPrice(content.planMed.is_fit, content.planMed.price)}</S.Price>
        </S.Th>

        <S.Th selected={selected.third}>
          <p>
            <S.ImageLogo src={iconSolar} alt="" /> {_planMax_}
          </p>
          <S.Price>{renderPlanPrice(content.planMax.is_fit, content.planMax.price)}</S.Price>
        </S.Th>
      </S.TrHead>
    </S.Thead>
  )

  const actionButtons = (
    <tfoot>
      <S.Tr>
        <S.Td />
        <S.Td>
          <S.ButtonGroup>
            <Button
              disabled={!content.planLow.is_fit}
              title={content.planLow.is_fit ? 'Clique para selecionar' : 'Plano nao disponível'}
              variant="outlined"
              color="gray"
              size="small"
              onClick={handleSelected}
              value="low"
              selected={selected.first}
            >
              Selecione
            </Button>
          </S.ButtonGroup>
        </S.Td>
        <S.Td>
          <S.ButtonGroup>
            <Button
              disabled={!content.planMed.is_fit}
              title={content.planMed.is_fit ? 'Clique para selecionar' : 'Plano nao disponível'}
              variant="outlined"
              color="gray"
              size="small"
              onClick={handleSelected}
              value="med"
              selected={selected.second}
            >
              Selecione
            </Button>
          </S.ButtonGroup>
        </S.Td>
        <S.Td>
          <S.ButtonGroup>
            <Button
              disabled={!content.planMax.is_fit}
              title={content.planMax.is_fit ? 'Clique para selecionar' : 'Plano nao disponível'}
              variant="outlined"
              color="gray"
              size="small"
              onClick={handleSelected}
              value="max"
              selected={selected.third}
            >
              Selecione
            </Button>
          </S.ButtonGroup>
        </S.Td>
      </S.Tr>
    </tfoot>
  )

  return (
    <S.ResponsiveTable>
      <S.SectionTable>
        {tableHead}
        {table}
        {actionButtons}
      </S.SectionTable>
    </S.ResponsiveTable>
  )
}

TablePlans.defaultProps = {
  content: {},
}

TablePlans.propTypes = {
  content: PropTypes.object,
}

export default TablePlans

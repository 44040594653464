export default function currencyFormatter(
  value,
  options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'BRL',
    style: 'currency',
  }
) {
  if (isNaN(value)) {
    return value
  }
  return value.toLocaleString('pt-br', options)
}

import styled from 'styled-components'

import theme from 'assets/styles/theme'

export const StyledEconomySimulationContent = styled.div`
  margin: 4rem;

  @media only screen and (max-width: 768px) {
    margin: 1rem;
    margin-bottom: 6rem;
  }
`

export const CalendlyStyle = styled.div`
  position: relative;
`

export const StyledTimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-height: 350px;
  overflow-y: scroll;
`

export const StyledDateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const StyledTime = styled.div`
  display: flex;
  padding: 20px 50px;
  border: 2px ${theme('colors.solar_21.gray_light')} solid;
  color: ${theme('colors.solar_21.gray_light')};
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  font-weight: bold;
  cursor: pointer;
  max-width: 200px;

  @media (max-width: 768px) {
    max-width: 100%;
    justify-content: center;
  }

  &:hover {
    background-color: ${theme('colors.solar_21.regular')};
    border: 2px solid transparent;
    color: black;
  }

  &.selected {
    background-color: ${theme('colors.solar_21.regular')};
    border: 2px solid transparent;
    color: black;
  }
`
export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`
export const ScheduleField = styled.div`
  margin: 2px 0;
  display: flex;
  align-items: flex-end;
  &.span {
    font-size: 1.2rem;
  }
`

export const ScheduleFieldKey = styled.span`
  font-weight: bold;
  margin-right: 5px;
  font-size: 1.2rem;
  color: ${theme('colors.solar_21.gray_slight_light')};
`

export const AuroraContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 50px 0;
`

export const ScheduleFieldValue = styled.span`
  font-size: 1.2rem;
  color: ${theme('colors.solar_21.gray')};
`

export const Bar = styled.div`
  background-color: ${props => props.color};
  width: 100%;
  height: 110px;
  border-radius: 80px;
  padding-left: 200px;
  display: flex;
  align-items: center;
  position: absolute;

  @media (max-width: 768px) {
    width: 100%;
    height: 90px;
    padding: 10px;
    padding-left: 90px;
    justify-content: center;
    flex-direction: column;
  }
`

export const StatusText = styled.span`
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight};
  font-size: 1.4rem;
  margin-right: ${props => props.marginRight};

  @media (max-width: 768px) {
    font-size: 1.1rem;
    justify-content: center;
    display: flex;
    text-align: center;
  }
`
export const AuroraImage = styled.img`
  z-index: 2;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`
export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`
export const CalendarIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

import produce from 'immer'

import type from '../../types'

const INITIAL_STATE = {
  pending: false,
  file: null,
  holder: null,
  isUploading: false,
  billValues: {
    status: '',
    consumer_class: null,
    value: null,
    consumption: null,
  },
  billOwner: {},
  billInfo: [],
  id: '',
}

export default function bill(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.BILL_ACCOUNT_SEND_REQUEST: {
        draft.pending = true
        draft.isUploading = true
        draft.file = action.payload.file
        draft.holder = action.payload.holder
        break
      }
      case type.BILL_ACCOUNT_SEND_SUCCESS: {
        draft.pending = false
        draft.isUploading = false
        draft.billValues = action.payload.data
        break
      }
      case type.BILL_ACCOUNT_SEND_FAILURE: {
        draft.pending = false
        draft.isUploading = false
        break
      }
      case type.BILL_ACCOUNT_RECEIVE_REQUEST: {
        draft.pending = true
        break
      }
      case type.BILL_ACCOUNT_RECEIVE_SUCCESS: {
        draft.pending = false
        draft.billValues = action.payload.data
        break
      }
      case type.BILL_ACCOUNT_RECEIVE_FAILURE: {
        draft.pending = false
        break
      }
      case type.BILL_OWNER_REQUEST: {
        draft.pending = true
        break
      }
      case type.BILL_OWNER_SUCCESS: {
        draft.pending = true
        draft.billOwner = action.payload.data
        break
      }
      case type.BILL_OWNER_FAILURE: {
        draft.pending = false
        break
      }
      case type.BILL_INFO_REQUEST: {
        draft.pending = true
        draft.billInfo = []
        draft.id = action.payload.id
        break
      }
      case type.BILL_INFO_SUCCESS: {
        draft.pending = false
        draft.billInfo = action.payload.data
        break
      }
      case type.BILL_INFO_FAILURE: {
        draft.pending = false
        draft.billInfo = []
        break
      }
      default:
    }
  })
}

import { toast } from 'react-toastify'

import { takeLatest, all, call, put } from 'redux-saga/effects'

import api from 'services/api'

import type from '../../types'
import {
  citiesFailure,
  citiesSuccess,
  statesSuccess,
  statesFailure,
  notificationSuccess,
  notificationFailure,
  notificationDealsSuccess,
  notificationDealsFailure,
} from './action'
import estados_cidades from './estados-cidades.json'

export function* stateRequest () {
  try {
    const data = estados_cidades.map(uf => uf.sigla).sort()
    yield put(statesSuccess({ data }))
  } catch (error) {
    yield put(statesFailure())
  }
}

export function* citiesRequest ({ payload }) {
  const { state } = payload
  try {
    const index = estados_cidades.findIndex(obj => obj.sigla === state)
    const obj = estados_cidades[index]
    const data = obj.cidades.sort()
    yield put(citiesSuccess({ data }))
  } catch (error) {
    yield put(citiesFailure())
  }
}

export function* notificationRequest () {
  try {
    const notification = yield call(api.get, '/portal/notifications')
    yield put(notificationSuccess(notification))
  } catch (error) {
    yield put(notificationFailure())
  }
}

export function* notificationDealsRequest ({ payload }) {
  const { id } = payload
  try {
    yield call(api.patch, `/portal/notifications/${id}`, { status: 'READ' })
    yield put(notificationDealsSuccess())
  } catch (error) {
    toast.error('Erro ao ler notificação')
    yield put(notificationDealsFailure())
  }
}

export default all([
  takeLatest(type.COMMON_STATES_REQUEST, stateRequest),
  takeLatest(type.COMMON_CITIES_REQUEST, citiesRequest),
  takeLatest(type.COMMON_NOTIFICATION_REQUEST, notificationRequest),
  takeLatest(type.COMMON_NOTIFICATIONDEALS_REQUEST, notificationDealsRequest),
])

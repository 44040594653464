import styled from 'styled-components'

import LogInBackground from '../../assets/images/background-login.jpeg'
import theme from '../../assets/styles/theme'

export const Wrapper = styled.div`
display: flex;
height: 100%;

@media screen and (max-width: 520px) {
  flex-direction: column-reverse;
}

@media only screen and (max-width: 520px) {
  overflow: scroll;
  height: auto;
}
`

export const PainelLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  flex-basis: 50%;
`

export const PainelRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  flex-basis: 50%;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${LogInBackground});
  background-repeat: no-repeat;
  background-size: cover;
`

export const StyledDiv = styled.div`
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1450px) {
    right: 140px;
  }
  @media only screen and (max-width: 1024px) {
    right: 35px;
  }
  @media only screen and (max-width: 768px) {
    right: 34px;
    bottom: 400px;
    top: auto;
    height: auto;
  }
  @media only screen and (max-width: 520px) {
    right: 75px;
    bottom: 80px;
    top: auto;
    height: auto;
  }
  @media only screen and (max-width: 375px) {
    right: 55px;
    bottom: 80px;
    top: auto;
    height: auto;
  }
  @media only screen and (max-width: 360px) {
    right: 50px;
    bottom: 60px;
    top: auto;
    height: auto;
  }
  @media only screen and (max-width: 320px) {
    right: 34px;
    bottom: 45px;
    top: auto;
    height: auto;
  }
`

export const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  @media only screen and (max-width: 520px) {
    margin-top: 1.2rem;
  }
`

export const StyledImg = styled.img`
  margin-right: 0.8rem;
  width: 10rem;
  @media only screen and (max-width: 320px) {
    width: 5rem;
  }
`

export const StyledText = styled.span`
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 8px;
  color: ${theme('colors.white')};
  @media only screen and (max-width: 520px) {
    font-size: 20px;
  }
`

export const StyledTitle = styled.span`
  font-weight: 300;
  font-size: 32px;
  color: ${theme('colors.white')};
  @media only screen and (max-width: 768px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 520px) {
    font-size: 20px;
  }
`

export const StyledSubtitle = styled.span`
  font-weight: bold;
  font-size: 50px;
  color: ${theme('colors.white')};
  margin-bottom: 1.5rem;
  @media only screen and (max-width: 768px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 520px) {
    font-size: 26px;
  }
`

export const StyledWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  display: block;

  @media only screen and (max-width: 1024px) {
    width: 80%;
  }

  @media only screen and (max-width: 520px) {
    width: 90%;
    margin: 16px auto;
    height: 100%;
  }
`

export const StyledTextBlack = styled.span`
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 8px;
  color: ${theme('colors.black')};
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 520px) {
    font-size: 16px;
  }
`

export const StyledTitleBlack = styled.span`
  font-weight: 300;
  font-size: 32px;
  color: ${theme('colors.black')};
  @media only screen and (max-width: 1024px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 520px) {
    font-size: 20px;
  }
`

export const StyledSubtitleBlack = styled.span`
  font-weight: bold;
  font-size: 50px;
  color: ${theme('colors.black')};
  @media only screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 520px) {
    font-size: 26px;
  }
`

export const StyledContent = styled.span`
  font-weight: 300;
  font-size: 18px;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
`

import produce from 'immer'

import type from '../../types'

const INITIAL_STATE = {
  pending: false,
  menu: [],
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.MENU_NOTIFICATIONCHECK_REQUEST: {
        draft.pending = true
        break
      }

      case type.MENU_NOTIFICATIONCHECK_SUCCESS: {
        draft.pending = false
        draft.menu = action.payload.data
        break
      }

      case type.MENU_NOTIFICATIONCHECK_FAILURE: {
        draft.pending = false
        break
      }

      default:
    }
  })
}

import React, {useCallback, useEffect, useState} from 'react'
import * as S from '../styles'
import * as BS from './styles'
import FeaturesCard from '../FeaturesCard/FeaturesCard'
import ContractIcon from 'assets/images/contract.svg'
import FileInput from 'components/fileInput'
import {useSelector} from 'react-redux'
import api from 'services/api'
import { toast } from 'react-toastify'
import { AxiosRequestConfig } from 'axios'

interface BarcodeProps {
  setPaymentOption: (option: string) => void
}

const Barcode = ({
  handleOpen,
  handleSign,
  handleAttorney,
  signedContract,
  signedContractAttorney,
}: {
  handleOpen: any
  handleSign: any
  handleAttorney: any
  signedContract: boolean
  signedContractAttorney: boolean
}) => {
  return (
    <S.Subcontainer>
      <BarcodeLeft
        handleOpen={handleOpen}
        handleSign={handleSign}
        handleAttorney={handleAttorney}
        signedContract={signedContract}
        signedContractAttorney={signedContractAttorney}
      />
      <BarcodeRight signedContractAttorney={signedContractAttorney} />
    </S.Subcontainer>
  )
}

const BarcodeRight = ({signedContractAttorney}: {signedContractAttorney: boolean}) => {
  const [isFileSent, setIsFileSent] = useState<boolean>(false)
  const [payPress, setPayPress] = useState<boolean>(false)
  const [fileToUpload, setFileToUpload] = useState<File | null>(null)
  const getFileName = useCallback(() => {
    if (fileToUpload) return fileToUpload.name
    return ''
  }, [fileToUpload])

  const profile = useSelector((stt: any) => stt.profile.profileValues)

  const handlePay = () => {
    api
      .patch(`portal/customers/create/${profile.id}/invoice`)
      .then(({data}) => {
        setPayPress(true)
        window.open(data.invoice_url, '_blank')
      })
      .catch(err => console.error('Error creating client invoice: ', err))
  }

  const getDocuments = useCallback(() => {
    api
      .get('/portal/customers/documents')
      .then(({data}) => {
        data.forEach((element: any) => {
          if (element.type === 'DOCUMENT') setIsFileSent(true)
        })
      })
      .catch(err => console.error(err))
  }, [])

  useEffect(() => {
    getDocuments()
  }, [getDocuments])

  const handleUpload = useCallback(async () => {
    if (isFileSent) {
      toast.error('Arquivo já encaminhado!')
      return
    }
    if (fileToUpload === null) {
      toast.error('Insira o documento necessário!')
      return
    }

    const formData = new FormData()
    formData.append('document', fileToUpload)

    const headerParams = {
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Content-Type': 'multipart/form-data',
    }

    await api
      .post('/portal/customers/documents/proposal', formData, headerParams as AxiosRequestConfig)
      .then(() => toast.success('Documentos enviados com sucesso!'))
      .catch(err => console.error('Error upaloadin document', err))
      .finally(() => getDocuments())
  }, [fileToUpload, getDocuments, isFileSent])

  return (
    <BS.ContainerFlexRight>
      <FeaturesCard header="Pagamento">
        <BS.ExplainingText>
          Clique em “Pagar” para gerar 3 opções de pagamento. PIX, Boleto ou Cartão de Crédito
        </BS.ExplainingText>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
          <S.Button
            disabled={signedContractAttorney === false || payPress === true}
            disabled_color={signedContractAttorney === false || payPress === true}
            onClick={() => handlePay()}
          >
            <S.ButtonText>Pagar</S.ButtonText>
          </S.Button>
        </div>
      </FeaturesCard>
      <FeaturesCard header="Documentos para homologação">
        <BS.ExplainingText>
          Envie um documento pessoal com foto (CNH, ou RG) para que seja possível homologar seu projeto nas
          concessionárias
        </BS.ExplainingText>
        <S.FileWrapper>
          <FileInput
            header={''}
            text="Arraste ou clique aqui para fazer upload"
            mimes={['PDF', 'JPG', 'PNG']}
            onChange={setFileToUpload}
            hasFile={!!fileToUpload}
            isFileSent={isFileSent}
            fileName={getFileName()}
          />
        </S.FileWrapper>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
          <S.Button
            disabled={payPress === false || isFileSent}
            disabled_color={payPress === false || isFileSent}
            onClick={() => handleUpload()}
          >
            <S.ButtonText>Submeter documentos</S.ButtonText>
          </S.Button>
        </div>
      </FeaturesCard>
    </BS.ContainerFlexRight>
  )
}

const BarcodeLeft = ({
  handleOpen,
  handleSign,
  handleAttorney,
  signedContract,
  signedContractAttorney,
}: {
  handleOpen: any
  handleSign: any
  handleAttorney: any
  signedContract: boolean
  signedContractAttorney: boolean
}) => {
  const profile = useSelector((state: any) => state.profile.profileValues)

  const checkContract = useCallback(() => {
    const currentStep = profile.steps.find((step: any) => step.status === 'PENDING')
    currentStep.type === 'CONTRACT' ? handleSign(true) : handleOpen(true)
  }, [profile, handleOpen, handleSign])

  return (
    <FeaturesCard header="Contrato">
      <BS.Container>
        <BS.ImgContainer>
          <BS.Image src={ContractIcon} alt="Barcode" />
        </BS.ImgContainer>
        <BS.Text>
          Assine digitalmente o seu contrato e depois faça o pagamento para finalização da compra.
        </BS.Text>
        <BS.Button
          disabled={signedContract === true}
          disabled_color={signedContract === true}
          onClick={() => checkContract()}
        >
          <S.ButtonText>Assinar Contrato De Compra</S.ButtonText>
        </BS.Button>
        <BS.Button
          disabled={signedContract === false || signedContractAttorney === true}
          disabled_color={signedContract === false || signedContractAttorney === true}
          onClick={() => handleAttorney()}
        >
          <S.ButtonText>Assinar Procuração</S.ButtonText>
        </BS.Button>
      </BS.Container>
    </FeaturesCard>
  )
}

export default Barcode

export const ccMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const cvvMask = [/\d/, /\d/, /\d/]
export const cvvMask4n = [/\d/, /\d/, /\d/, /\d/]

import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import Modal from 'components/ui/modal'
import PropTypes from 'prop-types'
import {signatureAccept, requestTermsRequest} from 'store/modules/signature/action'

import GeneralConditions from '../general-conditions'
import {DivButton, ButtonModal, DivText, DivTitle, Title, DivTextBottom} from './styles'

const TermsModal = ({open, onClose, contractId}) => {
  const {formatMessage} = useIntl()
  const _title_ = formatMessage({id: 'signature.modal.title'})
  const _accept_ = formatMessage({id: 'signature.modal.button.yes'})
  const _notAccept_ = formatMessage({id: 'signature.modal.button.no'})
  const dispatch = useDispatch()

  const readContract = useSelector(state => state.signature.contract)

  const [cursorBlock, setCursorBlock] = useState('not-allowed')

  const [disable, setDisable] = useState(true)

  const handleNotAccept = () => {
    const payload = {
      modal: false,
      check: false,
    }
    dispatch(signatureAccept(payload))
  }

  const handleAccept = () => {
    const payload = {
      modal: false,
      check: true,
    }
    dispatch(signatureAccept(payload))

    const termsUsePayload = {
      terms: true,
      contractId,
    }

    dispatch(requestTermsRequest(termsUsePayload))
  }

  useEffect(() => {
    if (readContract.read) {
      setCursorBlock('pointer')
      setDisable(false)
    }
  }, [readContract])

  return (
    <>
      {open ? (
        <Modal onClose={onClose} width="1000px">
          <DivTitle>
            <Title>{_title_}</Title>
          </DivTitle>
          <DivText>
            <GeneralConditions />
          </DivText>
          <DivTextBottom />
          <DivButton>
            <ButtonModal onClick={handleNotAccept}>{_notAccept_}</ButtonModal>
            <ButtonModal
              onClick={handleAccept}
              style={{
                cursor: `${cursorBlock}`,
              }}
              disabled={!!disable}
            >
              {_accept_}
            </ButtonModal>
          </DivButton>
        </Modal>
      ) : null}
    </>
  )
}

TermsModal.defaultProps = {
  open: null,
  onClose: () => {},
  contractId: '',
}

TermsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  contractId: PropTypes.string,
}

export default TermsModal

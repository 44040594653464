import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {isEmpty, last} from 'lodash'

import PageHeader from 'components/pageHeader'
import Button from 'components/button'
import {FullScreenLoader} from 'components/fullScreenLoader'

import api from 'services/api'

import {toast} from 'react-toastify'

import CostInfos from './CostInfos/CostInfos'
import AccessModal from './AccessModal/AccessModal'
import ProposalModal from './ProposalModal/ProposalModal'
import BanksOptions from './BanksOptions/BanksOptions'

import PlanIcon from 'assets/images/plan_signature_yellow.svg'

import * as S from './styles'
import GeneralDataCards from './GeneralDataCards'
import DetailsCard from './DetailsCard'

import {ReactComponent as DetailIcon} from 'assets/images/icon-proposaldetail.svg'
import history from 'services/history'

export type ProposalType = {
  id: string
  created_at: string
  updated_at: string
  power_of_the_project: number
  client_id: string
  distributor_kit_cost: number
  sale_value: number
  franchisee_commission: number
  final_value: number
  final_installed_wp: number
  value_kit_for_customer: number
  nf_value: number
  tax_solar21: number
  contribution_margin: number
  total_amount_receivable_franchisee: number
  inverter_quantity: number
  inverter_power: number
  inverter_brand: string
  inverter_warranty: string
  module_quantity: number
  module_brand: string
  module_power: number
  annual_production: number
  annual_savings: number
  payback: number
  module_warranty: number
  structure_type: string
  structure_warranty: string
  pdf_link: string
  type: string
}

const SUBTITLE_TEXT = 'Os valores e tamanho do seu sistema podem variar após a visita técnica.'
const ACCEPT_PROPOSAL_TEXT = 'Avançar'
const DOWNLOAD_PDF = 'Baixar PDF'
const BUY_DIRECT = 'Compra à vista'
const BUY_FINANTIATED = 'Financiamento'

const ProposalStep = () => {
  const location = useLocation()

  const profile = useSelector((state: any) => state.profile.profileValues)

  const [openAccess, setOpenAccess] = useState<boolean>(false)
  const [openProposal, setOpenProposal] = useState<boolean>(false)
  const [isInitial, setIsInitial] = useState<boolean>(true)
  const [proposal, setProposal] = useState<ProposalType | undefined>()
  const [dismissInitialAcceptance, setDismissInitial] = useState<boolean>(true)
  const [isDownloadingPdf, setIsDownloadingPdf] = useState<boolean>(false)

  useEffect(() => {
    const isItInitialProposal = location.pathname.includes('initial')
    setIsInitial(isItInitialProposal)
  }, [location])

  useEffect(() => {
    if (isEmpty(profile) || !profile.id) return

    if (isEmpty(proposal))
      api
        .get(`portal/customers/${profile.id}/proposal`)
        .then(({data}) => {
          const check = isInitial ? 'INITIAL' : 'FINAL'
          const filteredData = data.filter((item: ProposalType) => item.type === check)
          setProposal(last(filteredData))
        })
        .catch(err => console.error('proposal retrieving did not work: ', err))
  }, [profile, proposal, isInitial])

  useEffect(() => {
    if (isEmpty(profile) || !profile.id) return

    const initialProposal = profile.steps.find((step: any) => step.type === 'PROPOSAL_INITIAL')

    setDismissInitial(initialProposal.status === 'APPROVED' || initialProposal.status === 'NOT_DONE')
  }, [profile])

  const pageTitle = useMemo(() => (isInitial ? 'Proposta inicial' : 'Proposta final'), [isInitial])
  const pageSub = useMemo(() => (isInitial ? SUBTITLE_TEXT : ''), [isInitial])
  const buttonText01 = useMemo(() => (isInitial ? DOWNLOAD_PDF : BUY_DIRECT), [isInitial])
  const buttonText02 = useMemo(() => (isInitial ? ACCEPT_PROPOSAL_TEXT : BUY_FINANTIATED), [isInitial])

  const getProjectPower = useCallback(() => {
    if (!proposal?.power_of_the_project) return 0

    return proposal.power_of_the_project.toLocaleString('pt-BR', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 3,
    })
  }, [proposal])

  const getPowerCost = useCallback(() => {
    if (!proposal) return 0

    const {power_of_the_project, final_value} = proposal
    if (!power_of_the_project || !final_value) return 0

    const pricePerPower = final_value / (power_of_the_project * 1000)

    return pricePerPower.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }, [proposal])

  const getAnnualProduction = useCallback(() => {
    if (!proposal?.annual_production) return 0

    const {annual_production} = proposal
    const yearProduction = annual_production > 0 ? annual_production : annual_production * -1

    return yearProduction.toFixed(1).toString().replace('.', ',')
  }, [proposal])

  const getAnnualSavings = useCallback(() => {
    if (!proposal?.annual_savings) return 0

    const {annual_savings} = proposal
    const yearSavings = annual_savings > 0 ? annual_savings : annual_savings * -1

    return (yearSavings / 1000).toFixed(1).toString().replace('.', ',') + 'mil'
  }, [proposal])

  const getPayback = useCallback(() => {
    if (!proposal?.payback) return 0
    return proposal.payback + ' meses'
  }, [proposal])

  const handleOpenAccess = () => setOpenAccess(true)

  const handleCloseProposal = () => setOpenProposal(false)

  const handleCloseAccess = useCallback(() => {
    api
      .put(`admin/customers/${profile.id}/approve/proposal`)
      .catch(err => console.error('Error updating client step to technical visit: ', err))
      .finally(() => {
        setOpenAccess(false)
        window.location.reload()
      })
  }, [profile])

  const handleGoToPayment = () => {
    api
      .put('portal/customers/approve/proposal_final')
      .catch(err => console.error('Error updating client step to checkout: ', err))
      .finally(() => history.push(`/pt/payment`))
  }

  const handleClickFinish = useCallback(
    (status: string = '') => {
      if (isInitial) handleOpenAccess()
      if (!isInitial) {
        localStorage.setItem('paymentType', status)
        handleGoToPayment()
      }
    },
    [isInitial]
  )

  const downloadPDF = async () => {
    setIsDownloadingPdf(true)
    if (proposal?.pdf_link) {
      try {
        const response = await fetch(proposal?.pdf_link)
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = `Proposta ${proposal?.type === 'INITIAL' ? 'Inicial' : 'Final'} Solar21.pdf`
        link.click()
        URL.revokeObjectURL(url)
      } catch (error) {
        console.error(error)
      }
    } else toast.error('Erro ao baixar arquivo. Atualize e tente novamente.')
    setIsDownloadingPdf(false)
  }

  return (
    <>
      {isDownloadingPdf && <FullScreenLoader />}

      <S.Container>
        <PageHeader title={pageTitle} description={pageSub} icon={PlanIcon} />
        <GeneralDataCards
          main={[getProjectPower(), getPowerCost(), getAnnualProduction(), getAnnualSavings(), getPayback()]}
        />
        <S.Subcontainer>
          <DetailsCard simulationValues={proposal} isInitial={isInitial} setOpenProposal={setOpenProposal} />
        </S.Subcontainer>
        <S.DetailedProposal onClick={() => setOpenProposal(true)}>
          <DetailIcon /> Proposta Detalhada
        </S.DetailedProposal>
        <CostInfos totalCost={proposal?.final_value || 0} />
        {isInitial ? <BanksOptions /> : null}
        <S.ButtonWrapper>
          <Button
            disabled={isInitial && dismissInitialAcceptance}
            text={buttonText01}
            color={isInitial ? '#ffd500' : undefined}
            onClick={() => (isInitial ? downloadPDF() : handleClickFinish('CASH_PURCHASE'))}
          />
          <Button
            disabled={isInitial && dismissInitialAcceptance}
            text={buttonText02}
            onClick={() => handleClickFinish('FINANCING')}
          />
        </S.ButtonWrapper>
        <AccessModal
          open={openAccess}
          handleClose={() => setOpenAccess(false)}
          handleContinue={handleCloseAccess}
        />
        <ProposalModal open={openProposal} handleClose={handleCloseProposal} proposal={proposal} />
      </S.Container>
    </>
  )
}

export default ProposalStep

import type from '../../types'

export function b2cSimulationRequest(value) {
  return {
    type: type.B2C_PLAN_SIMULATION_REQUEST,
    payload: {value},
  }
}

export function b2cSimulationSuccess(data) {
  return {
    type: type.B2C_PLAN_SIMULATION_SUCCESS,
    payload: {data},
  }
}

export function b2cSimulationFailure() {
  return {
    type: type.B2C_PLAN_SIMULATION_FAILURE,
  }
}

export function b2cSignatureRequest() {
  return {
    type: type.B2C_PLAN_SIGNATURE_REQUEST,
  }
}

export function b2cSignatureSuccess() {
  return {
    type: type.B2C_PLAN_SIGNATURE_SUCCESS,
  }
}

export function b2cSignatureFailure() {
  return {
    type: type.B2C_PLAN_SIGNATURE_FAILURE,
  }
}

export function setSelectedPlan(selectedPlan) {
  return {
    type: type.SET_PLAN_SELECTED_PLAN,
    payload: {selectedPlan},
  }
}

export function b2cConfirmationRequest() {
  return {
    type: type.B2C_PLAN_CONFIRMATION_REQUEST,
  }
}

export function b2cConfirmationSuccess() {
  return {
    type: type.B2C_PLAN_CONFIRMATION_SUCCESS,
  }
}

export function b2cConfirmationFailure() {
  return {
    type: type.B2C_PLAN_CONFIRMATION_FAILURE,
  }
}

export function b2cAllPlansRequest() {
  return {
    type: type.B2C_ALL_PLAN_REQUEST,
  }
}

export function b2cAllPlansSuccess(data) {
  return {
    type: type.B2C_ALL_PLAN_SUCCESS,
    payload: {data},
  }
}

export function b2cAllPlansFailure() {
  return {
    type: type.B2C_ALL_PLAN_FAILURE,
  }
}

export function b2cPlanRequest(idPlan) {
  return {
    type: type.B2C_PLAN_REQUEST,
    payload: {idPlan},
  }
}

export function b2cPlanSuccess(data) {
  return {
    type: type.B2C_PLAN_SUCCESS,
    payload: {data},
  }
}

export function b2cPlanFailure() {
  return {
    type: type.B2C_PLAN_FAILURE,
  }
}

export function b2cPlanCheckoutRequest(card, planId) {
  return {
    type: type.B2C_PLAN_CHECKOUT_REQUEST,
    payload: {card, planId},
  }
}

export function b2cPlanCheckoutSuccess(data) {
  return {
    type: type.B2C_PLAN_CHECKOUT_SUCCESS,
    payload: {data},
  }
}

export function b2cPlanCheckoutFailure() {
  return {
    type: type.B2C_PLAN_CHECKOUT_FAILURE,
  }
}

export function b2cVerifyCheckoutRequest() {
  return {
    type: type.B2C_VERIFY_CHECKOUT_REQUEST,
  }
}

export function b2cVerifyCheckoutRequestSuccess(data) {
  return {
    type: type.B2C_VERIFY_CHECKOUT_SUCCESS,
    payload: {data},
  }
}

export function b2cVerifyCheckoutRequestFailure() {
  return {
    type: type.B2C_VERIFY_CHECKOUT_FAILURE,
  }
}

export function b2cVerifyArchiveRequest() {
  return {
    type: type.B2C_VERIFY_ARCHIVE_REQUEST,
  }
}

export function b2cVerifyArchiveRequestSuccess(data) {
  return {
    type: type.B2C_VERIFY_ARCHIVE_SUCCESS,
    payload: {data},
  }
}

export function b2cVerifyArchiveRequestFailure() {
  return {
    type: type.B2C_VERIFY_ARCHIVE_FAILURE,
  }
}

export function getPlanSimulatedRequest() {
  return {
    type: type.GET_PLAN_SIMULATED_REQUEST,
  }
}

export function getPlanSimulatedSuccess(selectedPlanSimulated) {
  return {
    type: type.GET_PLAN_SIMULATED_SUCCESS,
    payload: {selectedPlanSimulated},
  }
}

export function getPlanSimulatedFailure() {
  return {
    type: type.GET_PLAN_SIMULATED_FAILURE,
  }
}

export function createPlanSimulatedRequest(planSimulated) {
  return {
    type: type.CREATE_PLAN_SIMULATED_REQUEST,
    payload: {planSimulated},
  }
}

export function createPlanSimulatedSuccess(selectedPlanSimulated) {
  return {
    type: type.CREATE_PLAN_SIMULATED_SUCCESS,
    payload: {selectedPlanSimulated},
  }
}

export function createPlanSimulatedFailure() {
  return {
    type: type.CREATE_PLAN_SIMULATED_FAILURE,
  }
}

export function getFreeMonthRequest(id) {
  return {
    type: type.GET_FREE_MONTH_REQUEST,
    payload: {id},
  }
}

export function getFreeMonthSuccess(freeMonths) {
  return {
    type: type.GET_FREE_MONTH_SUCCESS,
    payload: {freeMonths},
  }
}

export function getFreeMonthFailure() {
  return {
    type: type.GET_FREE_MONTH_FAILURE,
  }
}

export function setIsBookingPayment(isBookingPayment) {
  return {
    type: type.SET_IS_BOOKING_PAYMENT,
    payload: {isBookingPayment},
  }
}

export function setClientPlanId(clientPlanId) {
  return {
    type: type.SET_CLIENT_PLAN_ID,
    payload: {clientPlanId},
  }
}

import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'

import FooterLinks from 'components/footerLinks'
import PropTypes from 'prop-types'
import history from 'services/history'
import styled from 'styled-components'
import {getCurrentStep, getRouteByStep} from 'utils/getCurrentStep'

import Navbar from '../../components/navbar'
import {isEmpty} from 'lodash'

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 20rem;
  font-weight: normal;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
  @media (min-width: 769px) and (max-width: 1400px) {
    margin-left: 2rem;
  }
`

const ContentMain = styled.div`
  flex: 1 0 auto;
  /* min-height: 85vh; */
  /* min-height: 100%; */
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
`

function LayoutDefault({children}) {
  const steps = useSelector(state => state.menu.menu)
  const profile = useSelector(state => state.profile.profileValues)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [hasProfileData, setHasProfileData] = useState(false)

  useEffect(() => {
    if (steps.length && isFirstRender && hasProfileData) {
      const {customer_success_id, id, subscription_lending} = profile
      const isLending = subscription_lending === 'LENDING'
      const newStepsArray = customer_success_id
        ? steps.concat([{client_id: id, type: 'PROJECT_STATUS', status: 'APPROVED'}])
        : steps
      const nameUrl = window.location.href
      if (!nameUrl.includes('proposal-final')) {
        history.push(`/pt${getRouteByStep(getCurrentStep(newStepsArray), isLending)}`)
      }else{
        const paymentType = localStorage.getItem('paymentType')
        if(paymentType) history.push(`/pt/payment`)
      }
      setIsFirstRender(false)
    }
  }, [steps, isFirstRender, hasProfileData, profile])

  useEffect(() => {
    setHasProfileData(!isEmpty(profile))
  }, [profile])

  return (
    <Wrapper>
      <Navbar />
      <Content>
        <ContentMain>{children}</ContentMain>
        <FooterLinks />
      </Content>
    </Wrapper>
  )
}

LayoutDefault.propTypes = {
  children: PropTypes.element.isRequired,
}

export default LayoutDefault

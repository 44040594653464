import React from 'react'
import {useIntl} from 'react-intl'

import history from 'services/history'
import MetaPixel from './MetaPixel';
import Logo from './logo.svg'
import {Container, Title, SubTitle, SubTitle2, Seconds, Logo as LogoStyle} from './style'

const ThankyouPage = () => {
  const [seconds, setSeconds] = React.useState(3)
  const [pinged, setPinged] = React.useState(false)

  const {formatMessage} = useIntl()

  const _title_ = formatMessage({id: 'thankyou.title'})
  const _subtitle_ = formatMessage({id: 'thankyou.subtitle'})
  const _subtitle2_ = formatMessage({id: 'thankyou.subtitle2'})

  React.useEffect(() => {
    if (!seconds) return
    if (process.env.NODE_ENV === 'production' && !pinged) {
      setPinged(true)
      // <gtm>
      window.gtag('event', 'conversion', {send_to: 'AW-813153404/Mgj6CLnkktsCEPz43oMD'})
      // </gtm>

      // <ln>
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
      window._linkedin_data_partner_ids.push('2234761')

      // eslint-disable-next-line no-inner-declarations
      function send(l) {
        // eslint-disable-next-line no-console
        console.info('[linkedin analytics] Loaded')
        const s = document.getElementsByTagName('script')[0]
        const b = document.createElement('script')
        if (!l) {
          // eslint-disable-next-line func-names
          window.lintrk = function (a, b) {
            window.lintrk.q.push([a, b])
          }
          window.lintrk.q = []
        }
        b.type = 'text/javascript'
        b.async = true
        b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
        s.parentNode.insertBefore(b, s)
      }
      send(window.lintrk)
      // </ln>
    }
    const interval = setInterval(() => {
      if (seconds === 0) clearInterval(interval)
      setSeconds(seconds - 1)
      // if (seconds === 0) clearInterval(interval)
    }, 1000)
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval)
  }, [seconds, pinged])

  React.useEffect(() => {
    if (seconds === 0) {
      history.push('/pt/bill')
      localStorage.removeItem('showThankYou')
    }
  }, [seconds])

  return (
    <Container>
      <MetaPixel />
      <Title>{_title_}</Title>
      <LogoStyle src={Logo} alt="Solar21 Logo" />
      <SubTitle>{_subtitle_}</SubTitle>
      <SubTitle2>{_subtitle2_}</SubTitle2>
      <Seconds>{seconds}</Seconds>
    </Container>
  )
}

export default ThankyouPage

import React, {useCallback, useEffect, useState} from 'react'

import {Dialog} from '@material-ui/core'

import api from 'services/api'
import Button from 'components/button'

import * as S from '../styles'
import {isEmpty} from 'lodash'
import {ProposalType} from '..'

type ProjectInfosType = {
  id?: string
  created_at?: string
  updated_at?: string
  solar21_tax?: number
  not_compensated_tax?: number
  dealership_tax?: number
  kwh_availability?: number
  concurrency_factor?: number
  module_power?: number
  total_losses?: number
  number_of_modules?: number
  inverter_power?: number
  module_name?: string
  inverter_name?: string
  commission?: number
  installation_material_cost?: number
  technical_comment?: string
  structure?: string
  new_standard_justify?: string
}

type ClientBillType = {
  id?: string
  created_at?: string
  updated_at?: string
  bill_owner_id?: string
  status?: string
  value?: number
  amount?: number
  consumer_class?: boolean
  type_consumer_class?: string
  consumer_unit?: string
  consumption?: boolean
  consumption_average?: number
  kwh_tax?: number
  dealership?: string
  client_code?: string
  is_valid_client_city?: boolean
  expiration_month?: string
  power_pattern?: string
  consumption_profile?: string
  holder?: boolean
  is_valid_bill_value?: boolean
  new_consumption_media?: number
  consumption_history_kwh_values?: {average?: number; total?: number}
}

export default function ProposalModal({
  open,
  handleClose,
  proposal,
}: {
  open: boolean
  handleClose: () => void
  proposal?: ProposalType
}) {
  const [infos, setInfos] = useState<ProjectInfosType | null>(null)
  const [bill, setBill] = useState<ClientBillType | null>(null)

  useEffect(() => {
    if (!isEmpty(infos)) return
    api
      .get('/portal/customers/project-infos')
      .then(({data}) => setInfos(data))
      .catch(err => console.error('Error retrieving clients project infos: ', err))
  }, [infos])

  useEffect(() => {
    if (!isEmpty(bill)) return
    api
      .get('/portal/customers/bills')
      .then(({data}) => setBill(data))
      .catch(err => console.error('Error retrieving clients bill info: ', err))
  }, [bill])

  const convertToLocale = (value: number | undefined, isMoney: boolean = true) => {
    const fractionalValue = isMoney ? 2 : 0
    const fractionDigitsObj = {
      minimumFractionDigits: fractionalValue,
      maximumFractionDigits: fractionalValue,
    }

    return value ? value.toLocaleString('pt-BR', fractionDigitsObj) : '0'
  }

  const normalizaStringResponse = (str: string | undefined) => (str ? str : 'Não informado')

  const getProposalDeadline = useCallback(() => {
    if (!proposal?.created_at) return '*Proposta sem prazo de validade'

    const createdAt = new Date(proposal.created_at)
    createdAt.setDate(createdAt.getDate() + 15)
    const formatedDate = createdAt.toLocaleDateString()

    return `Validade da proposta ${formatedDate}`
  }, [proposal])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <S.ModalHeader>
        Detalhamento da proposta
        <S.CloseButton onClick={handleClose}>x</S.CloseButton>
      </S.ModalHeader>
      <S.ProposaModalWrapper>
        <S.BoldSpan>Instalação e homologação de sistema fotovoltaico conectado à rede</S.BoldSpan>
        <S.ContentWrapper>
          <S.UnderlinedBox>
            <S.BoldSpan>Informações da unidade consumidora</S.BoldSpan>
          </S.UnderlinedBox>
          <S.ColumnBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Tarifa da Concessionária</S.ContentUnit>
              <S.ContentUnit>R$ {convertToLocale(infos?.dealership_tax)}</S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Consumo médio mensal (12 meses)</S.ContentUnit>
              <S.ContentUnit>
                {convertToLocale(bill?.consumption_history_kwh_values?.average, false)} kWh
              </S.ContentUnit>
            </S.SpaceBetweenBox>
            {bill?.new_consumption_media ? (
              <S.SpaceBetweenBox>
                <S.ContentUnit>Novo padrão de consumo</S.ContentUnit>
                <S.ContentUnit>{convertToLocale(bill?.new_consumption_media, false)} kWh</S.ContentUnit>
              </S.SpaceBetweenBox>
            ) : null}
            <S.SpaceBetweenBox>
              <S.ContentUnit>Tipo de padrão</S.ContentUnit>
              <S.ContentUnit>{normalizaStringResponse(bill?.power_pattern)}</S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Tipo de estrutura</S.ContentUnit>
              <S.ContentUnit>{normalizaStringResponse(proposal?.structure_type)}</S.ContentUnit>
            </S.SpaceBetweenBox>
          </S.ColumnBox>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <S.UnderlinedBox>
            <S.BoldSpan>Informações do projeto</S.BoldSpan>
          </S.UnderlinedBox>
          <S.ColumnBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Potência do sistema</S.ContentUnit>
              <S.ContentUnit>
                {(proposal?.power_of_the_project || 0).toString().replace('.', ',')} kWp
              </S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Produção de energia 1º ano</S.ContentUnit>
              <S.ContentUnit>{convertToLocale(proposal?.annual_production, false)} kWh</S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Produção de energia média mensal</S.ContentUnit>
              <S.ContentUnit>
                {convertToLocale((proposal?.annual_production ?? 0) / 12, false)} kWh
              </S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Área de instalação</S.ContentUnit>
              <S.ContentUnit>{((proposal?.power_of_the_project || 0) * 6).toFixed(0)} m²</S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Produção específica</S.ContentUnit>
              <S.ContentUnit>
                {convertToLocale((proposal?.annual_production || 0) / (proposal?.power_of_the_project || 1))}
              </S.ContentUnit>
            </S.SpaceBetweenBox>
          </S.ColumnBox>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <S.UnderlinedBox>
            <S.BoldSpan>Informações financeiras</S.BoldSpan>
          </S.UnderlinedBox>
          <S.ColumnBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Investimento total</S.ContentUnit>
              <S.ContentUnit>R$ {convertToLocale(proposal?.final_value)} </S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Economia média mensal</S.ContentUnit>
              <S.ContentUnit>R$ {convertToLocale((proposal?.annual_savings ?? 0) / 12)}</S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Economia estimada 1º ano</S.ContentUnit>
              <S.ContentUnit>R$ {convertToLocale(proposal?.annual_savings)}</S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Economia em 25 anos</S.ContentUnit>
              <S.ContentUnit>R$ {convertToLocale((proposal?.annual_savings ?? 0) * 25)}</S.ContentUnit>
            </S.SpaceBetweenBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Payback (Retorno do investimento)</S.ContentUnit>
              <S.ContentUnit>{normalizaStringResponse(proposal?.payback.toString())} meses</S.ContentUnit>
            </S.SpaceBetweenBox>
          </S.ColumnBox>
        </S.ContentWrapper>
        <S.ContentWrapper>
          <S.UnderlinedBox>
            <S.BoldSpan>Cronograma</S.BoldSpan>
          </S.UnderlinedBox>
          <S.ColumnBox>
            <S.SpaceBetweenBox>
              <S.ContentUnit>Prazo para início de produção de energia</S.ContentUnit>
              <S.ContentUnit>3 meses</S.ContentUnit>
            </S.SpaceBetweenBox>
          </S.ColumnBox>
        </S.ContentWrapper>
        <S.BoldSpan>{getProposalDeadline()}</S.BoldSpan>
        <Button text="Fechar" onClick={handleClose} />
      </S.ProposaModalWrapper>
    </Dialog>
  )
}

import styled from 'styled-components'

import Calibri from 'assets/fonts/calibri-7.ttf'

export const Paragraph = styled.p`
  font-face {
    font-family: 'Calibri';
    src: url(${Calibri}) format('ttf');
  }
  margin-bottom: 10px;
  font-family: Calibri;
  font-size: 16px;
`

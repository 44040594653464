import styled from 'styled-components'

export const StyledInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  width: 100%;
`

export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  column-gap: 1rem;
  padding: 1rem 0;
`

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

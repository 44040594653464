import {takeLatest, all, put, call} from 'redux-saga/effects'

import api from 'services/api'

import type from '../../types'
import {
  requestContractSuccess,
  requestContractFailure,
  requestTermsRequestSucess,
  requestTermsRequestFailure,
  requestSignedContractSuccess,
  requestSignedContractFailure,
} from './action'

export function* getContract() {
  try {
    const response = yield call(api.get, '/portal/customers/contracts')

    const data = response.data

    yield put(requestContractSuccess(data))
  } catch (error) {
    yield put(requestContractFailure())
  }
}

export function* postTerms({payload}) {
  try {
    const response = {}
    const newPayload = {
      is_client_signed: payload.data.terms,
      // client_signed_ip: `${response.data.ip}`,
    }

    yield call(api.patch, `/portal/customers/contracts/${payload.data.contractId}`, newPayload)

    yield put(requestTermsRequestSucess({response, payload}))
  } catch (error) {
    yield put(requestTermsRequestFailure())
  }
}

export function* postSigned({payload}) {
  try {
    const response = {}
    const payloadContract = {
      is_client_signed: true,
      // client_signed_ip: `${response.data.ip}`,
    }

    yield call(api.patch, `/portal/customers/contracts/${payload.data.contractId}`, payloadContract)

    yield put(requestSignedContractSuccess({response, payload}))
  } catch (error) {
    yield put(requestSignedContractFailure())
  }
}

export default all([
  takeLatest(type.SIGNATURE_CONTRACT_REQUEST, getContract),
  takeLatest(type.SIGNATURE_TERMS_REQUEST, postTerms),
  takeLatest(type.SIGNATURE_SIGNED_REQUEST, postSigned),
])

import React from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'

import Solar_21_black from 'assets/images/logo.svg'
import Button from 'components/ui/button'
import history from 'services/history'
import lang from 'services/language'
import {signUpPhoneRequest} from 'store/modules/auth/action'
import {getQueryVariable} from 'utils/url'

import SignupForm from './partials/forms/b2c/signupForm'
import {
  Wrapper,
  PainelLeft,
  PainelRight,
  StyledDiv,
  StyledLogo,
  StyledImg,
  StyledSubtitle,
  StyledTitle,
  StyledWrapper,
  StyledTitleBlack,
  StyledSubtitleBlack,
  StyledContent,
} from './styles'

const Login = () => {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()

  const pending = useSelector(state => state.auth.pending)

  const buttonText = formatMessage({id: 'common.button.access'})

  const _signUpTitle_ = formatMessage({id: 'signup.left.title'})
  const _signUpSubtitle_ = formatMessage({id: 'signup.left.subtitle'})
  const _leftTitle_ = formatMessage({id: 'signup.right.title'})
  const _leftSubtitle_ = formatMessage({id: 'signup.right.subtitle'})
  // const _leftDescription_ = formatMessage({id: 'signup.right.description'})
  const countryCodeInvalid = formatMessage({id: 'code.COUNTRY_CODE_INVALID'})
  const phoneInvalid = formatMessage({id: 'code.PHONE_NUMBER_INVALID'})

  const handleSubmit = values => {
    const {phone, country_code, recaptcha} = values

    if (!recaptcha)
      return toast.error('Por favor, complete o desafio de racaptcha!', {
        position: 'top-left',
        autoClose: 10000,
      })

    const newCountry_code = country_code.replace(/[^0-9]/g, '')
    const newPhone = phone.replace(/[^0-9]/g, '')

    if (newCountry_code === '' || newCountry_code.length !== 2) return toast.error(countryCodeInvalid)
    if (newPhone === '' || newPhone.length !== 11) return toast.error(phoneInvalid)

    const fullNumber = `${newCountry_code}${newPhone}`

    return dispatch(
      signUpPhoneRequest({
        phone: fullNumber,
        coupon: localStorage.getItem('@s21cpn'),
        secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
        recaptcha,
      })
    )
  }

  React.useEffect(() => {
    const coupon = getQueryVariable('cupom') || getQueryVariable('coupon')
    if (coupon) localStorage.setItem('@s21cpn', coupon)
  }, [])

  return (
    <Wrapper>
      <PainelLeft>
        <StyledDiv>
          <StyledLogo>
            <StyledImg src={Solar_21_black} />
          </StyledLogo>
          <StyledTitle>{_leftTitle_}</StyledTitle>
          <StyledSubtitle>{_leftSubtitle_}</StyledSubtitle>
          <Button
            width="250px"
            marginBottom="50px"
            onClick={() => {
              history.push(`/${lang}/login`)
            }}
          >
            {buttonText}
          </Button>
        </StyledDiv>
      </PainelLeft>
      <PainelRight>
        <StyledWrapper>
          <StyledLogo>
            <StyledImg src={Solar_21_black} />
          </StyledLogo>
          <div style={{marginBottom: '1.2rem', display: 'flex', flexDirection: 'column'}}>
            <StyledTitleBlack>{_signUpTitle_}</StyledTitleBlack>
            <StyledSubtitleBlack>{_signUpSubtitle_}</StyledSubtitleBlack>
            <StyledContent>Para simular sua economia, digite seu celular.</StyledContent>
            <StyledContent>Seus dados estão seguros e você não precisa de senha.</StyledContent>
          </div>
          <SignupForm onSubmit={handleSubmit} submitting={pending} />
        </StyledWrapper>
      </PainelRight>
    </Wrapper>
  )
}

export default Login

import React, {FC} from 'react'

const RawCheckIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M19.378 8.1106L11.9194 15.6666L8.19118 11.8886"
        stroke="#020203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7984 12.6666C20.7984 17.6376 16.8214 21.6666 11.9145 21.6666C7.00766 21.6666 3.03064 17.6376 3.03064 12.6666C3.03064 7.69563 7.00766 3.66663 11.9145 3.66663C13.3488 3.66663 14.6991 4.01963 15.8984 4.63063"
        stroke="#020203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RawCheckIcon

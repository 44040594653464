import React from 'react'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'

import {PersistGate} from 'redux-persist/integration/react'

import './config/ReactotronConfig'

import GlobalStyle from './assets/styles/global'
import IntlProviderConfigured from './i18n/IntlProviderConfigured'
import Routes from './routes'
import history from './services/history'
import {store, persistor} from './store'
import {getQueryVariable} from 'utils/url'
import * as Cookies from 'utils/cookie'

function App() {
  React.useEffect(() => {
    if (Cookies.getCookie('solar21:utms')) return
    const utms = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'gclid',
      'franchisee',
      'representative',
    ]

    const cookieC = {}

    utms.forEach(item => {
      const value = getQueryVariable(item)
      if (value) cookieC[item] = value
    })

    if (cookieC.gclid) cookieC.utm_source = `google`

    if (!cookieC.utm_source && !cookieC.gclid) cookieC.utm_source = 'organico'

    if (Object.keys(cookieC).length) {
      Cookies.setCookie(
        `solar21:utms`,
        JSON.stringify(cookieC),
        10,
        process.env.NODE_ENV === `development` ? `localhost` : `.solar21.com.br`
      )
    }
  }, [])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <IntlProviderConfigured>
          <Router history={history}>
            <>
              <Routes />
              <GlobalStyle />
              <ToastContainer autoClose={3000} />
            </>
          </Router>
        </IntlProviderConfigured>
      </PersistGate>
    </Provider>
  )
}

export default App

import styled from 'styled-components'

export const StyledImage = styled.img`
  height: 250px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const StyledflexGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemFlex = styled.div`
  margin: 15px 0;
  width: 170px;
`

export const StyledMinTitle = styled.p`
  font-size: 0.688em;
  letter-spacing: 2.37px;
  color: #1a1818;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
`

export const StyledTextPrice = styled.p`
  font-family: 'Roboto', sans-serif
  font-size: 1.625em;
  letter-spacing: 0px;
  color: #010202;
  font-weight: 300;
  vertical-align: middle;
  display: table-cell;

  & small {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 0.513em;
    font-weight: 300;
    color: #010202;
    position: relative;
    top: -5px;
  }
`

export const StyledDivider = styled.div`
  :before {
    content: '';
    border-left: 2px solid #ffd500;
    font-size: 35px;
    margin: 0px 15px 0 0;
    position: relative;
  }
`

export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: flex-center;

  @media (max-width: 768px) {
    justify-content: center;
    & button {
      padding: 10px 15px;
    }
  }
`

import styled from 'styled-components'

import theme from 'assets/styles/theme'

export const StyledPlanContent = styled.div`
  margin: 4rem;

  @media only screen and (max-width: 768px) {
    margin: 1rem;
    margin-bottom: 6rem;
  }
`

export const IconContainer = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 20px;
`

export const StyledPlanGroup = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const StyledPlanGroupTitle = styled.span`
  display: flex;
  align-items: center;
  font-size: 22px;
  color: ${theme('colors.solar_21.gray')};
  font-weight: 500;
  margin-bottom: 0.5rem;

  img {
    margin-right: 0.6rem;
  }
`

export const StyledPlanGroupDescription = styled.span`
  color: ${theme('colors.solar_21.gray')};
  font-weight: 200;
  font-size: 22px;
  display: flex;
  align-items: center;
  & img {
    margin: 0 10px;
  }
`

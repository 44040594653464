import type from '../../types'

export function signInRequest(data) {
  return {
    type: type.AUTH_SIGN_IN_REQUEST,
    payload: {data},
  }
}

export function signInSuccess() {
  return {
    type: type.AUTH_SIGN_IN_SUCCESS,
  }
}

export function signInFailure() {
  return {
    type: type.AUTH_SIGN_IN_FAILURE,
  }
}

export function logoutClientService() {
  return {
    type: type.AUTH_LOGOUT_CLIENT,
  }
}

export function logoutClientSuccess() {
  return {
    type: type.AUTH_LOGOUT_CLIENT_SUCCESS,
  }
}

export function logoutClientFailure() {
  return {
    type: type.AUTH_LOGOUT_CLIENT_FAILURE,
  }
}

export function accessRequest(phone, code) {
  return {
    type: type.AUTH_ACCESS_CODE_REQUEST,
    payload: {phone, code},
  }
}

export function accessSuccess(token) {
  return {
    type: type.AUTH_ACCESS_CODE_SUCCESS,
    payload: {token},
  }
}

export function accessFailure(phone) {
  return {
    type: type.AUTH_ACCESS_CODE_FAILURE,
    paylaod: {phone},
  }
}

export function signUpRequest(name, email, state, city, unit_type, bill, phone, coupon, hasPartnership) {
  return {
    type: type.AUTH_SIGN_UP_REQUEST,
    payload: {name, email, state, city, unit_type, bill, phone, coupon, hasPartnership},
  }
}

export function signUpSuccess() {
  return {
    type: type.AUTH_SIGN_UP_SUCCESS,
  }
}

export function signUpFailure() {
  return {
    type: type.AUTH_SIGN_UP_FAILURE,
  }
}

export function signUpPhoneRequest(data) {
  return {
    type: type.AUTH_SIGN_UP_PHONE_REQUEST,
    payload: data,
  }
}

export function signUpPhoneSuccess() {
  return {
    type: type.AUTH_SIGN_UP_PHONE_SUCCESS,
  }
}

export function signUpPhoneFailure() {
  return {
    type: type.AUTH_SIGN_UP_PHONE_FAILURE,
  }
}

export function signUpAccessRequest(phone, code) {
  return {
    type: type.AUTH_SIGN_UP_ACCESS_CODE_REQUEST,
    payload: {phone, code},
  }
}

export function signUpAccessSuccess() {
  return {
    type: type.AUTH_SIGN_UP_ACCESS_CODE_SUCCESS,
  }
}

export function signUpAccessFailure() {
  return {
    type: type.AUTH_SIGN_UP_ACCESS_CODE_FAILURE,
  }
}

export function resetAuthVariables() {
  return {
    type: type.AUTH_RESET_VARIABLES,
  }
}

export function signUpRequestPj(
  bill,
  city,
  cnpj,
  email,
  razaoSocial,
  state,
  unit_type,
  document_type,
  phone
) {
  return {
    type: type.AUTH_SIGN_UP_REQUEST_PJ,
    payload: {bill, city, cnpj, email, razaoSocial, state, unit_type, document_type, phone},
  }
}

export function signUpSuccessPj() {
  return {
    type: type.AUTH_SIGN_UP_REQUEST_PJ_SUCCESS,
  }
}

export function signUpFailurePj() {
  return {
    type: type.AUTH_SIGN_UP_REQUEST_PJ_FAILURE,
  }
}

export function setSignInFailure(signInFailure) {
  return {
    type: type.SET_AUTH_SIGN_IN_FAILURE,
    payload: {signInFailure},
  }
}

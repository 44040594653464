import React from 'react'
import {useIntl} from 'react-intl'

import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  margin-left: 20px;
  flex-shrink: 0;
  height: 50px;
  line-height: 50px;
  color: #fff;
  @media (min-width: 769px) {
    & a:not(:first-child) {
      border-left: 2px solid #8f8d8d;
    }
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    height: 180px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    & a {
      text-decoration: underline;
      text-align: center;
      margin-bottom: 5px;
    }
    /* display: flex; */
    /* flex-direction: column; */
    margin-bottom: 60px;
  }
`

const Link = styled.a`
  font-family: 'Roboto';
  font-weight: 500;
  color: #8f8d8d;
  font-size: 16px;
  padding: 0 8px 0 8px;
`

const FooterLinkItem = ({target, label}) => (
  <Link href={target} target="_blank" rel="noopener noreferrer">
    {label}
  </Link>
)

const FooterLinks = () => {
  const {formatMessage} = useIntl()
  const _privacyPolicy = formatMessage({id: 'footerlinks.privacyPolicy'})
  // const _generalConditions = formatMessage({id: 'footerlinks.generalConditions'})

  return (
    <Container>
      {/* <FooterLinkItem
        target="https://www.solar21.com.br/static/docs/condicoes-gerais.pdf"
        label={_generalConditions}
      /> */}
      <FooterLinkItem
        target="https://www.solar21.com.br/static/docs/politica-de-privacidade.pdf"
        label={_privacyPolicy}
      />
    </Container>
  )
}

FooterLinkItem.propTypes = {
  target: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default FooterLinks

import type from '../../types'

export function sendDocRequest(file) {
  return {
    type: type.PLAN_DOC_SEND_REQUEST,
    payload: { file },
  }
}

export function sendDocSuccess(data) {
  return {
    type: type.PLAN_DOC_SEND_SUCCESS,
    payload: { data },
  }
}

export function sendDocFailure(value) {
  return {
    type: type.PLAN_DOC_SEND_FAILURE,
    payload: { value },
  }
}

export function setIdPlanSelectedRequest(idPlan) {
  return {
    type: type.SET_SELECTED_PLAN_MODAL_REQUEST,
    payload: { idPlan },
  }
}

export function getConsumerHolderRequest() {
  return {
    type: type.PLAN_HOLDER_REQUEST,
  }
}

export function sendIsHolderSuccess(data) {
  return {
    type: type.PLAN_HOLDER_SUCCESS,
    payload: { data },
  }
}

export function sendIsHolderFailure(value) {
  return {
    type: type.PLAN_HOLDER_FAILURE,
    payload: { value },
  }
}

export function dataConsumerRequest(consumer) {
  return {
    type: type.CONSUMER_INFORMATIONS_MODAL_REQUEST,
    payload: { consumer },
  }
}

export function dataConsumerSuccess(data) {
  return {
    type: type.CONSUMER_INFORMATIONS_MODAL_SUCCESS,
    payload: { data },
  }
}

export function dataConsumerFailure() {
  return {
    type: type.CONSUMER_INFORMATIONS_MODAL_FAILURE,
  }
}

export function getUserPlan() {
  return {
    type: type.GET_USER_PLAN,
  }
}

export function getUserPlanSuccess(data) {
  return {
    type: type.GET_USER_PLAN_SUCCESS,
    payload: { data },
  }
}

export function getUserPlanFailure() {
  return {
    type: type.GET_USER_PLAN_FAILURE,
  }
}

export function setShowConsumerModal(showConsumerModal) {
  return {
    type: type.CONSUMER_SET_SHOW_CONSUMER_MODAL,
    payload: { showConsumerModal },
  }
}

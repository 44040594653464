import styled from 'styled-components'
import {theme} from 'assets/styles/theme'

export const Wrapper = styled.div`
  display: flex;
  alignitems: center;
`

export const Logo = styled.img`
  width: 10rem;

  @media screen and (max-width: 400px) {
    width: 5rem;
  }
`

const getColor = (color?: string) => {
  if (color === 'black') return theme.colors.black
  return theme.colors.white
}

type TextProps = {
  color?: string
}

export const Text = styled.span<TextProps>`
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 8px;
  margin-left: 16px;
  color: ${({color}) => getColor(color)};

  @media screen and (max-width: 400px) {
    font-size: 18px;
    letter-spacing: 2px;
    margin-left: 10px;
  }
`

import {isEmpty} from 'lodash'
import React, {useState, useEffect, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useSelector, useDispatch} from 'react-redux'

import history from 'services/history'
import {b2cAllPlansRequest, getFreeMonthRequest} from 'store/modules/plan/action'
import {partnershipRequest} from 'store/modules/user/action'
import {requestContract} from 'store/modules/signature/action'
import {getCurrentStep} from 'utils/getCurrentStep'
import priceFormatter from 'utils/priceFormatter'

import ConfirmationIcon from '../../assets/images/plan_confirmation.svg'
import PlanIcon from '../../assets/images/plan_signature_yellow.svg'
import {FullScreenLoader} from 'components/fullScreenLoader'
import PageHeader from '../../components/pageHeader'
import ModalConfirmationPlan from './partials/dados-modal'
import TablePlans from './partials/table-plans'
import TablePlansMobile from './partials/table-plans/mobile'
import * as S from './styles'

function Plans() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
  const {formatMessage} = useIntl()
  const dispatch = useDispatch()
  const selectedPlan = useSelector(state => state.plan.selectedPlan)
  const profile = useSelector(state => state.profile.profileValues)
  const allPlan = useSelector(state => state.plan.PlanValues)
  const clientPlanId = useSelector(state => state.plan.clientPlanId)
  const freeMonths = useSelector(state => state.plan.freeMonths)
  const contracts = useSelector(state => state.signature.url)
  const steps = useSelector(state => state.menu.menu)
  const user = useSelector(state => state.user.user)
  const partnership = useSelector(state => state.user.partnership)

  const _pageTitle_ = formatMessage({id: 'plans.header.title'})
  const _pageDescription_ = formatMessage({id: 'plans.header.description'})
  const _simulateNow_ = formatMessage({id: 'plans.content.confirmation'})
  const _simulatePlan_ = formatMessage({id: 'plans.content.confirmationSubtitle'})
  const _annualSaving_ = formatMessage({id: 'plans.table.content.annual.savings'})

  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (contracts?.length) history.push('/pt/customers-signature')
  }, [contracts])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const currentStep = getCurrentStep(steps)
    if (currentStep === 'CHECKOUT') history.push('/pt/booking-payment')
  }, [steps])

  useEffect(() => {
    dispatch(b2cAllPlansRequest())
    dispatch(requestContract())
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(user.data)) dispatch(partnershipRequest(user.data.id))
  }, [dispatch, user])

  useEffect(() => {
    if (!isEmpty(profile)) dispatch(getFreeMonthRequest(profile.id))
  }, [dispatch, profile])

  const getTableData = useCallback(() => {
    if (isEmpty(allPlan)) return {}

    return {
      title: _annualSaving_,
      planLow: (() => {
        const planLow = allPlan.find(clientPlan => clientPlan.plan.type === 'LOW')
        return {
          annual_savings: planLow?.annual_savings,
          annual_production: planLow?.annual_production,
          total_potential: planLow?.total_potential,
          deadline: 3,
          deadline_type: planLow?.deadline_type,
          economy_per_month: planLow?.economy_per_month,
          is_fit: planLow?.is_fit,
          new_bill: planLow?.new_bill,
          old_bill: planLow?.old_bill,
          has_assistance: planLow?.plan.has_assistance,
          has_monitoring_by_app: planLow?.plan.has_monitoring_by_app,
          joining_fee: planLow?.plan.joining_fee,
          name: planLow?.plan.name,
          plan_id: planLow?.plan_id,
          price: planLow?.price,
          reduction_percent: planLow?.reduction_percent,
          free: '1 mês',
          freeMonth: freeMonths.low,
        }
      })(),
      planMed: (() => {
        const planMed = allPlan.find(clientPlan => clientPlan.plan.type === 'MEDIUM')
        return {
          annual_savings: planMed?.annual_savings,
          annual_production: planMed?.annual_production,
          total_potential: planMed?.total_potential,
          deadline: 5,
          deadline_type: planMed?.deadline_type,
          economy_per_month: planMed?.economy_per_month,
          is_fit: planMed?.is_fit,
          new_bill: planMed?.new_bill,
          old_bill: planMed?.old_bill,
          has_assistance: planMed?.plan.has_assistance,
          has_monitoring_by_app: planMed?.plan.has_monitoring_by_app,
          joining_fee: planMed?.plan.joining_fee,
          name: planMed?.plan.name,
          plan_id: planMed?.plan_id,
          price: planMed?.price,
          reduction_percent: planMed?.reduction_percent,
          free: '2 meses',
          freeMonth: freeMonths.med,
        }
      })(),
      planMax: (() => {
        const planMax = allPlan.find(clientPlan => clientPlan.plan.type === 'MAX')
        return {
          annual_savings: planMax?.annual_savings,
          annual_production: planMax?.annual_production,
          total_potential: planMax?.total_potential,
          deadline: 20,
          deadline_type: planMax?.deadline_type,
          economy_per_month: planMax?.economy_per_month,
          is_fit: planMax?.is_fit,
          new_bill: planMax?.new_bill,
          old_bill: planMax?.old_bill,
          has_assistance: planMax?.plan.has_assistance,
          has_monitoring_by_app: planMax?.plan.has_monitoring_by_app,
          joining_fee: planMax?.plan.joining_fee,
          name: planMax?.plan.name,
          plan_id: planMax?.plan_id,
          price: planMax?.price,
          reduction_percent: planMax?.reduction_percent,
          free: '3 meses',
          freeMonth: freeMonths.max,
          partnership_name: partnership.description,
          partnership_validation: partnership.is_validated,
        }
      })(),
    }
  }, [allPlan, _annualSaving_, freeMonths, partnership])

  const dataTable = getTableData()

  const handleCloseModal = useCallback(() => setOpenModal(false), [])

  const getPlanSelected = useCallback(() => {
    if (isEmpty(dataTable)) return {}

    return {
      low: (
        <ModalConfirmationPlan
          open={openModal}
          onClose={handleCloseModal}
          planTitle={dataTable.planLow.name}
          planValue={parseFloat(priceFormatter(dataTable.planLow.price))}
          planSave={parseFloat(priceFormatter(dataTable.planLow.economy_per_month))}
          planId={dataTable.planLow.plan_id}
        />
      ),
      med: (
        <ModalConfirmationPlan
          open={openModal}
          onClose={handleCloseModal}
          planTitle={dataTable.planMed.name}
          planValue={parseFloat(priceFormatter(dataTable.planMed.price))}
          planSave={parseFloat(priceFormatter(dataTable.planMed.economy_per_month))}
          planId={dataTable.planMed.plan_id}
        />
      ),
      max: (
        <ModalConfirmationPlan
          open={openModal}
          onClose={handleCloseModal}
          planTitle={dataTable.planMax.name}
          planValue={parseFloat(priceFormatter(dataTable.planMax.price))}
          planSave={parseFloat(priceFormatter(dataTable.planMax.economy_per_month))}
          planId={dataTable.planMax.plan_id}
        />
      ),
    }
  }, [dataTable, openModal, handleCloseModal])

  const planSelected = getPlanSelected()

  const handleOpenConfirmModal = () => {
    if (!clientPlanId) setOpenModal(true)
    else history.push('/pt/booking-payment')
  }

  return isEmpty(allPlan) || isEmpty(dataTable) ? (
    <FullScreenLoader />
  ) : (
    <>
      <S.PlanContent>
        <PageHeader title={_pageTitle_} description={_pageDescription_} icon={PlanIcon} />

        <S.PlanGroup>
          {windowWidth > 768 ? (
            <S.PlanGroupTitle>
              <img src={ConfirmationIcon} alt={_simulateNow_} />
              <span style={{fontWeight: 'bold'}}>{_simulateNow_} </span>
              <span className="next-steps" style={{opacity: 0.5, marginLeft: 10}}>
                {' '}
                → Reserva de Pagamento → Assinatura
              </span>
            </S.PlanGroupTitle>
          ) : (
            <S.PlanGroupTitle>
              <img src={ConfirmationIcon} alt={_simulateNow_} />
              <span style={{fontWeight: 'bold'}}>{_simulateNow_} </span>
              <span className="next-steps" style={{opacity: 0.5, marginLeft: 10}}>
                {' '}
              </span>
            </S.PlanGroupTitle>
          )}

          <S.PlanGroupDescription>{_simulatePlan_}</S.PlanGroupDescription>
        </S.PlanGroup>
        <S.FlexGroup>
          {windowWidth > 768 ? (
            <TablePlans content={dataTable} onSelect={handleOpenConfirmModal} />
          ) : (
            <TablePlansMobile
              totalPotential={allPlan.filter(e => e.plan.type === 'MAX')[0]?.total_potential}
              content={dataTable}
              onSelect={handleOpenConfirmModal}
            />
          )}
        </S.FlexGroup>
      </S.PlanContent>

      {planSelected[selectedPlan]}
    </>
  )
}

export default Plans

import React from 'react'
import { useIntl } from 'react-intl'

import { useField } from 'formik'
import PropTypes from 'prop-types'

import theme from '../../assets/styles/theme'
import Box from './Box'
import InputUI from './Input'
import LabelUI from './Label'
import SelectUI from './Select'

// ======================================================================
// ErrorMessage
// ======================================================================

export const ErrorMessage = ({ meta }) => {
  const { formatMessage } = useIntl()

  if (meta.error) {
    let message = meta.error

    if (typeof message === 'string' && message.indexOf('.') >= 0 && message.indexOf(' ') === -1) {
      message = formatMessage({ id: message })
    }

    return (
      <Box color={theme('colors.red.600')} fontSize="0.857em">
        {message}
      </Box>
    )
  }

  return null
}

ErrorMessage.defaultProps = {
  meta: { error: undefined, touched: false },
}

ErrorMessage.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
}
// ======================================================================
// Input
// ======================================================================

export const Input = React.forwardRef(
  ({ name, label, debounce, labelVariant, mask, styleGroup, textTransform, ...rest }, ref) => {
    const [field, meta] = useField({ name })

    const hasError = meta.error
    const [value, setValue] = React.useState(field.value)
    const intervalRef = React.useRef(null)
    const handleChange = e => {
      setValue(e.target.value)
    }

    const handleMaskChange = e => {
      const { regex, replace } = mask
      const doc = e.target.value.replace(regex, replace)

      field.onChange({ target: { id: field.id, name: field.name, value: doc } })
    }

    React.useEffect(() => {
      if (debounce) {
        intervalRef.current = setTimeout(() => {
          if (value !== field.value) {
            field.onChange({ target: { id: field.id, name: field.name, value } })
          }
        }, debounce)
      }
      return () => clearTimeout(intervalRef.current)
    }, [debounce, field, value])

    return (
      <Box mb={3} {...styleGroup}>
        {label && (
          <Box mb={2}>
            <LabelUI {...rest}>
              <Box
                mb="6px"
                fontWeight={labelVariant ? 'bold' : null}
                color={labelVariant ? theme('colors.white') : null}
              >
                {label}
              </Box>
              {debounce && !mask && (
                <InputUI
                  {...rest}
                  size="large"
                  onChange={handleChange}
                  textTransform={textTransform}
                  hasError={hasError || null}
                  ref={ref}
                />
              )}
              {!debounce && !mask && (
                <InputUI
                  {...field}
                  {...rest}
                  hasError={hasError || null}
                  textTransform={textTransform}
                  ref={ref}
                />
              )}
              {mask && (
                <InputUI
                  {...field}
                  {...rest}
                  onChange={handleMaskChange}
                  textTransform={textTransform}
                  hasError={hasError || null}
                  ref={ref}
                />
              )}
            </LabelUI>
          </Box>
        )}

        {!label && (
          <Box>
            <InputUI
              {...field}
              {...rest}
              textTransform={textTransform}
              ref={ref}
              hasError={hasError || null}
            />
          </Box>
        )}
        <ErrorMessage meta={meta} />
      </Box>
    )
  }
)

Input.defaultProps = {
  label: null,
  debounce: null,
  labelVariant: false,
  mask: null,
  styleGroup: null,
  textTransform: '',
  maskNumber: null,
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  debounce: PropTypes.number,
  labelVariant: PropTypes.bool,
  mask: PropTypes.shape({
    regex: PropTypes.instanceOf(RegExp),
    replace: PropTypes.string,
    ignore: PropTypes.instanceOf(RegExp),
  }),
  styleGroup: PropTypes.shape({}),
  textTransform: PropTypes.string,
  maskNumber: PropTypes.string,
}

// ======================================================================
// Select
// ======================================================================

export const Select = ({ name, label, children, labelVariant, ...rest }) => {
  const [field, meta] = useField({ name })
  const hasError = meta.error && meta.touched

  return (
    <Box mb={3}>
      {label && (
        <Box mb={2}>
          <LabelUI {...rest} size="medium">
            <Box
              mb="6px"
              fontWeight={labelVariant ? 'bold' : null}
              color={labelVariant ? theme('colors.white') : null}
            >
              {label}
            </Box>
            <SelectUI {...field} {...rest} hasError={hasError}>
              {children}
            </SelectUI>
          </LabelUI>
        </Box>
      )}

      {!label && (
        <Box>
          <SelectUI {...field} {...rest} hasError={hasError}>
            {children}
          </SelectUI>
        </Box>
      )}

      <ErrorMessage meta={meta} />
    </Box>
  )
}

Select.defaultProps = {
  label: null,
  children: null,
  labelVariant: false,
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
  labelVariant: PropTypes.bool,
}

import React, {useMemo, FC, useRef, useState, DragEvent, useEffect, useCallback} from 'react'
import {toast} from 'react-toastify'
import {CheckIcon, CloseIcon, CloudUploadIcon} from '../icons'
import * as S from './styles'

type FileInputProps = {
  header: string
  text: string
  mimes: string[]
  onChange: Function
  hasFile?: boolean
  isFileSent?: boolean
  fileName?: string
}

const VALID_MIME_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']

const FileInput: FC<FileInputProps> = ({
  header,
  text,
  mimes,
  onChange,
  hasFile = false,
  isFileSent = false,
  fileName = '',
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const getCurrentFileName = useCallback(() => {
    function elipseFileName(currentName: string) {
      const nameArray = currentName.split('.')
      return nameArray[0].substring(0, 15).concat('... .').concat(nameArray[1])
    }

    let fileNameToShow = 'Arquivo enviado'
    if (selectedFile?.name)
      fileNameToShow = selectedFile.name.length > 15 ? elipseFileName(selectedFile.name) : selectedFile.name
    if (fileName) fileNameToShow = fileName.length > 15 ? elipseFileName(fileName) : fileName

    return fileNameToShow
  }, [selectedFile, fileName])

  useEffect(() => {
    onChange(selectedFile)
  }, [selectedFile, onChange])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const mimeTypes = useMemo(() => `(${mimes.join(' / ')})`, [mimes])

  const dragPrevent = useCallback((e: DragEvent<HTMLDivElement>) => e.preventDefault(), [])

  const handleFiles = useCallback((files: FileList) => {
    if (files[0].size > 10000000) {
      toast.error('Tamanho do arquivo excedido! Carregue um arquivo com até 10MB.')
      return
    }

    const isFileValid = VALID_MIME_TYPES.indexOf(files[0].type) >= 0

    isFileValid ? setSelectedFile(files[0]) : toast.error('Tipo de arquivo não suportado!')
  }, [])

  const fileDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.stopPropagation()
      e.preventDefault()

      const files = e.dataTransfer.files
      if (files.length) handleFiles(files)
    },
    [handleFiles]
  )

  const filesSelected = useCallback(() => {
    if (fileInputRef?.current?.files?.length) handleFiles(fileInputRef.current.files)
  }, [handleFiles])

  const renderDropArea = useCallback(() => {
    const fileInputClicked = () => fileInputRef?.current?.click()

    if (hasFile || isFileSent)
      return (
        <S.FileArea>
          <S.IconArea>
            <CheckIcon />
          </S.IconArea>
          <S.FileName>{getCurrentFileName()}</S.FileName>
          <S.ButtonWrapper>
            {!isFileSent && (
              <S.RemoveButton type="button" onClick={() => setSelectedFile(null)}>
                <CloseIcon />
              </S.RemoveButton>
            )}
          </S.ButtonWrapper>
        </S.FileArea>
      )

    return (
      <S.DropArea
        onDragOver={dragPrevent}
        onDragEnter={dragPrevent}
        onDragLeave={dragPrevent}
        onDrop={fileDrop}
        onClick={fileInputClicked}
      >
        <S.IconArea>
          <CloudUploadIcon />
          <input ref={fileInputRef} type="file" onChange={filesSelected} />
        </S.IconArea>
        <S.TextArea>
          <S.InfoText>{text}</S.InfoText>
          <S.MimeText>{mimeTypes}</S.MimeText>
        </S.TextArea>
      </S.DropArea>
    )
  }, [hasFile, filesSelected, mimeTypes, text, dragPrevent, fileDrop, isFileSent, getCurrentFileName])

  return (
    <S.Wrapper>
      {header && <S.HeaderText>{header}</S.HeaderText>}
      {renderDropArea()}
    </S.Wrapper>
  )
}

export default FileInput

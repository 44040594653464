import {all} from 'redux-saga/effects'

import auth from './auth/sagas'
import bill from './bill/sagas'
import booking from './booking-payment/sagas'
import common from './common/sagas'
import menu from './menu/sagas'
import plan from './plan/sagas'
import profile from './profile/sagas'
import schedule from './schedule/sagas'
import signature from './signature/sagas'
import user from './user/sagas'
import customer from './customer/sagas'
import faq from './faq/sagas'

export default function* rootSaga() {
  return yield all([
    auth,
    bill,
    booking,
    common,
    plan,
    schedule,
    menu,
    signature,
    profile,
    user,
    customer,
    faq,
  ])
}

import types from 'store/types'

export function signatureAccept (data) {
  return {
    type: types.SIGNATURE_ACCEPT_REQUEST,
    payload: { data },
  }
}

export function signedContractAdhesion (data) {
  return {
    type: types.SIGNATURE_SIGNED_ADHESION_REQUEST,
    payload: { data },
  }
}

export function signedContractProcuration (data) {
  return {
    type: types.SIGNATURE_SIGNED_PROCURATION_REQUEST,
    payload: { data },
  }
}

export function readContract (data) {
  return {
    type: types.SIGNATURE_READ_REQUEST,
    payload: { data },
  }
}

export function requestContract () {
  return {
    type: types.SIGNATURE_CONTRACT_REQUEST,
  }
}

export function requestContractSuccess (data) {
  return {
    type: types.SIGNATURE_CONTRACT_SUCCESS,
    payload: { data },
  }
}

export function requestContractFailure () {
  return {
    type: types.SIGNATURE_CONTRACT_FAILURE,
  }
}

export function requestTermsRequest (data) {
  return {
    type: types.SIGNATURE_TERMS_REQUEST,
    payload: { data },
  }
}

export function requestTermsRequestSucess (data) {
  return {
    type: types.SIGNATURE_TERMS_SUCCESS,
    payload: { data },
  }
}

export function requestTermsRequestFailure () {
  return {
    type: types.SIGNATURE_TERMS_FAILURE,
  }
}

export function requestSignedContract (data) {
  return {
    type: types.SIGNATURE_SIGNED_REQUEST,
    payload: { data },
  }
}

export function requestSignedContractSuccess (data) {
  return {
    type: types.SIGNATURE_SIGNED_SUCCESS,
    payload: { data },
  }
}

export function requestSignedContractFailure () {
  return {
    type: types.SIGNATURE_SIGNED_FAILURE,
  }
}

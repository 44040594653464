import type from '../../types'

export function menuRequest() {
  return {
    type: type.MENU_NOTIFICATIONCHECK_REQUEST,
  }
}

export function menuSuccess({ data }) {
  return {
    type: type.MENU_NOTIFICATIONCHECK_SUCCESS,
    payload: { data },
  }
}

export function menuFailure() {
  return {
    type: type.MENU_NOTIFICATIONCHECK_FAILURE,
  }
}

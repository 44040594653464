import React from 'react'

import appStore from '../../assets/images/app-store.svg'
import appGoogle from '../../assets/images/google-play.svg'
import iconGroup from '../../assets/images/icon-group.svg'
import iconWelcome from '../../assets/images/icon-welcome.svg'
import instagram from '../../assets/images/instagram.svg'
import linkedin from '../../assets/images/linkedin.svg'
import youtube from '../../assets/images/youtube.svg'

import {
  Background,
  StyledImg,
  Content,
  VerticalLive,
  DescriptionVerticalLine,
  DescriptionApp,
  StyleApp,
  Apps,
  TextApp,
  StyledFooter,
  DescriptionFooter,
  FooterImg,
  StyledIconImg,
  IconImg,
  DescriptionSpan,
  StyledDivsDescription,
  StyledFooterImg,
} from './styles'

function welcome() {
  return (
    <Background>
      <Content>
        <StyledIconImg>
          <StyledImg src={iconWelcome} />
          <IconImg src={iconGroup} />
        </StyledIconImg>
        <VerticalLive>
          <DescriptionVerticalLine>
            Bem vindo à <DescriptionSpan>Solar21</DescriptionSpan>
          </DescriptionVerticalLine>
        </VerticalLive>
        <DescriptionApp>
          Reinvente a sua maneira de consumir energia. Você economiza na conta de luz e o planeta agradece.
        </DescriptionApp>
        <StyleApp>
          <TextApp>Baixe nosso App de monitoramento</TextApp>
          <Apps src={appGoogle} />
          <Apps src={appStore} />
        </StyleApp>
        <StyledFooter>
          <DescriptionFooter pLeft="60px" pRight="0%">
            Solar21 | Ilumine o futuro
          </DescriptionFooter>
          <StyledDivsDescription>
            <StyledDivsDescription>
              <DescriptionFooter pLeft="5px" pRight="5px" style={{fontWeighttW: '500'}}>
                Como funciona
              </DescriptionFooter>
              <DescriptionFooter pLeft="5px" pRight="5px" style={{fontWeighttW: '300'}}>
                Residencial
              </DescriptionFooter>
            </StyledDivsDescription>
            <StyledDivsDescription>
              <DescriptionFooter pLeft="25px" pRight="15px" style={{fontWeighttW: '500'}}>
                Sobre nós
              </DescriptionFooter>
              <StyledFooterImg>
                <a href="https://www.instagram.com/solar21_/?hl=pt">
                  <FooterImg src={instagram} />
                </a>
                <a href="https://www.linkedin.com/organization-guest/company/solar21">
                  <FooterImg src={linkedin} />
                </a>
                <a href="https://www.youtube.com/channel/UCAY9-h-ACnazep_pygYFqbQ">
                  <FooterImg src={youtube} />
                </a>
              </StyledFooterImg>
            </StyledDivsDescription>
          </StyledDivsDescription>
        </StyledFooter>
      </Content>
    </Background>
  )
}

export default welcome

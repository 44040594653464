import produce from 'immer'

import type from '../../types'

const INITIAL_STATE = {
  pending: false,
  value: null,
  simulation: {
    photovoltaic_system: {},
    annual_values: {},
    plan: {
      low: false,
      med: false,
      max: false,
    },
  },
  selectedPlanSimulated: undefined,
  selectedPlan: 'max',
  plan: {},
  checkout: {},
  checkoutSuccess: false,
  file: null,
  PlanValues: [],
  card: {
    planId: '',
    dataCard: {},
  },
  state: {
    get: {
      plan: false,
    },
    post: {
      document: false,
    },
  },
  verifyCheckout: false,
  verifyArchive: false,
  payloadArchive: [],
  isBookingPayment: false,
  planId: '',
  freeMonths: {low: null, med: null, max: null},
}

export default function simulation(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.B2C_PLAN_SIMULATION_REQUEST: {
        draft.pending = true
        draft.value = action.payload.value
        break
      }
      case type.B2C_PLAN_SIMULATION_SUCCESS: {
        draft.pending = false
        draft.simulation = action.payload.data
        break
      }
      case type.B2C_PLAN_SIMULATION_FAILURE: {
        draft.pending = false
        break
      }
      case type.B2C_PLAN_SIGNATURE_REQUEST: {
        draft.pending = true
        draft.value = action.payload.value
        break
      }
      case type.B2C_PLAN_SIGNATURE_SUCCESS: {
        draft.pending = false
        draft.value = action.payload.data
        break
      }
      case type.B2C_PLAN_SIGNATURE_FAILURE: {
        draft.pending = true
        draft.value = action.payload.value
        break
      }
      case type.SET_PLAN_SELECTED_PLAN: {
        draft.selectedPlan = action.payload.selectedPlan
        break
      }
      case type.B2C_PLAN_CONFIRMATION_REQUEST: {
        draft.pending = true
        draft.value = action.payload.value
        break
      }
      case type.B2C_PLAN_CONFIRMATION_SUCCESS: {
        draft.pending = false
        draft.confirmationValue = action.payload.data
        break
      }
      case type.B2C_PLAN_CONFIRMATION_FAILURE: {
        draft.pending = false
        break
      }
      case type.B2C_ALL_PLAN_REQUEST: {
        draft.pending = true
        break
      }
      case type.B2C_ALL_PLAN_SUCCESS: {
        draft.pending = false
        draft.PlanValues = action.payload.data
        break
      }
      case type.B2C_ALL_PLAN_FAILURE: {
        draft.pending = false
        break
      }
      case type.B2C_PLAN_REQUEST: {
        draft.pending = true
        draft.idPlan = action.payload.idPlan
        break
      }
      case type.B2C_PLAN_SUCCESS: {
        draft.pending = false
        draft.plan = action.payload.data
        break
      }
      case type.B2C_PLAN_FAILURE: {
        draft.pending = false
        break
      }
      case type.B2C_PLAN_CHECKOUT_REQUEST: {
        draft.pending = true
        draft.card = action.payload.card
        break
      }
      case type.B2C_PLAN_CHECKOUT_SUCCESS: {
        draft.checkoutSuccess = true
        draft.pending = false
        draft.dataCart = action.payload.data
        break
      }
      case type.B2C_PLAN_CHECKOUT_FAILURE: {
        draft.checkoutSuccess = false
        draft.pending = false
        break
      }

      case type.B2C_VERIFY_CHECKOUT_REQUEST: {
        draft.pending = true
        break
      }

      case type.B2C_VERIFY_CHECKOUT_SUCCESS: {
        draft.checkoutSuccess = true
        draft.pending = false
        draft.verifyCheckout = action.payload.data.checkoutCheck
        break
      }

      case type.B2C_VERIFY_CHECKOUT_FAILURE: {
        draft.pending = false
        break
      }

      case type.B2C_VERIFY_ARCHIVE_REQUEST: {
        draft.pending = true
        break
      }

      case type.B2C_VERIFY_ARCHIVE_SUCCESS: {
        draft.pending = false
        draft.verifyArchive = action.payload.data.archiveCheck
        draft.payloadArchive = action.payload.data.payloadArchive
        break
      }
      case type.GET_USER_PLAN: {
        draft.state.get.plan = true
        break
      }

      case type.GET_USER_PLAN_SUCCESS: {
        draft.state.get.plan = false
        draft.plan = action.payload.data
        if (!action.payload?.data) draft.selectedPlanModal = null
        break
      }

      case type.GET_USER_PLAN_FAILURE: {
        draft.state.get.plan = false
        break
      }

      case type.B2C_VERIFY_ARCHIVE_FAILURE: {
        draft.pending = false
        break
      }

      case type.GET_PLAN_SIMULATED_REQUEST: {
        draft.pending = true
        break
      }
      case type.GET_PLAN_SIMULATED_SUCCESS: {
        draft.pending = false
        draft.selectedPlanSimulated = action.payload.selectedPlanSimulated
        break
      }
      case type.GET_PLAN_SIMULATED_FAILURE: {
        draft.pending = false
        break
      }

      case type.CREATE_PLAN_SIMULATED_REQUEST: {
        draft.pending = true
        break
      }
      case type.CREATE_PLAN_SIMULATED_SUCCESS: {
        draft.pending = false
        break
      }
      case type.CREATE_PLAN_SIMULATED_FAILURE: {
        draft.pending = false
        draft.selectedPlanSimulated = action.payload.selectedPlanSimulated
        break
      }

      case type.GET_FREE_MONTH_REQUEST: {
        draft.pending = true
        break
      }
      case type.GET_FREE_MONTH_SUCCESS: {
        draft.pending = false
        draft.freeMonths = action.payload.freeMonths
        break
      }
      case type.GET_FREE_MONTH_FAILURE: {
        draft.pending = false
        break
      }

      case type.SET_IS_BOOKING_PAYMENT: {
        draft.isBookingPayment = action.payload.isBookingPayment
        break
      }

      case type.SET_CLIENT_PLAN_ID: {
        draft.clientPlanId = action.payload.clientPlanId
        break
      }

      default:
    }
  })
}

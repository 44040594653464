import React from 'react'

import PropTypes from 'prop-types'

import ArchiveDownIcon from 'assets/images/icon-archive-down.svg'

import {
  StyledContainer,
  StyledArchiveName,
  StyledArchiveStatus,
  StyledTypeStatus,
  DivNameArchive,
  DivStatus,
  SpanStatus,
  ArchiveIcon,
} from './style'

export default function archiveCard ({ name, type, status }) {
  const statusDescription = {
    PENDING: 'Em análise',
    DENIED: 'Negado',
    APPROVED: 'Aprovado',
  }[status]

  const backgroundColor = {
    PENDING: '#FFEBC0',
    DENIED: '#feb2b2',
    APPROVED: '#cff3bc',
  }[status]

  const typeDescription = {
    DOCUMENT: 'Documento',
    CARD: 'Cartão CNPJ',
    RECEIPT: 'Representante legal',
  }[type]

  return (
    <StyledContainer>
      <StyledArchiveName>
        <ArchiveIcon src={ ArchiveDownIcon } />
        <DivNameArchive>
          <p>{ name }</p>
          <small>{ typeDescription }</small>
        </DivNameArchive>
      </StyledArchiveName>
      <StyledArchiveStatus background={ backgroundColor }>
        <DivStatus>
          <SpanStatus>Status</SpanStatus>
        </DivStatus>
        <StyledTypeStatus />
        <DivStatus>
          <span>{ statusDescription }</span>
        </DivStatus>
      </StyledArchiveStatus>
    </StyledContainer>
  )
}

archiveCard.defaultProps = {
  name: '',
  type: 'DOCUMENT',
  status: 'PENDING',
}

archiveCard.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(['DOCUMENT', 'CARD', 'RECEIPT']),
  status: PropTypes.oneOf(['PENDING', 'DENIED', 'APPROVED']),
}

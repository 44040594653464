import React from 'react'

interface ISimpleCountDown {
  seconds: number
  onChange: (seconds: number) => void
}

const SimpleCountDown: React.FC<ISimpleCountDown> = ({seconds, onChange}) => {
  React.useEffect(() => {
    if (!seconds) return

    const intervalId = setInterval(() => onChange(seconds - 1), 1000)

    return () => clearInterval(intervalId)
    // eslint-disable-next-line
  }, [seconds])

  React.useEffect(() => {
    if (seconds === 0) onChange(0)
    // eslint-disable-next-line
  }, [seconds])

  return <span>{seconds || ''}</span>
}

export default SimpleCountDown

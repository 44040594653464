import React from 'react'
import {FormattedMessage} from 'react-intl'
import {toast} from 'react-toastify'

import {takeLatest, all, put, call} from 'redux-saga/effects'

import history from 'services/history'

import api from '../../../services/api'
import type from '../../types'
import {b2cVerifyArchiveRequestSuccess} from '../plan/action'
import {
  sendDocSuccess,
  sendDocFailure,
  sendIsHolderSuccess,
  dataConsumerSuccess,
  dataConsumerFailure,
  sendIsHolderFailure,
  getUserPlanSuccess,
  getUserPlanFailure,
  setShowConsumerModal,
} from './action'

export function* uploadDoc({payload}) {
  const {file} = payload
  try {
    const headerParams = {
      'Content-Type': 'multipart/form-data',
    }
    const response = yield call(api.post, '/portal/customers/documents', file, headerParams)

    const data = response.data

    yield put(sendDocSuccess(data))
    if (response.data.length !== 0)
      yield put(b2cVerifyArchiveRequestSuccess({payloadArchive: data, archiveCheck: true}))
  } catch (error) {
    toast.error(<FormattedMessage id="bill.file.error.send">{msg => msg}</FormattedMessage>)
    yield put(sendDocFailure())
  }
}

export function* getHolder() {
  try {
    const response = yield call(api.get, 'portal/customers/bills')
    yield put(sendIsHolderSuccess(response.data))
  } catch (error) {
    yield put(sendIsHolderFailure(error))
  }
}

export function* sendConsumerAndGenerateContracts({payload}) {
  const {consumer} = payload
  try {
    let newDocument
    let newPhone
    let newDateOfBirth

    if (consumer.document_value !== '' && consumer.document_value !== undefined) {
      newDocument = consumer.document_value.replace(/[ .-]/g, '')
    }
    if (consumer.phone !== '' && consumer.phone !== undefined) {
      newPhone = consumer.phone.replace(/[ -()]/g, '')
    }
    if (consumer.date_of_birth) {
      newDateOfBirth = consumer.date_of_birth.split('/')
      newDateOfBirth = `${newDateOfBirth[1]}-${newDateOfBirth[0]}-${newDateOfBirth[2]}`
    }

    if (consumer.isCNPJ)
      yield call(api.post, `portal/customers/legitimates`, {
        ...consumer,
        isCNPJ: undefined,
        document_value: undefined,
        legal_document: newDocument,
        date_of_birth: newDateOfBirth,
      })

    const response = yield call(api.patch, `portal/customers/bills/owners`, {
      ...consumer,
      isCNPJ: undefined,
      document_value: newDocument,
      phone: newPhone,
      date_of_birth: newDateOfBirth,
    })
    yield call(api.post, 'portal/customers/contracts')

    const data = response.data
    yield put(dataConsumerSuccess(data))
    yield put(setShowConsumerModal(false))

    toast.success(<FormattedMessage id="booking.consumer.data.success">{msg => msg}</FormattedMessage>)
    history.push('/pt/customers-signature')
    window.location.reload()
  } catch (error) {
    toast.error(<FormattedMessage id="booking.consumer.data.error">{msg => msg}</FormattedMessage>)
    yield put(dataConsumerFailure(error))
  }
}

export function* getUserPlan() {
  try {
    const response = yield call(api.get, `portal/customers/plans/selected-plan`)
    yield put(getUserPlanSuccess(response.data))
  } catch (err) {
    yield put(getUserPlanFailure())
  }
}

export default all([
  takeLatest(type.PLAN_DOC_SEND_REQUEST, uploadDoc),
  takeLatest(type.PLAN_HOLDER_REQUEST, getHolder),
  takeLatest(type.GET_USER_PLAN, getUserPlan),
  takeLatest(type.CONSUMER_INFORMATIONS_MODAL_REQUEST, sendConsumerAndGenerateContracts),
])

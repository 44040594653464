import React from 'react'
import {Redirect} from 'react-router'

import {LANGUAGES} from '../i18n/languages'
import Route from './Route'

export const RouteMultiLangue = props => {
  const defaultLanguage = LANGUAGES.default
  const {path} = props
  const isBasePathWithoutLang = path === '/'

  if (isBasePathWithoutLang) return <Redirect to={`/${defaultLanguage}`} />

  return <Route {...props} defaultLanguage={defaultLanguage} />
}

import {useEffect, useState} from 'react'

export const useVerificationCode = (code, callback) => {
  const [previousCode, setPreviousCode] = useState()

  useEffect(() => {
    if (code?.length === 6 && previousCode !== code) {
      setPreviousCode(code)
      callback(new MouseEvent('click'), code)
    }
  }, [callback, code, previousCode])
}

import {theme} from 'assets/styles/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: ${theme.width.full};
  margin-top: ${theme.spacing[8]};
`

export const HeaderText = styled.h4`
  font-family: Roboto;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize['3xl']};
  line-height: ${theme.lineHeight.tight};
  margin-bottom: ${theme.spacing[4]};
  text-align: center;
`

export const InfoText = styled.h5`
  font-family: Roboto;
  font-weight: ${theme.fontWeight.normal};
  font-size: ${theme.fontSize.xl};
  line-height: ${theme.lineHeight.tight};
  margin-bottom: ${theme.spacing[8]};
  text-align: center;
`

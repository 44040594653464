import type from '../../types'

export function statesRequest() {
  return {
    type: type.COMMON_STATES_REQUEST,
  }
}

export function statesSuccess({ data }) {
  return {
    type: type.COMMON_STATES_SUCCESS,
    payload: { data },
  }
}

export function statesFailure() {
  return {
    type: type.COMMON_STATES_FAILURE,
  }
}

export function citiesRequest(state) {
  return {
    type: type.COMMON_CITIES_REQUEST,
    payload: { state },
  }
}

export function citiesSuccess({ data }) {
  return {
    type: type.COMMON_CITIES_SUCCESS,
    payload: { data },
  }
}

export function citiesFailure() {
  return {
    type: type.COMMON_CITIES_FAILURE,
  }
}

export function notificationRequest() {
  return {
    type: type.COMMON_NOTIFICATION_REQUEST,
  }
}
export function notificationSuccess({ data }) {
  return {
    type: type.COMMON_NOTIFICATION_SUCCESS,
    payload: { data },
  }
}

export function notificationDealsRequest(id) {
  return {
    type: type.COMMON_NOTIFICATIONDEALS_REQUEST,
    payload: { id },
  }
}

export function notificationDealsSuccess() {
  return {
    type: type.COMMON_NOTIFICATIONDEALS_SUCCESS,
  }
}

export function notificationDealsFailure() {
  return {
    type: type.COMMON_NOTIFICATIONDEALS_FAILURE,
  }
}

export function notificationFailure() {
  return {
    type: type.COMMON_NOTIFICATION_FAILURE,
  }
}

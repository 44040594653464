import React from 'react'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import theme from '../../assets/styles/theme'

const sizes = {
  large: css`
    font-size: 1.143rem;
  `,
  medium: css`
    font-size: 1rem;
  `,
  small: css`
    font-size: 0.9rem;
  `,
}

const colors = {
  black: css`
    color: ${theme('colors.solar_21.black')};
  `,
  white: css`
    color: ${theme('colors.white')};
  `,
}

const StyledLabel = styled.label`
  font-weight: ${props => props.fontWeight || 'normal'};
  letter-spacing: 0.77px;
  ${props => colors[props.color]};
  ${props => props.size === 'medium' && sizes.medium}
  ${props => props.size === 'small' && sizes.small}
`

const Label = ({ children, size, id, fontWeight, color }) => {
  return (
    <StyledLabel htmlFor={id} size={size} fontWeight={fontWeight} color={color}>
      {children}
    </StyledLabel>
  )
}

Label.defaultProps = {
  id: null,
  size: 'medium',
  fontWeight: '400',
  children: null,
  color: 'black',
}

Label.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  fontWeight: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small', 'large', 'larger']),
  color: PropTypes.oneOf(['black', 'white']),
}

export default Label

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'solar_21',
      storage,
      whitelist: ['auth', 'user', 'schedule'],
    },
    reducers
  )

  return persistedReducer
}

import React, {FC, useMemo} from 'react'

import * as S from '../styles'

const CostInfos: FC<{totalCost: number}> = ({totalCost}) => {
  const value = useMemo(
    () => totalCost.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}),
    [totalCost]
  )

  return (
    <S.CostWrapper>
      <S.ValueWrapper>
        <S.TotalCost>Valor total</S.TotalCost>
        <S.MoneyWrapper>
          <S.MoneySign>R$</S.MoneySign>
          <S.MoneyValue>{value}</S.MoneyValue>
        </S.MoneyWrapper>
      </S.ValueWrapper>
    </S.CostWrapper>
  )
}

export default CostInfos

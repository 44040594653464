/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'

import LogoSolar21 from 'assets/images/icon-welcome.svg'
import IcInstagram from 'assets/images/instagram.svg'
import IcLinkedin from 'assets/images/linkedin.svg'
import IcYoutube from 'assets/images/youtube.svg'
import history from 'services/history'
import {singUpInitial} from 'store/modules/initial/action'

import {
  Container,
  MainContent,
  Header,
  Logo,
  Content,
  VerticalLive,
  Description,
  Types,
  GroupButtons,
  ButtonHome,
  Footer,
  FooterNav,
  FooterNavItem,
  FooterNavSocial,
  Slogan,
} from './styles'

function Initial() {
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)

  const dispatch = useDispatch()

  const handleSignup = isBusiness => {
    dispatch(
      singUpInitial({
        isBusiness,
      })
    )
    history.push(`/pt/sign-up`)
  }

  useEffect(() => {
    const billValue = searchParams.get('bill')

    if (billValue) localStorage.setItem('bill-value', billValue)
  }, [searchParams])

  return (
    <Container>
      <MainContent>
        <Header>
          <Logo src={LogoSolar21} alt="Solar21" />
        </Header>

        <Content>
          <VerticalLive>
            <h1>
              <span>Energia solar</span> por assinatura,<p></p>
              <span>ZERO</span> investimento e<p>processos 100% digitais.</p>
            </h1>
          </VerticalLive>

          <Description>
            <p>Assine o seu plano solar, produza energia limpa e economize até 30% na sua conta de luz!</p>
          </Description>

          <Types>
            <h2>Crie ou acesse sua conta:</h2>

            <GroupButtons>
              <ButtonHome onClick={() => handleSignup(false)}>Para a minha casa</ButtonHome>
            </GroupButtons>
          </Types>
        </Content>
      </MainContent>

      <Footer>
        <p>
          Solar21 | #OFuturoé<Slogan>Amarelo</Slogan>
        </p>

        <FooterNav>
          <FooterNavItem>
            <h3>
              <a href="https://www.solar21.com.br" title="Home">
                Home
              </a>
            </h3>
          </FooterNavItem>
          <FooterNavItem>
            <h3>
              <a href="https://www.solar21.com.br/quem-somos" title="Sobre nós">
                Sobre nós
              </a>
            </h3>
          </FooterNavItem>
          <FooterNavItem>
            <h3>
              <a href="https://www.solar21.com.br/como-funciona" title="Como funciona">
                Como funciona
              </a>
            </h3>
          </FooterNavItem>
          <FooterNavItem>
            <h3>
              <a href="https://www.solar21.com.br/residencial" title="Planos">
                Planos
              </a>
            </h3>
          </FooterNavItem>
          <FooterNavItem>
            <h3>
              <a href="https://www.solar21.com.br/manifesto" title="Manifesto">
                Manifesto
              </a>
            </h3>
          </FooterNavItem>
          <FooterNavItem>
            <h3>
              <a
                href="https://www.solar21.com.br/static/docs/politica-de-privacidade.pdf"
                title="Política de privacidade"
                target="_blank"
              >
                Política de privacidade
              </a>
            </h3>
          </FooterNavItem>
          <FooterNavItem>
            <h3>
              <a
                href="https://www.solar21.com.br/static/docs/condicoes-gerais.pdf"
                title="Condições gerais"
                target="_blank"
              >
                Condições gerais
              </a>
            </h3>
          </FooterNavItem>
          <FooterNavSocial>
            <FooterNavItem>
              <a href="https://www.instagram.com/solar21_" title="instagram">
                <img src={IcInstagram} alt="instagram" />
              </a>
            </FooterNavItem>
            <FooterNavItem>
              <a href="https://www.linkedin.com/organization-guest/company/solar21" title="linkedin">
                <img src={IcLinkedin} alt="linkedin" />
              </a>
            </FooterNavItem>
            <FooterNavItem>
              <a href="https://www.youtube.com/channel/UCAY9-h-ACnazep_pygYFqbQ" title="youtube">
                <img src={IcYoutube} alt="youtube" />
              </a>
            </FooterNavItem>
          </FooterNavSocial>
        </FooterNav>
      </Footer>
    </Container>
  )
}

export default Initial

export const getAllowedRoutesBySteps = step => {
  const freeRoutes = ['/:lang/profile', '/:lang/bill', '/:lang/thank-you', '/:lang/payment']

  // TODO: removes comment below to enable client to navigate throw correct steps

  /*const allowedRoutes = {
    TEST: ['/:lang/thank-you'],
    BILL: ['/:lang/bill'],
    PROPOSAL_INITIAL: ['/:lang/bill', '/:lang/proposal-initial'],
    TECHNICAL_VISIT: ['/:lang/bill', '/:lang/technical-visit', '/:lang/proposal-initial'],
    PROPOSAL_FINAL: [
      '/:lang/bill',
      '/:lang/proposal-initial',
      '/:lang/technical-visit',
      '/:lang/proposal-final',
    ],
    SIGNATURE: [
      '/:lang/bill',
      '/:lang/technical-visit',
      '/:lang/plan-signature',
      '/:lang/booking-payment',
      '/:lang/payment',
    ],
    CHECKOUT: [
      '/:lang/bill',
      '/:lang/technical-visit',
      '/:lang/plan-signature',
      '/:lang/booking-payment',
      '/:lang/customers-signature',
      '/:lang/payment',
      '/:lang/proposal-initial',
      '/:lang/proposal-final',
    ],
    CONTRACT: [
      '/:lang/bill',
      '/:lang/technical-visit',
      '/:lang/plan-signature',
      '/:lang/booking-payment',
      '/:lang/customers-signature',
      '/:lang/proposal-initial',
      '/:lang/proposal-final',
    ],
    INSTALATION: [
      '/:lang/bill',
      '/:lang/technical-visit',
      '/:lang/plan-signature',
      '/:lang/booking-payment',
      '/:lang/customers-signature',
    ],
    PROJECT_STATUS: [
      '/:lang/bill',
      '/:lang/technical-visit',
      '/:lang/plan-signature',
      '/:lang/booking-payment',
      '/:lang/customers-signature',
      '/:lang/project-status',
    ],
    SUPPLY: [
      '/:lang/bill',
      '/:lang/technical-visit',
      '/:lang/plan-signature',
      '/:lang/booking-payment',
      '/:lang/customers-signature',
      '/:lang/payment',
      '/:lang/proposal-initial',
      '/:lang/proposal-final',
    ],
  }[step]

  return [...(freeRoutes || []), ...(allowedRoutes || [])]*/

  return freeRoutes
}

import React from 'react'

import PropTypes from 'prop-types'

import {ReactVerificationCodeInput} from './styles'

export const VerificationCodeInput = props => {
  return <ReactVerificationCodeInput auto-complete="one-time-code" {...props} />
}

VerificationCodeInput.defaultProps = {
  type: 'number',
  fields: 6,
  onChange: () => {},
  onComplete: () => {},
  fieldWidth: 58,
  fieldHeight: 58,
  autoFocus: true,
  values: undefined,
  placeholder: ['_', '_', '_', '_', '_', '_'],
}

VerificationCodeInput.propTypes = {
  type: PropTypes.oneOf(['number', 'text']),
  fields: PropTypes.number,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  fieldWidth: PropTypes.number,
  fieldHeight: PropTypes.number,
  autoFocus: PropTypes.bool,
  values: PropTypes.array,
  placeholder: PropTypes.array,
}

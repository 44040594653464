// @ts-nocheck
import styled from 'styled-components'
import {OutlinedInput, Box, Accordion} from '@material-ui/core'
import Button from 'components/ui/button'

import theme from 'assets/styles/theme'

export const SearchContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SearchLabel = styled.label`
  margin-bottom: 5px;
  padding-left: 20px;
`

export const SearchInput = styled(OutlinedInput)`
  border-radius: 50px !important;

  & > input {
    padding: 10px !important;
    text-align: center !important;
    font-size: 20px;
  }
`

export const OptionsBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 10px;
  row-gap: 10px;
  max-height: 30vh;
  overflow-y: auto;
  padding: 10px;
`

export const StyledAccordion = styled(Accordion)`
  font-size: 1.2rem;
`

export const InfoBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`

export const Info = styled.span`
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
`

export const ButtonBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`

export const CloseButton = styled(Button)`
  font-size: 1rem;
  height: 3.5rem;
  background-color: transparent !important;
  color: ${theme('colors.solar_21.gray_slight_light')};
  border-color: ${theme('colors.solar_21.gray_slight_light')};
  width: fit-content;

  &:hover {
    color: ${theme('colors.solar_21.black')};
    border-color: ${theme('colors.solar_21.regular')};
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const HelpButton = styled(Button)`
  font-size: 1rem;
  height: 3.5rem;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const MoreInfoBox = styled(Box)`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 20px;
`

export const MoreInfo = styled.span`
  width: 100%;
  border: 1px solid ${theme('colors.solar_21.gray_slight_light')};
  border-radius: 8px;
  padding: 30px 15px;
  text-align: center;
  font-size: 20px;
`

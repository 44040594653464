import {theme} from 'assets/styles/theme'
import styled from 'styled-components'

type StyleType = {
  single?: boolean
}

export const Container = styled.div`
  margin: ${theme.spacing[12]} ${theme.spacing[6]} 0 ${theme.spacing[12]};
  padding: ${theme.spacing[3]};
  min-height: 60vh;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;

  @media only screen and (max-width: 768px) {
    margin: ${theme.spacing[4]};
    margin-bottom: ${theme.spacing[24]};
    row-gap: 1rem;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`
export const Subcontainer = styled.div`
  justify-self: center;
  width: 75%;
  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`
export const FileContainer = styled.div`
  margin-top: 1rem;
`

export const NoFranchiseContainer = styled.div`
  margin-left: 150px;
  margin-right: 150px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 20px;
  }
`

export const TitleWhirlpool = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  display: flex;
  align-items: flex-end;

  /* light/text/primary */

  color: #25282d;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  @media only screen and (max-width: 768px) {
    width: 95%;
    font-size: 20px;
  }
`

export const CardContainer = styled.div`
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`

export const OverlayWrapper = styled.div`
  width: 100%;
  min-height: 120%;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  font-size: 2rem;
  font-family: Roboto;
  font-weight: 700;

  @media only screen and (max-width: 768px) {
    font-size: 1.8rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem 0.5rem;
    min-height: 0;
    height: fit-content;
  }
`

export const OverlayBody = styled.div<StyleType>`
  display: grid;
  grid-template-columns: ${({single}) => (single ? '1fr' : '1fr 1fr')};
  column-gap: 2rem;
  font-size: 1rem;
  font-family: Roboto;
  font-weight: 500;
`

export const OverlayCard = styled.div`
  height: 23rem;
  width: 18rem;
  background-color: ${theme.colors.white};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 1rem 0.5rem;

  &:hover {
    box-shadow: 2px 5px 3px ${theme.colors.gray[400]};
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const OverlaySingleCard = styled.div`
  height: 15rem;
  width: 23rem;
  background-color: ${theme.colors.white};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.5rem;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    width: 20rem;
  }
`

export const FakeIconWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  background-color: ${theme.colors.solar_21.regular};
  border-radius: 2.5rem;
  display: grid;
  place-items: center;
  margin-bottom: 1rem;
`

export const OverlayCardTitle = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`

export const OverlayCardText = styled.span<StyleType>`
  text-align: center;
  margin-top: ${({single}) => (single ? '0' : '1rem')};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
`

export const OverlaySelectArea = styled.div`
  display: grid;
  place-items: center;
  background-color: ${theme.colors.solar_21.regular};
  cursor: pointer;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 1rem 0;
  font-weight: bold;
`

export const OverlayButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin: 0.5rem 0;
  width: 100%;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`

export const WaitingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`

export const WaitingButtonWrapper = styled.div`
  margin-top: 1rem;
`

export const AppDonwloadContainer = styled.div`
  margin-top: 1rem;

  @media only screen and (max-width: 768px) {
    margin-top: 0.5rem;
  }
`

import produce from 'immer'

import type from '../../types'

const INITIAL_STATE = {
  pending: true,
  state: {
    get: true,
    put: false,
  },
  profileValues: {},
  error: false,
}

export default function profile(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.PROFILE_REQUEST: {
        draft.pending = true
        break
      }
      case type.PROFILE_SUCCESS: {
        draft.pending = false
        draft.profileValues = action.payload.data
        break
      }
      case type.PROFILE_FAILURE: {
        draft.error = true
        break
      }
      case type.PROFILE_REQUEST_UPDATE: {
        draft.state.put = true
        break
      }
      case type.PROFILE_REQUEST_UPDATE_SUCCESS: {
        draft.state.put = false
        break
      }
      case type.PROFILE_REQUEST_UPDATE_FAILURE: {
        draft.state.put = false
        break
      }
      default:
    }
  })
}

import React from 'react'
import {FormattedMessage} from 'react-intl'
import {toast} from 'react-toastify'

import {all, call, put, takeLatest} from 'redux-saga/effects'

import api from '../../../services/api'
import type from '../../types'
import {profileRequest} from '../profile/action'
import {
  getBillFailure,
  getBillInfoFailure,
  getBillInfoSuccess,
  getBillSuccess,
  receiveBillFailure,
  receiveBillSuccess,
  sendBillFailure,
  sendBillSuccess,
} from './action'

export function* uploadBill({payload}) {
  const {file, holder} = payload
  try {
    const headerParams = {
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Content-Type': 'multipart/form-data',
    }

    const response = yield call(api.post, `/portal/customers/bills?is_holder=${holder}`, file, headerParams)
    const data = response.data

    yield put(sendBillSuccess(data))
    yield put(profileRequest())
  } catch (error) {
    toast.error(<FormattedMessage id="bill.file.error.send">{msg => msg}</FormattedMessage>)
    yield put(sendBillFailure())
    yield put(profileRequest())
  }
}

export function* receiveBill() {
  try {
    const response = yield call(api.get, `/portal/customers/bills`)
    const data = response.data

    yield put(receiveBillSuccess(data))
  } catch (error) {
    toast.error(<FormattedMessage id="bill.file.error.receive">{msg => msg}</FormattedMessage>)
    yield put(receiveBillFailure())
  }
}
export function* receiveBillOwner() {
  try {
    const response = yield call(api.get, `/portal/customers/bills/owners`)

    const data = response.data

    yield put(getBillSuccess(data))
  } catch (error) {
    yield put(getBillFailure())
  }
}
export function* receiveBillInfo({payload}) {
  try {
    const {id} = payload

    const {data} = yield call(api.get, `/portal/customers/bill?client_bill_file_id=${id}`)

    yield put(getBillInfoSuccess(data))
  } catch (error) {
    yield put(getBillInfoFailure())
  }
}

export default all([
  takeLatest(type.BILL_ACCOUNT_SEND_REQUEST, uploadBill),
  takeLatest(type.BILL_ACCOUNT_RECEIVE_REQUEST, receiveBill),
  takeLatest(type.BILL_OWNER_REQUEST, receiveBillOwner),
  takeLatest(type.BILL_INFO_REQUEST, receiveBillInfo),
])

import React, {FC} from 'react'
import ModalContent from 'components/ui/modal'
import YellowCheckIcon from 'components/icons/YellowCheckIcon'
import * as S from './styles'
import Button from 'components/button'

const Modal: FC<{onClose: any}> = ({onClose}) => {
  return (
    <ModalContent onClose={onClose} width="535px">
      <YellowCheckIcon />
      <S.Wrapper>
        <S.HeaderText>Conta de luz enviada!</S.HeaderText>
        <S.InfoText>
          Avaliaremos as informações e em até 24h você receberá um retorno por e-mail, telefone ou WhatsApp.
        </S.InfoText>
      </S.Wrapper>
      <Button text="Entendi" disabled={false} onClick={onClose} />
    </ModalContent>
  )
}

export default Modal

import React from 'react'
import {BsArrowLeft, BsArrowRight} from 'react-icons/bs'

import PropTypes from 'prop-types'

import FAQIcon from 'assets/images/faq-button.svg'

import history from '../../services/history'
import Button from '../ui/button'
import {Container, Content, ButtonBack,FaqWppButtonContainer,
  TooltipCard,
  TooltipText,
  TooltipBox,} from './styles'

export default function ButtonGroup({status, back, next, nextTitle}) {
  return (
    <Container>
      <Content>
        <ButtonBack startIcon={<BsArrowLeft size={24} />} onClick={() => history.push(`/pt/${back}`)}>
          Voltar
        </ButtonBack>
        <Button
          onClick={() => history.push(`/pt/${next}`)}
          endIcon={<BsArrowRight size={24} />}
          disabled={status}
        >
          {nextTitle || 'Avançar'}
        </Button>
        <FaqWppButtonContainer>
        <TooltipCard>
        <TooltipText>
        <a href='https://www.solar21.com.br/faq'> <img src={FAQIcon} alt={'FAQIcon'}/></a>
        </TooltipText>
        <TooltipBox>
          <p>Acesse nossa FAQ</p>
        </TooltipBox>
      </TooltipCard>           
        {/* <img src={WPPIcon}/> */}
        </FaqWppButtonContainer>
      </Content>
    </Container>
  )
}

ButtonGroup.defaultProps = {
  status: false,
  back: '',
  next: '',
}

ButtonGroup.propTypes = {
  status: PropTypes.bool,
  back: PropTypes.string,
  next: PropTypes.string,
}

import styled from 'styled-components'

import theme from 'assets/styles/theme'

export const StyleComp = styled.div`
  .file-input {
    display: none;
  }

  .container p {
    color: red;
    text-align: center;
  }

  .drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    max-width: 800px;
    height: 200px;
  }

  .drop-message {
    text-align: center;
    color: ${theme('colors.solar_21.gray')};
    font-family: Arial;
    font-size: 20px;
  }

  .file-progressBar {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    display: inline-block;
    margin: 0px 10px 5px 5px;
    vertical-align: top;
    flex: 6 !important;
    margin-left: 50px;
    box-sizing: content-box;
    width: calc(100% - 50px);
  }

  .file-progressBar div {
    height: auto;
    color: #fff;
    text-align: right;
    line-height: 15px;
    width: 0;
    background-color: #4caf50;
    border-radius: 3px;
    font-size: 13px;
  }

  .file-display-container {
    position: fixed;
    width: 805px;
  }

  .file-status-bar {
    width: 100%;
    vertical-align: top;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    line-height: 50px;
    height: 50px;
  }

  .file-status-bar > div {
    overflow: hidden;
  }

  .file-type {
    display: inline-block !important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
  }

  .file-name {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #4aa1f3;
  }

  .file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
  }

  .file-error-message {
    color: red;
  }

  .file-type-logo {
    width: 50px;
    height: 50px;
    background: url(../images/generic.png) no-repeat center center;
    background-size: 100%;
    position: absolute;
  }

  .file-size {
    display: inline-block;
    vertical-align: top;
    color: #30693d;
    margin-left: 10px;
    margin-right: 5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
  }

  .file-remove {
    position: absolute;
    top: 20px;
    right: 10px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -10px;
  }

  .modal {
    z-index: 999;
    display: none;
    overflow: hidden;
  }

  .modal .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    left: 0;
  }

  .modal .modal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .modal-image-text {
    position: absolute;
    color: red;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .upload-modal {
    z-index: 999;
    display: none;
    overflow: hidden;
  }

  .upload-modal .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress-container {
    background: white;
    width: 500px;
    height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  .progress-container span {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 20px;
  }

  .progress {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #efefef;
    height: 20px;
    border-radius: 5px;
  }

  .progress-bar {
    position: absolute;
    background-color: #4aa1f3;
    height: 20px;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
  }

  .error {
    color: red;
  }
`
export const FileContainerText = styled.span`
  color: #a3b1cc;
  font-size: 22px;
  display: flex;
  flex-direction: column;
`
export const StyledButtonUpload = styled.label`
  background: ${theme('colors.solar_21.regular')};
  color: ${theme('colors.black')};
  font-weight: bold;
  font-size: 1.25rem;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;

  @media only screen and (max-width: 1024px) {
    font-size: 1rem;
  }

  input[type='file'] {
    display: none;
  }
`

export const StyleButton = styled.div`
  .file-upload-btn {
    outline: none;
    margin: 20px 0 -70px 0;
    z-index: 1;
  }

  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const StyledPosSend = styled.div`
  background: #f2f4f7;
  height: 180px;
  width: 100%;
  display: flex;
  align-items: center;
`
export const StyledImageUpload = styled.img`
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  padding: 50px 20px;
`

export const StyledImageName = styled.div`
  color: #a2abba;
`

export const StyledProgress = styled.div`
  width: 90%;
  height: 10px;
  background: #b6bcc6;
  border-radius: 10px;
`

export const StyledProgressBar = styled.div`
  width: 30%;
  height: 10px;
  background: #43cc00;
  border-radius: 10px;
  position: relative;
  top: -10px;
`

export const StyledInformations = styled.div`
  width: inherit;
`

export const StyledChecked = styled.img`
  width: 40px;
  margin: 0 20px 0 -20px;
`

import React from 'react'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import theme from '../../assets/styles/theme'

const StyledTextContainer = styled.span`
  color: ${props => props.color};
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  ${props =>
    props.hover &&
    css`
      :hover {
        color: ${theme('colors.solar_21.regular')};
        cursor: pointer;
      }
    `};
`

export default function Text({ size, color, weight, children, hover, ...rest }) {
  return (
    <StyledTextContainer size={size} color={color} weight={weight} hover={hover} {...rest}>
      {children}
    </StyledTextContainer>
  )
}

Text.defaultProps = {
  size: `${theme('fontSize.base')}`,
  color: 'white',
  weight: 'normal',
  hover: false,
  children: null,
}

Text.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
  hover: PropTypes.bool,
  children: PropTypes.node,
}

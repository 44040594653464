import type from '../../types'

export function profileRequest() {
  return {
    type: type.PROFILE_REQUEST,
  }
}

export function profileSuccess(data) {
  return {
    type: type.PROFILE_SUCCESS,
    payload: { data },
  }
}

export function profileFailure() {
  return {
    type: type.PROFILE_FAILURE,
  }
}

export function updateProfileRequest(data) {
  return {
    type: type.PROFILE_REQUEST_UPDATE,
    payload: { data },
  }
}

export function updateProfileRequestSuccess() {
  return {
    type: type.PROFILE_REQUEST_UPDATE_SUCCESS,
  }
}

export function updateProfileRequestFailure() {
  return {
    type: type.PROFILE_REQUEST_UPDATE_FAILURE,
  }
}

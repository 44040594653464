import styled from 'styled-components'
import {theme} from 'assets/styles/theme'

export const CardWrapper = styled.div`
  width: ${theme.width.full};
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const SingleCardWrapper = styled.div`
  width: ${theme.width.full};
  height: fit-content;
`

export const HeaderText = styled.h5`
  font-family: Roboto;
  font-weight: ${theme.fontWeight.normal};
  font-size: ${theme.fontSize.xl};
  line-height: ${theme.lineHeight.tight};
  margin-bottom: 20px;
`

export const CardsContainer = styled.div`
  width: ${theme.width.full};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${theme.spacing[2]};
`

export const CarouselContainer = styled.div`
  width: ${theme.width.full};

  .thumbs-wrapper.axis-vertical {
    margin: 0 !important;
  }

  .dot {
    box-shadow: 1px 1px 2px ${theme.colors.solar_21.gray_dark} !important;
    border: 1px solid ${theme.colors.solar_21.gray_light} !important;
    opacity: 0.5 !important;
  }

  .dot.selected {
    box-shadow: 2px 2px 4px ${theme.colors.solar_21.regular} !important;
    border: 1px solid ${theme.colors.solar_21.regular} !important;
    opacity: 1 !important;
  }
`

export const PaperCard = styled.div`
  width: ${theme.width.full};
  height: 240px;
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing[6]};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  place-items: center;
  border: 1px solid ${theme.colors.solar_21.gray_light};
  background-color: ${theme.colors.white};
`

export const SingleCard = styled.div`
  width: ${theme.width.full};
  height: 120px;
  grid-column: 1 / span 2;
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing[6]};
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  border: 1px solid ${theme.colors.solar_21.gray_light};
  background-color: ${theme.colors.white};

  @media (max-width: 768px) {
    padding: ${theme.spacing[3]};
    grid-template-columns: 60px 1fr;
    place-items: center;
    height: 180px;
  }
`

export const InfoSeparator = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing[2]};
`

export const IconWrapper = styled.div`
  width: 60px;
  min-height: 60px;
  border-radius: 30px;
  background-color: ${theme.colors.solar_21.regular};
  display: grid;
  place-items: center;
`

export const CardHeader = styled.h5`
  font-family: Roboto;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.xl};
  line-height: ${theme.lineHeight.tight};
  text-align: center;
`

export const SingleCardHeader = styled.h5`
  font-family: Roboto;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.xl};
  line-height: ${theme.lineHeight.tight};
  text-align: left;

  @media (max-width: 768px) {
    margin-left: ${theme.spacing[3]};
  }
`

export const CardText = styled.h6`
  font-family: Roboto;
  font-weight: ${theme.fontWeight.normal};
  font-size: ${theme.fontSize.xl};
  line-height: ${theme.lineHeight.tight};
  text-align: center;
`

export const SingleCardText = styled.h6`
  font-family: Roboto;
  font-weight: ${theme.fontWeight.normal};
  font-size: ${theme.fontSize.xl};
  line-height: ${theme.lineHeight.tight};
  text-align: left;

  @media (max-width: 768px) {
    margin-left: ${theme.spacing[3]};
    font-size: ${theme.fontSize.base};
  }
`

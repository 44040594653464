import types from 'store/types'

export function updateScheduleFormData (scheduleFormData) {
  return {
    type: types.UPDATE_TECHNICAL_VISIT_SCHEDULE_FORM_DATA,
    payload: { scheduleFormData },
  }
}

export function requestSchedule () {
  return {
    type: types.TECHNICAL_VISIT_SCHEDULE_REQUEST,
  }
}

export function requestScheduleSuccess (data) {
  return {
    type: types.TECHNICAL_VISIT_SCHEDULE_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function requestScheduleFailure () {
  return {
    type: types.TECHNICAL_VISIT_SCHEDULE_REQUEST_FAILURE,
  }
}

export function requestFormPost (form) {
  return {
    type: types.TECHNICAL_VISIT_SCHEDULE_FORM_POST,
    payload: { form },
  }
}

export function requestFormPostSuccess () {
  return {
    type: types.TECHNICAL_VISIT_SCHEDULE_FORM_POST_SUCCESS,
  }
}

export function requestFormPostFailure () {
  return {
    type: types.TECHNICAL_VISIT_SCHEDULE_FORM_POST_FAILURE,
  }
}

import produce from 'immer'

import type from '../../types'

const INITIAL_STATE = {
  pending: false,
  token: null,
  signed: false,
  phone: null,
  signInFailure: false,
  failureSendCodeSms: false,
  failureAccessCode: false,
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.AUTH_SIGN_IN_REQUEST: {
        draft.pending = true
        draft.signInFailure = false
        draft.phone = action.payload.data.phone
        draft.secret = action.payload.data.secret
        draft.recaptcha = action.payload.data.recaptcha
        break
      }
      case type.AUTH_SIGN_IN_SUCCESS: {
        draft.pending = false
        draft.signInFailure = false
        break
      }
      case type.AUTH_SIGN_IN_FAILURE: {
        draft.pending = false
        draft.signInFailure = true
        break
      }
      case type.AUTH_LOGOUT_CLIENT: {
        draft.pending = true
        draft.token = ''
        draft.signed = false
        break
      }
      case type.AUTH_LOGOUT_CLIENT_SUCCESS: {
        draft.pending = false
        draft.token = ''
        draft.signed = false
        break
      }
      case type.AUTH_LOGOUT_CLIENT_FAILURE: {
        draft.pending = false
        break
      }
      case type.AUTH_ACCESS_CODE_REQUEST: {
        draft.pending = true
        draft.failureAccessCode = false
        break
      }
      case type.AUTH_ACCESS_CODE_SUCCESS: {
        draft.token = action.payload.token
        draft.pending = false
        draft.failureAccessCode = false
        draft.signed = true
        break
      }
      case type.AUTH_ACCESS_CODE_FAILURE: {
        draft.token = null
        draft.pending = false
        draft.failureAccessCode = true
        draft.signed = false
        break
      }
      case type.AUTH_SIGN_UP_REQUEST: {
        draft.pending = true
        draft.failure = false
        break
      }
      case type.AUTH_SIGN_UP_SUCCESS: {
        draft.pending = false
        break
      }
      case type.AUTH_SIGN_UP_FAILURE: {
        draft.pending = false
        break
      }
      case type.AUTH_SIGN_UP_PHONE_REQUEST: {
        draft.pending = true
        draft.phone = action.payload.phone
        break
      }
      case type.AUTH_SIGN_UP_PHONE_SUCCESS: {
        draft.pending = false
        break
      }
      case type.AUTH_SIGN_UP_PHONE_FAILURE: {
        draft.pending = false
        break
      }
      case type.AUTH_SIGN_UP_ACCESS_CODE_REQUEST: {
        draft.pending = true
        draft.failureSendCodeSms = false
        break
      }
      case type.AUTH_SIGN_UP_ACCESS_CODE_SUCCESS: {
        draft.pending = false
        draft.failureSendCodeSms = false
        break
      }
      case type.AUTH_SIGN_UP_ACCESS_CODE_FAILURE: {
        draft.pending = false
        draft.failureSendCodeSms = true
        break
      }
      case type.AUTH_RESET_VARIABLES: {
        draft.pending = false
        break
      }

      case type.AUTH_SIGN_UP_REQUEST_PJ: {
        draft.pending = true
        break
      }

      case type.AUTH_SIGN_UP_REQUEST_PJ_SUCCESS: {
        draft.pending = false
        break
      }
      case type.AUTH_SIGN_UP_REQUEST_PJ_FAILURE: {
        draft.pending = false
        break
      }

      case type.SET_AUTH_SIGN_IN_FAILURE: {
        draft.signInFailure = action.payload.signInFailure
        break
      }

      default:
    }
  })
}

// @ts-nocheck
import styled from 'styled-components'
import {Check as CheckIcon} from '@material-ui/icons'
import {Box} from '@material-ui/core'

export const MainProjectStatus = styled.div`
  margin: 4rem;
  padding-bottom: 40px;

  @media only screen and (max-width: 768px) {
    margin: 1rem;
    padding-bottom: 100px;
  }
`

export const Subtitle = styled.span`
  display: block;
  font-size: 30px;
  // font-weight: bold;
  margin-top: 40px;
  color: #a4a2a2;
`

export const StepChecked = styled(CheckIcon)`
  background-color: green;
  color: white;
  border-radius: 50%;
  padding: 3px;
`

export const FutureStep = styled(CheckIcon)`
  background: #dbdbdb;
  border-radius: 50%;
  padding: 5px;
  color: transparent;
`

export const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 260px;
  margin-top: 50px;
  padding: 2rem;
  margin-bottom: 60px;

  border-radius: 7px;
  border: 2px dashed #a3b1cc;
  background: #f2f4f7;
`

export const ContainerBtnNeedContact = styled.div`
  display: flex;
  align-content: end;
  justify-content: end;

  @media (max-width: 800px) {
    width: 100%;
    align-content: center;
    justify-content: center;
  }
`

export const MessageContainer = styled(Box)`
  width: 100%;
  margin: 10px 0 80px;
  max-height: 30vh;
  overflow-y: auto;
`

export const Message = styled.p`
  width: 100%;
  font-weight: bold;
  text-align: center;
  line-height: ${({color}: any) => (color ? '1' : '1.2')};
  color: ${({color}: any) => (color ? '#0005' : 'inherit')};
  font-size: ${({color}: any) => (color ? 'inherit' : '1.1rem')};
  margin: ${({color}: any) => (color ? '0 0 15px' : '20px 0')};
`

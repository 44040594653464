import React, {useEffect, useState, useCallback, useLayoutEffect} from 'react'
import {useDispatch} from 'react-redux'

import PropTypes from 'prop-types'

import Modal from '../../../../components/ui/modal'
import {
  requestSignedContract,
  signedContractAdhesion,
  signedContractProcuration,
} from '../../../../store/modules/signature/action'
import {DivIframe, DivButton, ButtonIframe} from './style'

const ModalIframe = ({
  open,
  onClose,
  url,
  contractId,
  contractType,
  contractAdhesion,
  contractProcuration,
  isUnified,
}) => {
  const [concluded, setConcluded] = useState(false)

  const dispatch = useDispatch()

  const handleConcluded = () => {
    const payload = {
      modalClicksignEmbead: false,
      check: true,
      signedAdhesion: contractAdhesion,
      signedProcuration: contractProcuration,
    }

    if (contractType === 'P' && concluded) {
      payload.signedProcuration = true
      dispatch(signedContractProcuration(payload))
      setConcluded(false)
    }

    if (contractType === 'T' && concluded) {
      payload.signedAdhesion = true
      dispatch(signedContractAdhesion(payload))
      setConcluded(false)
    }
  }

  const handleClose = () => {
    onClose()
  }

  const [responseSign, setResponseSign] = useState({})

  // get event clicksign
  function Clicksign(urlSign) {
    let iframe
    let target
    const endpoint = process.env.REACT_APP_CLICKSIGN_URL
    const origin = `${window.location.protocol}//${window.location.host}`
    const listen = {}

    const mount = () => {
      const path = `/sign/${urlSign}`
      const params = `?embedded=true&origin=${origin}`
      const src = endpoint + path + params

      target = document.getElementById('click')

      iframe = document.createElement('iframe')
      iframe.setAttribute('src', src)
      iframe.setAttribute('style', 'width: 100%; height: 580px;')

      window.addEventListener('message', setResponseSign)

      return target.appendChild(iframe)
    }

    const on = (ev, fn) => {
      if (!listen[ev]) {
        listen[ev] = []
      }
      return listen[ev].push(fn)
    }

    const trigger = ev => {
      ;(listen[ev] || []).forEach(fn => {
        fn()
      })
    }

    const unmount = () => {
      if (iframe) {
        target.removeChild(iframe)

        target = null
        iframe = null

        window.removeEventListener('message', trigger)
      }

      return true
    }

    return {
      endpoint,
      origin,
      mount,
      unmount,
      on,
      trigger,
    }
  }

  useEffect(() => {
    if (responseSign.data === 'signed') {
      const payload = {
        contractId,
      }

      dispatch(requestSignedContract(payload))
      setConcluded(true)
    }
  }, [responseSign, dispatch, contractId])

  const handleClickContract = useCallback(url => {
    const contract = Clicksign(url)
    contract.mount('click')
  }, [])

  useLayoutEffect(() => {
    const x = document.getElementById('click')
    if (x) handleClickContract(url)
  }, [handleClickContract, url, contractId])

  return (
    <>
      {open ? (
        <Modal onClose={onClose} width="1200px">
          <DivIframe>
            <div id="click" />
          </DivIframe>
          <DivButton>
            <ButtonIframe onClick={concluded ? handleConcluded : handleClose}>Sair</ButtonIframe>
          </DivButton>
        </Modal>
      ) : null}
    </>
  )
}

ModalIframe.defaultProps = {
  open: null,
  isUnified: false,
  onClose: () => {},
  url: '',
  contractId: undefined,
  contractType: '',
  contractAdhesion: null,
  contractProcuration: null,
}

ModalIframe.propTypes = {
  open: PropTypes.bool,
  isUnified: PropTypes.bool,
  onClose: PropTypes.func,
  url: PropTypes.string,
  contractId: PropTypes.string,
  contractType: PropTypes.string,
  contractAdhesion: PropTypes.bool,
  contractProcuration: PropTypes.bool,
}

export default ModalIframe

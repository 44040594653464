import {theme} from 'assets/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
  margin: ${theme.spacing[12]} ${theme.spacing[6]} 0 ${theme.spacing[16]};
  padding: ${theme.spacing[3]};
  min-height: 60vh;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;

  @media only screen and (max-width: 768px) {
    margin: ${theme.spacing[4]};
    margin-bottom: ${theme.spacing[24]};
  }
`

export const Subcontainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 1060px) {
    grid-template-columns: 1fr;
  }
`

export const SubcontainerGap = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-between;
  gap: 65px;
  @media only screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 1060px) {
    grid-template-columns: 1fr;
  }
`

export const BigNumberWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Sheet = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex: 1;
  padding: 2.5rem;
  margin-right: 2rem;
  background-color: #f9f9f9;
  border-radius: ${theme.spacing[2]};

  @media only screen and (max-width: 1200px) {
    margin-bottom: 1rem;
  }
`

export const SheetFeatures = styled.div`
  flex: 1;
  padding: 2.5rem;
  margin-right: 2rem;
  background-color: ${theme.colors.gray[200]};
  border-radius: ${theme.spacing[2]};

  @media only screen and (max-width: 1200px) {
    margin-bottom: 1rem;
  }
`

export const SubTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 130%;

  @media only screen and (max-width: 1060px) {
    font-size: 1.5rem;
  }
`

type DividerProps = {
  height: number
}
export const Divider = styled.div<DividerProps>`
  height: ${props => props.height}px;
`
export const UnderlinedText = styled.div`
  border-bottom: 3px solid ${theme.colors.solar_21.regular};
  width: 7rem;
  margin-bottom: 0.5rem;
  overflow: visible;
  white-space: nowrap;
`

export const UnderlinedTextSmall = styled.div`
  border-bottom: 3px solid ${theme.colors.solar_21.regular};
  width: 3rem;
  margin-bottom: 0.5rem;
  overflow: visible;
  white-space: nowrap;
`

export const Description = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`

export const Text = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 130%;
`

export const SubText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 130%;
  padding-bottom: 0.3rem;
`

export const ContentColumns = styled.div`
  width: 100%;
  display: grid;
  gap: 5rem 2rem;
  grid-template-columns: 1fr 30%;
`

export const DivContent = styled.div`
  width: 100%;
  min-height: 2rem; 
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    align-items: center;
  }
`

export const IconWrapper = styled.div`
  margin-bottom: 0.5rem;
`

export const ButtonText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
`
interface ButtonProps {
  disabled_color?: boolean
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  width: 65%;
  background: ${props => (props.disabled_color ? '#EBEBE4' : '#ffd500')};
  border: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  margin-top: auto;
  cursor: pointer;
`

export const IconLeft = styled.img`
  width: 3rem;
  height: 3rem;
`

export const IconRight = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  align-self: center;
  justify-self: center;
`

export const IconTitle = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1.8rem;
  line-height: '130%';
  margin-left: 1.8rem;
`

export const ContentDescription = styled.span`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 1.5rem;

  @media only screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 1060px) {
    font-size: 1.5rem;
  }
`

export const BigNumberContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export const MultiplierText = styled.span`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 2.5rem;
`

export const MultiplierNumber = styled.span`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 4rem;

  @media only screen and (max-width: 1060px) {
    font-size: 3rem;
  }
`

export const DescriptioContent = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  column-gap: 1rem;
`

export const DescriptionText = styled.span`
  align-self: center;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 130%;

  @media only screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 1060px) {
    font-size: 1.5rem;
  }
`

export const CenterDiv = styled.div`
  display: grid;
  place-items: center;
`

export const MonthsValue = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 3.2rem;
`

export const MonthsDescription = styled.span`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 1.5rem;
  text-align: center;

  @media only screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 1060px) {
    font-size: 1.5rem;
  }
`

export const Icon = styled.img`
  height: 25px;
  width: 25px;
`

export const CostWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2rem;
`

export const ValueWrapper = styled.div`
  width: 50%;
  display: grid;
  place-items: center;
  grid-template-columns: 200px 1fr;

  @media only screen and (max-width: 1060px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }
`

export const MoneyWrapper = styled.div`
  display: flex;
  justify-self: end;
  align-items: flex-end;
  margin-right: 2rem;
`

export const TotalCost = styled.span`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 2rem;
  line-height: 130%;
  padding: 0 1.5rem;

  @media only screen and (max-width: 1060px) {
    margin-right: 0.5rem;
  }
`

export const MoneySign = styled.span`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 1.5rem;
  margin: 0 0.4rem 0.4rem 0;

  @media only screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 1060px) {
    font-size: 1.5rem;
  }
`

export const MoneyValue = styled.span`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 3rem;
`

export const BanksWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1060px) {
    flex-direction: column;
  }
`

export const SingleBank = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  cursor: pointer;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-right: 32px;
  @media only screen and (max-width: 1060px) {
    flex-direction: column;
  }
`

export const FilesDescription = styled.span`
  font-family: Roboto;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.25;
`

export const FileWrapper = styled.div`
  margin-top: 15px;
`

export const FileContainer = styled.div`
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`

export const ModalWrapper = styled.div`
  width: 35vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  font-size: 1.5rem;
`

export const FakeInfoIcon = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #ffd500;
  display: grid;
  place-items: center;
  font-size: 2rem;
  font-weight: 500;
`

export const BanksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 68px;
`

export const BankIcon = styled.img`
  width: 48px;
  height: 48px;
`

export const PixWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

export const PixPhoto = styled.img`
  width: 120px;
  height: 120px;
`

export const PixText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
`

export const WaitingPayment = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 100px;
`

export const WaitingTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  padding-top: 20px;
  padding-bottom: 38px;
`

export const WaitingIconWrapper = styled.div`
  width: 83px;
  height: 83px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffd500;
`

export const WaitingIcon = styled.img`
  width: 48px;
  height: 48px;
`

export const WaitingDescription = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding-bottom: 36px;
`
export const WaitingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 187px;
  height: 36px;
  background: #ffd500;
  border-radius: 8px;
  cursor: pointer;
`

export const WaitingButtonText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #020203;
`

export const ExplainingText = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  text-align: justify;
`

export const MainBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Box = styled.div`
  width: 50%;
`

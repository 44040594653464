import produce from 'immer'

import types from 'store/types'

const INITIAL_STATE = {
  messages: [],
  isLoading: false,
}

export default function customer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.CUSTOMER_SUCCESS_MESSAGE_REQUEST:
        draft.isLoading = true
        break
      case types.CUSTOMER_SUCCESS_MESSAGE_SUCCESS:
        draft.isLoading = false
        draft.messages = action.payload.messages
        break
      case types.CUSTOMER_SUCCESS_MESSAGE_FAILURE:
        draft.isLoading = false
        break
      case types.CUSTOMER_SUCCESS_UPLOAD_REQUEST:
        draft.isLoading = true
        break
      case types.CUSTOMER_SUCCESS_UPLOAD_SUCCESS:
        draft.isLoading = false
        break
      case types.CUSTOMER_SUCCESS_UPLOAD_FAILURE:
        draft.isLoading = false
        break
      case types.CUSTOMER_SUCCESS_TABLE_REQUEST:
        draft.isLoading = true
        break
      case types.CUSTOMER_SUCCESS_TABLE_SUCCESS:
        draft.isLoading = false
        break
      case types.CUSTOMER_SUCCESS_TABLE_FAILURE:
        draft.isLoading = false
        break
      default:
    }
  })
}

import {createGlobalStyle} from 'styled-components'

import 'react-toastify/dist/ReactToastify.css'
import theme from './theme'

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
    font-size: ${theme('fontSize.base')};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-rendering: optimizeLegibility; 
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
    font-family: 'Roboto', sans-serif
  }
  .nld-chatbot .nld-brand {
    display: none !important;
  }

  #container.visible {
    display: none;
  }
`

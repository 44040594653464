import React from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'

import * as Yup from 'yup'

import Solar_21_black from 'assets/images/logo.svg'
import {signUpAccessRequest} from 'store/modules/auth/action'

import {formatPhone} from 'utils/phone'
import AccessAccountForm from '../forms/b2c/accessAccountForm'
import {
  Background,
  PainelLeft,
  PainelRight,
  StyledImg,
  StyledLogo,
  StyledSubtitleBlack,
  StyledText,
  StyledTitleBlack,
  StyledWrapper,
  Wrapper,
} from './styles'

function AccessCode() {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()

  const submitting = useSelector(state => state.auth.pending)
  const failure = useSelector(state => state.auth.failureSendCodeSms)
  const fullNumber = useSelector(state => state.auth.phone)

  const _accessCode_ = formatMessage({id: 'common.access.code'})
  const _requiredCode_ = formatMessage({id: 'common.access.form.requiredCode'})
  const _title_ = formatMessage({id: 'signup.access.title'})
  const _subtitle_ = formatMessage({id: 'signup.access.subtitle'})
  const _maxCode_ = formatMessage({id: 'common.access.form.maxCode'})

  async function handleSubmit(phone, verificationCode) {
    try {
      await Yup.string()
        .min(6, _maxCode_)
        .required(_requiredCode_)
        .validate(verificationCode, {abortEarly: true})

      dispatch(signUpAccessRequest(phone, verificationCode))
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.error(err.errors[0])
      }
    }
  }

  return (
    <Wrapper>
      <PainelLeft>
        <Background />
      </PainelLeft>
      <PainelRight>
        <StyledWrapper>
          <StyledLogo>
            <StyledImg src={Solar_21_black} />
          </StyledLogo>
          <div style={{marginBottom: '1.2rem', display: 'flex', flexDirection: 'column'}}>
            <StyledTitleBlack>{_title_}</StyledTitleBlack>
            <StyledSubtitleBlack>{_subtitle_}</StyledSubtitleBlack>
            <StyledText>
              {_accessCode_}
              {formatPhone(fullNumber)}
            </StyledText>
          </div>
          <AccessAccountForm
            onSubmit={(event, code) => {
              event.preventDefault()
              handleSubmit(fullNumber, code)
            }}
            submitting={submitting}
            failure={failure}
            fullNumber={fullNumber}
          />
        </StyledWrapper>
      </PainelRight>
    </Wrapper>
  )
}

export default AccessCode

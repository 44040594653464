import theme from 'assets/styles/theme'
import styled from 'styled-components'

export const StyledPlanContent = styled.div`
  margin: 4rem;

  @media only screen and (max-width: 768px) {
    margin: 1rem;
    margin-bottom: 6rem;
  }
`

export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`

export const PlanGroup = styled.div`
  margin-top: 2rem;
`

export const PlanGroupTitle = styled.span`
  display: flex;
  align-items: center;
  font-size: 22px;
  color: ${theme('colors.solar_21.gray')};
  font-weight: 500;
  margin-bottom: 0.5rem;

  img {
    margin-right: 0.6rem;
  }
`

export const PlanGroupDescription = styled.span`
  color: ${theme('colors.solar_21.gray')};
  font-weight: 200;
  font-size: 22px;
`

export const StyledFlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 60px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media (min-width: 769px) and (max-width: 1400px) {
  }
`
export const DivCard = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const DivCheckBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`
export const TitleCheckBox = styled.p`
  margin-left: 5px;
  text-align: left;
  text-decoration: underline;
  font-family: 'Raleway', sans-serif;
  font-size: 22px;
  letter-spacing: 0px;
  color: #a4a2a2;

  @media only screen and (max-width: 768px) {
    font-size: 17px;
  }
`

export const DivAurora = styled.div`
  width: 100%;
  text-align: center;
`

export const DivCheckImg = styled.div`
  width: auto;
  margin-top: 50px;
`

export const StyledCheckImg = styled.img`
  width: 132px;
  height: 132px;
  opacity: 1;
`

export const DivParagraph = styled.div`
  margin-top: 20px;
`

export const FirstParagraph = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #a4a2a2;
  opacity: 1;
`
export const SecondParagraph = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #a4a2a2;
  opacity: 1;
`

export const DivAuroraImg = styled.div`
  margin-top: 20px;
`

export const AuroraImg = styled.img`
  width: 345px;
  height: 431px;
`

export const StyleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

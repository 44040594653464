import produce from 'immer'

import type from '../../types'

const INITIAL_STATE = {
  pending: false,
  payload: {},
  contract: {},
  url: {},
}

export default function signature(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.SIGNATURE_ACCEPT_REQUEST: {
        draft.pending = true
        draft.payload = action.payload.data
        break
      }

      case type.SIGNATURE_SIGNED_ADHESION_REQUEST: {
        draft.pending = true
        draft.payload = action.payload.data
        break
      }

      case type.SIGNATURE_SIGNED_PROCURATION_REQUEST: {
        draft.pending = true
        draft.payload = action.payload.data
        break
      }

      case type.SIGNATURE_READ_REQUEST: {
        draft.pending = true
        draft.contract = action.payload.data
        break
      }

      case type.SIGNATURE_CONTRACT_REQUEST: {
        draft.pending = true
        break
      }

      case type.SIGNATURE_CONTRACT_SUCCESS: {
        draft.pending = false
        draft.url = action.payload.data
        break
      }

      case type.SIGNATURE_CONTRACT_FAILURE: {
        draft.pending = false
        break
      }

      case type.SIGNATURE_TERMS_REQUEST: {
        draft.pending = true
        break
      }

      case type.SIGNATURE_TERMS_SUCCESS: {
        draft.pending = false
        draft.url = action.payload.data
        break
      }
      case type.SIGNATURE_TERMS_FAILURE: {
        draft.pending = false
        break
      }

      case type.SIGNATURE_SIGNED_REQUEST: {
        draft.pending = true
        break
      }

      case type.SIGNATURE_SIGNED_SUCCESS: {
        draft.pending = false
        draft.url = action.payload.data
        break
      }

      case type.SIGNATURE_SIGNED_FAILURE: {
        draft.pending = false
        break
      }

      default:
    }
  })
}

import React from 'react'

import * as S from './styles'

export interface BigRowProps {
  icon: string
  iconAlt: string
  iconTitle: string
  marca: string
  garantia: string
  potencia?: string
  multiplier: number | string
}

const BigRow = ({icon, iconAlt, iconTitle, marca, garantia, potencia, multiplier}: BigRowProps) => {
  return (
    <>
      <S.DivContent>
        <S.RowIconWrapper>
          <S.IconLeft src={icon} alt={iconAlt} />
          <S.IconTitle>{iconTitle}</S.IconTitle>
        </S.RowIconWrapper>
        <S.RowContentDescription>
          {iconTitle === 'Estrutura' ? 'Tipo:' : 'Marca:'} {marca}
        </S.RowContentDescription>
        <S.RowContentDescription>Garantia: {garantia}</S.RowContentDescription>
        {potencia && <S.RowContentDescription>Potência: {potencia}</S.RowContentDescription>}
      </S.DivContent>
      <S.DivContent>
        <S.BigNumberContent>
          <S.MultiplierText>x</S.MultiplierText>
          <S.MultiplierNumber>{multiplier}</S.MultiplierNumber>
        </S.BigNumberContent>
      </S.DivContent>
    </>
  )
}

export default BigRow

import React, {FC} from 'react'

import * as S from '../styles'

type FeaturesCardProps = {
  header: string
}

const FeaturesCard: FC<FeaturesCardProps> = ({header, children}) => {
  return (
    <S.BigNumberWrapper>
      {header ? (
        <S.UnderlinedTextSmall>
          <S.SubTitle>{header}</S.SubTitle>
        </S.UnderlinedTextSmall>
      ) : (
        <div style={{marginTop: 50}}></div>
      )}
      <S.SheetFeatures>{children}</S.SheetFeatures>
    </S.BigNumberWrapper>
  )
}

export default FeaturesCard

import {toast} from 'react-toastify'

import {takeLatest, all, put, call} from 'redux-saga/effects'

import api from 'services/api'

import type from '../../types'
import {requestScheduleSuccess, requestScheduleFailure} from './action'

export function* requestSchedule() {
  try {
    const {data} = yield call(api.get, '/portal/customers/scheduling')

    yield put(requestScheduleSuccess(data))
  } catch (error) {
    // yield put(requestScheduleFailure())
    yield put(requestScheduleSuccess({}))
  }
}

export function* requestFormPost({payload}) {
  try {
    const {data} = yield call(api.post, '/portal/customers/scheduling', payload.form)
    yield put(requestScheduleSuccess(data))
    toast.success('Visita técnica agendada com sucesso!')
  } catch (error) {
    yield put(requestScheduleFailure())
    toast.error('Não foi possível agendar a visita técnica.')
  }
}

export default all([
  takeLatest(type.TECHNICAL_VISIT_SCHEDULE_REQUEST, requestSchedule),
  takeLatest(type.TECHNICAL_VISIT_SCHEDULE_FORM_POST, requestFormPost),
])

import React from 'react'

import {Dialog} from '@material-ui/core'

import Button from 'components/button'

import * as S from '../styles'

type AccessModalPropsType = {
  open: boolean
  handleClose: () => void
  handleContinue: () => void
}

export default function AccessModal({open, handleClose, handleContinue}: AccessModalPropsType) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <S.ModalWrapper>
        <S.FakeInfoIcon>i</S.FakeInfoIcon>
        <S.AccessTitle style={{fontSize: '2rem'}}>Esta é uma proposta inicial</S.AccessTitle>
        <S.AccessText>
          Para elaborarmos uma proposta final personalizada, precisamos fazer uma visita na sua propriedade.{' '}
          <br />
          Fique tranquilo! O Agendamento não tem custo.
        </S.AccessText>
        <Button text="Entendi" onClick={() => handleContinue()} />
      </S.ModalWrapper>
    </Dialog>
  )
}

import React, {FC} from 'react'

const YellowCheckIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 4.60001C23.46 4.60001 4.6 23.46 4.6 46C4.6 68.54 23.46 87.4 46 87.4C68.54 87.4 87.4 69 87.4 46C87.4 23 69 4.60001 46 4.60001ZM40.02 62.1L25.3 47.38L29.9 42.78L40.02 52.9L62.1 30.82L66.7 35.42L40.02 62.1Z"
        fill="#FFD500"
      />
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.02 62.1L25.3 47.38L29.9 42.78L40.02 52.9L62.1 30.82L66.7 35.42L40.02 62.1Z"
        fill="#1A1818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.02 62.0998L25.3 47.3798L29.9 42.7798L40.02 52.8998L62.1 30.8198L66.7 35.4198L40.02 62.0998Z"
        fill="#1A1818"
      />
    </svg>
  )
}

export default YellowCheckIcon

import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import type from 'store/types'

import createStore from './createStore'
import rootReducer from './modules/rootReducer'
import rootSaga from './modules/rootSaga'
import persistReducers from './persistReducers'

const sagaMonitor =
  process.env.NODE_ENV === 'development' && console.tron ? console.tron.createSagaMonitor() : null

const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

const middlewares = [sagaMiddleware]

const store = createStore(
  persistReducers((state, action) => {
    if (action.type === type.AUTH_LOGOUT_CLIENT_SUCCESS) {
      storage.removeItem('persist:solar_21')
      state = undefined
    }
    return rootReducer(state, action)
  }),
  middlewares
)
const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }

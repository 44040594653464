import React from 'react'

import PropTypes from 'prop-types'

import Box from '../Box'
import { StyledButton } from './styles'

export default function Button({ children, width, startIcon, endIcon, radius, selected, ...props }) {
  return (
    <StyledButton {...props} width={width} radius={radius} selected={selected}>
      {startIcon && (
        <Box mr="6px" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {startIcon}
        </Box>
      )}
      {children}
      {endIcon && (
        <Box ml="6px" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {endIcon}
        </Box>
      )}
    </StyledButton>
  )
}

Button.defaultProps = {
  children: null,
  color: 'default',
  size: 'medium',
  variant: 'filled',
  width: 'auto',
  as: 'button',
  radius: '10px',
  startIcon: null,
  endIcon: null,
  selected: false,
}

Button.propTypes = {
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  children: PropTypes.node,
  color: PropTypes.oneOf(['default', 'primary', 'gray']),
  size: PropTypes.oneOf(['medium', 'small']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'link', 'border']),
  width: PropTypes.string,
  radius: PropTypes.string,
  as: PropTypes.elementType,
  selected: PropTypes.bool,
}

import types from 'store/types'

export function userRequest() {
  return {
    type: types.USER_REQUEST,
  }
}

export function userRequestFailure() {
  return {
    type: types.USER_REQUEST_FAILURE,
  }
}

export function userRequestSuccess(user) {
  return {
    type: types.USER_REQUEST_SUCCESS,
    payload: {user},
  }
}

export function partnershipRequest(id) {
  return {
    type: types.PARTNERSHIP_REQUEST,
    payload: {id},
  }
}

export function partnershipSuccess(partnership) {
  return {
    type: types.PARTNERSHIP_SUCCESS,
    payload: {partnership},
  }
}

export function partnershipFailure() {
  return {
    type: types.PARTNERSHIP_FAILURE,
  }
}

export function sendEmployeeCardRequest(id, file) {
  return {
    type: types.EMPLOYEE_CARD_SEND_REQUEST,
    payload: {id, file},
  }
}

export function sendEmployeeCardSuccess() {
  return {
    type: types.EMPLOYEE_CARD_SEND_SUCCESS,
  }
}

export function sendEmployeeCardFailure() {
  return {
    type: types.EMPLOYEE_CARD_SEND_FAILURE,
  }
}

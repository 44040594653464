import React, {useEffect} from 'react'
import {FiXCircle, FiCheckSquare} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'

import DocumentIcon from 'assets/images/ico_document.svg'
import ProfileIcon from 'assets/images/ico_profile.svg'
import SignatureIcon from 'assets/images/ico_signature.svg'
import SummaryIcon from 'assets/images/plan_confirmation.svg'
import PlanIcon from 'assets/images/profile.svg'
import PageHeader from 'components/pageHeader'
import CheckoutForm from 'components/shared/CheckoutForm'
import Modal from 'components/ui/modal'
import {useState} from 'hooks/useState'
import api from 'services/api'
import {getBillRequest} from 'store/modules/bill/action'
import {profileRequest, updateProfileRequest} from 'store/modules/profile/action'
import currency from 'utils/currencyFormatter'
import {phone, cep} from 'utils/formatter'

import ShowCardChange from './partials/cc/show'
import FormProfile from './partials/form'
import OrderSummary from './partials/order-sumary'
import {
  StyledPlanContent,
  StyledPlanGroup,
  StyledPlanGroupTitle,
  StyledPlanGroupDescription,
  IconContainer,
} from './styles'

function Profile() {
  const dispatch = useDispatch()
  const updating = useSelector(state => state.profile.state.put)
  const dataProfile = useSelector(state => state.profile.profileValues)
  const billOwner = useSelector(state => state.bill.billOwner)
  // const userType = useSelector(state => state.user.user.type || {})

  const {state, setState} = useState({
    loadingCC: true,
    ccData: null,
    showDialogUpdateCC: false,
  })

  const getCC = () => {
    setState({loadingCC: true})
    api
      .get('/portal/customers/cc')
      .then(res => {
        if (res.data.success) {
          setState({ccData: res.data.data})
        }
        /* eslint-disable */
        console.log(res.data)
        /* eslint-enable */
      })
      .finally(() => setState({loadingCC: false}))
  }

  const handleSubmitCC = token => {
    /* eslint-disable */
    // console.log('submit new cc data. Token => ', token)
    api.put('/portal/customers/cc', {token}).then(res => {
      // console.log('response change card =>', res.data)
      if (res.data.success) {
        getCC()
        setState({showDialogUpdateCC: false})
      }
    })
    /* eslint-enable */
  }

  useEffect(() => {
    getCC()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(profileRequest())
    dispatch(getBillRequest())
  }, [dispatch])

  const userForm = {
    email: dataProfile.email,
    countryCode: dataProfile.phone?.slice(0, 2),
    phone: dataProfile.phone?.slice(2),
  }

  const handleSubmit = data => {
    dispatch(updateProfileRequest(data))
    dispatch(profileRequest())
  }

  const plan = dataProfile.selected_client_plan?.plan.name
    ? `${dataProfile.selected_client_plan?.plan.name} - ${currency(dataProfile.selected_client_plan?.price)}`
    : 'Nenhum plano selecionado'

  const orderSummary = {
    plan,
    instalation: '0,00',
    joingFee: '0,00',
    phone: dataProfile.phone && phone(dataProfile.phone.substring(2)),
    postal_code: cep(billOwner?.postal_code) || '',
    address: billOwner?.address || '',
    address_number: billOwner?.address_number || '',
    complement: billOwner?.address_complement || '',
    address_neighborhood: billOwner?.address_neighborhood || '',
    citie: billOwner?.city || '',
    state: billOwner?.state || '',
  }

  return (
    <StyledPlanContent>
      <PageHeader
        title="Meu perfil"
        description="Confira aqui seu perfil e dados da assinatura"
        icon={PlanIcon}
      />

      <StyledPlanGroup>
        <StyledPlanGroupTitle>
          <img src={ProfileIcon} alt="" />
          {dataProfile.name}
        </StyledPlanGroupTitle>
        <StyledPlanGroupDescription>Alterar meus dados</StyledPlanGroupDescription>
        <FormProfile dataForm={userForm} onSubmit={handleSubmit} loading={updating} />
      </StyledPlanGroup>

      <StyledPlanGroup>
        <StyledPlanGroupTitle>
          <img src={SummaryIcon} alt="" />
          Resumo do Pedido
        </StyledPlanGroupTitle>
        <StyledPlanGroupDescription>
          Confira aqui suas informações e resumo da sua assinatura
        </StyledPlanGroupDescription>
        <OrderSummary data Summary={orderSummary} />
      </StyledPlanGroup>

      {!state.loadingCC && state.ccData && (
        <StyledPlanGroup>
          <StyledPlanGroupTitle>
            <img src={SummaryIcon} alt="" />
            Cobrança
          </StyledPlanGroupTitle>
          <StyledPlanGroupDescription>Gerencie aqui suas informações de cobrança</StyledPlanGroupDescription>
          <div>
            <ShowCardChange
              onClickEdit={() => setState({showDialogUpdateCC: true})}
              cardData={state.ccData}
            />
            {state.showDialogUpdateCC && (
              <Modal onClose={() => setState({showDialogUpdateCC: false})} loading={false} width="450px">
                <CheckoutForm
                  dispatchToken={handleSubmitCC}
                  loading={false}
                  submitBtnLabel="Atualizar cartão"
                />
              </Modal>
            )}
          </div>
        </StyledPlanGroup>
      )}

      <StyledPlanGroup>
        <StyledPlanGroupTitle>
          <img src={DocumentIcon} alt="" />
          Documentos
        </StyledPlanGroupTitle>
        <StyledPlanGroupDescription>
          <IconContainer>
            {dataProfile.documents?.length > 0 ? (
              <FiCheckSquare size="100%" color="#01fe20" />
            ) : (
              <FiXCircle size="100%" color="#ff0600" />
            )}
          </IconContainer>
          {dataProfile.documents?.length > 0
            ? 'Documento de identificação enviado'
            : 'Documento de identificação não enviado'}
        </StyledPlanGroupDescription>
      </StyledPlanGroup>

      <StyledPlanGroup>
        <StyledPlanGroupTitle>
          <img src={SignatureIcon} alt="" />
          Assinatura
        </StyledPlanGroupTitle>
        <StyledPlanGroupDescription>
          <IconContainer>
            {dataProfile.contracts?.length > 0 ? (
              <FiCheckSquare size="100%" color="#01fe20" />
            ) : (
              <FiXCircle size="100%" color="#ff0600" />
            )}
          </IconContainer>
          {dataProfile.contracts?.length > 0 ? 'Contrato assinado' : 'Contrato não assinado'}
        </StyledPlanGroupDescription>
      </StyledPlanGroup>
    </StyledPlanContent>
  )
}

export default Profile

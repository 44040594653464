import styled from 'styled-components'

export const DivContent = styled.div`
  width: 100%;
  min-height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 1.5rem;
    align-items: center;
  }
`

export const RowIconWrapper = styled.div`
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`

export const IconLeft = styled.img`
  width: 3rem;
  height: 3rem;
`

export const IconTitle = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1.8rem;
  line-height: '130%';
  margin-left: 1.8rem;
`

export const RowContentDescription = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  padding-bottom: 5px;
  line-height: 18px;
  margin-left: 10px;
`

export const BigNumberContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export const MultiplierText = styled.span`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 2.5rem;
`

export const MultiplierNumber = styled.span`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 4rem;

  @media only screen and (max-width: 1060px) {
    font-size: 3rem;
  }
`

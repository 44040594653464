import React from 'react'
import {BsArrowRight} from 'react-icons/bs'
import {useIntl, FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'

import {Form} from '@unform/web'
import Select from 'components/Form/Select'
import Loading from 'components/loading'
import CheckoutForm from 'components/shared/CheckoutForm'
import PropTypes from 'prop-types'
import {b2cPlanCheckoutRequest} from 'store/modules/plan/action'
import * as Yup from 'yup'

import {StyledForm, StyledInputs, StyledFlex, StyledButton} from './styles'

export default function BookingPayment({planId}) {
  const {formatMessage} = useIntl()
  const _send_ = formatMessage({id: 'booking.checkout.send'})

  const loading = useSelector(state => state.plan.pending)
  const userType = useSelector(state => state.user.user.data.type || {})
  const dispatch = useDispatch()

  const [loadingToken, setLoadingToken] = React.useState(false)

  const submitToken = async token => {
    /* eslint-disable */
    setLoadingToken(true)
    dispatch(b2cPlanCheckoutRequest(token, planId))
    // api
    //   .post('/')
    //   .then(res => {})
    //   .catch(err => {})
    //   .finally(() => setLoadingToken(false))
    //  console.log('submit token here => token => ', token)
    /* eslint-enable */
  }

  async function handleSubmit(data) {
    try {
      const schema = Yup.object().shape({
        expires_day: Yup.number().required(),
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      dispatch(b2cPlanCheckoutRequest(data, planId))
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.error(<FormattedMessage id="booking.plan.checkout.error.input">{msg => msg}</FormattedMessage>)
      }
    }
  }

  const optionsExpiresDay = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 15,
      label: '15',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 25,
      label: '25',
    },
  ]

  return (
    <StyledForm>
      {userType.type === 'CPF' ? (
        <CheckoutForm dispatchToken={submitToken} loading={loadingToken || loading} submitBtnLabel={_send_} />
      ) : (
        <Form onSubmit={handleSubmit}>
          <StyledInputs>
            <StyledFlex>
              (
              <Select
                name="expires_day"
                placeholder=""
                label="Dia do Vencimento"
                options={optionsExpiresDay}
              />
              )
            </StyledFlex>
            <StyledFlex>
              <label htmlFor="submitButton">&nbsp;</label>
              <StyledButton
                disabled={loading}
                type="submit"
                id="submitButton"
                endIcon={<BsArrowRight size={25} />}
              >
                {loading ? <Loading color="black" width="25px" height="25px" /> : _send_}
              </StyledButton>
            </StyledFlex>
          </StyledInputs>
        </Form>
      )}
    </StyledForm>
  )
}

BookingPayment.defaultProps = {
  planId: '',
}

BookingPayment.propTypes = {
  planId: PropTypes.string,
}

import React from 'react'
import {BsArrowRight} from 'react-icons/bs'

import {Form} from '@unform/web'
import Input from 'components/Form/Input'
import Select from 'components/Form/Select'
import Loading from 'components/loading'
import {useIuguContext} from 'contexts/IuguContext'
import {useState} from 'hooks/useState'
import {
  StyledForm,
  StyledInputs,
  StyledFlex,
  StyledIcoCart,
  StyledInputCart,
  StyledButton,
} from 'pages/booking-payment/partials/form/styles'
import PropTypes from 'prop-types'

import {ccMask, cvvMask, cvvMask4n} from './ccMask'
import {toast} from 'react-toastify'

const CheckoutForm = ({dispatchToken, loading, submitBtnLabel}) => {
  const {state, setState} = useState({
    cvvMask,
  })

  const {isReady, Iugu} = useIuguContext()
  const year = new Date().getFullYear()
  const years = Array.from(new Array(20), (val, index) => index + year)
  const month = Array.from(new Array(12), (val, index) => index + 1)

  const optionsMonth = month.map(month => {
    return {
      value: `00${month}`.slice(-2),
      label: `00${month}`.slice(-2),
    }
  })

  const optionsYear = years.map(year => {
    return {
      value: year,
      label: year,
    }
  })

  const handleCCtokenError = error => {
    const options = [
      {label: 'number', parsed: 'Número do cartão'},
      {label: 'verification_value', parsed: 'Código de segurança'},
      {label: 'expiration', parsed: 'Data de validade'},
      {label: 'first_name', parsed: 'Nome do titular'},
      {label: 'last_name', parsed: 'Sobrenome do titular'},
      {label: 'full_name', parsed: 'Nome do titular'},
    ]

    options.forEach(opt => {
      if (Object.keys(error).includes(opt.label)) {
        if (error[opt.label] === 'is_invalid') {
          toast.error(`O ${opt.parsed} é inválido`)
        }
      }
    })
  }

  const onSubmit = data => {
    /* eslint-disable */
    const {number, holder_name: name, month, year, verification_number: cvv} = data

    const cc = Iugu.CreditCard(
      number.replaceAll('_', ''),
      month,
      year,
      name.trim().split(' ')[0],
      name.trim().split(' ').slice(-1)[0],
      cvv.replaceAll('_', '')
    )
    window.cc = cc
    Iugu.createPaymentToken(cc, response => {
      if (response.errors) {
        // console.log('[CheckoutForm] errors on create token =>', response.errors)
        handleCCtokenError(response.errors)
        // alert('Cartão inválido')
      } else {
        // console.log(`[CheckoutForm] Token created =>${response.id}`)
        dispatchToken(response.id)
      }
    })
    /* eslint-enable */
  }

  const onBlur = () => {
    const value = document.querySelector('.card-number').value
    if (!value) return
    const brand = Iugu.utils.getBrandByCreditCardNumber(value.replaceAll('_', ''))

    // eslint-disable-next-line
    // console.log('brand =>', brand)

    if (brand) {
      const blc = brand.replaceAll('_', '').toLowerCase()
      if (blc === 'visa' || blc === 'master') {
        setState({cvvMask})
      } else if (blc === 'amex') {
        setState({cvvMask: cvvMask4n})
      }
    } else {
      setState({cvvMask})
    }
  }

  if (!isReady || !Iugu) return <p>Carregando...</p>

  return (
    <StyledForm>
      <Form onSubmit={onSubmit}>
        <StyledInputs>
          <StyledFlex>
            <StyledInputCart>
              <Input
                className="card-number"
                onBlur={onBlur}
                name="number"
                type="tel"
                label="Número do cartão"
                placeholder=""
                mask={ccMask}
              />
              <StyledIcoCart />
            </StyledInputCart>
          </StyledFlex>
        </StyledInputs>

        <StyledInputs>
          <StyledFlex>
            <Input name="holder_name" label="Nome do titular do cartão" placeholder="" mask={false} />
          </StyledFlex>
        </StyledInputs>

        <StyledInputs>
          <StyledFlex>
            <Select name="month" placeholder="Mês" label="Data de validade" options={optionsMonth} />
          </StyledFlex>
          <StyledFlex>
            <Select name="year" placeholder="Ano" label="&nbsp;" options={optionsYear} />
          </StyledFlex>
        </StyledInputs>

        <StyledInputs>
          <StyledFlex>
            <Input name="verification_number" label="CVV" placeholder="CVV" type="tel" mask={state.cvvMask} />
          </StyledFlex>
          <StyledFlex>
            <label htmlFor="submitButton">&nbsp;</label>
            <StyledButton
              disabled={loading}
              type="submit"
              id="submitButton"
              endIcon={<BsArrowRight size={25} />}
            >
              {loading ? <Loading color="black" width="25px" height="25px" /> : 'Confirme'}
            </StyledButton>
          </StyledFlex>
        </StyledInputs>
      </Form>
    </StyledForm>
  )
}

CheckoutForm.defaultProps = {
  submitBtnLabel: 'Atualizar',
  loading: false,
}

CheckoutForm.propTypes = {
  dispatchToken: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  submitBtnLabel: PropTypes.string,
}

export default CheckoutForm

import styled from 'styled-components'

import BackgroundWelcome from '../../assets/images/bacground-welcome.png'
import theme from '../../assets/styles/theme'

export const Background = styled.div`
  background-image: url(${BackgroundWelcome});
  background-position: 100% 80%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  @media only screen and (max-width: 768px) {
    background-position: 15% 80%;
  }
`
export const StyledIconImg = styled.div`
  display: flex;
  width: 95%;
  height: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    height: 10%;
    margin-bottom: 15px;
  }
`
export const IconImg = styled.img`
  height: 50%;
  @media only screen and (max-width: 768px) {
    height: 86%;
  }
`
export const StyledImg = styled.img`
  position: relative;
  height: 100px;

  @media only screen and (max-width: 768px) {
    height: 90%;
  }
`
export const Content = styled.div`
  padding: 5% 0% 0% 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 70%;
`
export const VerticalLive = styled.div`
  border-left: 7px solid yellow;
  height: 20%;
  margin-top: 4%;
  margin-bottom: 3%;
  padding-left: 2%;
  align-items: center;
  display: flex;
`

export const DescriptionVerticalLine = styled.div`
  font-family: 'Raleway', sans-serif;
  font-size: 250%;
  color: #ffffff;
  font-weight: 800;
  width: 60%;
  align-items: center;
`
export const DescriptionApp = styled.div`
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 115%;
  width: 29%;
  padding-left: 2%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 60%;
  }
`
export const StyleApp = styled.div`
  padding: 2% 0% 0% 0%;
  width: 36%;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`
export const TextApp = styled.div`
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 115%;
  height: 40%;
  padding-left: 5%;
  @media only screen and (max-width: 768px) {
    padding-left: 2%;
  }
`
export const Apps = styled.img`
  width: 40%;
  padding: 0% 0% 0% 5%;
  @media only screen and (max-width: 768px) {
    width: 50%;
    padding: 0%;
    padding: 0% 0% 0% 3%;
  }
`
export const StyledFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #12121291;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: 29%;
  }
`
export const DescriptionFooter = styled.div`
  color: white;
  padding-left: ${props => props.pLeft};
  padding-right: ${props => props.pRight};
  font-family: 'Roboto', sans-serif;
  font-size: 120%;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    padding: 0%;
    justify-content: center;
    margin-top: 2px;
    font-size: 100%;
  }
`
export const FooterImg = styled.img`
  margin: 0px 5px;
  display: flex;
`
export const StyledFooterImg = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    justify-content: center;
    margin-top: 5px;
  }
`
export const DescriptionSpan = styled.span`
  color: ${theme('colors.solar_21.regular')};
`
export const StyledDivsDescription = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

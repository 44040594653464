/**
 * Get query variable value by key
 *
 * @author @xbrunosousa
 * @param {string} search Key name
 * @returns {string|null} Value of key
 */
export const getQueryVariable = search => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')

  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === search) {
      return decodeURIComponent(pair[1])
    }
  }
  return null
}

import React from 'react'

import PropTypes from 'prop-types'

import { StyledSummary, StyledList, StyledListFlex, StyledItem, StyledLi, StyledUl } from './styles'

function OrderSummary({ dataSummary }) {
  return (
    <>
      <StyledSummary>
        <StyledListFlex>
          <StyledItem>
            <StyledList>
              <StyledUl>
                <StyledLi>
                  <b>Plano: </b> {dataSummary.plan}
                </StyledLi>
                <StyledLi>
                  <b>Tx. de Instalação: </b> R$ {dataSummary.instalation}
                </StyledLi>
                <StyledLi>
                  <b>Tx. de Adesão: </b> R$ {dataSummary.joingFee}
                </StyledLi>
              </StyledUl>
            </StyledList>
          </StyledItem>

          <StyledItem>
            <StyledList>
              <StyledUl>
                <StyledLi>
                  <b>Telefone: </b> {dataSummary.phone}
                </StyledLi>
                <StyledLi>
                  <b>CEP: </b> {dataSummary.postal_code}
                </StyledLi>
                <StyledLi>
                  <b>Endereço: </b> {dataSummary.address}
                </StyledLi>
                <StyledLi>
                  <b>Número: </b> {dataSummary.address_number}
                </StyledLi>
                <StyledLi>
                  <b>Complemento: </b> {dataSummary.complement}
                </StyledLi>
                <StyledLi>
                  <b>Bairro: </b> {dataSummary.address_neighborhood}
                </StyledLi>
                <StyledLi>
                  <b>Cidade: </b> {dataSummary.citie}
                </StyledLi>
                <StyledLi>
                  <b>Estado: </b> {dataSummary.state}
                </StyledLi>
              </StyledUl>
            </StyledList>
          </StyledItem>
        </StyledListFlex>
      </StyledSummary>
    </>
  )
}

OrderSummary.defaultProps = {
  dataSummary: {},
}

OrderSummary.propTypes = {
  dataSummary: PropTypes.object,
}

export default OrderSummary

import React, { useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'

import CloseIco from '../../../assets/images/close.svg'
import { StyledOverlayModal, StyledModalClose, StyledModalWrapper } from './styles'

const Modal = ({ onClose, children, width, loading }) => {
  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  const clickClose = useCallback(
    e => {
      const code = e.keyCode ? e.keyCode : e.which
      if (code === 27 && loading !== true) handleCloseModal()
    },
    [handleCloseModal, loading]
  )

  const handleOverlayClick = e => {
    const id = e.target.getAttribute('id')
    if (id === 'overlay') {
      e.stopPropagation()
      handleCloseModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', clickClose)
    return () => {
      document.removeEventListener('keydown', clickClose)
    }
  }, [clickClose])

  return (
    <StyledOverlayModal onClick={loading ? null : handleOverlayClick} id="overlay">
      <StyledModalWrapper id="modal" width={width}>
        <StyledModalClose onClick={loading ? null : handleCloseModal}>
          <img src={CloseIco} alt="" />
        </StyledModalClose>
        {children}
      </StyledModalWrapper>
    </StyledOverlayModal>
  )
}

Modal.defaultProps = {
  onClose: () => {},
  children: null,
  loading: false,
  width: '',
}

Modal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.array,
  loading: PropTypes.bool,
  width: PropTypes.string,
}

export default Modal

import styled from 'styled-components'

export const StyledOverlayModal = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  &.is-closed {
    display: hidden;
  }
  @media only screen and (min-width: 769px) {
    left: 0;
  }
`

export const StyledModalClose = styled.span`
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px;
  cursor: pointer;
`

export const StyledModalWrapper = styled.div`
  font-family: 'Roboto';
  overflow-y: auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: 0.3s animateModal cubic-bezier(0.42, 0, 0.56, 1.13);
  background-color: #fff;
  box-shadow: #ccc;
  padding: 50px 50px !important;
  width: ${props => (props.width ? props.width : 'auto')};
  min-width: 100px;
  min-height: 100px;
  height: auto;
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  max-height: 90vh;
  transform: translate(-50%, -50%);
  & h1 {
    text-align: center;
    width: 80%;
    margin: 15px 0;
  }
  @keyframes animateModal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
    margin: auto;
  }
`

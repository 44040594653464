export type StepsType = {
  id: number
  status: string
  title: string
}

export const steps: StepsType[] = [
  {
    id: 0,
    status: 'PROJECT_IN_PREPARATION',
    title: 'Projeto em elaboração',
  },
  {
    id: 1,
    status: 'PROJECT_SUBMITTED_TO_DEALERSHIP',
    title: 'Projeto submetido à concessionária',
  },
  {
    id: 2,
    status: 'PROJECT_APPROVED_BY_THE_CONCESSIONAIRE',
    title: 'Projeto aprovado pela concessionária',
  },
  {
    id: 3,
    status: 'EQUIPMENT_DISPATCHED',
    title: 'Equipamento despachado',
  },
  {
    id: 4,
    status: 'EQUIPMENT_DELIVERED',
    title: 'Equipamento entregue',
  },
  {
    id: 5,
    status: 'INSPECTION_APPROVED',
    title: 'Vistoria aprovada',
  },
  {
    id: 6,
    status: 'SYSTEM_CONNECTED_AND_WORKING',
    title: 'Sistema conectado e funcionando',
  },
]

import React, { useState } from 'react'

import {Dialog, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from '@material-ui/core'
import Button from 'components/button'

import * as Yup from 'yup'

import * as S from './styles'
import {Form, Formik} from 'formik'
import api from 'services/api'
import { toast } from 'react-toastify'
import { FullScreenLoader } from 'components/fullScreenLoader'

type ConsumerModalType = {
  open: boolean
  onClose: any
  setOpenSignature: Function
}

type FormikValuesType = {
  maritalStatus: string
  birthDate?: string | null
  occupation: string
}

const ValidationSchema = Yup.object().shape({
  maritalStatus: Yup.string().required('Required'),
  birthDate: Yup.string().required('Required'),
  occupation: Yup.string().required('Required'),
})

const marital_status = [
  {label: 'Solteiro', value: 'solteiro'},
  {label: 'Casado', value: 'casado'},
  {label: 'Viúvo', value: 'viuvo'},
  {label: 'Divorciado', value: 'divorciado'},
  {label: 'União estável', value: 'uniao estavel'},
]

const ModalConsumer = ({open, onClose, setOpenSignature}: ConsumerModalType) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (values: FormikValuesType) => {
    const {birthDate, maritalStatus, occupation} = values
    setIsLoading(true);
    await api
      .post('portal/customers/create_contracts', {
        occupation,
        marital_status: maritalStatus,
        date_of_birth: birthDate,
      })
      .then(() => {
        toast.success('Contratos gerados!', {
          onClose: () =>  window.location.reload(),
          autoClose: 2000
        })
      })
      .catch(err => {
        toast.error('Erro ao gerar contratos!',{
          autoClose: 2000
        })
        setIsLoading(false);
        return
      })
     
  }

  if (isLoading) return <FullScreenLoader />

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Formik
        initialValues={{maritalStatus: '', birthDate: '', occupation: ''}}
        onSubmit={values => handleSubmit(values)}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({values, errors, handleChange}) => (
          <Form>
            <S.StyledInputs>
              <Typography variant="h6" gutterBottom>
                Entre com os dados para o contrato:
              </Typography>
              <S.FlexContainer>
                <InputLabel error={!!errors.occupation}>Profissão</InputLabel>
              </S.FlexContainer>
              <TextField
                style={{width: '100%', marginBottom: '1rem'}}
                onChange={handleChange}
                name="occupation"
                value={values.occupation}
                error={!!errors.occupation}
              />
              <S.FlexContainer>
                <InputLabel error={!!errors.maritalStatus}>Estado civil</InputLabel>
              </S.FlexContainer>
              <FormControl style={{width: '100%', marginBottom: '1rem'}}>
                <Select
                  error={!!errors.maritalStatus}
                  value={values.maritalStatus}
                  onChange={handleChange}
                  name="maritalStatus"
                >
                  {marital_status.map((status, index) => (
                    <MenuItem key={`${status}=${index}`} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <S.FlexContainer>
                <InputLabel error={!!errors.birthDate}>Data de nascimento</InputLabel>
              </S.FlexContainer>
              <TextField
                style={{width: '100%', marginBottom: '1rem'}}
                onChange={handleChange}
                name="birthDate"
                value={values.birthDate}
                error={!!errors.birthDate}
                type="date"
              />
              <S.StyledButtonGroup>
                <Button color="#ccc" onClick={onClose} text="Cancelar" />
                <Button type="submit" text="Continuar" />
              </S.StyledButtonGroup>
            </S.StyledInputs>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default ModalConsumer

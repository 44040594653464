import types from 'store/types'

export function faqRequest() {
  return {
    type: types.FAQ_REQUEST,
  }
}

export function faqSuccess(faqs) {
  return {
    type: types.FAQ_SUCCESS,
    payload: {faqs},
  }
}

export function faqFailure() {
  return {
    type: types.FAQ_FAILURE,
  }
}

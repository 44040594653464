import produce from 'immer'

import types from 'store/types'

const INITIAL_STATE = {
  user: {},
  isLoading: false,
  partnership: {},
  isUploadingCard: false,
}

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.USER_REQUEST:
        draft.isLoading = true
        break
      case types.USER_REQUEST_SUCCESS:
        draft.isLoading = false
        draft.user = action.payload.user
        break
      case types.USER_REQUEST_FAILURE:
        draft.isLoading = false
        break
      case types.PARTNERSHIP_REQUEST:
        draft.isLoading = true
        draft.partnership = {}
        break
      case types.PARTNERSHIP_SUCCESS:
        draft.isLoading = false
        draft.partnership = action.payload.partnership
        break
      case types.PARTNERSHIP_FAILURE:
        draft.isLoading = false
        draft.partnership = {}
        break
      case types.EMPLOYEE_CARD_SEND_REQUEST:
        draft.isUploadingCard = true
        break
      case types.EMPLOYEE_CARD_SEND_SUCCESS:
        draft.isUploadingCard = false
        break
      case types.EMPLOYEE_CARD_SEND_FAILURE:
        draft.isUploadingCard = false
        break
      default:
    }
  })
}

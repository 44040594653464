export default {
  pt: {
    // error code
    'code.PHONE_NOT_FOUND': 'Telefone não encontrado',
    'code.UNAUTHORIZED': 'Código de acesso inválido',
    'code.LOGOUT_FAILURE': 'Não foi possível fazer logout',
    'code.COUNTRY_CODE_INVALID': 'Preencha corretamente o campo País',
    'code.PHONE_NUMBER_INVALID': 'Preencha corretamento o campo Celular',

    // common
    'common.logo.title-residential': 'RESIDENCIAL',
    'common.logo.title-commercial': 'COMERCIAL',
    'common.button.register': 'Cadastrar',
    'common.button.send': 'Enviar',
    'common.button.back': 'Voltar',
    'common.button.signup': 'Não possui cadastro? Cadastre-se grátis.',
    'common.button.forward': 'Avançar',
    'common.button.submitting': 'Enviando...',
    'common.button.confirm': 'Confirmar',
    'common.button.confirming': 'Confirmando...',
    'common.button.access': 'Acessar',
    'common.button.prefer': 'Selecionar',
    'common.month': 'mês',
    'common.year': 'Ano',
    'common.money': 'R$',
    'common.youSave': 'Você economiza',
    'common.defaultAccount': 'Sua conta padrão',
    'common.newValue': 'Sua conta nova',
    'common.plan.low': 'Plano Low',
    'common.plan.med': 'Plano Med',
    'common.plan.max': 'Plano Max',
    'common.yes': 'Sim',
    'common.no': 'Não',
    'common.file.text': 'Selecionar documento com foto',

    'common.form.required': 'Campo obrigatório',
    'common.form.requiredCountryCode': 'DDI obrigatório',
    'common.form.countryCode': 'País',
    'common.form.minimumCountryCode': 'DDD deve possuir no mínimo três digítos',
    'common.form.requiredCellphone': 'Celular é obrigatório',
    'common.form.cellphone': 'Celular',
    'common.form.minimumCellphone': 'Celular deve possuir no mínimo 11 digítos',
    'common.form.maxCellphone': 'Celular deve possuir no máximo 11 digítos',
    'common.form.verificationMessage': 'Você vai receber um código para confirmar seu telefone.',
    'common.form.name': 'Nome Completo',
    'common.form.name.required': 'Nome é obrigatório',
    'common.form.name.min': 'Nome deve possuir pelo menos três caracteres',
    'common.form.email': 'Email',
    'common.form.coupon': 'Cupom (se você tiver)',
    'common.form.email.required': 'Email é obrigatório',
    'common.form.email.valid': 'Email inválido',
    'common.form.unit.valid': 'Infelizmente não atendemos apartamentos',
    'common.form.uf': 'Estado',
    'common.form.uf.firstOption': 'Selecione uma UF',
    'common.form.uf.required': 'UF é obrigatório',
    'common.form.city': 'Cidade',
    'common.form.city.firstOption': 'Selecione uma cidade',
    'common.form.city.required': 'Cidade é obrigatório',
    'common.form.unit': 'Tipo de Unidade',
    'common.form.unit.firstOption': 'Selecione uma unidade',
    'common.form.unit.required': 'Unidade é obrigatório',
    'common.form.bill': 'Valor da conta',
    'common.form.bill.required': 'Valor da conta é obrigatória',
    'common.form.cnpj': 'CNPJ',
    'common.form.cnpj.required': 'CNPJ é obrigatório',
    'common.form.cnpj.max': 'CNPJ deve possuir no máximo 14 caracteres',
    'common.form.razaoSocial': 'Razão Social',
    'common.form.cnpj.razaoSocial': 'Razão Social é obrigatório',
    'common.form.razaoSocial.required': 'Razão Social é obrigatório',
    'common.form.razaoSocial.min': 'Razão Social deve possuir no mínimo 3 caracteres',

    'common.placeholder.name': 'Digite seu nome completo',
    'common.placeholder.email': 'Digite seu email',
    'common.placeholder.coupon': 'Digite seu cupom de desconto se tiver',
    'common.placeholder.bill': 'Digite o valor da conta',
    'common.placeholder.cnpj': 'Digite o CNPJ',

    'common.access.form.requiredCode': 'Código de acesso é obrigatório',
    'common.access.form.maxCode': 'Código de acesso deve ter somento um digíto',
    'common.access.code': 'Código de acesso enviado por SMS para: ',

    // Aurora
    'aurora.bill_value': 'Valor da Conta',
    'aurora.consumption_class': 'Classe de Consumo',
    'aurora.consumption': 'Consumo',
    'aurora.analyze.hi': 'Oi',
    'aurora.analyze.introduction': 'Eu sou a Aurora e estou',
    'aurora.analyze.light_bill': 'analisando a conta de luz',
    'aurora.analyze.moment': 'só um instante...',
    'aurora.bill.pending':
      'Humm ! Infelizmente não conseguimos ler a sua conta. Não se preocupe, vamos avaliar e retornamos em breve.',
    'aurora.bill.denied':
      'Ops ! infelizmente sua conta não segue os nossos requisitos e não podemos seguir. Favor enviar um novo arquivo.',
    'aurora.bill.approved':
      'Uhuuu! Sua conta foi aprovada. Agora você está apto a fazer o agendamento da visita técnica GRATUITA!',
    'aurora.bill.not.approved':
      'Obrigada pelo envio da sua conta de luz! Estamos analisando as informações e retornaremos em breve! Fique atento ao seu e-mail.',
    'aurota.bill.city.not.approved':
      'Ops ! infelizmente sua conta não segue os nossos requisitos e não podemos seguir.',
    'aurora.bill.analyzing': 'Oi, eu sou a Aurora e estou analisando a conta de luz, só um instante...',

    // footer
    'footer.right.text': 'Solar21 | Ilumine o futuro',
    'footer.left.howItWorks': 'Como funciona',
    'footer.left.commercial': 'Comercial',
    'footer.left.residential': 'Residencial',
    'footer.left.aboutUs': 'Sobre nós',

    // footer links
    'footerlinks.privacyPolicy': 'Política de Privacidade',
    'footerlinks.generalConditions': 'Condições gerais dos planos de assinatura',

    // navbar
    'navbar.link.economySimulation': 'Simulação de Economia',
    'navbar.link.electricityBill': 'Conta de Luz',
    'navbar.link.technicalVisit': 'Visita Técnica',
    'navbar.link.planSubscription': 'Assinatura do Plano',
    'navbar.link.projectStatus': 'Status do Projeto',
    'navbar.link.logout': 'Sair',
    'navbar.link.myProfile': 'Meu perfil',
    'navbar.link.proposalInitial': 'Proposta Inicial',
    'navbar.link.proposalFinal': 'Proposta Final',
    'navbar.link.payment': 'Contrato',

    // login
    'login.right.title': 'Ainda não tem cadastro?',
    'login.right.subtitle': 'Cadastre-se grátis',
    'login.left.title': 'Já possui cadastro?',
    'login.left.subtitle': 'Acesse a plataforma',
    'login.left.description':
      'Digite seu celular para entrar. Seus dados estão seguros e você não precisa de senha.',

    // signup
    'signup.left.title': 'Ainda não tem cadastro?',
    'signup.left.subtitle': 'Cadastre-se grátis',
    'signup.right.title': 'Já possui cadastro?',
    'signup.right.subtitle': 'Acesse a plataforma',
    'signup.right.description':
      'Para simular sua economia, digite seu celular. Seus dados estão seguros e você não precisa de senha.',
    'signup.access.title': 'Ainda não tem cadastro?',
    'signup.access.subtitle': 'Cadastre-se grátis',
    'signup.access.description':
      'Você está a um passo de ter energia barata, sustentável e sem dor de cabeça. Preencha os dados abaixo e faça uma simulação de economia.',
    'signup.invalid': 'Algo deu de errado, tente novamente.',
    'signup.with.success': 'Cadastro Efetuado com Sucesso.',
    'signup.valid': 'Conta cadastrada com sucesso.',

    // simulation
    'simulation.header.title': 'Simulação',
    'simulation.header.description': 'Descubra o quanto você pode economizar com energia solar.',
    'simulation.content.simulateNow': 'Simule agora mesmo!',
    'simulation.content.simulatePlan': 'Para simular seu plano solar, preencha o campo abaixo.',
    'simulation.content.lightBill': 'Quanto é a sua conta mensal de luz?',
    'simulation.content.economy.title': 'Quanto você pode economizar:',
    'simulation.content.economy.description':
      '*Esses valores são uma estimativa. Somente após a visita técnica podemos informar valores definitivos.',
    'simulation.content.rightImages.title': 'Disponível em todos os planos',
    'simulation.content.rightImages.accession': 'Tx. de adesão Zero',
    'simulation.content.rightImages.solarPower': 'Instalação Grátis',
    'simulation.content.rightImages.freeInstallation': 'Potência Solar 4,8 kWp',
    'simulation.content.annualSavings': 'Economia anual',
    'simulation.content.planTerm': 'Prazo do Plano',

    // bill
    'bill.page.title': 'Conta de Luz',
    'bill.page.description': 'Envie sua conta de luz para nossa avaliação',
    'bill.page.holder': 'Você é titular da conta de luz?',
    'bill.page.file.text.consumption': 'Arraste e solte o arquivo que contenha o consumo de sua conta de luz',
    'bill.page.file.text.value': 'Arraste e solte o arquivo que contenha o valor de sua conta de luz',
    'booking.page.file.text.value': 'Arraste e solte o arquivo',

    // bill Files
    'bill.file.not.permitted': 'Tipo de arquivo não permitido',
    'bill.file.sending': 'Enviando arquivos...',
    'bill.file.send': 'Arquivo enviado!',
    'bill.file.error.send': 'Erro ao subir arquivo!',
    'bill.file.button.send': 'Enviar',
    'bill.file.error.receive': 'Erro ao receber arquivo!',

    // Technicalvisit
    'technicalvisit.header.title': 'Visita Técnica',
    'technicalvisit.header.description': 'Agende sem custo a visita no melhor dia e horário para você.',
    'technicalvisit.form.phone': 'Telefone',
    'technicalvisit.form.zip': 'CEP',
    'technicalvisit.form.address': 'Endereço',
    'technicalvisit.form.gps': 'Localização GPS (opcional)',
    'technicalvisit.form.number': 'Número',
    'technicalvisit.form.complement': 'Complemento',
    'technicalvisit.form.city': 'Cidade',
    'technicalvisit.form.state': 'Estado',
    'technicalvisit.errors.date': 'Por favor selecione um dia e horario.',

    // Plans
    'plans.header.title': 'Assinatura do Plano',
    'plans.header.description': 'Escolha o melhor plano para você.',
    'plans.content.confirmation': 'Confirmação do plano',
    'plans.content.confirmationSubtitle': 'Selecione seu plano e siga para a reserva de pagamento',
    'plans.content.plan.low': 'Plano Low',
    'plans.content.plan.med': 'Plano Med',
    'plans.content.plan.max': 'Plano Máx',
    'plans.content.smart.save': 'Smart Save',
    'plans.content.economy.save': 'Economia de',
    'plans.table.content.deadline': 'Prazo do Plano',
    'plans.table.content.annual.savings': 'Economia anual estimada',
    'plans.table.content.month.savings': 'Economia média mensal',
    'plans.table.content.monthly.payment': '',
    'plans.table.content.installation': 'Instalação',
    'plans.table.content.installation.membership': 'Instalação e Taxa de adesão',
    'plans.table.content.membership.fee': 'Tx. de adesão',
    'plans.table.content.powerandsize': 'Tamanho do sistema solar',
    'plans.table.content.SystemSolar': 'Sistema solar será seu',
    'plans.table.content.monitoring': 'Monitoramento via App',
    'plans.table.content.maintenance.and.service': 'Manutenção e assistência 24h',
    'plans.table.content.free.monthly.fee': 'Mensalidade grátis',
    'plans.table.content.free.month.fee': '1ª mensalidade grátis',
    'plans.table.content.free.month.fee.partnership': '1ª e 2ª mensalidades grátis',
    'plans.table.content.free': 'Grátis',
    'plans.table.content.zero': 'Zero',
    'plans.table.content.twenty': 'Após 20° ano',
    // Plans Panel right
    'plans.content.rightImages.title': 'Disponível em todos os planos',
    'plans.content.rightImages.solarTree': 'Equivalente a 3 árvores plantadas por ano',
    'plans.content.rightImages.power': 'Potência Solar 4,8 kWp',
    // Plans Modal Confirm
    'plans.content.modal.title': 'Você escolheu o plano',
    'plans.type.modal.low': 'Plano Smart Save Low',
    'plans.type.modal.medium': 'Plano Smart Save Medium',
    'plans.type.modal.max': 'Plano Smart Save Max',
    'plans.content.modal.plan.value': 'valor do plano',
    'plans.content.modal.plan.save': 'economia mensal',
    'plan.button.confirm': 'Confirmar',
    // Plans Reservation
    'plans.content.booking': 'Reserva de Pagamento',
    'plans.content.bookingSubtitle':
      'Reserve os dados do seu cartão de crédito. Fique tranquilo, você não será cobrado agora.',
    'plans.content.modal.warning.text':
      'Fique tranquilo, essa escolha não é definitiva. É só pra entender qual a sua preferência. Após a visita técnica você poderá escolher o melhor plano pra você.',

    'bookink.plan.detail': 'Detalhes da contratação',
    'bookink.plan.detail.smart.save': 'Smart Save Plano',
    'booking.plan.detail.instalation': 'Taxa de instalação',
    'booking.plan.detail.joining_fee': 'Taxa de adesão',
    'booking.plan.detail.total': 'Total',
    'booking.plan.detail.plan': 'Detalhes do Plano',
    'booking.plan.detail.economy_per_month': 'Economia Mensal',
    'booking.plan.detail.annual_savings': 'Economia Anual',
    'booking.plan.detail.reduction_percent': '% de Economia',
    'booking.plan.order.title': 'Pré-autorização cartão de crédito',
    'booking.plan.checkout.success': 'Checkout efetuado com sucesso',
    'booking.plan.checkout.error': 'Erro ao fazer checkout',
    'booking.plan.checkout.error.input': 'Por favor, verifique se os dados preenchidos estão corretos',
    'booking.checkout.card': 'Número do cartão',
    'booking.checkout.card.placeholder': 'Digite somente os números',
    'booking.checkout.titular': 'Nome do titular',
    'booking.checkout.titular.placeholder': 'Digite o nome impresso',
    'booking.checkout.validthu': 'Validade',
    'booking.checkout.validthuMonth': 'Mês',
    'booking.checkout.validthuYear': 'Ano',
    'booking.checkout.secureCode': 'Código de segurança',
    'booking.checkout.secureCode.placeholder': 'CVV',
    'booking.checkout.send': 'Enviar',
    'booking.plan.doc.title': 'Documentos',
    'booking.plan.doc.subTitle': 'Envie seus documentos para avaliação.',
    'booking.plan.doc.label.upload': '* Documento do titular da Unidade Consumidora.',
    'booking.plan.doc.label.cnpjCard': '* Cartão CNPJ da empresa ou condomínio.',
    'booking.plan.doc.label.comproveLegal':
      '* Documento que comprove o representante legal da empresa ou condomínio.',
    'booking.plan.doc.label.documentLegal': '* Documento do representante legal da empresa ou condomínio.',
    'booking.plan.doc.label.info':
      'Tire uma foto ou insira um documento com foto (frente e verso). Pode ser CNH, identidade ou outro.',
    'booking.assign.plan': 'Assine o contrato',
    'booking.consumer.data.success': 'Dados salvo com sucesso',
    'booking.consumer.data.error': 'Erro ao salvar',
    'booking.consumer.error.input': 'Por favor, verifique se os dados preenchidos estão corretos',
    'booking.label.checkSuccess': 'Checkout Realizado com Sucesso !!!',
    'booking.label.archiveSuccess': 'Arquivo Enviado com Sucesso !!!',
    'booking.plan.doc.label.cnpjCardInfo': 'Arraste e solte o arquivo que contenha o CNPJ',
    'booking.plan.doc.label.comproveLegalInfo':
      'Arraste e solte o arquivo que comprove o representante legal',
    'booking.plan.doc.label.documentLegalInfo':
      'Arraste e solte o arquivo que contenha o documento do representante',
    // Welcome
    'welcome.content.titleOne': 'Bem-vindo à ',
    'welcome.content.titleTwo': 'Solar21',
    'welcome.content.description':
      'Reinvente a sua maneira de consumir energia. Você economiza na conta de luz e o planeta agradece.',
    'welcome.content.descriptionApp': 'Baixe nosso App de monitoramento',
    'welcome.content.footerDescriptionOne': 'Solar21 | Ilumine o futuro',
    'welcome.content.footerDescriptionThree': 'Como funciona',
    'welcome.content.footerDescriptionFour': 'Comercial',
    'welcome.content.footerDescriptiomFive': 'Residencial',
    'welcome.content.footerDescriptionSix': 'Sobre nós',

    // Signature
    'signature.content.title': 'Assinatura',
    'signature.content.description': 'Leia atentamente as Condições Gerais e o Termo de Adesão',
    'signature.modal.title': 'Condições Gerais',
    'signature.modal.button.yes': 'Aceito',
    'signature.modal.button.no': 'Não Aceito',
    'signature.check.description': 'Li e concordo com as Condições gerais.',
    'signature.check.next': 'Finalizar',
    'signature.button.visualization': 'Visualize para assinar',
    'signature.firstParagraph.description': 'Dados para assinatura enviados com sucesso!',
    'signature.secondParagraph.description':
      'Os dados enviados serão analisados e em breve você receberá uma confirmação no seu e-mail.',
    'signature.signedTrue.description':
      'Pronto! Agora você é assinante Solar21 e em breve produzirá energia limpa e barata.',

    // Documents Here
    'documents.here': 'Arraste e solte seu(s) arquivo(s) aqui',

    // thank you page
    'thankyou.title': 'Seja bem-vindo à',
    'thankyou.subtitle': 'sua jornada por um mundo',
    'thankyou.subtitle2': 'sustentável começa em',
  },
}

import React from 'react'
import {Switch} from 'react-router-dom'

import ThankYouPage from 'pages/thankyou'
import welcome from 'pages/welcome'

import Bill from '../pages/bill'
import BookingPayments from '../pages/booking-payment'
import Initial from '../pages/initial'
import Login from '../pages/login'
import PaymentStep from '../pages/payment'
import AccessAccount from '../pages/login/partials/access-acount'
import PlanSignature from '../pages/plans'
import Profile from '../pages/profile'
import Signature from '../pages/signature'
import SignUp from '../pages/signup'
import AccessSignup from '../pages/signup/partials/access-code'
import CreateAccount from '../pages/signup/partials/create-account'
import TechnicalVisit from '../pages/technicalvisit'
import ProposalStep from '../pages/proposal-step'
import ProjectStatusPage from 'pages/project-status'
import {RouteMultiLangue} from './RouteMultiLangue'

export default function Routes() {
  return (
    <Switch>
      <RouteMultiLangue path="/:lang" exact component={Initial} />
      <RouteMultiLangue path="/:lang/login" exact component={Login} />
      <RouteMultiLangue path="/:lang/welcome" exact component={welcome} />

      <RouteMultiLangue path="/:lang/access-account" exact component={AccessAccount} />
      <RouteMultiLangue path="/:lang/sign-up" exact component={SignUp} />
      <RouteMultiLangue path="/:lang/sign-up/access-code" exact component={AccessSignup} />
      <RouteMultiLangue path="/:lang/create-account" exact component={CreateAccount} />

      <RouteMultiLangue path="/:lang/thank-you" exact component={ThankYouPage} />
      <RouteMultiLangue path="/:lang/profile" exact component={Profile} isPrivate />
      <RouteMultiLangue path="/:lang/bill" component={Bill} isPrivate />
      <RouteMultiLangue path="/:lang/technical-visit" exact component={TechnicalVisit} isPrivate />

      <RouteMultiLangue path="/:lang/proposal-initial" exact component={ProposalStep} isPrivate />
      <RouteMultiLangue path="/:lang/proposal-final" exact component={ProposalStep} isPrivate />

      <RouteMultiLangue path="/:lang/payment" exact component={PaymentStep} isPrivate />

      <RouteMultiLangue path="/:lang/plan-signature" exact component={PlanSignature} isPrivate />
      <RouteMultiLangue path="/:lang/project-status" exact component={ProjectStatusPage} isPrivate />
      <RouteMultiLangue path="/:lang/customers-signature" exact component={Signature} isPrivate />
      <RouteMultiLangue path="/:lang/booking-payment" exact component={BookingPayments} isPrivate />

      <RouteMultiLangue path={['/:lang/simulation', '/:lang/bill']} exact component={Bill} isPrivate />
    </Switch>
  )
}

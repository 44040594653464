import React from 'react'
import { FiMail, FiEye, FiSearch } from 'react-icons/fi'
import MaskedInput from 'react-text-mask'

import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Brazil from '../../assets/images/brazil.svg'
import theme from '../../assets/styles/theme'

const borders = {
  before: css`
    border-radius: 6px 0 0 6px;
  `,
  after: css`
    border-radius: 0 6px 6px 0;
  `,
  both: css`
    border-radius: 0px;
  `,
}

const sizes = {
  large: css`
    padding: 16px;
  `,
  medium: css`
    padding: 13px;
  `,
  small: css`
    ${theme('fontSize.sm')};
    padding: 10px;
  `,
}

const variants = {
  filled: css`
    background: ${theme('colors.white')};
    border: 1px solid ${theme('colors.solar_21.gray_light')};
    :focus {
      box-shadow: 0 0 0 1px ${theme('colors.solar_21.gray_light')};
    }
  `,

  outlined: css`
    color: ${theme('colors.blue.900')};
    background: ${theme('colors.white')};
    border: 1px solid ${theme('colors.gray.400')};
    ${props =>
      props.dark &&
      css`
        background: ${theme('colors.blue.100')};
      `}
    :focus {
      border: 1px solid ${theme('colors.blue.600')};
      box-shadow: 0 0 0 1px ${theme('colors.blue.600')};
    }
  `,
}

const StyledInput = styled.input`
  text-transform: ${props => props.textTransform};
  font-size: ${theme('fontSize.base')};
  box-sizing: border-box;
  color: ${theme('colors.gray.700')};
  ::placeholder {
    color: ${theme('colors.solar_21.gray')};
  }
  :disabled {
    color: ${theme('colors.gray.700')};
    background-color: ${theme('colors.blue.100')};
    cursor: not-allowed;
  }
  :focus {
    outline: none !important;
  }
  width: ${props => (props.width ? props.width : 'auto')};
  text-align: ${props => (props.align ? props.align : null)};
  ${props => sizes[props.size]}
  ${props => variants[props.variant]}
  ${props =>
    props.hasIcon &&
    css`
      padding-left: ${props.size === 'large' ? '52px' : '36px'};
    `}
  ${props =>
    props.hasError &&
    css`
      border: 1px solid ${theme('colors.red.500')};
    `}
  ${props => borders[props.auxiliar]}

  ::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
`

const StyledWrapper = styled.div`
  display: flex;
  position: relative;
`
const StyledWrapperInput = styled.div`
  position: relative;
  width: 100%;
`

const StyledIcon = styled.i`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  height: 100%;
  padding: 17px 12px;
  box-sizing: border-box;
  svg {
    width: ${props => (props.size === 'large' ? '20px' : '16px')};
    height: ${props => (props.size === 'large' ? '20px' : '16px')};
    color: ${theme('colors.gray.500')};
  }
`

const StyledInputText = styled.div`
  background-color: grey;
  background: ${theme('colors.gray.400')};
  color: ${theme('colors.gray.600')};
  font-weight: regular;
  ${props => sizes[props.size]};
  ${props => borders[props.side]};
`

const BrazilIcon = styled.img`
  width: ${props => (props.size === 'large' ? '20px' : '16px')};
  height: ${props => (props.size === 'large' ? '20px' : '16px')};
  /* color: ${theme('colors.gray.500')}; */
`
// ===================================================

const Input = React.forwardRef(
  (
    {
      width,
      icon,
      size,
      onSearch,
      auxiliar,
      beforeText,
      afterText,
      placeholder,
      align,
      textTransform,
      maskNumber,
      ...rest
    },
    ref
  ) => {
    const handleKeyPress = e => {
      if (e.key === 'Enter') onSearch(e)
    }
    return (
      <StyledWrapper>
        {beforeText && (
          <StyledInputText side="before" size={size}>
            {beforeText}
          </StyledInputText>
        )}
        <StyledWrapperInput auxiliar={auxiliar}>
          {icon && (
            <StyledIcon size={size}>
              {icon === 'mail' && <FiMail onClick={onSearch} />}
              {icon === 'eye' && <FiEye onClick={onSearch} />}
              {icon === 'search' && <FiSearch color={theme('colors.blue.600')} onClick={onSearch} />}
              {icon === 'brazil' && <BrazilIcon src={Brazil} />}
            </StyledIcon>
          )}
          {maskNumber ? (
            <MaskedInput
              mask={maskNumber}
              id="my-input-id"
              ref={ref}
              size={size}
              onKeyPress={handleKeyPress}
              auxiliar={auxiliar}
              width={width}
              align={align}
              hasIcon={icon}
              placeholder={placeholder}
              textTransform={textTransform}
              {...rest}
              render={(ref, props) => <StyledInput ref={ref} {...props} />}
            />
          ) : (
            <StyledInput
              ref={ref}
              {...rest}
              size={size}
              onKeyPress={handleKeyPress}
              auxiliar={auxiliar}
              width={width}
              align={align}
              hasIcon={icon}
              placeholder={placeholder}
              textTransform={textTransform}
            />
          )}
        </StyledWrapperInput>
        {afterText && (
          <StyledInputText side="after" size={size}>
            {afterText}
          </StyledInputText>
        )}
      </StyledWrapper>
    )
  }
)

Input.defaultProps = {
  size: 'medium',
  variant: 'filled',
  width: '100%',
  placeholder: null,
  icon: null,
  hasError: null,
  onSearch: () => {},
  beforeText: null,
  afterText: null,
  auxiliar: null,
  align: null,
  textTransform: '',
  maskNumber: null,
}

Input.propTypes = {
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  variant: PropTypes.oneOf(['filled', 'outlined']),
  onSearch: PropTypes.func,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.oneOf(['mail', 'eye', 'search', 'brazil']),
  hasError: PropTypes.bool,
  auxiliar: PropTypes.oneOf(['after', 'before', 'both']),
  beforeText: PropTypes.string,
  afterText: PropTypes.string,
  align: PropTypes.string,
  textTransform: PropTypes.string,
  maskNumber: PropTypes.string,
}

export default Input

import React, {useEffect, useState, useCallback} from 'react'
import {isEmpty} from 'lodash'

import ModalContent from 'components/ui/modal'
import {InputAdornment, AccordionSummary, AccordionDetails} from '@material-ui/core'
import {Search, Add, ArrowRightAlt} from '@material-ui/icons'

import * as S from './styles'

interface IFaq {
  id: number
  description: string
  title: string
}

type HelpModalProps = {
  open: boolean
  onClose: () => any
  questions?: IFaq[]
}

const HelpModal = ({open, onClose, questions}: HelpModalProps) => {
  const [search, setSearch] = useState<string>('')
  const [faqs, setFaqs] = useState<IFaq[] | undefined>([])

  useEffect(() => {
    setFaqs(
      isEmpty(search)
        ? questions
        : questions?.filter(_faq => _faq.title.toLowerCase().includes(search.toLowerCase()))
    )
  }, [questions, search])

  useEffect(() => {
    setFaqs(questions)
  }, [questions])

  const handleWAppLink = () => window.open('https://wa.me/5511999492399', '_blanck')

  const renderInfoOptions = useCallback(
    () => (
      <>
        <S.SearchContainer>
          <S.SearchLabel htmlFor="modal-search">Faça uma busca</S.SearchLabel>
          <S.SearchInput
            id="modal-search"
            type="text"
            value={search}
            placeholder="Qual a sua pergunta?"
            onChange={e => setSearch(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
          />
        </S.SearchContainer>
        <S.OptionsBox>
          {faqs?.map((question, index) => (
            <>
              <S.StyledAccordion key={`accordion-${question.id}-${index}`}>
                <AccordionSummary
                  expandIcon={<Add />}
                  aria-controls={`accordion-${question.id}-content`}
                  id={`accordion-${question.id}-header`}
                >
                  {question.title}
                </AccordionSummary>
                <AccordionDetails dangerouslySetInnerHTML={{__html: question.description}} />
              </S.StyledAccordion>
            </>
          ))}
        </S.OptionsBox>
      </>
    ),
    [search, faqs]
  )

  return (
    <>
      {open && (
        <ModalContent onClose={onClose} width="535px" loading={false}>
          {renderInfoOptions()}
          <S.InfoBox>
            <S.Info>Não econtrou o que precisa?</S.Info>
            <S.Info>Temos uma equipe pronta para te atender pelo WhatsApp!</S.Info>
          </S.InfoBox>
          <S.ButtonBox>
            <S.CloseButton onClick={onClose}>Fechar</S.CloseButton>
            <S.HelpButton onClick={handleWAppLink}>
              Falar com a equipe <ArrowRightAlt fontSize="large" />
            </S.HelpButton>
          </S.ButtonBox>
        </ModalContent>
      )}
    </>
  )
}

export default HelpModal

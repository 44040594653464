import React from 'react'

import BigNumberCard from './BigNumberCard/BigNumberCard'
import {CardData} from './data'
import * as S from './styles'

const GeneralDataCards = ({main}: any) => {
  return (
    <S.CardsContainer>
      {CardData(main).map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <BigNumberCard key={`BigNumberCard-${index}`} {...item} />
      ))}
    </S.CardsContainer>
  )
}

export default GeneralDataCards

import styled from 'styled-components'

import theme from 'assets/styles/theme'

export const StyledPlanContent = styled.div`
  margin: 4rem;
  padding-bottom: 40px;

  @media only screen and (max-width: 768px) {
    margin: 1rem;
    padding-bottom: 100px;
  }
`

export const StyledButtonSendDoc = styled.div`
  float: right;
  padding: 10px 0;

  @media only screen and (max-width: 768px) {
    float: none;
    padding: 0;

    > button {
      width: 100%;
    }
  }
`

export const StyledPlanGroup = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const StyledPlanGroupTitle = styled.span`
  display: flex;
  align-items: center;
  font-size: 22px;
  color: ${theme('colors.solar_21.gray')};
  font-weight: 500;
  margin-bottom: 0.5rem;

  img {
    margin-right: 0.6rem;
  }
`

export const StyledPlanGroupDescription = styled.span`
  color: ${theme('colors.solar_21.gray')};
  font-weight: 200;
  font-size: 18px;
`

export const StyledFlexGroup = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const StyledFlexList = styled.div`
  width: 49%;
  background: #f2f4f7;
  padding: 80px;
  @media screen and (max-width: 992px) {
    padding: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`

export const StyledBox = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 400px;
  margin: 0 auto;
`

export const StyledTitleBox = styled.div`
  text-align: left;
  font-weight: 700;
  letter-spacing: 0px;
  color: #9f9c9c;
  font-size: 1.375em;
`

export const StyledBottomBox = styled.div`
  font-size: 1.375em;
  color: #9f9c9c;
  line-height: 2.3;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 15px;
  white-space: nowrap;
  & div:first-child {
    width: 60%;
    float: left;
  }
  & div:last-child {
    width: 40%;
    float: right;
  }
`

export const StyledItemBox = styled.div`
  max-width: 350px;
  border-bottom: 1px solid #9f9c9c;
  height: 50px;
  color: #9f9c9c;
  padding-top: 10px;
  letter-spacing: 0px;
  white-space: nowrap;
  & div:first-child {
    width: 70%;
    float: left;
  }
  & div:last-child {
    width: 30%;
    float: right;
  }
  & div {
    font-family: 'Roboto', sans-serif;
  }
`

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 260px;
  margin-top: 0.5rem;
  padding: 2rem;
  margin-bottom: 60px;

  border-radius: 7px;
  border: 2px dashed #a3b1cc;
  background: #f2f4f7;
`

export const StyledLabelInputImage = styled.label`
  color: ${theme('colors.solar_21.gray')};
  font-weight: 200;
  font-size: 22px;
`

export const StyledImage = styled.img``

export const StyledTooltipText = styled.span`
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: -50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8em;
`

export const StyledTooltip = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${StyledTooltipText} {
    visibility: visible;
    opacity: 1;
  }
`

export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const StyledSuccessCheckout = styled.div`
  filter: ${props => (props.disabled ? 'saturate(1)' : 'saturate(0)')};
`

export const StyledtBlockSendDocuments = styled.div`
  width: 100%;
  height: 270px;
  position: absolute;
  z-index: 9;
`
export const StyledCheckoutDiv = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 25px;
`

export const StyledCheckoutLabel = styled.div`
  margin-top: 5px;
`

export const StyledDiv = styled.div`
  margin-bottom: 25px;
`

export const StyledContractPreview = styled.div`
  margin-top: -30px;
  margin-left: 70px;
  width: 80%;

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 10px;
    margin-left: 30px;
    width: 80%;
  }
`

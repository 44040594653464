import styled from 'styled-components'

import theme from '../../assets/styles/theme'

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
`
export const StyledArchiveName = styled.div`
  background: #f2f4f7;
  /* height: 70px; */
  width: auto;

  display: flex;
  align-items: center;
  padding: 15px;
  padding-left: 30px;
`

export const StyledArchiveStatus = styled.div`
  background-color: ${props => props.background};
  opacity: 1;
  display: flex;
  align-items: center;
  height: 80px;
  width: auto;
  padding-left: 20px;
`
export const StyledTypeStatus = styled.div`
  border-right: 3px solid ${theme('colors.solar_21.regular')};
  height: 35px;
`
export const DivNameArchive = styled.div`
  padding: 27px;
  width: auto;
  height: auto;
`

export const DivStatus = styled.div`
  padding: 15px;
`
export const SpanStatus = styled.div`
  color: #ffc107;
  font-weight: bold;
`

export const ArchiveIcon = styled.img`
  width: 45px;
`

import styled from 'styled-components'

export const StyledSummary = styled.div`
  background: #f2f4f7;
  width: 100%;
  min-height: 100px;
  height: auto;
  padding: 60px 60px 30px 60px;
  margin-top: 30px;
  margin-bottom: 50px;
`

export const StyledList = styled.div`
  margin-bottom: 30px;
  margin-right: 40px;
  color: #8f9cb1;
`

export const StyledListFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const StyledItem = styled.div``

export const StyledUl = styled.ul``
export const StyledLi = styled.li`
  line-height: 1.8;
`

import styled from 'styled-components'

import Button from '../ui/button'

export const Container = styled.div`
  @media (max-width: 1024px) {
    position: relative;
    right: 0;
    bottom: 1rem;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const ButtonBack = styled(Button)`
  background-color: #fff;
  border-color: #cfcfcf;
  color: #cfcfcf;

  &:hover {
    border-color: #c1c1c1;
    color: #c1c1c1;
  }
`

export const TooltipText = styled.div`
  cursor: pointer;
`
export const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% - 120px);
  left: -30px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: max-content;
  padding: 5px 5px;
  border-radius: 4px;

  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;

  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 65px;
    top: 20px;
    position: absolute;

    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.5s ease-in-out;
  }
`
export const TooltipCard = styled.div`
  position: relative;
  padding-left: 15px;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #000;
    background-color: #fff;
    width: max-content;
    padding: 8px 8px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));


    &:before {
      border-color: #fff #fff transparent transparent;
    }
  }
`

export const FaqWppButtonContainer = styled.div`
  display: grid;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    display: none;
  }
`

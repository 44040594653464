import React, { useRef, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { useField } from '@unform/core'
import PropTypes from 'prop-types'

import solar_21_small from '../../assets/images/down-arrow.svg'

const animatedComponents = makeAnimated()
const customStyles = {
  option: provided => ({
    ...provided,
    borderBottom: '1px solid d8d8d8',
    padding: 15,
  }),
  control: () => ({
    fontSize: '1em',
    boxSizing: 'border-box',
    background: `url(${solar_21_small}) no-repeat right #ffffff`,
    border: '1px solid #d8d8d8',
    color: '#b7b7b7',
    marginBottom: '20px',
    appearance: 'none',
    backgroundPosition: 'right 12px center',
    paddingRight: '40px',
    height: '50px',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  },
}

const SelectDefault = ({ name, label, ...rest }) => {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map(option => option.value)
        }
        if (!ref.state.value) {
          return ''
        }
        return ref.state.value.value
      },
    })
  }, [fieldName, registerField, rest.isMulti])

  return (
    <>
      {label && <label htmlFor={label}>{label}</label>}
      <Select
        components={animatedComponents}
        styles={customStyles}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
    </>
  )
}

SelectDefault.defaultProps = {
  name: '',
  label: '',
}

SelectDefault.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
}

export default SelectDefault

import produce from 'immer'

import type from '../../types'

/*
Pending should start with "true" because
we don't know which page to show the user
before the get returns.
*/
const INITIAL_STATE = {
  pending: true,
  scheduleFormData: {
    cep: '',
    address: '',
    neighborhood: '',
    complement: '',
    number: '',
    city: '',
    state: '',
  },
  schedule: {
    id: '',
    phone: '',
    cep: '',
    address: '',
    number: '',
    complement: '',
    city: '',
    state: '',
    neighborhood: '',
    schedule: null,
    status: null,
  },
}

export default function schedule(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.UPDATE_TECHNICAL_VISIT_SCHEDULE_FORM_DATA: {
        draft.scheduleFormData = { ...draft.scheduleFormData, ...action.payload.scheduleFormData }
        break
      }
      case type.TECHNICAL_VISIT_SCHEDULE_REQUEST: {
        draft.pending = true
        break
      }
      case type.TECHNICAL_VISIT_SCHEDULE_REQUEST_SUCCESS: {
        draft.pending = false
        draft.schedule = action.payload.data
        break
      }
      case type.TECHNICAL_VISIT_SCHEDULE_REQUEST_FAILURE: {
        draft.pending = false
        break
      }
      case type.TECHNICAL_VISIT_SCHEDULE_FORM_POST: {
        draft.pending = true
        break
      }
      case type.TECHNICAL_VISIT_SCHEDULE_FORM_POST_SUCCESS: {
        draft.pending = false
        break
      }
      case type.TECHNICAL_VISIT_SCHEDULE_FORM_POST_FAILURE: {
        draft.pending = false
        break
      }
      default:
    }
  })
}

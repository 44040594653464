import React from 'react'
import ReactLoading from 'react-loading'

import theme from 'assets/styles/theme'

import { Container } from './styles'

function FullScreenLoader () {
  return (
    <Container>
      <ReactLoading type="spin" width="100px" height="100px" color={ theme('colors.yellow.500') } />
    </Container>
  )
}

export { FullScreenLoader }

import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import theme from 'assets/styles/theme'
// import DoubleRadio from 'components/doubleRadio'
import Button from 'components/ui/button'
import Checkbox from 'components/ui/Checkbox'
import CurrencyInput from 'components/ui/CurrencyInput'
import {Input, Select} from 'components/ui/Fields'
import {FormikProvider, useFormik} from 'formik'
import PropTypes from 'prop-types'
import api from 'services/api'
import history from 'services/history'
import lang from 'services/language'
import {statesRequest} from 'store/modules/common/action'
import styled from 'styled-components'
import * as Yup from 'yup'

const Form = styled.form`
  width: 100%;
  @media only screen and (max-width: 520px) {
    margin-bottom: 1.2rem;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  @media screen and (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`
const CheckboxGrid = styled.div`
  display: grid;
  @media screen and (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`
const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TitleCheckBox = styled.p`
  margin-left: 5px;
  text-align: left;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  letter-spacing: 0px;
  color: #a4a2a2;

  @media only screen and (max-width: 768px) {
    font-size: 17px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 14px;
  }
`

const UseTerm = styled.a`
  text-decoration: underline;
  color: ${theme('colors.solar_21.regular')};
`
const UseTermError = styled.p`
  color: ${theme('colors.red.600')};
  font-size: 0.857em;
`

const AutoCompleteStyled = styled(Autocomplete)`
  margin-top: 6px !important;
  & .MuiInputBase-input {
    height: 8px;
  }
`

const useStyles = makeStyles(theme => ({
  popupIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          d: "path('M7 10l7 7 7-7z')",
          fill: '#B7B7B7',
        },
      },
    },
  },
}))

export const CssTextField = styled(TextField)(p => ({
  backgroundColor: 'white',
  borderWidth: '1px solid #D8D8D8',
  color: '#131313',

  '& input::placeholder': {
    fontSize: '14px',
    color: '#131313',
  },
  '& .MuiAutocomplete-input:first-child': {
    color: '#181818',
    fontWeight: '300 !important',
  },
  '& label.Mui-focused': {
    borderWidth: '1px solid #D8D8D8',
    borderColor: '#D8D8D8',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#D8D8D8',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: '#D8D8D8',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderWidth: '1px solid #D8D8D8',
      borderColor: '#D8D8D8',
    },
    '& fieldset': {
      borderRadius: `0 0 0 0`,
    },
  },
}))

export default function CreateAccountForm({onSubmit, submitting}) {
  const {formatMessage} = useIntl()
  const dispatch = useDispatch()

  const states = useSelector(state => state.common.states)
  const classes = useStyles()

  const _email_ = formatMessage({id: 'common.form.email'})
  const _coupon_ = formatMessage({id: 'common.form.coupon'})
  const _requiredEmail_ = formatMessage({id: 'common.form.email.required'})
  const _validEmail_ = formatMessage({id: 'common.form.email.valid'})
  const _validUnit_ = formatMessage({id: 'common.form.unit.valid'})
  const _name_ = formatMessage({id: 'common.form.name'})
  const _requiredName_ = formatMessage({id: 'common.form.name.required'})
  const _minName_ = formatMessage({id: 'common.form.name.min'})
  const _uf_ = formatMessage({id: 'common.form.uf'})
  const _ufFirstOption_ = formatMessage({id: 'common.form.uf.firstOption'})
  const _requiredUf_ = formatMessage({id: 'common.form.uf.required'})
  const _city_ = formatMessage({id: 'common.form.city'})
  const _cityFirstOption_ = formatMessage({id: 'common.form.city.firstOption'})
  const _requiredCity_ = formatMessage({id: 'common.form.city.required'})
  const _unit_ = formatMessage({id: 'common.form.unit'})
  const _requiredUnit_ = formatMessage({id: 'common.form.unit.required'})
  const _bill_ = formatMessage({id: 'common.form.bill'})
  const _requiredBillValue_ = formatMessage({id: 'common.form.bill.required'})

  const _send_ = formatMessage({id: 'common.button.send'})
  const _submitting_ = formatMessage({id: 'common.button.submitting'})
  const _back_ = formatMessage({id: 'common.button.back'})

  const _namePlaceholder_ = formatMessage({id: 'common.placeholder.name'})
  const _emailPlaceholder_ = formatMessage({id: 'common.placeholder.email'})
  const _billPlaceholder_ = formatMessage({id: 'common.placeholder.bill'})
  const _couponPlaceholder_ = formatMessage({id: 'common.placeholder.coupon'})

  const [hasUseTermError, setHasUseTermError] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [hasErrorBill, setHasErrorBill] = useState(false)
  const [cities, setCities] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handleValidateForm = async values => {
    const schema = Yup.object().shape({
      name: Yup.string().required(_requiredName_).min(3, _minName_),
      email: Yup.string().required(_requiredEmail_).email(_validEmail_),
      state: Yup.string().required(_requiredUf_),
      city: Yup.string().required(_requiredCity_),
      unit_type: Yup.string().required(_requiredUnit_),
      bill: Yup.number(_requiredBillValue_).min(1, _requiredBillValue_).required(_requiredBillValue_),
    })
    formikbag.setFieldValue('email', formikbag.values.email.toLowerCase())
    if (!isChecked) setHasUseTermError(true)

    if (!values?.bill) setHasErrorBill(true)

    const errors = {}

    await schema.validate(values, {abortEarly: false}).catch(error =>
      error.inner.forEach(item => {
        errors[item.path] = item.message
      })
    )

    if (values.unit_type === 'APARTAMENTO') errors.unit_type = _validUnit_

    return errors
  }

  const formikbag = useFormik({
    initialValues: {
      name: '',
      email: '',
      state: '',
      city: '',
      unit_type: '',
      terms: false,
      bill: localStorage.getItem('bill-value') || '',
      coupon: '',
      isPartnership: 'false',
    },
    onSubmit,
    validate: handleValidateForm,
    validateOnBlur: false,
    validateOnChange: false,
  })

  const toInputUppercase = e => {
    e.target.value = ('' + e.target.value).toUpperCase()
  }

  useEffect(() => {
    dispatch(statesRequest())
  }, [dispatch])

  useEffect(() => {
    if (formikbag.values.state) {
      setIsLoading(true)
      api.get(`/portal/cities/${formikbag.values.state}`).then(({data}) => {
        if (data.success) {
          setCities(data.data)
          setIsLoading(false)
        }
      })
    }
  }, [formikbag.values.state])

  const handleClickCheckbox = () => {
    setIsChecked(!isChecked)
    formikbag.setFieldValue('terms', !isChecked)
    setHasUseTermError(false)
  }

  return (
    <FormikProvider value={formikbag}>
      <Form>
        <StyledGrid>
          <Input label={_name_} name="name" placeholder={_namePlaceholder_} />
          <Input label={_email_} name="email" placeholder={_emailPlaceholder_} />
          {/*  */}

          <GridGroup>
            <Select label={_uf_} name="state">
              <option value="">{_ufFirstOption_}</option>
              {states.map(uf => (
                <option value={uf} key={uf}>
                  {uf}
                </option>
              ))}
            </Select>
            {isLoading ? (
              <Select label={_city_} name="city" disabled={true}>
                <option value="">{_cityFirstOption_}</option>
                {cities.map(city => (
                  <option value={city.city_id} key={city.city_id}>
                    {city.name}
                  </option>
                ))}
              </Select>
            ) : (
              <>
                <label>
                  Cidade
                  <AutoCompleteStyled
                    disabled={!cities.length || !formikbag.values.state}
                    options={cities}
                    classes={{
                      popupIndicator: classes.popupIndicator,
                    }}
                    getOptionLabel={option => option.name}
                    onChange={(event, value) => formikbag.setFieldValue('city', value.city_id)}
                    getOptionSelected={option => option.city_id}
                    renderInput={params => {
                      params.inputProps.autoComplete = 'new-password'
                      return (
                        <CssTextField
                          placeholder={_cityFirstOption_}
                          {...params}
                          name="city"
                          variant="outlined"
                        />
                      )
                    }}
                  />
                </label>
              </>
            )}
          </GridGroup>
          <GridGroup>
            <Select label={_unit_} name="unit_type">
              <option value="">Selecione uma unidade</option>
              <option value="Comercial">Comercial</option>
              <option value="Residencial (casa)">Residencial (casa)</option>
              <option value="APARTAMENTO">Residencial (apartamento)</option>
            </Select>
            <CurrencyInput
              onChange={e => {
                const {value} = e.target
                const withoutPrefix = parseFloat(value.replace('R$ ', '').replace(',', '.'))
                setHasErrorBill(false)
                formikbag.setFieldValue('bill', withoutPrefix)
              }}
              label={_bill_}
              name="bill"
              placeholder={_billPlaceholder_}
              hasError={hasErrorBill}
              errorMessage="O valor da conta é obrigatório"
            />
          </GridGroup>
          <Input
            label={_coupon_}
            name="coupon"
            placeholder={_couponPlaceholder_}
            onInput={toInputUppercase}
          />
          {/* <DoubleRadio
            header="Você é um colaborador da Whirlpool Corporation?"
            name="isPartnership"
            onChange={() => console.log('change partnership: ', formikbag.values.isPartnership)}
          /> */}
          <CheckboxGrid>
            <CheckboxContainer>
              <Checkbox name="terms" checked={isChecked} onClick={handleClickCheckbox} />
              <TitleCheckBox>
                Declaro que estou ciente dos{' '}
                <UseTerm href={process.env.REACT_APP_PRIVACY_POLICY} target="blank">
                  termos de uso
                </UseTerm>
              </TitleCheckBox>
            </CheckboxContainer>
            {hasUseTermError && <UseTermError>Você deve concordar com os Termos!</UseTermError>}
          </CheckboxGrid>
        </StyledGrid>
        <Button
          width="100%"
          type="submit"
          disabled={!!submitting}
          onClick={formikbag.handleSubmit}
          style={{marginTop: '1.2rem'}}
        >
          {submitting ? _submitting_ : _send_}
        </Button>
        <Button
          width="100%"
          variant="link"
          style={{marginTop: '1.2rem'}}
          radius="25px"
          onClick={() => {
            history.push(`/${lang}/sign-up`)
          }}
        >
          {_back_}
        </Button>
      </Form>
    </FormikProvider>
  )
}

CreateAccountForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
}

CreateAccountForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
}

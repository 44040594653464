import produce from 'immer'

import type from '../../types'

const INITIAL_STATE = {
  pending: false,
  cities: [],
  states: [],
  notifications: [],
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.COMMON_STATES_REQUEST: {
        draft.pending = true
        break
      }
      case type.COMMON_STATES_SUCCESS: {
        draft.pending = false
        draft.states = action.payload.data
        break
      }
      case type.COMMON_STATES_FAILURE: {
        draft.pending = false
        break
      }
      case type.COMMON_CITIES_REQUEST: {
        draft.pending = true
        break
      }
      case type.COMMON_CITIES_SUCCESS: {
        draft.pending = false
        draft.cities = action.payload.data
        break
      }
      case type.COMMON_CITIES_FAILURE: {
        draft.pending = false
        break
      }
      case type.COMMON_NOTIFICATION_REQUEST: {
        draft.pending = true
        break
      }
      case type.COMMON_NOTIFICATION_SUCCESS: {
        draft.pending = false
        draft.notifications = action.payload.data
        break
      }
      case type.COMMON_NOTIFICATION_FAILURE: {
        draft.pending = false
        draft.notifications = []
        break
      }
      case type.COMMON_NOTIFICATIONDEALS_REQUEST: {
        draft.pending = true
        break
      }
      case type.COMMON_NOTIFICATIONDEALS_SUCCESS: {
        draft.pending = false
        break
      }
      case type.COMMON_NOTIFICATIONDEALS_FAILURE: {
        draft.pending = false
        break
      }
      default:
    }
  })
}

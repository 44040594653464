import React, { useState, useEffect } from 'react'

import { Form } from '@unform/web'
import PropTypes from 'prop-types'

import Input from 'components/Form/Input'
import Loading from 'components/loading'
import Button from 'components/ui/button'

import {
  StyledForm,
  StyledInputs,
  StyledFlex,
  StyledIcoCountry,
  StyledInputIco,
  ButtonContainer,
} from './styles'

function FormProfile ({ onSubmit, dataForm, loading }) {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [country, setCountry] = useState('')

  const handleSubmit = () => {
    onSubmit({ email, phone: `${country}${phone.replace(/[^0-9]/g, '')}` })
  }

  useEffect(() => {
    setEmail(dataForm.email)
    setPhone(dataForm.phone)
    setCountry(dataForm.countryCode)
  }, [dataForm])

  return (
    <StyledForm>
      <Form onSubmit={ () => { } }>
        <StyledInputs>
          <StyledFlex size="450px">
            <Input
              name="email"
              label="Email"
              placeholder="seuemailatual@gmail.com.br"
              value={ email }
              onChange={ e => setEmail(e.target.value) }
              mask={ false }
            />
          </StyledFlex>
          <StyledFlex size="100px">
            <StyledInputIco>
              <StyledIcoCountry />

              <Input
                name="pais"
                label="País"
                onChange={ e => setCountry(e.target.value) }
                placeholder="+55"
                value={ country }
                mask={ false }
              />
            </StyledInputIco>
          </StyledFlex>
          <StyledFlex size="320px">
            <Input
              name="phone"
              label="Celular"
              placeholder="(99) 90000-0000"
              value={ phone }
              onChange={ e => setPhone(e.target.value) }
              mask="(99) 99999-9999"
            />
          </StyledFlex>
          <ButtonContainer>
            <Button size="small" type="submit" onClick={ handleSubmit }>
              { loading ? <Loading color="black" width="20px" height="20px" /> : 'Atualizar' }
            </Button>
          </ButtonContainer>
        </StyledInputs>
      </Form>
    </StyledForm>
  )
}

FormProfile.defaultProps = {
  dataForm: null,
  onSubmit: () => { },
  loading: false,
}

FormProfile.propTypes = {
  onSubmit: PropTypes.func,
  dataForm: PropTypes.object,
  loading: PropTypes.bool,
}

export default FormProfile

import React from 'react'

import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'

import BillIconYellow from '../../assets/images/bill_yellow.svg'
import BillIcon from '../../assets/images/bill.svg'
import PlanYellow from '../../assets/images/plan_signature_yellow.svg'
import PlanIcon from '../../assets/images/plan.svg'
import SimulationIconYellow from '../../assets/images/simulation_yellow.svg'
import ProjectStatusIconYellow from '../../assets/images/project_status_yellow.svg'
import ProjectStatusIconWhite from '../../assets/images/project_status_white.svg'
import EconomyIcon from '../../assets/images/simulation.svg'
import VisitYellow from '../../assets/images/visit_yellow.svg'
import VisitIcon from '../../assets/images/visit.svg'
import theme from '../../assets/styles/theme'

const SidebarNavItem = styled.li`
  width: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
`

const SidebarNavLink = styled.div`
  display: flex;
  align-items: center;
  height: 5rem;
  cursor: pointer;
  ${props =>
    props.active &&
    css`
      background: #1a1818;
    `};

  &:hover {
    background: #5a5a5a;
  }
  @media only screen and (max-width: 768px) {
    justify-content: center;
    img.ic_nav {
      margin: 0 15px;
    }
    span {
      display: none;
    }
  }
  @media (min-width: 769px) and (max-width: 1400px) {
    justify-content: center;
    img.ic_nav {
      margin: 0 auto;
    }
    span {
      display: none;
    }
  }
`

const StyledLabel = styled.span`
  color: ${theme('colors.white')};
  font-size: 20px;
  ${props =>
    props.active &&
    css`
      font-weight: 600;
    `};
`

export const StyledIcon = styled.img`
  max-width: 2rem;
  height: 2rem;
  margin: 0 0.5rem 0 1.5rem;
`

export default function Nav({onChange, selected, items, fullWidth}) {
  const handleClick = (item, key, enabled) => e => {
    if (enabled) onChange(item, key, e)
  }

  return (
    <SidebarNavItem>
      {Object.entries(items).map(([key, value]) => {
        const active = selected === key ? true : null
        const item = value
        const enabled = item.hasOwnProperty('enable') ? item.enable : true

        const icon = {
          simulation: active ? SimulationIconYellow : EconomyIcon,
          bill: active ? BillIconYellow : BillIcon,
          visit: active ? VisitYellow : VisitIcon,
          plan: active ? PlanYellow : PlanIcon,
          projectStatus: active ? ProjectStatusIconYellow : ProjectStatusIconWhite,
        }

        return (
          <SidebarNavLink
            key={key}
            onClick={handleClick(item, key, enabled)}
            active={active}
            enabled={enabled}
            fullWidth={fullWidth ? 1 : 0}
            style={{cursor: `${item.style}`}}
          >
            <StyledIcon src={icon[item.icon]} alt={item.label} active={active} className="ic_nav" />
            <StyledLabel active={active}>{item.label}</StyledLabel>
            {item.status}
          </SidebarNavLink>
        )
      })}
    </SidebarNavItem>
  )
}

Nav.defaultProps = {
  onChange: () => {},
  selected: null,
  fullWidth: false,
}

Nav.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.string,
  items: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  fullWidth: PropTypes.bool,
}

import styled from 'styled-components'
import {theme} from 'assets/styles/theme'

type ButtonProps = {
  color?: string
}

const getColor = (color: string | undefined) => (color ? color : `${theme.colors.solar_21.regular}`)

export const Button = styled.button<ButtonProps>`
  display: flex;
  height: 4rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border: none;
  background: ${props => getColor(props.color)};
  border-radius: ${theme.spacing[2]};

  &:disabled {
    cursor: default;
    background: ${theme.colors.solar_21.gray_light};
  }
`

export const Text = styled.h5`
  font-family: Roboto;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.xl};
  line-height: ${theme.lineHeight.tight};
`

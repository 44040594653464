import styled from 'styled-components'

import Button from '../ui/button'

export const Container = styled.div`
  width: 25%;
  margin-left: 15px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
  }
  margin-bottom: 100px;
`
export const DivTitle = styled.div`
  text-align: center;
  margin-bottom: 5px;
`
export const DivImage = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cfcfcf;
  opacity: 1;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
`
export const DivButton = styled.div`
  margin-top: 10px;
`

export const Image = styled.img`
  width: 70%;
  position: relative;
  margin-bottom: 20px;
`

export const SignedContainer = styled.img`
  width: 30%;
  position: absolute;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
`

export const Title = styled.p`
  font: SemiBold 24px/22px Raleway;
  letter-spacing: 0px;
  color: #a4a2a2;
  opacity: 1;
  font-size: 27px;
`

export const ButtonSignature = styled(Button)`
  width: 100%;
  margin: 0;
  font-size: 15px;
`

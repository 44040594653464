import React from 'react'

import theme from 'assets/styles/theme'
import Button from 'components/ui/button'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import amex from './logos/amex.svg'
import diners from './logos/diners.svg'
import elo from './logos/elo.svg'
import master from './logos/master.svg'
import visa from './logos/visa.svg'

const BrandLogo = styled.img`
  width: 75px;
`

const Details = styled.div`
  color: ${theme('colors.solar_21.gray')};
  font-size: 18px;
  font-weight: 400;
  & span {
    display: block;
  }
`

const InfoContainer = styled.div`
  margin-top: 20px;
  background-color: #f2f4f7;
  padding: 25px 60px 25px 60px;
  display: flex;
  align-items: center;
  & div {
    margin-right: 25px;
  }
`

const ShowCardChange = ({cardData, onClickEdit, submitLabel}) => {
  const getBrandLogo = () => {
    const brandLowerCase = cardData.brand.toLowerCase()
    const options = {
      visa,
      mastercard: master,
      master,
      amex,
      diners,
      elo,
    }
    return options[brandLowerCase]
  }

  const parseDueDate = date => {
    if (parseInt(Number(date.split('/')[0]), 10) < 10) {
      return `0${date}`
    }
    return date
  }

  return (
    <InfoContainer>
      <div className="brand">
        <BrandLogo src={getBrandLogo()} alt="Bandeira do Cartão" />
      </div>
      <Details>
        <span>
          Cartão {cardData.brand} que termina com {cardData.number.replace('XXXX-XXXX-XXXX-', '')}
        </span>
        <span>Nome: {cardData.holder}</span>
        <span>Vencimento: {parseDueDate(cardData.due)}</span>
      </Details>
      <div className="action">
        <Button size="small" onClick={onClickEdit} type="button">
          {submitLabel}
        </Button>
      </div>
    </InfoContainer>
  )
}

ShowCardChange.defaultProps = {
  submitLabel: 'Atualizar',
}

ShowCardChange.propTypes = {
  cardData: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
}

export default ShowCardChange

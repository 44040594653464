import produce from 'immer'

import type from '../../types'

const INITIAL_STATE = {
  pending: false,
  value: null,
  state: {
    get: {
      plan: false,
    },
    post: {
      document: false,
    },
  },
  simulationValues: {},
  selectedPlan: 'med',
  selectedPlanModal: '',
  plan: {},
  checkout: {},
  file: null,
  docValues: {
    status: '',
  },
  holder: false,
  consumer: {},
  isUpdateConsumerSuccess: false,
  successDocument: false,
  showConsumerModal: false,
  isConsumerLoading: false,
}

export default function simulation(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case type.PLAN_DOC_SEND_REQUEST: {
        draft.state.post.document = true
        draft.file = action.payload.file
        break
      }
      case type.PLAN_DOC_SEND_SUCCESS: {
        draft.successDocument = true
        draft.state.post.document = false
        draft.billValues = action.payload.data
        break
      }
      case type.PLAN_DOC_SEND_FAILURE: {
        draft.successDocument = false
        draft.state.post.document = false
        break
      }
      case type.PLAN_DOC_RECEIVE_REQUEST: {
        draft.pending = true
        draft.file = action.payload.file
        break
      }
      case type.PLAN_DOC_RECEIVE_SUCCESS: {
        draft.pending = false
        draft.billValues = action.payload.data
        break
      }
      case type.PLAN_DOC_RECEIVE_FAILURE: {
        draft.pending = false
        break
      }
      case type.SET_SELECTED_PLAN_MODAL_REQUEST: {
        draft.pending = true
        draft.selectedPlanModal = action.payload.idPlan
        break
      }

      case type.PLAN_HOLDER_REQUEST: {
        draft.pending = true
        break
      }
      case type.PLAN_HOLDER_SUCCESS: {
        draft.pending = false
        draft.holder = action.payload.data.holder
        break
      }

      case type.CONSUMER_INFORMATIONS_MODAL_REQUEST: {
        draft.pending = true
        draft.isConsumerLoading = true
        draft.consumer = action.payload.consumer
        break
      }
      case type.CONSUMER_INFORMATIONS_MODAL_SUCCESS: {
        draft.pending = false
        draft.isConsumerLoading = false
        draft.isUpdateConsumerSuccess = true
        break
      }
      case type.CONSUMER_INFORMATIONS_MODAL_FAILURE: {
        draft.isConsumerLoading = false
        draft.pending = false
        draft.isUpdateConsumerSuccess = false
        break
      }
      case type.GET_USER_PLAN: {
        draft.state.get.plan = true
        break
      }

      case type.GET_USER_PLAN_SUCCESS: {
        draft.state.get.plan = false
        if (!action.payload?.data) draft.selectedPlanModal = null
        break
      }

      case type.GET_USER_PLAN_FAILURE: {
        draft.state.get.plan = false
        draft.selectedPlanModal = null
        break
      }

      case type.CONSUMER_SET_SHOW_CONSUMER_MODAL: {
        draft.showConsumerModal = action.payload.showConsumerModal
        break
      }
      default:
    }
  })
}

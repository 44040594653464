import styled from 'styled-components'

export const BigNumberWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const UnderlinedText = styled.div`
  border-bottom: 3px solid #ffd500;
  width: 7rem;
  margin-bottom: 0.5rem;
  overflow: visible;
  white-space: nowrap;
  display: flex;
`

export const SubTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 130%;

  @media only screen and (max-width: 1060px) {
    font-size: 1.5rem;
  }
`

export const Sheet = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 2.5rem;
  margin-right: 2rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 3rem 0 2rem !important;
    margin-right: 0;
  }
`

export const ContentColumns = styled.div`
  width: 100%;
  display: grid;
  gap: 5rem 2rem;
  grid-template-columns: 1fr 30%;

  @media only screen and (max-width: 768px) {
    grid-template-columns: auto;
    gap: 0;
  }
`

export const IconHeader = styled.img`
  margin-left: 8px;
  margin-right: 8px;
  height: 28px;
  width: auto;
`

export default function priceFormatter(value, negative) {
  const priceSplitter = value => value && value.toFixed(2).replace('-', '').replace('.', ',')
  const replaceNegativeValue = value => value && value.toFixed().replace('-', '')

  if (negative) {
    return replaceNegativeValue(value)
  }

  return priceSplitter(value)
}

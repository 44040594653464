import React, { useEffect, useRef } from 'react'
import InputMask from 'react-input-mask'

import { useField } from '@unform/core'
import PropTypes from 'prop-types'

export default function Input({ name, label, ...rest }) {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value)
      },
      clearValue(ref) {
        ref.setInputValue('')
      },
    })
  }, [fieldName, registerField])

  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <InputMask ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error && <span className="error">{error}</span>}
    </>
  )
}

Input.defaultProps = {
  name: '',
  label: '',
  rest: '',
}

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  rest: PropTypes.string,
}

import {useWindowSize} from 'hooks/useWindowSize'
import React, {FC, ReactNode, useCallback, useMemo} from 'react'
import * as S from './styles'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import {Carousel} from 'react-responsive-carousel'

type CardType = {
  icon: ReactNode
  header: string
  text: string
}

type InfoCardsProps = {
  header: string
  cards: CardType[]
  CarouselThreshold?: number
  showCarouselStatus?: boolean
}

const InfoCards: FC<InfoCardsProps> = ({header, cards, CarouselThreshold = 1100, showCarouselStatus = false}) => {
  const isSingleCard = useMemo(() => cards.length === 1, [cards])

  const {width} = useWindowSize()

  const renderSingleCard = useCallback(() => {
    return (
      <S.SingleCard>
        <S.IconWrapper>{cards[0].icon}</S.IconWrapper>
        <S.InfoSeparator>
          <S.SingleCardHeader>{cards[0].header}</S.SingleCardHeader>
          <S.SingleCardText>{cards[0].text}</S.SingleCardText>
        </S.InfoSeparator>
      </S.SingleCard>
    )
  }, [cards])

  const renderCards = useCallback(() => {
    let cardsToRender: ReactNode = <></>

    if (width && width > CarouselThreshold)
      cardsToRender = (
        <S.CardsContainer>
          {cards.map((card: CardType, index: number) => (
            <S.PaperCard key={`card-${index}${card.header}`}>
              <S.IconWrapper>{card.icon}</S.IconWrapper>
              <S.InfoSeparator>
                <S.CardHeader>{card.header}</S.CardHeader>
                <S.CardText>{card.text}</S.CardText>
              </S.InfoSeparator>
            </S.PaperCard>
          ))}
        </S.CardsContainer>
      )
    else
      cardsToRender = (
        <S.CarouselContainer>
          <Carousel showStatus={showCarouselStatus}>
            {cards.map((card: CardType, index: number) => (
              <S.PaperCard key={`card-${index}${card.header}`}>
                <S.IconWrapper>{card.icon}</S.IconWrapper>
                <S.InfoSeparator>
                  <S.CardHeader>{card.header}</S.CardHeader>
                  <S.CardText>{card.text}</S.CardText>
                </S.InfoSeparator>
              </S.PaperCard>
            ))}
          </Carousel>
        </S.CarouselContainer>
      )

    return cardsToRender
  }, [cards, width, CarouselThreshold, showCarouselStatus])

  return isSingleCard ? (
    <S.SingleCardWrapper>
      <S.CardsContainer>{renderSingleCard()}</S.CardsContainer>
    </S.SingleCardWrapper>
  ) : (
    <S.CardWrapper>
      <S.HeaderText>{header}</S.HeaderText>
      {renderCards()}
    </S.CardWrapper>
  )
}

export default InfoCards

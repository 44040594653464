import PhoneIcon from 'assets/images/sales/celular.svg'
import DiscountIcon from 'assets/images/sales/discount.svg'
import FonteIcon from 'assets/images/sales/fonte-de-energia.svg'
import ParafusoIcon from 'assets/images/sales/parafuso.svg'
import SefatyIcon from 'assets/images/sales/safety.svg'
import PanelIcon from 'assets/images/sales/solar-panel.svg'
import TruckIcon from 'assets/images/sales/truck.svg'

interface BigRowProps {
  icon: string
  iconAlt: string
  iconTitle: string
  marca: string
  garantia: string
  potencia?: string
  multiplier: number | string
}

interface SmallRowProps {
  icon: string
  iconAlt: string
  description: string
  // eslint-disable-next-line react/require-default-props
  months?: number | string
  monthDescription: string
}

const getBrand = (value: string | undefined) => {
  if (!value) return 'Sem marca'
  return value
}

const getWarranty = (value: number | string | undefined) => {
  if (!value) return 'Sem garantia'

  let newValue = value
  // eslint-disable-next-line radix
  if (typeof value === 'string') newValue = Number.parseInt(value)
  return (newValue as number) > 1 ? `${newValue} anos` : `${newValue} ano`
}

const getPower = (value: number | undefined) => (value ? `${value}kWp` : '0kWp')

const getQuantity = (quantity: number | undefined) => {
  if (!quantity) return 0
  return quantity >= 10 ? quantity : `0${quantity}`
}

export const BigRowData = (simulationValues: any) =>
  [
    {
      icon: FonteIcon,
      iconAlt: 'icone fonte de energia',
      iconTitle: 'Inversor',
      marca: getBrand(simulationValues?.inverter_brand),
      garantia: getWarranty(simulationValues?.inverter_warranty),
      potencia: getPower(simulationValues?.inverter_power),
      multiplier: getQuantity(simulationValues?.inverter_quantity),
    },
    {
      icon: PanelIcon,
      iconAlt: 'icone fonte de energia',
      iconTitle: 'Módulos',
      marca: getBrand(simulationValues?.module_brand),
      garantia: getWarranty(simulationValues?.module_warranty),
      potencia: getPower(simulationValues?.module_power),
      multiplier: getQuantity(simulationValues?.module_quantity),
    },
    {
      icon: ParafusoIcon,
      iconAlt: 'ícone parafusos',
      iconTitle: 'Estrutura',
      marca: getBrand(simulationValues?.structure_type),
      garantia: getWarranty(simulationValues?.structure_warranty),
      multiplier: getQuantity(1),
    },
  ] as BigRowProps[]

export const SmallRowData = () =>
  [
    {
      icon: PhoneIcon,
      iconAlt: 'ícone telefone',
      description: 'Monitoramento pelo Super APP Solar21',
      monthDescription: 'grátis',
    },
    {
      icon: SefatyIcon,
      iconAlt: 'ícone segurança',
      description: 'Garantia de 1 ano do serviço de instalação',
      monthDescription: 'grátis',
    },
    {
      icon: DiscountIcon,
      iconAlt: 'ícone desconto',
      description: 'Desconto em parceiros de pet, saúde e outros',
      monthDescription: 'grátis',
    },
    {
      icon: TruckIcon,
      iconAlt: 'ícone frete',
      description: 'Frete',
      monthDescription: 'grátis',
    },
  ] as SmallRowProps[]

import React from 'react'
import * as Styles from './styles'
import {Table, TableBody, TableCell, TableContainer, TableRow, Paper, List, ListItem} from '@material-ui/core'
import Button from 'components/ui/button'

import NotHavPlan from 'assets/images/close.svg'
import HavPlan from 'assets/images/have_in_plan.svg'

import {setSelectedPlan as setSelectedPlanAction} from 'store/modules/plan/action'

import {useDispatch} from 'react-redux'

interface ITablePlansMobile {
  content: IContent
  totalPotential?: number
  onSelect?: (slug: string) => void
}

interface IPlan {
  slug?: string
  annual_savings: number
  annual_production: number
  deadline: number
  deadline_type: string
  economy_per_month: number
  free: string
  has_assistance: boolean
  has_monitoring_by_app: boolean
  is_fit: boolean
  joining_fee: number
  name: string
  new_bill: number
  old_bill: number
  plan_id: string
  price: number
  reduction_percent: number
  freeMonth: number
  partnership_name: string, 
  partnership_validation: string
}

interface IContent {
  planLow: IPlan
  planMax: IPlan
  planMed: IPlan
}

const TablePlansMobile: React.FC<ITablePlansMobile> = ({content, totalPotential, onSelect}) => {
  const dispatch = useDispatch()
  const [toggleState, setToggleState] = React.useState<string>('')
  const [plans, setPlans] = React.useState<IPlan[] | null>(null)
  const [selectedPlan, setSelectedPlan] = React.useState<IPlan | null>()

  const handleChangeTab = (planSlug: string, planId: string) => {
    // eslint-disable-next-line
    console.log('🚀 ~ file: index.tsx ~ line 16 ~ handleChangeTab ~ planId', planId)
    setToggleState(planId)
    const selected = plans?.find(p => p.plan_id === planId)
    if (selected) setSelectedPlan(selected)
  }

  React.useEffect(() => {
    console.log('🚀 ~ file: index.tsx ~ line 53 ~ totalPotential', totalPotential)
  }, [totalPotential])

  React.useEffect(() => {
    // eslint-disable-next-line
    if (content) mountPlans()
    // eslint-disable-next-line
  }, [content])

  const mountPlans = () => {
    let newPlans = []
    newPlans.push({
      slug: 'Low',
      ...content.planLow,
    })
    newPlans.push({
      slug: 'Med',
      ...content.planMed,
    })
    newPlans.push({
      slug: 'Max',
      ...content.planMax,
    })

    setPlans(newPlans)
    if (!selectedPlan?.plan_id) {
      setSelectedPlan(newPlans[0])
      setToggleState(newPlans[0].plan_id)
    }

    console.log('🚀 ~ file: index.tsx ~ line 59 ~ mountPlans ~ newPlans', newPlans)
  }

  const formatPrice = (price: number): string => {
    return price.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  interface IMountTableCell {
    name: string
    value: string | number | React.ReactNode
    secondValue?: string | number | React.ReactNode
    thirdValue?: string | number | React.ReactNode
    doubleRow?: boolean
  }

  const MountTableCell = ({name, value, doubleRow, secondValue, thirdValue}: IMountTableCell) =>
    doubleRow ? (
      <TableRow>
        <TableCell style={{background: '#fff'}} component="th" scope="row">
          {name}
        </TableCell>
        <TableCell
          style={{background: '#f2f3f7', justifyContent: 'center', textAlign: 'center'}}
          align="center"
        >
          <List>
            <ListItem
              style={{
                display: 'flow-root',
                justifyContent: 'center',
                textAlign: 'center',
                paddingTop: '0px',
                paddingBottom: '0px',
                fontFamily: 'Roboto',
              }}
            >
              {value}
            </ListItem>
            <ListItem
              style={{
                display: 'flow-root',
                justifyContent: 'center',
                textAlign: 'center',
                paddingTop: '5px',
                paddingBottom: '0px',
                fontFamily: 'Roboto',
              }}
            >
              {secondValue}
            </ListItem>
            <ListItem
              style={{
                display: 'flow-root',
                justifyContent: 'center',
                textAlign: 'center',
                paddingTop: '0px',
                paddingBottom: '0px',
                fontFamily: 'Roboto',
              }}
            >
              {thirdValue}
            </ListItem>
          </List>
        </TableCell>
      </TableRow>
    ) : (
      <TableRow>
        <TableCell style={{background: '#fff'}} component="th" scope="row">
          {name}
        </TableCell>
        <TableCell style={{background: '#f2f3f7', fontFamily: 'Roboto'}} align="center">
          {value}
        </TableCell>
      </TableRow>
    )

  // const getFreeMonths = (planSlug: string): string => {
  //   const options: any = {
  //     Low: '1 mês',
  //     Med: '2 meses',
  //     Max: '3 meses',
  //   }

  //   return options[planSlug as string]
  // }

  const onFinish = () => {
    const act = selectedPlan?.slug?.toLowerCase() || 'low'
    dispatch(setSelectedPlanAction(act))
    if (onSelect) onSelect(act)
  }

  return (
    <Styles.Container>
      <Styles.BlocTabs>
        {plans &&
          plans?.map(plan => (
            <Styles.Tabs
              key={plan.plan_id}
              isActive={toggleState === plan.plan_id}
              onClick={() => handleChangeTab(plan.slug || 'Low', plan.plan_id)}
            >
              <Styles.TabTitle>
                <img src="https://www.solar21.com.br/static/images/logo-header.svg" alt="Solar21" />
                Plano {plan.name}
              </Styles.TabTitle>
            </Styles.Tabs>
          ))}
      </Styles.BlocTabs>

      {!!selectedPlan?.name && (
        <Styles.ContentTabs>
          <Styles.Content key={selectedPlan?.plan_id} active={true}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <MountTableCell
                    name="Valor do plano"
                    value={selectedPlan.is_fit ? `${formatPrice(selectedPlan.price)}/mês` : 'Não apto'}
                  />
                  <MountTableCell name="Prazo do plano" value={selectedPlan.deadline + ' Anos'} />
                  <MountTableCell
                    name="Economia média mensal"
                    value={`${selectedPlan.reduction_percent.toFixed()} %`}
                    secondValue={formatPrice(selectedPlan.economy_per_month)}
                    doubleRow={true}
                  />
                  {content.planMax.partnership_validation ? (
                    <MountTableCell
                      name="1ª e 2ª mensalidades grátis"
                      value={
                        <img
                          src={selectedPlan.slug === 'Max' ? HavPlan : NotHavPlan}
                          alt={selectedPlan.slug === 'Max' ? 'Sim' : 'Não'}
                        />
                      }
                    />
                  ) : null}
                  {/* <MountTableCell
                    name="Mensalidade grátis"
                    value={selectedPlan.slug === 'Max' ? '1 mês' : <img src={NotHavPlan} alt="Não" />}
                  /> */}
                  <MountTableCell name="Instalação e Taxa de adesão" value="Grátis" />
                  <MountTableCell
                    name="Tamanho do sistema solar"
                    value={`${totalPotential} kWp`}
                    secondValue={selectedPlan.annual_production.toFixed()}
                    thirdValue={'kWh/ano'}
                    doubleRow={true}
                  />
                  <MountTableCell
                    name="Sistema solar será seu"
                    value={selectedPlan.slug === 'Max' ? 'Após 20º ano' : <img src={NotHavPlan} alt="Não" />}
                  />
                  <MountTableCell name="Monitoramento via APP" value={<img src={HavPlan} alt="Sim" />} />
                  <MountTableCell
                    name="Manutenção e assistência 24h"
                    value={<img src={HavPlan} alt="Sim" />}
                  />
                  {content.planMax.freeMonth ? (
                    <MountTableCell
                      name="1ª mensalidade grátis"
                      value={
                        <img
                          src={selectedPlan.freeMonth ? HavPlan : NotHavPlan}
                          alt={selectedPlan.freeMonth ? 'Sim' : 'Não'}
                        />
                      }
                    />
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Styles.Content>
        </Styles.ContentTabs>
      )}

      {!!selectedPlan?.name && (
        <div style={{marginTop: 20, width: '100%', display: 'flex', justifyContent: 'center'}}>
          <Button
            color="default"
            width="auto"
            size="medium"
            variant="filled"
            as="button"
            radius="10px"
            startIcon={null}
            endIcon={null}
            selected={false}
            onClick={onFinish}
            disabled={!selectedPlan.is_fit}
          >
            Selecionar Plano {selectedPlan?.name}
          </Button>
        </div>
      )}
    </Styles.Container>
  )
}

export default TablePlansMobile

import React from 'react'
import {FormattedMessage} from 'react-intl'
import {toast} from 'react-toastify'

import {all, call, put, takeLatest} from 'redux-saga/effects'
import {addDoc, collection, getFirestore} from 'firebase/firestore'

import api from '../../../services/api'
import history from '../../../services/history'
import lang from '../../../services/language'
import app from '../../../firebase'
import type from '../../types'
import {userRequestSuccess} from '../user/action'
import {
  accessFailure,
  accessSuccess,
  logoutClientFailure,
  logoutClientSuccess,
  resetAuthVariables,
  signInFailure,
  signInSuccess,
  signUpAccessFailure,
  signUpAccessSuccess,
  signUpFailure,
  signUpFailurePj,
  signUpPhoneFailure,
  signUpPhoneSuccess,
  signUpSuccess,
  signUpSuccessPj,
} from './action'

const errors = {
  1005: 'Esse email já está associado a outra conta.',
  1017: 'CPF inválido.',
  1018: 'CNPJ inválido.',

  2002: 'Senha muito fraca.',

  3000: 'Código inválido',

  5000: 'Telefone não encontrado',
  5001: 'Esse cliente já está em uso.',
  5002: 'Cidade não encontrada.',
  5003: 'Número não confirmado.',
  5004: 'Número de telefone já está associado a outra conta.',
}

export function* signIn({payload}) {
  const {phone, secret, recaptcha} = payload.data
  try {
    const db = getFirestore(app)
    const collectionRef = collection(db, 'Queue_sms')
    addDoc(collectionRef, {
      phone,
      coupon: null,
      secret,
      recaptcha,
      type: 'SIGN_IN',
      resend: false,
    })
    history.push(`/${lang}/access-account`)
    yield put(signInSuccess())
  } catch (error) {
    yield put(signInFailure())
  }
}

export function* logoutClient() {
  try {
    yield call(api.post, '/portal/logout')
    yield put(logoutClientSuccess())
    window.localStorage.clear()
  } catch (error) {
    toast.error(<FormattedMessage id="code.LOGOUT_FAILURE">{msg => msg}</FormattedMessage>)
    yield put(logoutClientFailure())
  }
}

export function* access({payload}) {
  const {phone, code} = payload

  try {
    const response = yield call(api.post, '/portal/login', {phone, code})
    const {access_token} = response.data

    api.defaults.headers.Authorization = `Bearer ${access_token}`
    const {data} = yield call(api.get, '/portal/customers')

    yield put(accessSuccess(access_token))
    yield put(userRequestSuccess(data))
  } catch (error) {
    api.defaults.headers.Authorization = ''
    yield put(accessFailure())
  }
}

export function* signUp({payload}) {
  const {name, email, state, city, unit_type, bill, phone, coupon, hasPartnership} = payload
  try {
    const response = yield call(api.post, '/portal/customers', {
      name,
      email,
      state,
      city,
      unit_type,
      bill,
      phone,
      coupon,
      hasPartnership,
    })

    toast.success(<FormattedMessage id="signup.with.success">{msg => msg}</FormattedMessage>)
    yield put(signUpSuccess())
    localStorage.setItem('showThankYou', '1')
    const {access_token} = response.data

    api.defaults.headers.Authorization = `Bearer ${access_token}`
    yield put(accessSuccess(access_token))

    const {data} = yield call(api.get, '/portal/customers')
    yield put(userRequestSuccess(data))
    history.push('/pt/bill')
  } catch (error) {
    if (error.response?.data?.message && error.response?.data?.message.includes('INVALID_COUPON')) {
      toast.error('Cupom inválido')
    }
    const errorMessage = errors[error.response?.data?.code]
    if (errorMessage) toast.error(errorMessage)
    else toast.error(<FormattedMessage id="signup.invalid">{msg => msg}</FormattedMessage>)
    yield put(signUpFailure())
  }
}

export function* signUpPhoneRequest({payload}) {
  const {phone, coupon, secret, recaptcha} = payload
  
  try {
    const db = getFirestore(app)
    const collectionRef = collection(db, 'Queue_sms')
    addDoc(collectionRef, {
      phone,
      coupon,
      secret,
      recaptcha,
      type: 'SIGN_UP',
      resend: false,
    })

    history.push(`/${lang}/sign-up/access-code`)  

    yield put(signUpPhoneSuccess())
  } catch (error) {
    const errorMessage = errors[error.response?.data?.code]
    if (errorMessage) toast.error(errorMessage)
    else toast.error('Algo deu errado. Tente novamente!')
    yield put(signUpPhoneFailure())
  }
}

export function* signUpAccessCode({payload}) {
  const {phone, code} = payload

  try {
    const response = yield call(api.post, '/portal/confirm-number', {phone, code})

    const {access_token} = response.data

    if (access_token != null){
      api.defaults.headers.Authorization = `Bearer ${access_token}`
      const {data} = yield call(api.get, '/portal/customers')

      yield put(accessSuccess(access_token))
      yield put(userRequestSuccess(data))
    }else{
      history.push(`/${lang}/create-account`)
    } 
    
    yield put(signUpAccessSuccess())
  } catch (error) {
    yield put(signUpAccessFailure())
  }
}

export function* setToken({payload}) {
  if (!payload) return

  const {token} = payload.auth
  yield put(resetAuthVariables())

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`
  }
}

export function* singUpPj({payload}) {
  const client_payload = {
    name: payload.razaoSocial,
    email: payload.email,
    state: payload.state,
    city: payload.city,
    unit_type: payload.unit_type,
    phone: payload.phone,
    bill: payload.bill,
    document_type: payload.document_type,
    document_value: payload.cnpj,
  }
  try {
    const response = yield call(api.post, '/portal/customers', client_payload)

    const {access_token} = response.data
    yield put(signUpSuccessPj())
    api.defaults.headers.Authorization = `Bearer ${access_token}`
    toast.success(<FormattedMessage id="signup.with.success">{msg => msg}</FormattedMessage>)
    yield put(accessSuccess(access_token))

    const {data} = yield call(api.get, '/portal/customers')
    yield put(userRequestSuccess(data))
  } catch (error) {
    const errorMessage = errors[error.response?.data?.code]
    if (errorMessage) toast.error(errorMessage)
    else toast.error(<FormattedMessage id="signup.invalid">{msg => msg}</FormattedMessage>)
    yield put(signUpFailurePj())
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(type.AUTH_SIGN_IN_REQUEST, signIn),
  takeLatest(type.AUTH_ACCESS_CODE_REQUEST, access),
  takeLatest(type.AUTH_SIGN_UP_REQUEST, signUp),
  takeLatest(type.AUTH_SIGN_UP_PHONE_REQUEST, signUpPhoneRequest),
  takeLatest(type.AUTH_SIGN_UP_ACCESS_CODE_REQUEST, signUpAccessCode),
  takeLatest(type.AUTH_LOGOUT_CLIENT, logoutClient),
  takeLatest(type.AUTH_SIGN_UP_REQUEST_PJ, singUpPj),
])

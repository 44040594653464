import styled from 'styled-components'

export const BigNumberWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const UnderlinedText = styled.div`
  border-bottom: 3px solid #ffd500;
  width: 7rem;
  margin-bottom: 0.5rem;
  overflow: visible;
  white-space: nowrap;
`

export const SubTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 130%;

  @media only screen and (max-width: 1060px) {
    font-size: 1.5rem;
  }
`

export const NumberSheet = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-right: 2rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 1rem;
  }
`

export const Text = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 130%;
`

export const SubText = styled.div`
  height: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 130%;
  padding-bottom: 0.3rem;
`

import React, {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import history from 'services/history'

import {Formik} from 'formik'

import BillIcon from 'assets/images/bill_yellow.svg'
import PageHeader from 'components/pageHeader'
import {createPlanSimulatedRequest} from 'store/modules/plan/action'

import {getBillInfoRequest} from 'store/modules/bill/action'

import {FullScreenLoader} from 'components/fullScreenLoader'
import * as S from './styles'

import DoubleRadioInput from 'components/doubleRadioInput'
import FileInput from 'components/fileInput'
import InfoCards from 'components/infoCards'
import Button from 'components/button'
import {isEmpty, last} from 'lodash'
import {profileRequest} from 'store/modules/profile/action'
import {cards, UploadedWildCard, UploadStatusEnum} from './data/cards'
import Modal from './partials/Modal'
import Overlay from './partials/Overlay'
import {partnershipRequest, userRequest} from 'store/modules/user/action'
import {toast} from 'react-toastify'
import api from 'services/api'
import {AxiosRequestConfig} from 'axios'

type DefaultStateType = {
  bill: {
    isUploading: boolean
    billInfo: any
  }
  profile: {
    profileValues: {
      steps: {
        type: string
        status: string
      }[]
      bill_value: string
      type: any
      bill_id: string
      bill_name: string
      bill: any
    }
  }
  user: {
    user: {
      id: string
    }
    partnership: {
      url: null | string
      id: string
    }
  }
}

const Bill = () => {
  const {formatMessage} = useIntl()
  const _pageTitle_ = formatMessage({id: 'bill.page.title'})
  const _pageDescription_ = formatMessage({id: 'bill.page.description'})

  const dispatch = useDispatch()
  const isUploading = useSelector((state: DefaultStateType) => state.bill.isUploading)
  const profile = useSelector((state: DefaultStateType) => state.profile.profileValues)
  const billInfo = useSelector((state: DefaultStateType) => state.bill.billInfo)
  const user = useSelector((state: DefaultStateType) => state.user.user)
  const partnership = useSelector((state: DefaultStateType) => state.user.partnership)

  const [sentCreate, setSentCreate] = useState<boolean>(false)
  const [holder, setHolder] = useState<boolean>(true)
  const [isCNPJ, setIsCNPJ] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isChoosing, setIsChoosing] = useState<boolean>(true)
  const [fileToUpload, setFileToUpload] = useState<File | null>(null)
  const [employeeCardToUpload, setEmployeeCardToUpload] = useState<File | null>(null)
  const [hasBillStatus, setHasBillStatus] = useState<boolean>(false)
  const [madeUpload, setMadeUpload] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [enableUpload, setEnableUpload] = useState<boolean>(true)
  const [isFileSent, setIsFileSent] = useState<boolean>(false)
  const [isEmployeeCardSent, setIsEmployeeCardSent] = useState<boolean>(false)
  const [technicalVisitStatus, setTechnicalVisitStatus] = useState<string | undefined>(undefined)
  const [lastBill, setLastBill] = useState<any>(null)
  const [hasPartnership, setHasPartnership] = useState<boolean>(false)
  const [clientWasRequested, setClientWasRequested] = useState<boolean>(false)
  const [isOverlay, setIsOverlay] = useState<boolean>(true)

  useEffect(() => {
    setIsChoosing(true)
    api
      .get('/portal/customers')
      .then(({data}) => {
        const {franchisee_id} = data

        setIsOverlay(!franchisee_id || franchisee_id === process.env.REACT_APP_BLOCKED_FRANCHISEE_UUID)
      })
      .catch(err => console.error('error getting user data: ', err))
      .finally(() => setIsChoosing(false))
  }, [])

  useEffect(() => {
    if (!isEmpty(billInfo)) setLastBill(last(billInfo))
  }, [billInfo])

  useEffect(() => {
    setIsLoading(true)
    const {steps, type, bill_id, bill_value} = profile

    const simulation = steps?.find(step => step.type === 'SIMULATION')
    const technicalVisit = steps?.find(step => step.type === 'TECHNICAL_VISIT')

    if (technicalVisit) setTechnicalVisitStatus(technicalVisit.status)
    if (type) setIsCNPJ(type.type === 'CNPJ')
    if (bill_id) dispatch(getBillInfoRequest(bill_id))

    setIsButtonDisabled(() => !(technicalVisit?.status === 'PENDING' || fileToUpload))

    if (!isEmpty(simulation) && simulation?.status !== 'APPROVED' && bill_value && !sentCreate) {
      setSentCreate(true)
      dispatch(
        createPlanSimulatedRequest({
          bill_value: parseInt(bill_value, 10),
          deadline_years: 20,
          nivel: 'MAX',
        })
      )
    }
    if (sentCreate) window.location.reload()
    if (simulation?.status !== 'APPROVED') dispatch(profileRequest())
    if (simulation?.status === 'APPROVED') setIsLoading(false)
  }, [fileToUpload, profile, dispatch, sentCreate])

  useEffect(() => {
    if ((profile.bill_id && profile.bill.status === 'PENDING') || isEmpty(lastBill)) setHasBillStatus(true)
    if (
      profile.bill_id &&
      lastBill?.justification_disapproved ===
        'Sua conta de luz está ilegível/incompleta. Por favor, envie um novo arquivo.'
    )
      setHasBillStatus(false)
    if (!profile.bill_id) setHasBillStatus(false)
  }, [profile, lastBill])

  useEffect(() => {
    if (isUploading) setMadeUpload(true)
  }, [isUploading])

  useEffect(() => {
    if (hasBillStatus && madeUpload) setOpenModal(true)
  }, [hasBillStatus, madeUpload])

  useEffect(() => {
    if (isCNPJ) setHolder(false)
  }, [isCNPJ])

  useEffect(() => {
    if (!user.id && !clientWasRequested) {
      setClientWasRequested(true)
      dispatch(userRequest())
    }
    if (user.id) {
      setClientWasRequested(true)
      dispatch(partnershipRequest(user.id))
    }
  }, [dispatch, user, clientWasRequested])

  useEffect(() => {
    setIsEmployeeCardSent(!!partnership.url)
    setHasPartnership(!!partnership.id)
  }, [partnership])

  const handleSend = useCallback(async () => {
    setIsLoading(true)
    if (fileToUpload === null) return

    if (!isEmployeeCardSent && employeeCardToUpload === null && hasPartnership) {
      toast.error('Envie também seu documento de identificação de funcionário!')
      return
    }

    const billForm = new FormData()
    billForm.append('file', fileToUpload)

    const headerParams = {
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Content-Type': 'multipart/form-data',
    }

    await api
      .post(`/portal/customers/bills?is_holder=${holder}`, billForm, headerParams as AxiosRequestConfig)
      .then(async () => {
        if (employeeCardToUpload === null || !hasPartnership) return

        const employeeCardForm = new FormData()
        employeeCardForm.append('file', employeeCardToUpload)
        await api.post(
          `/portal/client-partnership/${user.id}/emproyee-card`,
          employeeCardForm,
          headerParams as AxiosRequestConfig
        )
      })
      .catch(err => console.error(err))
      .finally(() => window.location.reload())
  }, [fileToUpload, holder, employeeCardToUpload, user, hasPartnership, isEmployeeCardSent])

  const handleHolder = useCallback(values => {
    setHolder(values?.owner === 'true')
  }, [])

  const onClose = useCallback(() => {
    setOpenModal(false)
    setIsButtonDisabled(true)
  }, [])

  const getCards = useCallback(
    (jumper = false) => {
      if (jumper) return UploadedWildCard(UploadStatusEnum.APP)

      if (profile.bill_id && profile.bill.status === 'PENDING' && isEmpty(lastBill))
        return UploadedWildCard(UploadStatusEnum.WAITING, '', technicalVisitStatus)

      if (profile.bill_id && profile.bill.status === 'DENIED' && isEmpty(lastBill))
        return UploadedWildCard(
          UploadStatusEnum.DISAPPROVED,
          'Sua conta de luz está ilegível/incompleta. Por favor, envie um novo arquivo.'
        )

      if (lastBill)
        return UploadedWildCard(lastBill.status, lastBill.justification_disapproved, technicalVisitStatus)

      return cards
    },
    [profile, technicalVisitStatus, lastBill]
  )

  const getBillInfoName = useCallback(() => {
    if (fileToUpload) return fileToUpload.name
    if (lastBill?.name) return lastBill.name
    return ''
  }, [lastBill, fileToUpload])

  useEffect(() => {
    setEnableUpload(() => {
      if (isCNPJ) return false
      if (profile.bill_id && profile.bill.status === 'PENDING' && isEmpty(lastBill)) return false
      if (
        lastBill?.status === 'DISAPPROVED' &&
        lastBill?.justification_disapproved ===
          'Sua conta de luz está ilegível/incompleta. Por favor, envie um novo arquivo.'
      )
        return true
      if (!lastBill?.status) return true
      return false
    })
  }, [isCNPJ, lastBill, profile])

  useEffect(() => {
    setIsFileSent(() => {
      if (lastBill?.status === 'APPROVED') return true
      if (
        lastBill?.status === 'DISAPPROVED' &&
        lastBill?.justification_disapproved !==
          'Sua conta de luz está ilegível/incompleta. Por favor, envie um novo arquivo.'
      )
        return true
      if (profile.bill_id && profile.bill.status === 'DENIED' && isEmpty(lastBill)) return false
      if (profile.bill_id && profile.bill.status === 'PENDING') return true
      return false
    })
  }, [lastBill, profile.bill_id, profile.bill])

  if (isLoading || isUploading || isChoosing) return <FullScreenLoader />

  return (
    <S.Container>
      {isOverlay ? (
        <Overlay />
      ) : (
        <>
          <PageHeader title={_pageTitle_} description={_pageDescription_} icon={BillIcon} />
          {/* <StepsHeader
        title="Conta de luz"
        subtitle={
          'Envie sua conta de luz sem gerar nenhum compromisso. Utilizaremos as informações para avaliar questões técnicas.'
        }
      />*/}
          <S.Subcontainer>
            <S.CardContainer>
              <InfoCards
                header={profile.bill_id ? '' : 'Entenda quais dados são utilizados em sua conta de luz:'}
                cards={getCards()}
                CarouselThreshold={1100}
              />
              {isFileSent ? (
                <S.AppDonwloadContainer>
                  <InfoCards header="Teste" cards={getCards(true)} />
                </S.AppDonwloadContainer>
              ) : null}
            </S.CardContainer>
            {enableUpload && (
              <Formik initialValues={{owner: holder.toString()}} onSubmit={() => {}}>
                <DoubleRadioInput header="Você é o titular da conta de luz?" onChange={handleHolder} />
              </Formik>
            )}
            <S.FileContainer>
              <FileInput
                header={hasBillStatus ? 'Conta de luz:' : 'Envie a sua conta de luz mais recente:'}
                text="Arraste ou clique aqui para fazer upload"
                mimes={['PDF', 'JPG', 'PNG']}
                onChange={setFileToUpload}
                hasFile={!!fileToUpload}
                isFileSent={isFileSent}
                fileName={getBillInfoName()}
              />
            </S.FileContainer>
            {hasPartnership && (isFileSent || fileToUpload) ? (
              <S.FileContainer>
                <S.TitleWhirlpool>Documento de identificação de funcionário</S.TitleWhirlpool>
                <FileInput
                  header={
                    isEmployeeCardSent
                      ? 'Foto enviada com sucesso:'
                      : 'Envie uma foto do seu crachá ou outro documento que comprove vínculo com a Whirlpool:'
                  }
                  text="Arraste ou clique aqui para fazer upload"
                  mimes={['PDF', 'JPG', 'PNG']}
                  onChange={setEmployeeCardToUpload}
                  hasFile={!!employeeCardToUpload}
                  isFileSent={isEmployeeCardSent}
                />
              </S.FileContainer>
            ) : null}

            <S.ButtonContainer>
              {/** TODO: remove comment bellow to enable client to schedule a technical visit */}
              {/**<Button
                text={hasBillStatus ? 'Agendar visita' : 'Enviar para avaliação'}
                disabled={isButtonDisabled || isUploading || (hasPartnership && !employeeCardToUpload)}
                onClick={() => (hasBillStatus ? history.push('/pt/technical-visit') : handleSend())}
              /> */}
              <Button
                text="Enviar para avaliação"
                disabled={isButtonDisabled || isUploading || hasBillStatus}
                onClick={() => handleSend()}
              />
            </S.ButtonContainer>
            {openModal && <Modal onClose={onClose} />}
          </S.Subcontainer>
        </>
      )}
    </S.Container>
  )
}

export default Bill

import {ClockIcon, JudgeIcon, RawCheckIcon} from 'components/icons'
import RawCloseIcon from 'components/icons/RawCloseIcon'
import SystemUpdateOutlinedIcon from '@material-ui/icons/SystemUpdateOutlined'
import React from 'react'

export enum UploadStatusEnum {
  WAITING = 'WAITING',
  DISAPPROVED = 'DISAPPROVED',
  APPROVED = 'APPROVED',
  APP = 'APP',
}

export const cards = [
  {
    icon: <RawCheckIcon />,
    header: 'O que olhamos?',
    text: 'Seu perfil de consumo, a tarifa da energia, o seu telhado e a saúde financeira do titular da conta.',
  },
  {
    icon: <JudgeIcon />,
    header: 'Respeitamos a Lei Geral de Proteção de Dados',
    text: 'Os seus dados estão seguros e não compartilhamos sem o seu consentimento.',
  },
]

export const UploadedWildCard = (
  type: UploadStatusEnum,
  reason: string = 'Sua conta não foi aprovada!',
  visitStatus: string = ''
) => {
  let text =
    'Avaliaremos as informações e em até 24h você receberá um retorno por e-mail, telefone ou WhatsApp.'
  if (type === UploadStatusEnum.APPROVED) text = 'Conta de luz aprovada! '
  if (type === UploadStatusEnum.APPROVED && visitStatus === 'PENDING')
    text = text + 'Agende agora a sua visita técnica.'
  if (type === UploadStatusEnum.DISAPPROVED) text = reason
  if (type === UploadStatusEnum.APP)
    text = 'No aplicativo é possível acompanhar de perto cada passo do processo'

  let header = 'Conta de luz enviada com sucesso!'
  if (type === UploadStatusEnum.DISAPPROVED) header = 'O arquivo foi enviado, contudo...'
  if (type === UploadStatusEnum.APP) header = 'Baixe nosso aplicativo'

  let icon = <ClockIcon />
  if (type === UploadStatusEnum.DISAPPROVED) icon = <RawCloseIcon />
  if (type === UploadStatusEnum.APPROVED) icon = <RawCheckIcon />
  if (type === UploadStatusEnum.APP) icon = <SystemUpdateOutlinedIcon style={{fontSize: 30}} />

  return [
    {
      icon,
      header,
      text,
    },
  ]
}

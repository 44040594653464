import {all, takeLatest, put, call} from 'redux-saga/effects'

import types from 'store/types'

import api from 'services/api'
import {faqSuccess, faqFailure} from './action'

export function* getFaqs() {
  try {
    const {data} = yield call(api.get, '/faq')

    yield put(faqSuccess(data))
  } catch (err) {
    yield put(faqFailure())
  }
}

export default all([takeLatest(types.FAQ_REQUEST, getFaqs)])

import React from 'react'

import AuroraScheduleConfirmed from 'assets/images/aurora/schedule-confirmed.svg'
import AuroraScheduleImpartial from 'assets/images/aurora/schedule-impartial.svg'
import AuroraScheduleUnconfirmed from 'assets/images/aurora/schedule-unconfirmed.svg'
import PropTypes from 'prop-types'
import {phone, cep} from 'utils/formatter'

import Modal from 'components/ui/modal'
import Button from 'components/ui/button'

import {
  AuroraContainer,
  AuroraImage,
  Bar,
  ScheduleContainer,
  StatusText,
  StatusSeparator,
  ScheduleFieldKey,
  ScheduleFieldValue,
  ScheduleField,
  ButtonCancel,
  ModalActions,
  ModalTextContent,
} from './styles'
import moment from 'moment'
import api from 'services/api'
import {useState} from 'hooks/useState'
import Tooltip from 'components/tooltip'
import {useDispatch} from 'react-redux'
import {requestSchedule} from 'store/modules/schedule/action'
import {toast} from 'react-toastify'

const ListScheduly = ({scheduling}) => {
  const dispatch = useDispatch()
  const {state, setState} = useState({
    loadingCancelVisit: false,
    disabledRescheduleReason: '',
    showDialogCancelConfirmation: false,
  })

  const checkCancel = () => {
    if (!scheduling.schedule) setState({disabledCancelVisit: true})
    const now = moment()
    const visit = moment(scheduling.schedule)
    const diffHours = moment.duration(visit.diff(now))
    if (diffHours.asHours() < 24) {
      setState({
        disabledRescheduleReason: 'Sua visita está muito próxima, não é possível cancelar',
      })
    }
  }

  React.useEffect(() => {
    checkCancel()
    // eslint-disable-next-line
  }, [])

  const cancelVisit = () => {
    setState({loadingCancelVisit: true})
    api
      .delete('/portal/customers/scheduling')
      .then(() => {
        dispatch(requestSchedule())
        toast.success('Agendamento cancelado')
      })
      .finally(() => setState({loadingCancelVisit: false}))
  }

  const auroraSelector = {
    CONFIRMED_SCHEDULE: (
      <AuroraContainer>
        <AuroraImage src={AuroraScheduleConfirmed} alt="aurora" />
        <Bar color="#cff3bc">
          <StatusText color="#43CC00" fontWeight="bold" marginRight="7px">
            Status da visita <StatusSeparator>|</StatusSeparator>
          </StatusText>
          <StatusText>
            Agendamento confirmado{' '}
            <strong>(Dia {moment(scheduling.schedule).format('DD/MM/YYYY [às] HH[h]')})</strong>
          </StatusText>
        </Bar>
      </AuroraContainer>
    ),

    ACCOMPLISHED_AND_APPROVED: (
      <AuroraContainer>
        <AuroraImage src={AuroraScheduleConfirmed} alt="aurora" />
        <Bar color="#cff3bc">
          <StatusText color="#43CC00" fontWeight="bold" marginRight="7px">
            Status da visita <StatusSeparator>|</StatusSeparator>
          </StatusText>
          <StatusText>Realizada e aprovada</StatusText>
        </Bar>
      </AuroraContainer>
    ),

    UNCONFIRMED_APPOINTMENT: (
      <AuroraContainer>
        <AuroraImage src={AuroraScheduleImpartial} alt="aurora" />
        <Bar color="#FFE2CF">
          <StatusText color="#FF7600" fontWeight="bold" marginRight="7px">
            Status da visita <StatusSeparator>|</StatusSeparator>
          </StatusText>
          <StatusText>Agendamento solicitado</StatusText>
        </Bar>
      </AuroraContainer>
    ),

    DONE_AND_NOT_APPROVED: (
      <AuroraContainer>
        <AuroraImage src={AuroraScheduleUnconfirmed} alt="aurora" />
        <Bar color="#FFE2CF">
          <StatusText color="#FF7600" fontWeight="bold" marginRight="7px">
            Status da visita <StatusSeparator>|</StatusSeparator>
          </StatusText>
          <StatusText>Realizado e não aprovado</StatusText>
        </Bar>
      </AuroraContainer>
    ),
  }

  return (
    <>
      <ScheduleContainer>
        <FieldRow keyValue="Telefone" value={phone(scheduling.phone)} />
        <FieldRow keyValue="CEP" value={cep(scheduling.cep)} />
        <FieldRow keyValue="Endereço" value={scheduling.address} />
        <FieldRow keyValue="Número" value={scheduling.number} />
        <FieldRow keyValue="Complemento" value={scheduling.complement} />
        <FieldRow keyValue="Bairro" value={scheduling.neighborhood} />
        <FieldRow keyValue="Cidade" value={scheduling.city} />
        <FieldRow keyValue="Estado" value={scheduling.state} />
        {/* <FieldRow
          keyValue="Agendamento"
          value={moment(scheduling.schedule).format('DD/MM/YYYY [às] HH[h]')}
        />
        <Tooltip
          enabled={!!state.disabledRescheduleReason}
          tooltipContent={<span>{state.disabledRescheduleReason}</span>}
        >
          <ButtonCancel
            width="200px"
            disabled={state.loadingCancelVisit || !!state.disabledRescheduleReason}
            onClick={() => setState({showDialogCancelConfirmation: true})}
          >
            Cancelar ou Reagendar
          </ButtonCancel>
        </Tooltip>
        {state.showDialogCancelConfirmation && (
          <Modal
            width="500px"
            disabled={state.loadingCancelVisit}
            onClose={() => setState({showDialogCancelConfirmation: false})}
          >
            <ModalTextContent>
              <>Tem certeza que deseja cancelar/reagendar a visita técnica?</>
            </ModalTextContent>
            <ModalActions>
              <Button
                onClick={() => setState({showDialogCancelConfirmation: false})}
                color="gray"
                variant="outlined"
                disabled={state.loadingCancelVisit}
              >
                Fechar
              </Button>
              <Button onClick={cancelVisit} disabled={state.loadingCancelVisit}>
                Confirmar
              </Button>
            </ModalActions>
          </Modal>
        )} */}
      </ScheduleContainer>

      {auroraSelector[scheduling.status]}
    </>
  )
}

function FieldRow({keyValue, value}) {
  return (
    <ScheduleField>
      <ScheduleFieldKey>{keyValue}:</ScheduleFieldKey>
      <ScheduleFieldValue>{value}</ScheduleFieldValue>
    </ScheduleField>
  )
}

const {isRequired: propStrReq} = PropTypes.string

ListScheduly.propTypes = {
  scheduling: PropTypes.shape({
    id: propStrReq,
    phone: propStrReq,
    cep: propStrReq,
    address: propStrReq,
    number: propStrReq,
    complement: propStrReq,
    city: propStrReq,
    state: propStrReq,
    neighborhood: propStrReq,
    schedule: null,
    status: propStrReq,
  }).isRequired,
}

FieldRow.propTypes = {
  keyValue: propStrReq,
  value: propStrReq,
}

export default ListScheduly

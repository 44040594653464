import {SvgIcon, SvgIconProps} from '@material-ui/core'
import React from 'react'

const DocumentFrontIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="115"
    height="71"
    viewBox="0 0 115 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="104" height="61" rx="3.5" fill="white" stroke="#A3AAB6" />
    <rect x="8" y="11" width="38" height="42" rx="4" fill="#D8DAE0" />
    <g clipPath="url(#clip0_1_14)">
      <path
        d="M27 40C30.6833 40 33.6667 37.0167 33.6667 33.3333C33.6667 29.65 30.6833 26.6667 27 26.6667C23.3167 26.6667 20.3333 29.65 20.3333 33.3333C20.3333 37.0167 23.3167 40 27 40ZM27 43.3333C22.55 43.3333 13.6667 45.5667 13.6667 50V53.3333H40.3333V50C40.3333 45.5667 31.45 43.3333 27 43.3333Z"
        fill="#697180"
      />
    </g>
    <rect x="51" y="15" width="22" height="5" rx="1" fill="#D9D9D9" />
    <rect x="51" y="24" width="44" height="5" rx="1" fill="#D9D9D9" />
    <rect x="51" y="33" width="44" height="5" rx="1" fill="#D9D9D9" />
    <rect x="51" y="47" width="22" height="5" rx="1" fill="#D9D9D9" />
    <g clipPath="url(#clip1_1_14)">
      <path
        d="M108.606 51.805C107.671 47.0612 103.505 43.5 98.5 43.5C94.5262 43.5 91.075 45.755 89.3562 49.055C85.2175 49.495 82 53.0013 82 57.25C82 61.8013 85.6988 65.5 90.25 65.5H108.125C111.92 65.5 115 62.42 115 58.625C115 54.995 112.181 52.0525 108.606 51.805ZM101.25 55.875V61.375H95.75V55.875H91.625L98.5 49L105.375 55.875H101.25Z"
        fill="#697180"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_14">
        <rect width="40" height="40" fill="white" transform="translate(7 20)" />
      </clipPath>
      <clipPath id="clip1_1_14">
        <rect width="33" height="33" fill="white" transform="translate(82 38)" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default DocumentFrontIcon

import {SvgIcon, SvgIconProps} from '@material-ui/core'
import React from 'react'

const DocumentBackIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="115"
    height="71"
    viewBox="0 0 115 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="104" height="61" rx="3.5" fill="white" stroke="#A3AAB6" />
    <rect x="8" y="30" width="22" height="5" rx="1" fill="#D9D9D9" />
    <rect x="8" y="39" width="44" height="5" rx="1" fill="#D9D9D9" />
    <rect x="8" y="48" width="44" height="5" rx="1" fill="#D9D9D9" />
    <g clipPath="url(#clip0_1_32)">
      <path
        d="M108.606 51.805C107.671 47.0612 103.505 43.5 98.5 43.5C94.5262 43.5 91.075 45.755 89.3562 49.055C85.2175 49.495 82 53.0013 82 57.25C82 61.8013 85.6988 65.5 90.25 65.5H108.125C111.92 65.5 115 62.42 115 58.625C115 54.995 112.181 52.0525 108.606 51.805ZM101.25 55.875V61.375H95.75V55.875H91.625L98.5 49L105.375 55.875H101.25Z"
        fill="#697180"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_32">
        <rect width="33" height="33" fill="white" transform="translate(82 38)" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default DocumentBackIcon

import React, {useCallback} from 'react'

import WarningIcon from 'assets/images/ico-warning.svg'
import Button from 'components/button'

import api from 'services/api'

import * as S from '../styles'

const Overlay = () => {
  const logout = useCallback(() => {
    api
      .post('/portal/logout')
      .catch(err => console.error('Error logging out the client: ', err))
      .finally(() => {
        localStorage.removeItem('persist:solar_21')
        window.location.reload()
      })
  }, [])

  return (
    <S.OverlayWrapper>
      <S.WaitingWrapper>
        <S.NoFranchiseContainer>
          <div>
            <img src={WarningIcon} alt="warning icon" />
          </div>
          <span>
            Infelizmente ainda não atuamos na sua região, mas não se preocupe, assim que chegarmos por aí você
            será avisado, ok?!
          </span>
        </S.NoFranchiseContainer>
        <S.WaitingButtonWrapper>
          <Button text="Fechar" onClick={logout} />
        </S.WaitingButtonWrapper>
      </S.WaitingWrapper>
    </S.OverlayWrapper>
  )
}

export default Overlay

import styled from 'styled-components'

import SignupBackground from 'assets/images/signup-b2b-background.jpg'
import {theme} from 'assets/styles/theme'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column-reverse;
    height: fit-content;
  }
`

export const ImageBackground = styled.div`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${SignupBackground});
  background-position: 40% 0;
  position: relative;

  @media screen and (max-width: 700px) {
    min-height: 400px;
  }
`

export const StickyAccess = styled.div`
  position: static; /* To be stick, change it here to stick */
  top: 0;
  left: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  gap: 16px;

  @media screen and (max-width: 700px) {
    height: 400px;
  }
`

type TextProps = {
  color?: string
}

const getColor = (color?: string) => {
  if (color === 'white') return theme.colors.white
  return theme.colors.black
}

export const Title = styled.span<TextProps>`
  font-weight: 300;
  font-size: 24px;
  color: ${({color}) => getColor(color)};

  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
`

export const Subtitle = styled.span<TextProps>`
  font-weight: bold;
  font-size: 32px;
  color: ${({color}) => getColor(color)};

  @media screen and (max-width: 400px) {
    font-size: 26px;
  }

  @media screen and (max-width: 320px) {
    font-size: 20px;
  }
`

export const TextContent = styled.span`
  font-weight: 300;
  font-size: 18px;
  text-align: justify;

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`

export const FormSide = styled.div`
  height: 100%;
  background-color: #f7fafc;
  display: grid;
  place-items: center;
  padding: 16px 0;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const FormWrapper = styled.div`
  width: 50%;
  display: grid;
  gap: 16px;

  @media screen and (max-width: 900px) {
    width: 80%;
  }
`

import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from 'components/ui/button'
import {VerificationCodeInput} from 'components/VerificationCodeInput'
import history from 'services/history'
import lang from 'services/language'

import UndoIcon from 'assets/images/undo-solid.svg'
import Solar21LinearProgress from 'components/shared/Solar21LinearProgress'
import {useVerificationCode} from 'hooks/useVerificationCode'
import {toast} from 'react-toastify'
import api from 'services/api'
import SimpleCountDown from 'components/shared/SimpleCountDown'
import {addDoc, collection, getFirestore} from 'firebase/firestore'
import app from '../../../../firebase'

const Form = styled.form`
  width: 100%;
  @media only screen and (max-width: 520px) {
    margin-bottom: 1.2rem;
  }
`

const VerificationCodeContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const MessageError = styled.div`
  position: relative;

  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;

  > small {
    font-size: 1rem;
    color: red;
  }
`

const ResendVerificationContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
`

const ResendVerification = styled.span`
  border-bottom: 1px solid transparent;
  ${props => {
    if (props.isDisabled) {
      return `
          cursor: not-allowed;
          border: 1px solid transparent;
          color: grey;
        `
    }
  }}
  &:hover {
    cursor: pointer;
    color: red;
    border-bottom: 1px solid red;
    ${props => {
      if (props.isDisabled) {
        return `
          cursor: not-allowed;
          border: 1px solid transparent;
          color: grey;
        `
      }
    }}
  }
`

const ResendVerificationIcon = styled.img`
  width: 25px;
  padding-right: 10px;
`

export default function AccessAccountForm({onSubmit, submitting, failure, hideResendCode, fullNumber}) {
  const {formatMessage} = useIntl()

  const [verificationCode, setVerificationCode] = useState()
  const [verificationCodeFieldSize, setVerificationCodeFieldSize] = useState({
    width: 58,
    height: 58,
  })
  const [timedownSmsResend, setTimedownSmsResend] = useState(30)
  const [disableResend, setDisableResend] = useState(false)
  const [resendingByEmail, setResendingByEmail] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useVerificationCode(verificationCode, onSubmit)

  const _confirm_ = formatMessage({id: 'common.button.confirm'})
  const _confirming_ = formatMessage({id: 'common.button.confirming'})
  const _back_ = formatMessage({id: 'common.button.back'})

  useEffect(() => {
    const setWindowWidthWhenResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', setWindowWidthWhenResize)
    return () => {
      window.removeEventListener('resize', setWindowWidthWhenResize)
    }
  }, [])

  useEffect(() => {
    const breakpoint = 500
    if (windowWidth < breakpoint)
      setVerificationCodeFieldSize({
        width: 45,
        height: 45,
      })
    else
      setVerificationCodeFieldSize({
        width: 58,
        height: 58,
      })
  }, [windowWidth])

  const resendSms = () => {
    setDisableResend(true)
    setDisableResend(true)

    const db = getFirestore(app)

    const collectionRef = collection(db, 'Queue_sms')
    addDoc(collectionRef, {
      phone: fullNumber,
      coupon: null,
      node_env: 'homolog',
      type: 'SIGN_IN',
    }).then(() => {
      toast.success('Código reenviado com sucesso')
    })
  }

  const resendByEmail = () => {
    setResendingByEmail(true)
    api
      .post('/portal/customers/resend-login-code-by-email', {
        phone: fullNumber,
      })
      .then(({data}) => {
        if (data.success) {
          toast.success('O código foi enviado para seu email cadastrado')
        }
        console.log('resendByEmail', data)
      })
      .finally(() => setResendingByEmail(false))
  }

  const resendWpp = () => {
    setDisableResend(true)

    const db = getFirestore(app)

    const collectionRef = collection(db, 'Queue_sms')
    addDoc(collectionRef, {
      phone: fullNumber,
      coupon: null,
      node_env: 'homolog',
      type: 'SIGN_UP',
      origin: 'REQUEST_WHATSAPP',
    }).then(() => {
      toast.success('Código reenviado via whatsapp com sucesso')
    })
  }

  return (
    <Form onSubmit={event => onSubmit(event, verificationCode)}>
      <VerificationCodeContainer>
        <VerificationCodeInput
          onChange={code => setVerificationCode(code)}
          fieldWidth={verificationCodeFieldSize.width}
          fieldHeight={verificationCodeFieldSize.height}
        />
      </VerificationCodeContainer>

      {failure && (
        <MessageError>
          <small>Por favor, informe um código válido!</small>
        </MessageError>
      )}

      {!hideResendCode && (
        <>
          <ResendVerificationContainer>
            <ResendVerification
              onClick={!!timedownSmsResend ? () => {} : resendSms}
              isDisabled={!!timedownSmsResend || disableResend}
            >
              <ResendVerificationIcon src={UndoIcon} alt="Ícone reenviar código via SMS" />
              Reenviar código via SMS{' '}
              <span style={{display: !!timedownSmsResend ? 'initial' : 'none'}}>
                em <SimpleCountDown seconds={timedownSmsResend} onChange={setTimedownSmsResend} /> segundos
              </span>
            </ResendVerification>
          </ResendVerificationContainer>

          {!timedownSmsResend ? <ResendVerificationContainer>
            <ResendVerification
              onClick={() => resendWpp}
              isDisabled={false}
            >
              <ResendVerificationIcon src={UndoIcon} alt="Ícone reenviar código via Whatsapp" />
              Reenviar código via Whatsapp{' '}
            </ResendVerification>
          </ResendVerificationContainer> : <></>}
        </>
      )}

      <ResendVerificationContainer>
        <ResendVerification onClick={resendByEmail} isDisabled={resendingByEmail}>
          <ResendVerificationIcon src={UndoIcon} alt="Ícone reenviar código por Email" />
          Reenviar código por e-mail{' '}
        </ResendVerification>
      </ResendVerificationContainer>

      {resendingByEmail && <Solar21LinearProgress />}

      <Button width="100%" type="submit" disabled={!!submitting} style={{marginTop: '1.2rem'}}>
        {submitting ? _confirming_ : _confirm_}
      </Button>
      <Button
        width="100%"
        variant="link"
        style={{marginTop: '1.2rem'}}
        onClick={() => history.push(`/${lang}`)}
      >
        {_back_}
      </Button>
    </Form>
  )
}

AccessAccountForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
  failure: false,
  hideResendCode: false,
}

AccessAccountForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  failure: PropTypes.bool,
  hideResendCode: PropTypes.bool,
  fullNumber: PropTypes.string,
}

import {all, takeLatest, put, call} from 'redux-saga/effects'
import {toast} from 'react-toastify'

import types from 'store/types'

import api from '../../../services/api'
import {
  partnershipFailure,
  partnershipRequest,
  partnershipSuccess,
  sendEmployeeCardFailure,
  sendEmployeeCardSuccess,
  userRequestFailure,
  userRequestSuccess,
} from './action'

export function* getUser() {
  try {
    const user = yield call(api.get, '/portal/customers')
    yield put(userRequestSuccess(user))
  } catch (err) {
    yield put(userRequestFailure())
  }
}

export function* getPartnership({payload}) {
  const {id} = payload
  try {
    const {data} = yield call(api.get, `portal/client-partnership/${id}`)
    yield put(partnershipSuccess(data))
  } catch (err) {
    yield put(partnershipFailure())
  }
}

export function* uploadEmployeeCard({payload}) {
  const {id, file} = payload

  try {
    const headerParams = {
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Content-Type': 'multipart/form-data',
    }

    yield call(api.post, `/portal/client-partnership/${id}/emproyee-card`, file, headerParams)

    yield put(sendEmployeeCardSuccess())
    yield put(partnershipRequest(id))
  } catch (error) {
    toast.error('Erro no envio do crachá. Por favor, tente novamente!')
    yield put(sendEmployeeCardFailure())
    yield put(partnershipRequest(id))
  }
}

export default all([
  takeLatest(types.USER_REQUEST, getUser),
  takeLatest(types.PARTNERSHIP_REQUEST, getPartnership),
  takeLatest(types.EMPLOYEE_CARD_SEND_REQUEST, uploadEmployeeCard),
])

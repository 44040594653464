import styled from 'styled-components'

import ic_credit_card from 'assets/images/credit-card.svg'
import Button from 'components/ui/button'

export const StyledForm = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
  }
`

export const StyledFormContainer = styled.div``

export const StyledInputCart = styled.div`
  position: relative;
  & input {
    padding: 12px 60px 12px 10px !important;
  }
`

export const StyledIcoCart = styled.span`
  position: absolute;
  right: 0;
  top: 25px;
  background: url(${ic_credit_card}) no-repeat;
  width: 60px;
  height: 45px;
  border-left: 1px solid #d8d8d8;
  background-position: 50% 50%;
`

export const StyledFlex = styled.div`
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`

export const StyledInputs = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  & input {
    width: 100%;
    margin-bottom: 15px;
    padding: 12px 16px;
    border: 1px solid #d8d8d8;
    background: #ffffff;
    font-size: 1em;
    font-family: 'Roboto';
    color: #b7b7b7;
    transition: border-color 0.2s;
    &:focus {
      border-color: #111;
    }
  }
  & label {
    display: block;
    margin-bottom: 5px;
    font-weight: 300;
    white-space: nowrap;
  }
  & ::placeholder {
    font-weight: 300;
    color: #b7b7b7;
  }
  & ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`
export const StyledButton = styled(Button)`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 850px) {
    width: 120px;
    font-size: 1em;
  }
`

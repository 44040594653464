import styled from 'styled-components'
import theme from 'assets/styles/theme'

export const AuroraContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 50px 0;
  position: relative;
`

export const StatusAction = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 25px;
  button {
    margin-right: 50px;
    &:first-child {
      margin-right: 10px;
    }
  }
`

export const AuroraImage = styled.img`
  z-index: 2;
  max-width: 150px;

  @media (min-width: 300px) and (max-width: 1024px) {
    height: 100px;
    width: 100px;
  }
`

export const Bar = styled.div`
  align-items: center;
  background-color: ${props => props.color};
  border-radius: 80px;
  display: flex;
  height: 110px;
  padding-left: 200px;
  position: absolute;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 90px;
    justify-content: center;
    padding: 10px;
    padding-left: 100px;
    width: 100%;
    text-align: left;
  }
`
export const StatusText = styled.span`
  color: ${props => props.color};
  font-size: 1.4rem;
  font-weight: ${props => props.fontWeight};
  margin-right: ${props => props.marginRight};

  @media (min-width: 300px) and (max-width: 1024px) {
    display: flex;
    font-size: 1rem;
    text-align: center;
  }
`

export const StatusSeparator = styled.span`
  @media (min-width: 300px) and (max-width: 1024px) {
    display: none;
  }
`

export const ScheduleFieldKey = styled.span`
  color: ${theme('colors.solar_21.gray_slight_light')};
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 5px;
`

export const ScheduleFieldValue = styled.span`
  color: ${theme('colors.solar_21.gray')};
  font-size: 1.2rem;
`
export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

export const ModalActions = styled.div`
  display: inline-flex;
`

export const ModalTextContent = styled.span`
  margin-bottom: 20px;
  font-size: 18px;
  text-align: justify;
  font-weight: bold;
`

export const ButtonCancel = styled.button`
  background: #ffd500;
  border-radius: 7px;
  border: 2px solid #ffd500;
  width: 260px;
  padding: 12px;
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
  color: ${theme('colors.black')};
  font-weight: 800;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    width: 70%;
  }

  &:hover {
    background: white;
    border: 2px solid #ffd500;
  }
`

export const ScheduleField = styled.div`
  align-items: flex-end;
  display: flex;
  margin: 2px 0;

  &.span {
    font-size: 1.2rem;
  }
`

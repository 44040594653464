import React, {useEffect, useState} from 'react'
import {BsArrowRight} from 'react-icons/bs'
import {useIntl, FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'

import icoInfo from 'assets/images/ico_info.svg'
import {FullScreenLoader} from 'components/fullScreenLoader'
import Button from 'components/ui/button'
import history from 'services/history'
import {
  dataConsumerRequest,
  sendDocRequest,
  setShowConsumerModal,
  getUserPlan,
  getConsumerHolderRequest,
} from 'store/modules/booking-payment/action'
import {b2cPlanRequest, b2cVerifyCheckoutRequest, b2cVerifyArchiveRequest} from 'store/modules/plan/action'
import {getCurrentStep} from 'utils/getCurrentStep'
import * as Yup from 'yup'

import CheckImg from '../../assets/images/check-mark.svg'
import DocumentHeaderIcon from '../../assets/images/icon-document.svg'
import ConfirmationIcon from '../../assets/images/plan_confirmation.svg'
import PlanIcon from '../../assets/images/plan_signature_yellow.svg'
import ArchiveCard from '../../components/archiveCard'
import PageHeader from '../../components/pageHeader'
import Form from './partials/form/index'
import ModalConsumer from './partials/modal-consumer'
import DropZone from './partials/upload'
import ModalSuccessUpload from './partials/upload/partials/ModalSuccessUpload'
import {
  StyledPlanContent,
  StyledPlanGroup,
  StyledPlanGroupTitle,
  StyledPlanGroupDescription,
  StyledFlexGroup,
  StyledFlexList,
  StyledBox,
  StyledTitleBox,
  StyledItemBox,
  StyledBottomBox,
  FileContainer,
  StyledLabelInputImage,
  StyledImage,
  StyledTooltipText,
  StyledTooltip,
  StyledButtonGroup,
  StyledSuccessCheckout,
  StyledtBlockSendDocuments,
  StyledCheckoutDiv,
  StyledCheckoutLabel,
  StyledDiv,
  StyledContractPreview,
} from './styles'

import api from 'services/api'
import {Grid, LinearProgress} from '@material-ui/core'
import CardUpload from './partials/CardUpload'

function Booking() {
  const {formatMessage} = useIntl()
  const dispatch = useDispatch()
  const selectorPlan = useSelector(state => state.plan.plan)
  const successCheckout = useSelector(state => state.plan.checkoutSuccess)
  const isLoading = useSelector(state => state.plan.pending)
  const userType = useSelector(state => state.user.user.type || {})
  const isConsumerLoading = useSelector(state => state.booking.isConsumerLoading)
  const successDocumentSend = useSelector(state => state.booking.successDocument)
  const showConsumerModal = useSelector(state => state.booking.showConsumerModal)
  const selectedPlan = useSelector(state => state.booking.selectedPlanModal)
  const steps = useSelector(state => state.menu.menu)
  const holder = useSelector(state => state.booking.holder)
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)

  const verifyCheckoutB2c = useSelector(state => state.plan.verifyCheckout)
  const verifyArchiveB2c = useSelector(state => state.plan.verifyArchive)
  const payloadArchive = useSelector(state => state.plan.payloadArchive)
  const clientPlanId = useSelector(state => state.plan.clientPlanId)
  const clientPlanPureId = useSelector(state => state.plan.plan.id)

  const _pageTitle_ = formatMessage({id: 'plans.header.title'})
  const _pageDescription_ = formatMessage({id: 'plans.header.description'})
  const _booking_ = formatMessage({id: 'plans.content.booking'})
  const _bookingPlan_ = formatMessage({id: 'plans.content.bookingSubtitle'})
  const _planDetail_ = formatMessage({id: 'bookink.plan.detail'})
  const _planInstalation_ = formatMessage({id: 'booking.plan.detail.instalation'})
  const _planJoingFee_ = formatMessage({id: 'booking.plan.detail.joining_fee'})
  const _planTotal_ = formatMessage({id: 'booking.plan.detail.total'})
  const _planDetailOrder_ = formatMessage({id: 'booking.plan.detail.plan'})
  const _planEconomyMonth_ = formatMessage({id: 'booking.plan.detail.economy_per_month'})
  const _planAnualSaving_ = formatMessage({id: 'booking.plan.detail.annual_savings'})
  const _planReductionPercent_ = formatMessage({id: 'booking.plan.detail.reduction_percent'})
  const _planOrderTitle_ = formatMessage({id: 'booking.plan.order.title'})
  const _bookingDocTitle_ = formatMessage({id: 'booking.plan.doc.title'})
  const _bookingDocSubTitle_ = formatMessage({id: 'booking.plan.doc.subTitle'})
  const _bookingDocLabel_ = formatMessage({id: 'booking.plan.doc.label.upload'})
  const _cardCnpj_ = formatMessage({id: 'booking.plan.doc.label.cnpjCard'})
  const _comprove_ = formatMessage({id: 'booking.plan.doc.label.comproveLegal'})
  const _comproveDocument_ = formatMessage({id: 'booking.plan.doc.label.documentLegal'})
  const _info_ = formatMessage({id: 'booking.plan.doc.label.info'})
  // const _fileValueText_ = formatMessage({id: 'booking.page.file.text.value'})
  const _documentsHere_ = formatMessage({id: 'documents.here'})

  const [uploadFile, setUploadFile] = useState({})

  const [_document, setDocument] = React.useState()
  const [documentBack, setBackDocument] = React.useState()

  const [isTreeDocument, setIsTreeDocument] = useState(false)

  const [isCheckout, setIsCheckout] = useState(false)
  const [isSendArchive, setIsSendArchive] = useState(false)

  const [alreadySendedDocument, setAlreadySendedDocument] = useState(false)

  const [autofillDocuments, setAutofillDocuments] = useState({})

  const isCNPJ = userType.type === 'CNPJ'

  const [archiveMap, setArchiveMap] = useState()
  const [, setBookingStatus] = useState(false)
  useEffect(() => {
    dispatch(b2cPlanRequest(clientPlanId))
    dispatch(b2cVerifyCheckoutRequest())
    dispatch(b2cVerifyArchiveRequest())
    dispatch(getUserPlan())
    dispatch(getConsumerHolderRequest())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    const currentStep = getCurrentStep(steps)
    if (!clientPlanId && currentStep !== 'CHECKOUT') history.push('/pt/plan-signature')
  }, [clientPlanId, steps])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (verifyCheckoutB2c) setIsCheckout(true)
    if (verifyArchiveB2c) {
      const documentVerification = payloadArchive.find(x => x.type === 'DOCUMENT')
      if (documentVerification.status === 'DENIED') {
        setIsTreeDocument(false)
      } else {
        setIsTreeDocument(true)
        setAlreadySendedDocument(true)
      }
      setIsSendArchive(true)

      const payloadArchiveMap = payloadArchive.map(data => {
        const isBookingPending = data.status === 'PENDING'
        setBookingStatus(isBookingPending)
        const isBookingDenied = data.status === 'DENIED'
        setIsSendArchive(!isBookingDenied)

        return (
          <ArchiveCard key={data.file_name} name={data.file_name} status={data.status} type={data.type} />
        )
      })

      setArchiveMap(payloadArchiveMap)
    }
  }, [verifyCheckoutB2c, verifyArchiveB2c, selectedPlan, payloadArchive])

  const priceSplitter = number =>
    number && number.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
  const replaceNegativeValue = number => number && number.toFixed().replace('-', '')

  const planList = {
    name: selectorPlan.plan?.name,
    price: priceSplitter(selectorPlan.price),
    joining_fee: priceSplitter(selectorPlan.plan?.joining_fee),
    economy_per_month: priceSplitter(selectorPlan.economy_per_month),
    annual_savings: priceSplitter(selectorPlan.annual_savings),
    new_bill: priceSplitter(selectorPlan.new_bill),
    old_bill: priceSplitter(selectorPlan.old_bill),
    reduction_percent: replaceNegativeValue(selectorPlan.reduction_percent),
    total: selectorPlan.price + selectorPlan.plan?.joining_fee,
  }

  const [loading, setLoading] = React.useState(false)

  const handleSend = React.useCallback(() => {
    const formData = new FormData()
    formData.append('document', _document)
    formData.append('document_back', documentBack)
    setLoading(true)
    dispatch(sendDocRequest(formData))
  }, [dispatch, _document, documentBack, setLoading])

  React.useEffect(() => {
    if (successDocumentSend) {
      setIsTreeDocument(true)
      setLoading(false)
    }
  }, [successDocumentSend, setLoading])

  const handleUpload = files => {
    if (files.file.length > 0) {
      setUploadFile(state => ({...state, [files.type]: files.file[0]}))
    }
  }

  useEffect(() => {
    if (uploadFile && !!Object.keys(uploadFile).length) handleSend()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile])

  const handleOpenConsumerModal = () => {
    setIsTreeDocument(false)
    dispatch(setShowConsumerModal(false))
  }

  const handleConfirmConsumerModal = () => {
    dispatch(setShowConsumerModal(true))
    setIsTreeDocument(false)
  }

  const handleCloseConsumerModal = () => {
    dispatch(setShowConsumerModal(false))
    setIsTreeDocument(false)
  }

  useEffect(() => {
    if (successDocumentSend) setIsTreeDocument(true)
  }, [successDocumentSend])

  useEffect(() => {
    if (
      (isCNPJ && uploadFile.card && uploadFile.receipt && uploadFile.document) ||
      (!isCNPJ && uploadFile.document)
    )
      setIsTreeDocument(false)
  }, [isCNPJ, uploadFile])

  async function handleSubmitConsumer(consumer, isHolder) {
    try {
      let consumerSchema
      const date_of_birth = Yup.string()
        .matches(
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
        )
        .required()
      const occupation = Yup.string().min(2).required()
      const marital_status = Yup.string()
        .equals(['solteiro', 'casado', 'viuvo', 'divorciado', 'uniao estavel'])
        .required()

      if (isHolder) {
        consumerSchema = Yup.object().shape({
          date_of_birth,
          occupation,
          marital_status,
        })
      } else {
        consumerSchema = Yup.object().shape({
          name: Yup.string().min(5).required(),
          document_value: Yup.string().min(11).required(),
          email: Yup.string().email().required(),
          phone: Yup.string().min(11).required(),
          date_of_birth,
          occupation,
          marital_status,
        })
      }

      await consumerSchema.validate(consumer)
      dispatch(
        dataConsumerRequest({
          ...consumer,
          isCNPJ,
        })
      )
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.error(<FormattedMessage id="booking.consumer.error.input">{msg => msg}</FormattedMessage>)
      } else {
        toast.error('Algo deu errado.')
      }
    }
  }

  const verifyCnpj = () => {
    if (isCNPJ)
      return (
        <>
          <StyledLabelInputImage>
            {_cardCnpj_}
            <StyledTooltip>
              <StyledImage src={icoInfo} />
              <StyledTooltipText>{_info_}</StyledTooltipText>
            </StyledTooltip>
          </StyledLabelInputImage>
          {!successCheckout && <StyledtBlockSendDocuments />}
          <FileContainer>
            <DropZone insideText={_documentsHere_} type="card" onChange={handleUpload} />
          </FileContainer>
          <StyledLabelInputImage>
            {_comprove_}
            <StyledTooltip>
              <StyledImage src={icoInfo} />
              <StyledTooltipText>{_info_}</StyledTooltipText>
            </StyledTooltip>
          </StyledLabelInputImage>
          {!successCheckout && <StyledtBlockSendDocuments />}
          <FileContainer>
            <DropZone insideText={_documentsHere_} type="receipt" onChange={handleUpload} />
          </FileContainer>
          <StyledLabelInputImage>
            {_comproveDocument_}
            <StyledTooltip>
              <StyledImage src={icoInfo} />
              <StyledTooltipText>{_info_}</StyledTooltipText>
            </StyledTooltip>
          </StyledLabelInputImage>
          {!successCheckout && <StyledtBlockSendDocuments />}
          <FileContainer>
            <DropZone insideText={_documentsHere_} type="document" onChange={handleUpload} />
          </FileContainer>
        </>
      )

    return (
      <>
        <StyledLabelInputImage>
          {_bookingDocLabel_}
          <StyledTooltip>
            <StyledImage src={icoInfo} />
            <StyledTooltipText>{_info_}</StyledTooltipText>
          </StyledTooltip>
        </StyledLabelInputImage>
        {!successCheckout && <StyledtBlockSendDocuments />}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CardUpload onSelectDocument={setDocument} isFront title="Frente do Documento *" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardUpload onSelectDocument={setBackDocument} title="Verso do Documento" />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={!_document || loading} onClick={handleSend}>
              Enviar
            </Button>
            {loading && <LinearProgress />}
          </Grid>
        </Grid>
        {/* <FileContainer>
          <DropZone
            insideText={'Envie uma foto ou PDF de frente e verso da sua CNH ou RG,'}
            selectBtnText="Selecione seu documento"
            type="document"
            onChange={handleUpload}
            onSend={handleSend}
          />
        </FileContainer> */}
      </>
    )
  }

  const getAutoFillDocuments = () => {
    api.get(`/portal/autofill-documents/${clientPlanPureId}`).then(({data: response}) => {
      if (response.success) setAutofillDocuments(response.data)
    })
  }

  /* eslint-disable */

  React.useEffect(() => {
    if (clientPlanPureId && (!autofillDocuments || !autofillDocuments?.length)) {
      getAutoFillDocuments()
    }
  }, [clientPlanPureId])

  /* eslint-enable */

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <StyledPlanContent>
        <PageHeader title={_pageTitle_} description={_pageDescription_} icon={PlanIcon} />
        <StyledPlanGroup>
          <StyledPlanGroupTitle>
            <img src={ConfirmationIcon} alt="" />
            <span style={{fontWeight: 'bold'}}>{_booking_}</span>{' '}
            {windowWidth > 768 ? (
              <span className="next-steps" style={{opacity: 0.5, marginLeft: 10}}>
                {' '}
                → Assinatura
              </span>
            ) : (
              <span className="next-steps" style={{opacity: 0.5, marginLeft: 10}}>
                {' '}
              </span>
            )}
          </StyledPlanGroupTitle>
          <StyledPlanGroupDescription>
            {isCNPJ
              ? 'Escolha a melhor data para a cobrança do boleto. Fique tranquilo, você só será cobrado 30 dias após a conexão do sistema solar.'
              : _bookingPlan_}
          </StyledPlanGroupDescription>
        </StyledPlanGroup>
        <StyledFlexGroup>
          <StyledFlexList>
            <StyledBox>
              <StyledTitleBox>{_planDetail_}</StyledTitleBox>
              <StyledItemBox>
                <div>Plano {planList.name} </div>
                <div>R$ {planList.price}</div>
              </StyledItemBox>
              <StyledItemBox>
                <div>{_planInstalation_}</div>
                <div>R$ 0,00</div>
              </StyledItemBox>
              <StyledItemBox>
                <div>{_planJoingFee_}</div>
                <div>R$ 0,00</div>
              </StyledItemBox>
              <StyledBottomBox>
                <div>{_planTotal_}</div>
                <div style={{fontFamily: 'Roboto'}}>R$ {priceSplitter(planList.total)}</div>
              </StyledBottomBox>
            </StyledBox>
            <StyledBox>
              <StyledTitleBox>{_planDetailOrder_}</StyledTitleBox>
              <StyledItemBox>
                <div>{_planEconomyMonth_}</div>
                <div>R$ {planList.economy_per_month}</div>
              </StyledItemBox>
              <StyledItemBox>
                <div>{_planAnualSaving_}</div>
                <div>R$ {planList.annual_savings}</div>
              </StyledItemBox>
              <StyledItemBox>
                <div>{_planReductionPercent_}</div>
                <div>{planList.reduction_percent}%</div>
              </StyledItemBox>
            </StyledBox>
          </StyledFlexList>

          <StyledFlexList>
            {isCheckout ? (
              <StyledCheckoutDiv>
                <div>
                  <img src={CheckImg} alt="" />
                </div>
                <StyledCheckoutLabel>
                  <p>Tudo certo, seu cartão foi aprovado, podemos seguir!</p>
                </StyledCheckoutLabel>
              </StyledCheckoutDiv>
            ) : (
              <>
                <StyledBox>
                  <StyledTitleBox>
                    {isCNPJ ? 'Escolha a data de vencimento do seu boleto' : _planOrderTitle_}
                  </StyledTitleBox>
                </StyledBox>
                <Form planId={selectorPlan.plan_id} />
                <StyledContractPreview>
                  {
                    <p style={{color: '#9F9C9C'}}>
                      Ver as{' '}
                      <a
                        style={{color: '#9F9C9C'}}
                        href="https://www.solar21.com.br/static/docs/condicoes-gerais.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>
                          <strong>condições gerais</strong>
                        </u>{' '}
                      </a>
                      e a{' '}
                      <strong>
                        <a
                          style={{color: '#9F9C9C'}}
                          href={autofillDocuments.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <u>minuta do termo de adesão</u>
                        </a>{' '}
                      </strong>
                      do plano escolhido
                    </p>
                  }
                </StyledContractPreview>
              </>
            )}
          </StyledFlexList>
        </StyledFlexGroup>
        <StyledPlanGroup>
          <StyledPlanGroupTitle>
            <img src={DocumentHeaderIcon} alt="" />
            {_bookingDocTitle_}
          </StyledPlanGroupTitle>
          <StyledPlanGroupDescription>{_bookingDocSubTitle_}</StyledPlanGroupDescription>
        </StyledPlanGroup>
        {isSendArchive ? (
          <>
            <StyledDiv>{archiveMap}</StyledDiv>

            {alreadySendedDocument && !isTreeDocument && !showConsumerModal && (
              <StyledButtonGroup>
                <Button endIcon={<BsArrowRight size={26} />} onClick={() => setIsTreeDocument(true)}>
                  Continuar
                </Button>
              </StyledButtonGroup>
            )}
          </>
        ) : (
          <StyledSuccessCheckout disabled={successCheckout}>{verifyCnpj()}</StyledSuccessCheckout>
        )}

        <ModalConsumer
          open={showConsumerModal}
          onClose={handleCloseConsumerModal}
          onSubmit={handleSubmitConsumer}
          title="Dados do titular da Unidade Consumidora"
          subTitle="Para continuar preencha os dados do titular da Unidade Consumidora"
          isHolder={isCNPJ ? false : holder}
          isLoading={isConsumerLoading}
        />

        <ModalSuccessUpload
          open={isTreeDocument}
          onClose={handleOpenConsumerModal}
          onConfirm={handleConfirmConsumerModal}
          Title="Documento enviado com sucesso!"
          subTitle="O seu documento será analisado e você será notificado por e-mail."
        />

        {/* { !isSendArchive && (
          <StyledButtonSendDoc>
            <Button type="button" onClick={ handleSend } disabled={ isTreeDocument }>
              { pending ? <Loading color="black" width="25px" height="25px" /> : 'Enviar Documentos' }
            </Button>
          </StyledButtonSendDoc>
        ) } */}
      </StyledPlanContent>
    </>
  )
}

export default Booking

import React from 'react'

import * as S from './styles'
import InfoRounded from '@material-ui/icons/InfoRounded'
import Tooltip from '@material-ui/core/Tooltip'

const Card = ({header, children, style, icon, sheetStyle, wrapper, infoIcon, isInitial, onClick}: any) => {
  return (
    <S.BigNumberWrapper style={wrapper}>
      {header && (
        <S.UnderlinedText>
          <S.SubTitle>{header}</S.SubTitle>
          {icon && <S.IconHeader src={icon} />}
          {infoIcon && !isInitial && (
            <Tooltip title="Abrir modal de informações" placement="top" arrow onClick={onClick}>
              <InfoRounded style={{color: '#FFD500', cursor: 'pointer'}} />
            </Tooltip>
          )}
        </S.UnderlinedText>
      )}
      <S.Sheet style={sheetStyle}>
        <S.ContentColumns style={style}>{children}</S.ContentColumns>
      </S.Sheet>
    </S.BigNumberWrapper>
  )
}

export default Card

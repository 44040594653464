import produce from 'immer'

import types from 'store/types'

const INITIAL_STATE = {
  faqs: [],
  isLoading: false,
}

export default function faq(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.FAQ_REQUEST:
        draft.isLoading = true
        break
      case types.FAQ_SUCCESS:
        draft.isLoading = false
        draft.faqs = action.payload.faqs
        break
      case types.FAQ_FAILURE:
        draft.isLoading = false
        break
      default:
    }
  })
}

import styled from 'styled-components'

import BackgroundInitialMobile from 'assets/images/background-initial-mobile.jpg'
import BackgroundInitial from 'assets/images/background-initial.jpeg'
import theme from 'assets/styles/theme'
import ButtonComponent from 'components/ui/button'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${BackgroundInitial});
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;

  @media screen and (max-width: 1366px) {
    height: auto;
  }

  @media screen and (max-width: 1024px) {
    background-attachment: scroll;
  }

  @media screen and (max-width: 500px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
      url(${BackgroundInitialMobile});
  }
`

export const Header = styled.div`
  display: flex;
  height: auto;
  justify-content: space-between;
  margin-left: 140px;
  margin-top: 140px;

  @media (max-width: 1366px) {
    margin: 60px 60px 0 60px;
  }

  @media (max-width: 768px) {
    margin: 20px 20px 0 20px;
  }
`

export const Logo = styled.img`
  position: relative;
  width: 120px;
  height: 120px;

  @media (max-width: ${theme('breakpoints.lg')}) {
    width: auto;
    height: 90%;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`

export const Content = styled.div`
  margin-left: 140px;

  @media (max-width: 1366px) {
    margin: 0px 60px;
  }

  @media (max-width: 768px) {
    margin: 0px 20px;
  }
`

export const VerticalLive = styled.div`
  display: flex;
  align-items: center;
  margin-top: 80px;
  padding-left: 20px;
  border-left: 7px solid ${theme('colors.solar_21.regular')};

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  > h1 {
    align-items: center;
    font-family: 'Raleway', sans-serif;
    font-size: 42px;
    font-weight: 800;
    color: ${theme('colors.white')};

    @media (max-width: 768px) {
      width: 100%;
      font-size: 22px;
    }

    > span {
      color: ${theme('colors.solar_21.regular')};
    }
  }
`

export const Description = styled.div`
  align-items: center;
  display: flex;
  margin-top: 20px;
  padding-left: 30px;
  width: 31%;

  @media (max-width: 1024px) {
    margin-top: 20px;
    width: 100%;
  }

  > p {
    color: ${theme('colors.white')};
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 300;

    @media (max-width: ${theme('breakpoints.lg')}) {
      font-size: 100%;
    }
  }
`

export const Types = styled.div`
  margin: 60px 0 100px 0;
  padding-left: 30px;

  @media (max-width: 320px) {
    margin: 20px 0 20px 0;
  }

  > h2 {
    color: ${theme('colors.white')};
    font-size: 20px;
    font-weight: 300;
  }
`

export const GroupButtons = styled.div`
  display: flex;
  margin-left: -5px;
  flex-wrap: wrap;
`

export const Button = styled(ButtonComponent)`
  background-color: transparent;
  color: ${theme('colors.white')};

  @media (max-width: 768px) {
    font-size: 16px;
    flex: 1;
  }

  &:hover {
    color: ${theme('colors.black')};
    background-color: ${theme('colors.solar_21.regular')};
  }
`

export const ButtonHome = styled.button`
  background: #ffd500;
  border-radius: 7px;
  border: 0px;
  width: 260px;
  padding: 12px;
  margin-top: 10px;
  margin-right: 20px;
  text-align: center;
  font-size: 18px;
  color: ${theme('colors.black')};
  font-weight: 800;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    width: 70%;
  }

  &:hover {
    opacity: 0.97;
  }
`

export const Footer = styled.div`
  align-items: center;
  background-color: #12121291;
  bottom: 0;
  color: ${theme('colors.white')};
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  left: 0;
  padding: 0 140px;
  position: fixed;
  width: 100%;
  height: 100px;

  @media (max-width: 1366px) {
    height: 80px;
    position: relative;
    padding: 0 60px;
  }

  @media (max-width: 1024px) {
    height: auto;
    padding: 20px;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    padding: 28px 0;
  }
`

export const FooterNav = styled.ul`
  display: flex;
  list-style: none;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 10px 0;
    flex-wrap: wrap;
  }
`
export const FooterNavItem = styled.li`
  align-items: center;
  display: flex;
  padding: 10px 10px;

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    font-size: 14px;
  }

  & > h3 {
    font-size: 18px;
    font-weight: normal;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    > a {
      color: ${theme('colors.white')};

      &:hover {
        color: ${theme('colors.solar_21.regular')};
      }
    }
  }
`

export const Social = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 10px;
`
export const FooterNavSocial = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`


export const MainContent = styled.div`
  margin-bottom: 100px;

  @media (max-width: 1336px) {
    margin-bottom: 0;
  }
`

export const Slogan = styled.span`
  color: #ffd500;
`

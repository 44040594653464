import React, {FC} from 'react'

const JudgeIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        d="M10.1008 11.2476L6.75513 7.90198"
        stroke="#020203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.88248 4.7738L13.2281 8.11946"
        stroke="#020203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.86163 18.4458H11.7159"
        stroke="#020203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.61441 18.4458L4.07026 17.0807C4.29739 16.3988 4.9348 15.9382 5.65359 15.9365H8.92397C9.64491 15.9356 10.2853 16.3967 10.5132 17.0807L10.9657 18.4458"
        stroke="#020203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.78978 6.98694L5.94967 8.45484C5.60944 8.72695 5.40087 9.13083 5.37598 9.56578C5.35109 10.0007 5.51222 10.4258 5.81919 10.7349L7.22102 12.1434C7.52546 12.4495 7.94585 12.6113 8.37692 12.5885C8.80798 12.5657 9.20892 12.3603 9.47933 12.0238L10.9648 10.1787C11.3163 9.74171 11.7145 9.34456 12.1525 8.99433L13.9926 7.52642C14.3329 7.25432 14.5414 6.85044 14.5663 6.41549C14.5912 5.98054 14.4301 5.5555 14.1231 5.24636L12.7238 3.83867C12.4193 3.53264 11.999 3.37078 11.5679 3.39361C11.1368 3.41644 10.7359 3.6218 10.4655 3.95828L8.97832 5.80341C8.62678 6.24034 8.22819 6.63724 7.78978 6.98694Z"
        stroke="#020203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3767 8.81616L17.3316 13.0091C17.8031 13.4098 18.0129 14.0393 17.8759 14.6427C17.7389 15.2461 17.2779 15.7234 16.6796 15.8812C16.0813 16.0389 15.4449 15.8511 15.0281 15.3937L10.6503 10.5726"
        stroke="#020203"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default JudgeIcon

import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'

import Solar_21_black from 'assets/images/logo.svg'
import Button from 'components/ui/button'
import history from 'services/history'
import lang from 'services/language'
import {setSignInFailure, signInRequest} from 'store/modules/auth/action'

import LoginForm from './partials/forms/loginForm'
import {
  Wrapper,
  PainelLeft,
  PainelRight,
  StyledDiv,
  StyledLogo,
  StyledImg,
  StyledSubtitle,
  StyledTitle,
  StyledWrapper,
  StyledTitleBlack,
  StyledSubtitleBlack,
  StyledContent,
} from './styles'

function Login() {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()

  const submitting = useSelector(state => state.auth.pending)
  const failure = useSelector(state => state.auth.signInFailure)

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const loginTitle = formatMessage({id: 'login.right.title'})
  const loginSubtitle = formatMessage({id: 'login.right.subtitle'})
  const buttonText = formatMessage({id: 'common.button.register'})

  const _leftTitle_ = formatMessage({id: 'login.left.title'})
  const _leftSubtitle_ = formatMessage({id: 'login.left.subtitle'})
  const _leftDescription_ = formatMessage({id: 'login.left.description'})
  const countryCodeInvalid = formatMessage({id: 'code.COUNTRY_CODE_INVALID'})
  const phoneInvalid = formatMessage({id: 'code.PHONE_NUMBER_INVALID'})

  function handleSubmit(values) {
    const {phone, country_code, recaptcha} = values

    if (!recaptcha)
      return toast.error('Por favor, complete o desafio de racaptcha!', {
        position: 'top-left',
        autoClose: 10000,
      })

    const newCountry_code = country_code.replace(/[^0-9]/g, '')
    const newPhone = phone.replace(/[^0-9]/g, '')

    if (newCountry_code === '' || newCountry_code.length !== 2)
      return toast.error(countryCodeInvalid, {
        position: 'top-left',
        autoClose: 10000,
      })
    if (newPhone === '' || newPhone.length !== 11)
      return toast.error(phoneInvalid, {
        position: 'top-left',
        autoClose: 10000,
      })

    const fullNumber = `${newCountry_code}${newPhone}`

    dispatch(
      signInRequest({
        phone: fullNumber,
        secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
        recaptcha,
      })
    )
    return 1
  }

  useEffect(() => {
    dispatch(setSignInFailure(false))
  }, [dispatch])

  return (
    <Wrapper>
      {windowWidth > 768 ? (
        <React.Fragment>
          <PainelLeft>
            <StyledWrapper>
              <StyledLogo>
                <StyledImg src={Solar_21_black} />
              </StyledLogo>
              <div style={{marginBottom: '1.2rem', display: 'flex', flexDirection: 'column'}}>
                <StyledTitleBlack>{_leftTitle_}</StyledTitleBlack>
                <StyledSubtitleBlack>{_leftSubtitle_}</StyledSubtitleBlack>
                <StyledContent>{_leftDescription_}</StyledContent>
              </div>
              <LoginForm onSubmit={handleSubmit} submitting={submitting} failure={failure} />
            </StyledWrapper>
          </PainelLeft>
          <PainelRight>
            <StyledDiv>
              <StyledLogo>
                <StyledImg src={Solar_21_black} />
              </StyledLogo>
              <StyledTitle>{loginTitle}</StyledTitle>
              <StyledSubtitle>{loginSubtitle}</StyledSubtitle>
              <Button
                width="250px"
                marginBottom="50px"
                onClick={() => {
                  history.push(`/${lang}/sign-up`)
                }}
              >
                {buttonText}
              </Button>
            </StyledDiv>
          </PainelRight>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PainelRight>
            <StyledDiv>
              <StyledLogo>
                <StyledImg src={Solar_21_black} />
              </StyledLogo>
              <StyledTitle>{loginTitle}</StyledTitle>
              <StyledSubtitle>{loginSubtitle}</StyledSubtitle>
              <Button
                width="250px"
                marginBottom="50px"
                onClick={() => {
                  history.push(`/${lang}/sign-up`)
                }}
              >
                {buttonText}
              </Button>
            </StyledDiv>
          </PainelRight>
          <PainelLeft>
            <StyledWrapper>
              <StyledLogo>
                <StyledImg src={Solar_21_black} />
              </StyledLogo>
              <div style={{marginBottom: '1.2rem', display: 'flex', flexDirection: 'column'}}>
                <StyledTitleBlack>{_leftTitle_}</StyledTitleBlack>
                <StyledSubtitleBlack>{_leftSubtitle_}</StyledSubtitleBlack>
                <StyledContent>{_leftDescription_}</StyledContent>
              </div>
              <LoginForm onSubmit={handleSubmit} submitting={submitting} failure={failure} />
            </StyledWrapper>
          </PainelLeft>
        </React.Fragment>
      )}
    </Wrapper>
  )
}

export default Login

import {PositionEnum} from '../BigNumberCard/BigNumberCard'

interface CardProps {
  card: string
  main: string
  side?: string
  sidePosition?: PositionEnum
}

export const CardData: (main: any[]) => CardProps[] = main =>
  [
    {
      card: 'Potência',
      main: main[0],
      side: ' kWp',
      sidePosition: PositionEnum.RIGHT,
    },
    {
      card: 'Preço por potência',
      main: main[1],
      side: ' R$/Wp',
      sidePosition: PositionEnum.RIGHT,
    },
    {
      card: 'Produção mensal',
      main: (parseInt(main[2]) / 12).toFixed(0).toString().replace('.', ','),
      side: ' kWh',
      sidePosition: PositionEnum.RIGHT,
    },
    {
      card: 'Economia anual',
      main: main[3],
      side: ' R$',
      sidePosition: PositionEnum.LEFT,
    },
    {
      card: ' Payback',
      main: main[4],
    },
  ] as CardProps[]

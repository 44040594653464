import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

export const ImgContainer = styled.div`
  padding-top: 105px;
  padding-bottom: 44px;
`

export const Image = styled.img`
  height: 143px;
`

export const Text = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #000000;
  text-align: center;
`
export const UploadDescription = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #000000;
`

interface ButtonProps {
  disabled_color?: boolean
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  width: 65%;
  background: ${props => (props.disabled_color ? '#EBEBE4' : '#ffd500')};
  border: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  margin-top: auto;
  cursor: pointer;
`

export const ContainerFlexRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const ExplainingText = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  text-align: justify;
`

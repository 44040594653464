import React, {useEffect} from 'react'
import {BsArrowLeft, BsArrowRight} from 'react-icons/bs'
import {useIntl} from 'react-intl'
import {useSelector, useDispatch} from 'react-redux'
import history from '../../services/history'

import {isEmpty} from 'lodash'

import Aurora from 'assets/images/aurora/approved.svg'
import CheckImg from 'assets/images/check-mark.svg'
import Procuracao from 'assets/images/contract-illustrations/procuracao.svg'
import Contract from 'assets/images/contract-illustrations/terms.svg'
import PlanIcon from 'assets/images/plan_signature_yellow.svg'
import SignatureImage from 'assets/images/signature-image.svg'
import PageHeader from 'components/pageHeader'
import SignatureCard from 'components/signatureCard'
import Button from 'components/ui/button'
import {useState} from 'hooks/useState'
import {requestContract} from 'store/modules/signature/action'
import {customerSuccessTableRequest} from 'store/modules/customer/action'

import Modal from './partials/modal'
import ModalIframe from './partials/modal-iframe'
import {
  StyledPlanContent,
  PlanGroup,
  PlanGroupTitle,
  PlanGroupDescription,
  DivCard,
  StyledButtonGroup,
  DivAurora,
  DivCheckImg,
  StyledCheckImg,
  DivParagraph,
  FirstParagraph,
  SecondParagraph,
  DivAuroraImg,
  AuroraImg,
  StyleButtonContainer,
} from './styles'
// import history from 'services/history'

function Signature() {
  const dispatch = useDispatch()
  const [unifiedContract, setUnifiedContract] = React.useState()
  const {formatMessage} = useIntl()
  const _pageTitle_ = formatMessage({id: 'plans.header.title'})
  const _pageDescription_ = formatMessage({id: 'plans.header.description'})
  const _signatureTitle_ = formatMessage({id: 'signature.content.title'})
  const _signatureDescription_ = formatMessage({id: 'signature.content.description'})
  const _back_ = formatMessage({id: 'common.button.back'})
  // const _foward_ = formatMessage({id: 'signature.check.next'})
  const _firstParagraph_ = formatMessage({id: 'signature.firstParagraph.description'})
  const _secondParagraph_ = formatMessage({id: 'signature.secondParagraph.description'})
  const _signedParagraph_ = formatMessage({id: 'signature.signedTrue.description'})

  const profile = useSelector(state => state.profile.profileValues)
  const signature = useSelector(state => state.signature?.payload)
  const contracts = useSelector(state => state.signature?.url)

  const {state, setState} = useState({
    isSignedContractOfAdhesion: false,
    isSignedContractPowerOfAttorney: false,
    checkTerms: false,
    openModal: false,
    disableSignature: false,
    forward: false,
    openModalIframe: false,
    urlModal: '',
    termsUrl: '',
    termsId: '',
    procurationUrl: '',
    procurationId: '',
    termsUseId: '',
    modalContractId: '',
    modalContractType: '',
    modalContractProcuration: false,
    modalContractAdhesion: false,
    // signedTrue: false,
    step: 0,
  })

  useEffect(() => {
    if (state.isSignedContractOfAdhesion) setState({step: 1})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isSignedContractOfAdhesion])

  useEffect(() => {
    if (state.isSignedContractPowerOfAttorney) setState({step: 2})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isSignedContractPowerOfAttorney])

  useEffect(() => {
    const updated = {}

    const checkObj = name => signature.hasOwnProperty(name)

    if (checkObj('modal')) updated.openModal = signature.modal
    if (checkObj('check')) updated.disableSignature = !signature.check
    if (checkObj('modalClicksignEmbead')) updated.openModalIframe = signature.modalClicksignEmbead
    if (checkObj('signedAdhesion')) updated.isSignedContractOfAdhesion = signature.signedAdhesion
    if (checkObj('signedProcuration')) updated.isSignedContractPowerOfAttorney = signature.signedProcuration

    setState(updated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature])

  useEffect(() => {
    dispatch(requestContract())
  }, [dispatch])

  useEffect(() => {
    if (contracts.length) {
      const _unifiedContract = contracts.find(item => item.type === 'CONTRACT_UNIFIED')

      if (_unifiedContract?.id) {
        setUnifiedContract({..._unifiedContract, url: _unifiedContract.url.split('n/')[1]})
      } else {
        const contractOfAdhesion = contracts.find(item => item.name === 'Termo de Adesão')
        const contractPowerOfAttorney = contracts.find(item => item.name === 'Procuração')
        const termsOfUse = contracts.find(item => item.type === 'TERM_OF_USE')

        const primaryUrl = contractOfAdhesion?.url?.split('n/')
        const secondUrl = contractPowerOfAttorney?.url?.split('n/')
        const termsUse = termsOfUse.id

        let step = 0
        if (contractOfAdhesion?.is_client_signed) step = 1
        if (contractPowerOfAttorney?.is_client_signed) step = 2

        const updatedState = {
          termsId: contracts[0].id,
          procurationId: contracts[1].id,
          termsUrl: primaryUrl[1],
          procurationUrl: secondUrl[1],
          termsUseId: termsUse,
          step,
          checkTerms: termsOfUse.is_client_signed,
        }

        if (typeof contractOfAdhesion?.is_client_signed === 'boolean') {
          updatedState.isSignedContractOfAdhesion = contractOfAdhesion.is_client_signed
        }
        if (typeof contractPowerOfAttorney?.is_client_signed === 'boolean') {
          updatedState.isSignedContractPowerOfAttorney = contractPowerOfAttorney.is_client_signed
        }
        setState(updatedState)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts, state.isSignedContractOfAdhesion, state.isSignedContractPowerOfAttorney])

  const handleClickTerms = () => {
    if (!!unifiedContract?.id) {
      setState({
        openModalIframe: true,
        urlModal: unifiedContract.url,
        modalContractId: unifiedContract.id,
        modalContractType: 'T',
        modalContractAdhesion: false,
        modalContractProcuration: false,
      })
    } else {
      setState({
        openModalIframe: true,
        urlModal: state.termsUrl,
        modalContractId: state.termsId,
        modalContractType: 'T',
        modalContractAdhesion: state.isSignedContractOfAdhesion,
        modalContractProcuration: state.isSignedContractPowerOfAttorney,
      })
    }
  }

  const handleClickProcuration = () => {
    setState({
      openModalIframe: true,
      urlModal: state.procurationUrl,
      modalContractId: state.procurationId,
      modalContractType: 'P',
      modalContractProcuration: state.isSignedContractPowerOfAttorney,
      modalContractAdhesion: state.isSignedContractOfAdhesion,
    })
  }

  const handleFollowProject = () => {
    if (profile && isEmpty(profile.customer_success)) dispatch(customerSuccessTableRequest(profile.id))
    history.push('/pt/project-status')
  }

  const handleFoward = () => setState({foward: true})

  const handleCloseModal = () => setState({openModalIframe: false})

  const someOptions = () => {
    if (state.checkTerms && state.isSignedContractOfAdhesion && state.isSignedContractPowerOfAttorney) {
      return (
        <DivAurora>
          <DivCheckImg>
            <StyledCheckImg src={CheckImg} />
          </DivCheckImg>
          <DivParagraph>
            <FirstParagraph>{_signedParagraph_}</FirstParagraph>
            <StyleButtonContainer>
              <Button onClick={handleFollowProject}>Acompanhar meu projeto</Button>
            </StyleButtonContainer>
          </DivParagraph>
        </DivAurora>
      )
    }

    if (state.foward) {
      return (
        <DivAurora>
          <DivCheckImg>
            <StyledCheckImg src={CheckImg} />
          </DivCheckImg>
          <DivParagraph>
            <FirstParagraph>{_firstParagraph_}</FirstParagraph>
            <SecondParagraph>{_secondParagraph_}</SecondParagraph>
          </DivParagraph>
          <DivAuroraImg>
            <AuroraImg src={Aurora} alt="Aurora" />
          </DivAuroraImg>
        </DivAurora>
      )
    }
    return (
      <>
        <PlanGroup>
          <PlanGroupTitle>
            <img src={SignatureImage} alt={_signatureTitle_} />
            {_signatureTitle_}
          </PlanGroupTitle>
          <PlanGroupDescription>{_signatureDescription_}</PlanGroupDescription>
        </PlanGroup>
        <DivCard>
          {unifiedContract ? (
            <SignatureCard
              disabled={state.step !== 0}
              title="Contratos"
              image={Contract}
              onClick={handleClickTerms}
              isSigned={unifiedContract?.is_client_signed}
            />
          ) : (
            <>
              <SignatureCard
                disabled={state.step !== 0}
                title="Termo de Adesão"
                image={Contract}
                onClick={handleClickTerms}
                isSigned={state.isSignedContractOfAdhesion}
              />
              <SignatureCard
                disabled={state.step !== 1}
                title="Procuração Técnica"
                image={Procuracao}
                onClick={handleClickProcuration}
                isSigned={state.isSignedContractPowerOfAttorney}
              />
            </>
          )}
        </DivCard>
        {/* <DivCheckBox>
          <Checkbox
            title={_descriptionCheck_}
            checked={state.checkTerms}
            disabled={!state.isSignedContractOfAdhesion || !state.isSignedContractPowerOfAttorney}
            id="checkbox-terms"
            onChange={e => {
              const {checked} = e.target
              setState({checkTerms: checked, openModal: checked})
            }}
          />
        </DivCheckBox> */}

        <StyledButtonGroup>
          <Button startIcon={<BsArrowLeft size={24} />}>{_back_}</Button>
          <Button
            onClick={handleFoward}
            disabled={
              (!state.isSignedContractOfAdhesion ||
                !state.isSignedContractPowerOfAttorney ||
                !state.checkTerms) &&
              !unifiedContract?.is_client_signed
            }
            endIcon={<BsArrowRight size={24} />}
          >
            Acompanhe sua instalação
          </Button>
        </StyledButtonGroup>
      </>
    )
  }

  return (
    <>
      <Modal open={state.openModal} contractId={state.termsUseId} />
      <ModalIframe
        isUnified={!!unifiedContract?.id}
        onClose={handleCloseModal}
        open={state.openModalIframe}
        url={state.urlModal}
        contractId={state.modalContractId}
        contractType={state.modalContractType}
        contractAdhesion={state.modalContractAdhesion}
        contractProcuration={state.modalContractProcuration}
      />

      <StyledPlanContent>
        <PageHeader title={_pageTitle_} description={_pageDescription_} icon={PlanIcon} />
        {someOptions()}
      </StyledPlanContent>
    </>
  )
}

export default Signature

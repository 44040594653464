import React, {useState, useEffect} from 'react'
import DayPicker from 'react-day-picker'
import {FiCalendar, FiClock, FiMapPin} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'

import theme from 'assets/styles/theme'
import Button from 'components/ui/button'
import {Input} from 'components/ui/Fields'
import {FormikProvider, useFormik} from 'formik'
import PropTypes from 'prop-types'
import {updateScheduleFormData} from 'store/modules/schedule/action'
import 'react-day-picker/lib/style.css'
import * as Yup from 'yup'

import {
  StyledTimerContainer,
  StyledDateTimeContainer,
  CalendarContainer,
  CalendarIconContainer,
  StyledTime,
  DualFieldInput,
  ButtonContainer,
  // WarningImage, COVID AURORA
} from './styles'

// import AuroraScheduleImpartial from 'assets/images/aurora/schedule-impartial.svg'
// import WarningIcon from 'assets/images/icon_warning.svg'                 USED FOR AURORA WARNING DURING COVID
// import {AuroraContainer, AuroraImage, Bar, StatusText, StatusTextLong, StatusSeparator} from './styles'

import {getNextDay} from 'utils/date'
import api from 'services/api'

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]
const WEEKDAYS_LONG = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado']
const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Quar', 'Qui', 'Sex', 'Sa']

const HOURS = ['09:00', '10:00', '11:00', '12:00', '14:00', '15:00', '16:00', '17:00']

// Form validation Schema
const validationSchema = Yup.object().shape({
  phone: Yup.string().required('Campo obrigatório').nullable(),
  cep: Yup.string().required('Campo obrigatório').nullable(),
  address: Yup.string().required('Campo obrigatório').nullable(),
  neighborhood: Yup.string().required('Campo obrigatório').nullable(),
  number: Yup.string().required('Campo obrigatório').nullable(),
  complement: Yup.string().nullable(),
  city: Yup.string().required('Campo obrigatório').nullable(),
  state: Yup.string().required('Campo obrigatório').nullable(),
})

function ScheduleCalendar({onSubmit}) {
  const dispatch = useDispatch()
  const [value, setValue] = useState(null)
  const [hour, setHour] = useState(null)
  const [disabledAddress, setDisabledAddress] = useState(false)
  const [disabledNeighborhood, setDisabledNeighborhood] = useState(false)
  const [disabledComplement, setDisabledComplement] = useState(false)
  const [disabledCity, setDisabledCity] = useState(false)
  const [disabledState, setDisabledState] = useState(false)
  const [disabledDays, setDisabledDays] = useState([])

  const billOwner = useSelector(state => state.bill.billOwner)

  const userPhone = useSelector(state => state.user.user.phone)
  const user = useSelector(state => state.user.user)
  const scheduleFormData = useSelector(state => state.schedule.scheduleFormData)

  const handleDate = (date, {disabled}) => {
    if (disabled) return 0
    setValue(date)
    return 1
  }

  const handleSubmit = form => {
    if (!value || !hour) return toast.error('Por favor selecione um dia e horario.')

    const formatedValue = new Date(value)
    formatedValue.setHours(hour.substring(0, 2))
    formatedValue.setMinutes(0)

    form.schedule = formatedValue

    onSubmit(form)
    return 1
  }

  const formikbag = useFormik({
    initialValues: {
      phone: userPhone?.substr(2),
      cep: billOwner?.postal_code || scheduleFormData.cep,
      address: billOwner?.address || scheduleFormData.address,
      neighborhood: scheduleFormData.neighborhood,
      number: billOwner?.address_number || scheduleFormData.number,
      complement: billOwner?.address_complement || scheduleFormData.complement,
      city: user?.city?.name || scheduleFormData.city,
      state: user?.state || scheduleFormData.state,
      schedule: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    if (billOwner.postal_code) formikbag.setFieldValue('cep', billOwner.postal_code)
    if (billOwner.address) formikbag.setFieldValue('address', billOwner.address)
    if (billOwner.address_neighborhood)
      formikbag.setFieldValue('neighborhood', billOwner.address_neighborhood)
    if (billOwner.address_complement) formikbag.setFieldValue('complement', billOwner.address_complement)
    if (billOwner.city) formikbag.setFieldValue('city', billOwner.city)
    if (billOwner.address_number) formikbag.setFieldValue('number', billOwner.address_number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billOwner])

  const setFieldValue = (fieldName, value) => {
    formikbag.setFieldValue(fieldName, value)
    dispatch(updateScheduleFormData({[fieldName]: value}))
  }

  const handleCepChange = async event => {
    const cep = event.target.value.replace(/\D/g, '')
    dispatch(updateScheduleFormData({...scheduleFormData, cep}))

    formikbag.setFieldValue('cep', cep)

    if (cep.length >= 8) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => res.json())
        .then(data => {
          setFieldValue('address', data.logradouro || '')
          setFieldValue('neighborhood', data.bairro || '')
          setFieldValue('complement', data.complemento || '')
          setFieldValue('city', data.localidade || '')
          setFieldValue('state', data.uf || '')

          if (data.logradouro) setDisabledAddress(true)
          else setDisabledAddress(false)

          if (data.bairro) setDisabledNeighborhood(true)
          else setDisabledNeighborhood(false)

          if (data.complemento) setDisabledComplement(true)
          else setDisabledComplement(false)

          if (data.localidade) setDisabledCity(true)
          else setDisabledCity(false)

          if (data.uf) setDisabledState(true)
          else setDisabledState(false)

          if (data.erro) {
            toast.error(
              'Não encontramos o CEP desejado. Por favor verifique os dados informados e tente novamente.',
              {
                autoClose: 10000,
              }
            )
          }
        })
        .catch(() => {
          toast.error(
            'Algo de errado, não conseguimos consultar o CEP desejado. Por favor tente novamente.',
            {
              autoClose: 10000,
            }
          )
        })
    }
  }

  const birthdayStyle = `.DayPicker-Day--selected {
    background-color: ${theme('colors.solar_21.regular')} !important;
    color: black !important;
    font-weight: bold;
  }

  .DayPicker-Month {
    margin: 0 !important
  }
  `

  const disabledStyle = `.DayPicker-Day--disabled {
    color: #ffaeb3 !important;
    font-weight: bold;
  }`

  const padding = `.DayPicker-Day {
    padding: 1.3rem !important;
    color: ${theme('colors.solar_21.gray')};
    font-weight: bold;
  }`

  const query = `@media (max-width: 768px) {
    .DayPicker-Day {
    padding: 0.875rem !important;
    }
  }`

  const days = `.DayPicker-Weekday abbr[title] {
    font-weight: bold;
    color: ${theme('colors.solar_21.regular')};
  }`

  const getApiDisabledDays = async () => {
    try {
      const {data} = await api.get('/portal/config-calendar')
      return data.data
    } catch (error) {
      return []
    }
  }

  const getDisabledDays = async () => {
    const disableds = [...disabledDays]

    const defaultDisable = [new Date(), {before: new Date()}, {daysOfWeek: [0]}]

    /** From API */
    const apiRequest = await getApiDisabledDays()
    if (apiRequest.length) apiRequest.forEach(item => disableds.push(new Date(item)))
    /** /From API */

    // 0 domingo, 1 segunda, 2 terça, 3 quarta, 4 quinta, 5 sexta, 6 sabado

    if (new Date().getHours() >= 12) {
      if (new Date().getDay() === 5) {
        ;[6, 0, 1].forEach(item => disableds.push(getNextDay(item)))
      } else if (new Date().getDay() === 6) {
        ;[0, 1].forEach(item => disableds.push(getNextDay(item)))
      } else {
        disableds.push(getNextDay(new Date().getDay() + 1))
      }
    } else {
      if (new Date().getDay() === 6 || new Date().getDay() === 0) {
        ;[1].forEach(item => disableds.push(getNextDay(item)))
      }
    }

    let _date_ = new Date()

    function addDays(date, days) {
      var result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    }

    for (let idx = 31; idx < 365 * 20; idx++) {
      disableds.push(addDays(_date_, idx))
    }

    // caution... clearArrDuplicatedItems() remove objects from array...
    setDisabledDays([...clearArrDuplicatedItems(disableds), ...defaultDisable])
  }

  const clearArrDuplicatedItems = arr => {
    const seen = {}
    const ret_arr = []
    for (let i = 0; i < arr.length; i++) {
      if (!(arr[i] in seen)) {
        ret_arr.push(arr[i])
        seen[arr[i]] = true
      }
    }
    return ret_arr
  }

  React.useEffect(() => {
    getDisabledDays()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <StyledDateTimeContainer>
        <CalendarContainer>
          <CalendarIconContainer>
            <FiCalendar size="30px" color={theme('colors.solar_21.regular')} style={{marginRight: '5px'}} />
            Data
          </CalendarIconContainer>
          <style>
            {birthdayStyle}
            {padding}
            {days}
            {query}
            {disabledStyle}
          </style>
          <DayPicker
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            selectedDays={value}
            onDayClick={handleDate}
            disabledDays={disabledDays}
          />
        </CalendarContainer>

        <CalendarContainer>
          <CalendarIconContainer>
            <FiClock size="30px" color={theme('colors.solar_21.regular')} style={{marginRight: '5px'}} />
            Horario
          </CalendarIconContainer>
          <StyledTimerContainer>
            {HOURS.map(h => (
              <StyledTime key={h} onClick={() => setHour(h)} selected={h === hour}>
                {h}
              </StyledTime>
            ))}
          </StyledTimerContainer>
        </CalendarContainer>
        <CalendarContainer style={{width: '100%'}}>
          <CalendarIconContainer>
            <FiMapPin size="30px" color={theme('colors.solar_21.regular')} style={{marginRight: '5px'}} />
            Endereço
          </CalendarIconContainer>
          <FormikProvider value={formikbag}>
            <DualFieldInput>
              <Input
                label="Telefone"
                name="phone"
                maskNumber={[
                  '(',
                  /[1-9]/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />

              <Input
                label="CEP"
                name="cep"
                maskNumber={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                onChange={e => handleCepChange(e)}
              />
            </DualFieldInput>
            <DualFieldInput>
              <Input
                label="Endereço"
                name="address"
                disabled={!!disabledAddress}
                onChange={event => setFieldValue('address', event.target.value)}
              />
              <Input
                label="Bairro"
                name="neighborhood"
                disabled={!!disabledNeighborhood}
                onChange={event => setFieldValue('neighborhood', event.target.value)}
              />
            </DualFieldInput>
            <DualFieldInput>
              <Input
                label="Número"
                name="number"
                onChange={event => setFieldValue('number', event.target.value)}
              />
              <Input
                label="Complemento"
                name="complement"
                disabled={!!disabledComplement}
                onChange={event => setFieldValue('complement', event.target.value)}
              />
            </DualFieldInput>
            <DualFieldInput>
              <Input
                label="Cidade"
                name="city"
                disabled={!!disabledCity}
                onChange={event => setFieldValue('city', event.target.value)}
              />
              <Input
                label="Estado"
                name="state"
                disabled={!!disabledState}
                onChange={event => setFieldValue('state', event.target.value)}
              />
            </DualFieldInput>
          </FormikProvider>
        </CalendarContainer>

        <div />
      </StyledDateTimeContainer>
      {/* <AuroraContainer>
        <AuroraImage src={AuroraScheduleImpartial} alt="aurora" />
        <WarningImage src={WarningIcon} />
        <Bar color="#FFE2CF">
          <StatusText color="#FF7600" fontWeight="bold" marginRight="5px">
            Atenção<StatusSeparator> | </StatusSeparator>
          </StatusText>
          <StatusTextLong>
            Devido ao surto da nova variante da COVID-19 e da Influenza, as{' '}
            <strong>visitas técnicas acontecerão EXCLUSIVAMENTE por videochamada</strong>. Dessa forma,
            protegemos você e os nossos técnicos.
          </StatusTextLong>
        </Bar>
      </AuroraContainer> */}
      <ButtonContainer>
        <Button type="submit" onClick={formikbag.handleSubmit}>
          Agende sem custo
        </Button>
      </ButtonContainer>
    </div>
  )
}

ScheduleCalendar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ScheduleCalendar

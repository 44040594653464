import styled from 'styled-components'

import theme from 'assets/styles/theme'

export const PlanContent = styled.div`
  margin: 4rem;

  @media only screen and (max-width: 768px) {
    margin: 1rem;
    margin-bottom: 6rem;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const PlanGroup = styled.div`
  margin-top: 2rem;
`

export const PlanGroupTitle = styled.span`
  display: flex;
  align-items: center;
  font-size: 22px;
  color: ${theme('colors.solar_21.gray')};
  font-weight: 500;
  margin-bottom: 0.5rem;

  img {
    margin-right: 0.6rem;
  }
`

export const PlanGroupDescription = styled.span`
  color: ${theme('colors.solar_21.gray')};
  font-weight: 200;
  font-size: 22px;
`

export const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 60px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 0rem;
    margin-bottom: 0px;
  }

  @media (min-width: 769px) and (max-width: 1400px) {
  }
`

import React from 'react'
import {useSelector} from 'react-redux'
import {Route, Redirect} from 'react-router-dom'

import PropTypes from 'prop-types'
import history from 'services/history'

import LayoutAuth from '../pages/_layouts/LayoutAuth'
import LayoutDefault from '../pages/_layouts/LayoutDefault'
import {getCurrentStep} from '../utils/getCurrentStep'
import {getAllowedRoutesBySteps} from './utils/getAllowedRoutesBySteps'
import {isEmpty} from 'lodash'

const RouteWrapper = ({component: Component, isPrivate, defaultLanguage, ...rest}) => {
  const signed = useSelector(state => state.auth.signed)
  const steps = useSelector(state => state.menu.menu)
  const profile = useSelector(state => state.profile.profileValues)

  if (localStorage.getItem('showThankYou') === '1' && signed && isPrivate) {
    return <Redirect to={`/${defaultLanguage}/thank-you`} />
  }
  if (!signed && isPrivate) {
    return <Redirect to={`/${defaultLanguage}`} />
  }
  if (signed && !isPrivate && !localStorage.getItem('showThankYou')) {
    return <Redirect to={`/${defaultLanguage}/bill`} />
  }

  if (steps?.length && rest.path !== '/:lang') {
    let newStepsArray = []
    if (profile.customer_success_id)
      newStepsArray = steps.concat([{client_id: profile.id, type: 'PROJECT_STATUS', status: 'APPROVED'}])
    const currentStep = getCurrentStep(isEmpty(newStepsArray) ? steps : newStepsArray)
    const allowedRoutes = getAllowedRoutesBySteps(currentStep)
    if (!allowedRoutes.includes(rest.path)) history.goBack()
  }

  let Layout

  if (localStorage.getItem('showThankYou') === '1') {
    Layout = LayoutAuth
  } else {
    Layout = signed ? LayoutDefault : LayoutAuth
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  defaultLanguage: PropTypes.string.isRequired,
}

RouteWrapper.defaultProps = {
  isPrivate: false,
}

export default RouteWrapper

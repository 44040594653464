import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import Text from './Text'

export const NotificatitonStructure = styled.div`
  cursor: pointer;
  margin: 0px 0px 0px -70px;
  width: 428px;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  display: flex;
  position: relative;
  background-color: white;
  margin-top: 5px;
  z-index: 9;

  @media only screen and (max-width: 768px) {
    margin: 0px 0px 0px -7px;
    width: 343px;
    margin-top: 4px;
  }
`
export const NotificatitonImg = styled.div`
  width: 28%;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NotificatitonText = styled.div`
  width: 72%;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
`
export const ImgNotification = styled.img`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CloseNotification = styled.div`
  height: 20px;
  background-color: transparent;
  position: absolute;
  width: 355px;
  margin: 8px 0px 0px 63px;

  @media only screen and (max-width: 768px) {
    width: 273px;
    margin: 7px 0px 0px 62px;
  }
`
export const CloseNotificationIcon = styled.img`
  width: 10px;
  height: 10px;
  float: right;
  cursor: pointer;
`

const Notification = ({ textRequest, image, notificationId }) => {
  return (
    <NotificatitonStructure>
      <NotificatitonImg id={notificationId}>
        <ImgNotification src={image} />
      </NotificatitonImg>
      <NotificatitonText id={notificationId}>
        <Text size="15px" font="Light 18px/24px Roboto" color="#010202">
          {textRequest}
        </Text>
      </NotificatitonText>
    </NotificatitonStructure>
  )
}

Notification.defaultProps = {
  textRequest: '',
  image: null,
  notificationId: null,
}

Notification.propTypes = {
  textRequest: PropTypes.string,
  image: PropTypes.string,
  notificationId: PropTypes.string,
}

export default Notification

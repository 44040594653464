import React from 'react'
import {FormattedMessage} from 'react-intl'
import {toast} from 'react-toastify'

import {takeLatest, all, put, call} from 'redux-saga/effects'

import {
  b2cSimulationFailure,
  b2cSimulationSuccess,
  b2cConfirmationSuccess,
  b2cConfirmationFailure,
  b2cPlanSuccess,
  b2cPlanFailure,
  b2cPlanCheckoutFailure,
  b2cAllPlansSuccess,
  b2cAllPlansFailure,
  b2cVerifyCheckoutRequestFailure,
  b2cVerifyCheckoutRequestSuccess,
  b2cVerifyArchiveRequestSuccess,
  b2cVerifyArchiveRequestFailure,
  createPlanSimulatedSuccess,
  createPlanSimulatedFailure,
  getPlanSimulatedSuccess,
  getPlanSimulatedFailure,
  setIsBookingPayment,
  getFreeMonthSuccess,
  getFreeMonthFailure,
} from 'store/modules/plan/action'

import api from '../../../services/api'
import history from '../../../services/history'
import type from '../../types'

export function* simulationB2CRequest({payload}) {
  const {value} = payload
  try {
    const response = yield call(api.post, 'portal/plans/simulator', {bill_value: value})
    const data = response.data
    yield put(b2cSimulationSuccess(data))
  } catch (error) {
    yield put(b2cSimulationFailure())
  }
}

export function* confirmationB2CRequest() {
  try {
    const response = yield call(api.get, 'portal/plans/')
    const data = response.data
    yield put(b2cConfirmationSuccess(data))
  } catch (error) {
    yield put(b2cConfirmationFailure())
  }
}

export function* requestAllPlans() {
  try {
    const response = yield call(api.get, `portal/customers/plans/`)
    const data = response.data

    yield put(b2cAllPlansSuccess(data))
  } catch (error) {
    yield put(b2cAllPlansFailure(error))
  }
}

export function* requestPlanSelected({payload}) {
  const {idPlan} = payload
  try {
    const response = yield call(api.get, `portal/customers/plans/${idPlan}`)
    const data = response.data
    yield put(b2cPlanSuccess(data))
    yield put(setIsBookingPayment(true))
  } catch (error) {
    yield put(b2cPlanFailure(error))
    yield put(setIsBookingPayment(false))
  }
}

export function* sendCartCheckout({payload}) {
  const {card: token, planId} = payload
  try {
    yield call(api.post, `portal/customers/plans/${planId}/sign`, {token})

    toast.success(<FormattedMessage id="booking.plan.checkout.success">{msg => msg}</FormattedMessage>)
    yield put(b2cVerifyCheckoutRequestSuccess({checkoutCheck: true}))
  } catch (error) {
    toast.error(<FormattedMessage id="booking.plan.checkout.error">{msg => msg}</FormattedMessage>)
    yield put(b2cPlanCheckoutFailure(error))
  }
}

export function* verifyCheckoutB2c() {
  try {
    const response = yield call(api.get, '/portal/customers/checkout')

    const data = response.data.exists

    if (data) yield put(b2cVerifyCheckoutRequestSuccess({checkoutCheck: data}))
  } catch (error) {
    yield put(b2cVerifyCheckoutRequestFailure(error))
  }
}

export function* verifyArchiveB2c() {
  try {
    const response = yield call(api.get, '/portal/customers/documents')
    const data = response.data

    if (response.data.length !== 0)
      yield put(b2cVerifyArchiveRequestSuccess({payloadArchive: data, archiveCheck: true}))
  } catch (error) {
    yield put(b2cVerifyArchiveRequestFailure(error))
  }
}

export function* getPlanSimulated() {
  try {
    const {data: selectedPlanSimulated} = yield call(api.get, '/portal/customers/selected-plan-simulated')
    yield put(getPlanSimulatedSuccess(selectedPlanSimulated))
  } catch (err) {
    yield put(getPlanSimulatedFailure())
  }
}

export function* createPlanSimulated({payload: {planSimulated}}) {
  try {
    const {data: selectedPlanSimulated} = yield call(
      api.post,
      '/portal/customers/plan-simulated',
      planSimulated
    )

    yield put(createPlanSimulatedSuccess(selectedPlanSimulated))
    history.push(`/pt-br/bill`)
  } catch (err) {
    yield put(createPlanSimulatedFailure())
    toast.error('Não foi possível selecionar a simulação do plano.')
  }
}

export function* getFreeMonths({payload}) {
  const {id} = payload

  try {
    const {data} = yield call(api.get, `/portal/customers/${id}/plans/sales_free_month`)

    yield put(getFreeMonthSuccess(data))
  } catch (err) {
    yield put(getFreeMonthFailure())
    toast.error('Não foi possível verificar a opção de 1º mês grátis')
  }
}

export default all([
  takeLatest(type.B2C_PLAN_SIMULATION_REQUEST, simulationB2CRequest),
  takeLatest(type.B2C_PLAN_SIGNATURE_REQUEST, confirmationB2CRequest),
  takeLatest(type.B2C_PLAN_REQUEST, requestPlanSelected),
  takeLatest(type.B2C_ALL_PLAN_REQUEST, requestAllPlans),
  takeLatest(type.B2C_PLAN_CHECKOUT_REQUEST, sendCartCheckout),
  takeLatest(type.B2C_VERIFY_CHECKOUT_REQUEST, verifyCheckoutB2c),
  takeLatest(type.B2C_VERIFY_ARCHIVE_REQUEST, verifyArchiveB2c),
  takeLatest(type.GET_PLAN_SIMULATED_REQUEST, getPlanSimulated),
  takeLatest(type.CREATE_PLAN_SIMULATED_REQUEST, createPlanSimulated),
  takeLatest(type.GET_FREE_MONTH_REQUEST, getFreeMonths),
])

import styled from 'styled-components'

export const StyledImage = styled.img`
  height: 250px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const StyledflexGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemFlex = styled.div`
  margin: 15px 0;
  width: 150px;
`

export const StyledMinTitle = styled.p`
  font-size: 0.688em;
  letter-spacing: 2.37px;
  color: #1a1818;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
`

export const StyledTextPrice = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 1.625em;
  letter-spacing: 0px;
  color: #010202;
  font-weight: 300;
  vertical-align: middle;
  display: table-cell;
  & small {
    text-align: center;
    font: Light 13px/18px Roboto;
    letter-spacing: 0px;
    color: #010202;
    font-size: 0.513em;
    position: relative;
    top: -5px;
  }
`

export const StyledDivider = styled.div`
  :before {
    content: '';
    border-left: 2px solid #ffd500;
    font-size: 35px;
    margin: 0px 15px 0 0;
    position: relative;
  }
`

export const StyledButtonGroup = styled.div`
  @media (max-width: 768px) {
    justify-content: center;
    & button {
      padding: 10px 15px;
    }
  }
`

export const StyledTitle = styled.p`
  font-size: 1.875em;
  color: #000000;
  text-align: left;
  & small {
    font-size: 1.25rem;
    color: #8e8d8d;
  }
`

export const StyledInputs = styled.div`
  display: block;
  margin-top: 30px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  & input {
    width: 100%;
    margin-bottom: 15px;
    padding: 12px 16px;
    border: 1px solid #d8d8d8;
    background: #ffffff;
    font-size: 1em;
    font-family: 'Roboto';
    color: #b7b7b7;
    transition: border-color 0.2s;
    &:focus {
      border-color: #111;
    }
  }
  & label {
    display: block;
    margin-bottom: 5px;
    font-weight: 300;
    white-space: nowrap;
  }
  & ::placeholder {
    font-weight: 300;
    color: #b7b7b7;
  }
  & ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const StyledFlex = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`

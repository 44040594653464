import ReactVerificationCodeInputComponent from 'react-verification-code-input'

import styled from 'styled-components'

import theme from 'assets/styles/theme'

export const ReactVerificationCodeInput = styled(ReactVerificationCodeInputComponent)`
  width: auto !important;

  .styles_react-code-input__CRulA {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > input {
      border: 1px solid ${theme('colors.solar_21.gray_light')};
      border-radius: 0;

      margin-right: 10px;

      font-family: Roboto, sans-serif;
      font-size: 35px;
      color: #b7b7b7;

      &::placeholder {
        color: #b7b7b7;
      }

      &:focus + input {
        border: 3px solid ${theme('colors.solar_21.gray_light')};
      }

      &:focus {
        border: 3px solid ${theme('colors.solar_21.gray')};
        color: ${theme('colors.solar_21.gray')};
        caret-color: transparent;
      }
    }

    & > input:last-child {
      margin-right: 0;
    }
  }
`

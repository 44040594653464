import React from 'react'

import {useWindowSize} from 'hooks/useWindowSize'

import SolarLogo from 'assets/images/logo.svg'

import Card from './fragments/Card'
import {BigRowData, SmallRowData} from './data'
import BigRow from './fragments/BigRow'
import SmallRow from './fragments/SmallRow'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
}

const DetailsCard = ({simulationValues, isInitial, setOpenProposal}: any) => {
  const {width} = useWindowSize()

  return (
    <>
      {width && width > 750 ? (
        <>
          <Card
            header="Detalhes dos equipamentos"
            wrapper={{display: 'flex', flexDirection: 'column'}}
            sheetStyle={{
              padding: '28px 20px 26px 32px',
              flexGrow: 1,
              alignItems: 'flex-start',
            }}
            isInitial={isInitial}
            setOpenProposal={setOpenProposal}
          >
            {BigRowData(simulationValues).map((item, index) => (
              <BigRow
                // eslint-disable-next-line react/no-array-index-key
                key={`BigRow-${index}`}
                icon={item.icon}
                iconAlt={item.iconAlt}
                iconTitle={item.iconTitle}
                marca={item.marca}
                potencia={item.potencia}
                garantia={item.garantia}
                multiplier={item.multiplier}
              />
            ))}
          </Card>
          <Card
            header="Benefícios"
            icon={SolarLogo}
            wrapper={{display: 'flex', flexDirection: 'column'}}
            sheetStyle={{
              padding: '52px 20px 26px 32px',
              flexGrow: 1,
              alignItems: 'flex-start',
            }}
            style={{gap: 'auto'}}
            isInitial={isInitial}
            onClick={() => setOpenProposal(true)}
          >
            {SmallRowData().map((item, index) => (
              <SmallRow
                // eslint-disable-next-line react/no-array-index-key
                key={`SmallRow-${index}`}
                icon={item.icon}
                iconAlt={item.iconAlt}
                description={item.description}
                months={item.months}
                monthDescription={item.monthDescription}
              />
            ))}
          </Card>{' '}
        </>
      ) : (
        <div style={{maxWidth: '85vw'}}>
          <Slider {...settings}>
            <Card
              header="Detalhes dos equipamentos"
              wrapper={{display: 'flex', flexDirection: 'column'}}
              sheetStyle={{
                padding: '28px 20px 26px 32px',
                flexGrow: 1,
                alignItems: 'flex-start',
              }}
              isInitial={isInitial}
              setOpenProposal={setOpenProposal}
            >
              {BigRowData(simulationValues).map((item, index) => (
                <BigRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={`BigRow-${index}`}
                  icon={item.icon}
                  iconAlt={item.iconAlt}
                  iconTitle={item.iconTitle}
                  marca={item.marca}
                  garantia={item.garantia}
                  multiplier={item.multiplier}
                />
              ))}
            </Card>
            <Card
              header="Benefícios"
              icon={SolarLogo}
              wrapper={{display: 'flex', flexDirection: 'column'}}
              sheetStyle={{
                padding: '52px 20px 26px 32px',
                flexGrow: 1,
                alignItems: 'flex-start',
              }}
              style={{gap: 'auto'}}
              isInitial={isInitial}
              onClick={() => setOpenProposal(true)}
            >
              {SmallRowData().map((item, index) => (
                <SmallRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={`SmallRow-${index}`}
                  icon={item.icon}
                  iconAlt={item.iconAlt}
                  description={item.description}
                  months={item.months}
                  monthDescription={item.monthDescription}
                />
              ))}
            </Card>
          </Slider>
        </div>
      )}
    </>
  )
}

export default DetailsCard

import React, {useCallback, useEffect, useState} from 'react'
import {AxiosRequestConfig} from 'axios'

import api from 'services/api'

import FileInput from 'components/fileInput'
import Button from 'components/button'
import * as S from '../styles'
import { toast } from 'react-toastify'

const FilesUploads = ({
  status,
  signedContract,
  signedContractAttorney,
}: {
  status: string
  signedContract: any
  signedContractAttorney: any
}) => {
  const [proofOfAddress, setProofOfAddress] = useState<File | null>(null)
  const [hasProofOfAddress, setHasProofOfAddress] = useState<boolean>(false)
  const [proofOfIncome, setProofOfIncome] = useState<File | null>(null)
  const [hasProofOfIncome, setHasProofOfIncome] = useState<boolean>(false)
  const [documentFile, setDocumentFile] = useState<File | null>(null)
  const [hasDocument, setHasDocument] = useState<boolean>(false)

  const getDocuments = useCallback(() => {
    api
      .get('/portal/customers/documents')
      .then(({data}) => {
        data.forEach((element: any) => {
          if (element.type === 'PROOF_OF_ADDRESS') setHasProofOfAddress(true)
          if (element.type === 'PROOF_OF_INCOME') setHasProofOfIncome(true)
          if (element.type === 'DOCUMENT') setHasDocument(true)
        })
      })
      .catch(err => console.error(err))
  }, [])

  const handleSend = useCallback(async () => {
    if (proofOfAddress === null || proofOfIncome === null || documentFile === null) {
      toast.error('Insira todos os documentos necessários!')
      return
    }

    const formData = new FormData()
    formData.append('document', documentFile)
    formData.append('proof_of_income', proofOfIncome)
    formData.append('proof_of_address', proofOfAddress)

    const headerParams = {
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Content-Type': 'multipart/form-data',
    }

    await api
      .post('/portal/customers/documents/proposal', formData, headerParams as AxiosRequestConfig)
      .then(() => toast.success('Documentos enviados com sucesso!'))
      .catch(err => console.error(err))
      .finally(() => window.location.reload())
  }, [documentFile, proofOfAddress, proofOfIncome])

  useEffect(() => {
    getDocuments()
  }, [getDocuments])

  const getFileName = useCallback(
    file => {
      switch (file) {
        case 'address':
          return proofOfAddress ? proofOfAddress.name : ''
        case 'income':
          return proofOfIncome ? proofOfIncome.name : ''
        case 'document':
          return documentFile ? documentFile.name : ''
        default:
          return ''
      }
    },
    [proofOfAddress, proofOfIncome, documentFile]
  )

  return (
    <S.FileContainer>
      <S.FilesDescription>
        Envie os seus documentos para avanço do seu processo junto ao banco parceiro.
      </S.FilesDescription>
      <FileInput
        header="Comprovante de residência:"
        text="Arraste ou clique aqui para fazer upload"
        mimes={['PDF', 'JPG', 'PNG']}
        onChange={setProofOfAddress}
        hasFile={!!proofOfAddress}
        isFileSent={hasProofOfAddress}
        fileName={getFileName('address')}
      />
      <FileInput
        header="Comprovante de renda:"
        text="Arraste ou clique aqui para fazer upload"
        mimes={['PDF', 'JPG', 'PNG']}
        onChange={setProofOfIncome}
        hasFile={!!proofOfIncome}
        isFileSent={hasProofOfIncome}
        fileName={getFileName('income')}
      />
      <FileInput
        header="Documento pessoal (CNH ou RG com CPF):"
        text="Arraste ou clique aqui para fazer upload"
        mimes={['PDF', 'JPG', 'PNG']}
        onChange={setDocumentFile}
        hasFile={!!documentFile}
        isFileSent={hasDocument}
        fileName={getFileName('document')}
      />
      <S.ButtonWrapper>
        <Button
          disabled={signedContract === false || signedContractAttorney === false}
          text="Submeter documentos"
          onClick={handleSend}
        />
      </S.ButtonWrapper>
    </S.FileContainer>
  )
}

export default FilesUploads

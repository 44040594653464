import {combineReducers} from 'redux'

import auth from './auth/reducer'
import bill from './bill/reducer'
import booking from './booking-payment/reducer'
import common from './common/reducer'
import initial from './initial/reducer'
import menu from './menu/reducer'
import plan from './plan/reducer'
import profile from './profile/reducer'
import schedule from './schedule/reducer'
import signature from './signature/reducer'
import user from './user/reducer'
import customer from './customer/reducer'
import faq from './faq/reducer'

export default combineReducers({
  auth,
  bill,
  common,
  plan,
  schedule,
  menu,
  signature,
  booking,
  initial,
  profile,
  user,
  customer,
  faq,
})

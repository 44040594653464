import styled from 'styled-components'

import solar_21_large from '../../assets/images/logo.svg'
// import solar_21_black from '../../assets/images/solar_21_logo_black.svg'
import solar_21_black_small from '../../assets/images/solar21_black_small.svg'
import theme from '../../assets/styles/theme'

export const Sidebar = styled.nav`
  position: fixed;
  width: 20rem;
  height: 100%;
  background: ${theme('colors.solar_21.gray_dark')};
  z-index: 10;

  @media (min-width: 769px) and (max-width: 1400px) {
    width: 5rem;
    bottom: 0;
    height: 100%;
    z-index: 10;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    bottom: 0;
    height: 5rem;
  }
`

export const SidebarNav = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    height: 100%;
    overflow: auto;
  }
`

export const SidebarNavItem = styled.li`
  width: 100%;

  &:last-child {
    margin-top: auto;

    svg {
      color: ${theme('colors.solar_21.regular')};
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
  }
`

export const SidebarNavLink = styled.div`
  display: flex;
  align-items: center;
  height: 5rem;
  cursor: pointer;

  svg {
    min-width: 2rem;
    min-height: 2rem;
    margin: 0 0.5rem 0 1.5rem;
    color: ${theme('colors.white')};
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
    img.ic_nav {
      margin: 0 10px;
    }
    span {
      display: none;
    }
  }

  @media (min-width: 769px) and (max-width: 1400px) {
    justify-content: center;
    padding-right: 20px;
    span {
      display: none;
    }
  }
`

export const SidebarNavLinkFAQ = styled.div`
  display: none;
  align-items: center;
  height: 5rem;
  cursor: pointer;

  svg {
    min-width: 2rem;
    min-height: 2rem;
    margin: 0 0.5rem 0 1.5rem;
    color: ${theme('colors.white')};
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    img.ic_nav {
      margin: 0 10px;
    }
    span {
      display: none;
    }
  }

  @media (min-width: 769px) and (max-width: 1400px) {
    justify-content: center;
    padding-right: 20px;
    span {
      display: none;
    }
  }
`

export const SidebarLogoContainer = styled.li`
  width: 100%;
  height: 8rem;
  min-height: 8rem;
  background: ${theme('colors.solar_21.regular')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 769px) and (max-width: 1400px) {
    height: 4.75rem;
    min-height: 4.7rem;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const SidebarLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 75px;
`

export const SidebarNavIcon = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;

  background-image: url(${solar_21_large});

  @media (min-width: 769px) and (max-width: 1400px) {
    background-image: url(${solar_21_black_small});
    background-size: 60%;
  }
`

export const AccountTypeText = styled.span`
  font-size: 13px;
  letter-spacing: 5px;
  color: ${theme('colors.solar_21.gray_dark')};

  @media (min-width: 769px) and (max-width: 1400px) {
    display: none;
  }
`

export const StyledIcon = styled.img`
  max-width: 2rem;
  min-height: 2rem;
  margin: 0 0.5rem 0 1.5rem;
`

export const StyledIconFAQ = styled.img`
  max-width: 2.4rem;
  min-height: 2rem;
  margin: 0 0.5rem 0 1.5rem;
`
export const IconsCheck = styled.img`
  position: absolute;
  width: 25px;
  height: 25px;
  left: 305px;
  z-index: 9999;

  @media (min-width: 769px) and (max-width: 1400px) {
    left: 65px;
  }

  @media only screen and (max-width: 768px) {
    left: unset;
    position: absolute;
    bottom: 66px;
  }
`

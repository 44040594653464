import type from '../../types'

export function sendBillRequest(file, holder) {
  return {
    type: type.BILL_ACCOUNT_SEND_REQUEST,
    payload: {file, holder},
  }
}

export function sendBillSuccess(data) {
  return {
    type: type.BILL_ACCOUNT_SEND_SUCCESS,
    payload: {data},
  }
}

export function sendBillFailure(value) {
  return {
    type: type.BILL_ACCOUNT_SEND_FAILURE,
    payload: {value},
  }
}

export function receiveBillRequest() {
  return {
    type: type.BILL_ACCOUNT_RECEIVE_REQUEST,
  }
}

export function receiveBillSuccess(data) {
  return {
    type: type.BILL_ACCOUNT_RECEIVE_SUCCESS,
    payload: {data},
  }
}

export function receiveBillFailure(value) {
  return {
    type: type.BILL_ACCOUNT_RECEIVE_FAILURE,
    payload: {value},
  }
}

export function getBillRequest() {
  return {
    type: type.BILL_OWNER_REQUEST,
  }
}

export function getBillSuccess(data) {
  return {
    type: type.BILL_OWNER_SUCCESS,
    payload: {data},
  }
}

export function getBillFailure(data) {
  return {
    type: type.BILL_OWNER_FAILURE,
    payload: {data},
  }
}

export function getBillInfoRequest(id) {
  return {
    type: type.BILL_INFO_REQUEST,
    payload: {id},
  }
}

export function getBillInfoSuccess(data) {
  return {
    type: type.BILL_INFO_SUCCESS,
    payload: {data},
  }
}

export function getBillInfoFailure() {
  return {
    type: type.BILL_INFO_FAILURE,
  }
}
